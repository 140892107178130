import React, { useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonIcon,
} from "@ionic/react";
import { setOnBoardingSkippedStatus } from "../../services/redux/actions";
import { chevronBack, chevronForwardOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.css";
import OnBoarding_1 from "../../images/OnBoarding_1.svg";
import OnBoarding_2 from "../../images/OnBoarding_2.svg";
import OnBoarding_3 from "../../images/OnBoarding_3.svg";

const OnBoarding: React.FC = () => {
  const dispatch = useDispatch();

  const setOnBoardingSkippedStatusFunction = (returnedData: Boolean) =>
    dispatch(setOnBoardingSkippedStatus(returnedData));

  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<any>(null);
  const history = useHistory();

  const getIndex = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    {
      setSlideIndex(index);
    }
  };
  const styles = {
    container: {
      "--progress":
        slideIndex == 0
          ? "120deg"
          : slideIndex == 1
            ? "210deg"
            : slideIndex == 2
              ? "360deg"
              : "0deg",
    } as React.CSSProperties,
  };
  const skipFunction = () => {
    setOnBoardingSkippedStatusFunction(true);
    history.push("/app/signIn");
  };
  const nextSlideFunction = async () => {
    const swiper = sliderRef.current?.getSwiper();
    slideIndex < 2
      ? swiper?.then((e: any) => {
        e.slideNext();
      })
      : skipFunction();
  };

  return (
    <IonContent className="max-h-screen">
      <IonPage className="max-h-screen">
        <div className="w-full grid grid-cols-3 z-50">
          <div className="col-span-1 py-6 px-8 flex flex-row justify-start items-center">
            {slideIndex > 0 ? (
              <button
                onClick={() => {
                  history.push("/app/signIn");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronForwardOutline}
                  className="text-2xl text-primaryColor100"
                />
              </button>
            ) : null}
          </div>
          <div></div>
          <div className="col-span-1 py-6 flex flex-row justify-center items-center">
            <p
              onClick={skipFunction}
              className="font-bold text-primaryColor100 text-xl"
            >
              تخطي
            </p>
          </div>
        </div>
        <IonSlides
          ref={sliderRef}
          onIonSlideDidChange={getIndex}
          className="h-full w-full"
        >
          <IonSlide className="h-full w-full flex flex-col justify-start items-center pt-28 px-4">
            <div className="h-36">
              <img src={OnBoarding_1} />
            </div>
            <p className="mt-12 font-bold text-3xl text-primaryColor100">
              سرعة ودقة في الوصول
            </p>
            <p className="mt-4 font-bold text-xl text-black">
              تمكنك تقنية ذكي في الوصول للمعلومات التي ترغب في الحصول عليها
              بحرفية وسرعة!
            </p>
          </IonSlide>
          <IonSlide className="h-full w-full flex flex-col justify-start items-center pt-28 px-4">
            <div className="h-36">
              <img className="h-36" src={OnBoarding_2} />
            </div>
            <p className="mt-12 font-bold text-3xl text-primaryColor100">
              سرعة ودقة في الوصول
            </p>
            <p className="mt-4 font-bold text-xl text-black">
              تمكنك تقنية ذكي في الوصول للمعلومات التي ترغب في الحصول عليها
              بحرفية وسرعة!
            </p>
          </IonSlide>
          <IonSlide className="h-full w-full flex flex-col justify-start items-center pt-28 px-4">
            <div className="h-36">
              <img className="h-36" src={OnBoarding_3} />
            </div>
            <p className="mt-12 font-bold text-3xl text-primaryColor100">
              سرعة ودقة في الوصول
            </p>
            <p className="mt-4 font-bold text-xl text-black">
              تمكنك تقنية ذكي في الوصول للمعلومات التي ترغب في الحصول عليها
              بحرفية وسرعة!
            </p>
          </IonSlide>
        </IonSlides>
        <div className="flex flex-row justify-center items-center z-50 py-12">
          <div id="div" className="p-1" style={styles.container}>
            <button
              onClick={nextSlideFunction}
              className="flex flex-col justify-center items-center w-full h-full rounded-full bg-primaryColor100 border-2 border-solid border-white"
            >
              <IonIcon icon={chevronBack} className="text-3xl text-white" />
            </button>
          </div>
        </div>
      </IonPage>
    </IonContent>
  );
};

export default OnBoarding;
