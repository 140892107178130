import { IonPage } from "@ionic/react";
import Button from "../../components/atoms/button";

const SignOutModel = ({
  SignOutModelDismiss,
}: {
  SignOutModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonPage>
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
        <p className="font-bold text-primaryColor100 text-2xl">
          هل تريد تسجيل الخروج ؟
        </p>

        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
          <Button
            theme={"danger"}
            text={"الخروج"}
            size={"big"}
            onClick={() => {
              window.localStorage.removeItem("persistantState");
              window.location.assign(`/app/signIn`);
            }}
          />
          <Button
            theme={"primary"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              SignOutModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default SignOutModel;
