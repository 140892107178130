import { useState, useRef } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  useIonToast,
  IonList,
  IonLabel,
  IonItem,
  IonInput,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import Button from "../../../components/atoms/button";
import Spinner from "../../../components/atoms/spinner";
import {
  CreateBuildingsWalletSingleFunc,
  onFileUploadValidationSize,
} from "../../../services/API";
import {
  convertToBase64,
  presentToastFunction,
} from "../../../services/genericMethoud/generice";
import ErrorModal from "../../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { Field, Formik } from "formik";

const CreateBuildingsWalletSingleModal = ({
  itemsDataS,
  dismissCreateBuildingsWalletSingleModal,
}: {
  itemsDataS: any;
  dismissCreateBuildingsWalletSingleModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [dataDesc, setDataDesc] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [presentToast] = useIonToast();
  const inputClassWithError =
    "input-error block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputClassWithOutError =
    "block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentDetailsAddModel, DetailsAddModelDismiss] = useIonModal(
    DetailsAddModel,
    {
      DetailsAddModelDismiss: (data: string, role: string) =>
        DetailsAddModelDismiss(data, role),
    }
  );

  function openDetailsAddModel() {
    presentDetailsAddModel({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.3,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          console.log("ev", ev.detail.data);
          if (dataDesc) {
            setDataDesc((rowData: any) => [...rowData, ev.detail.data]);
          } else {
            setDataDesc(ev.detail.data);
          }
          console.log(dataDesc);
        }
      },
    });
  }

  const validate = (values: any) => {
    let errors: any = {};
    if (!values.Title) {
      errors.Title = "يجب الإدخال";
    }
    console.log(errors);

    return errors;
  };

  const handleRemoveLogos = (id: any) => {
    const newlogos = dataDesc.filter((getId: any, index: any) => index !== id);
    setDataDesc(newlogos);
  };

  return (
    <IonPage>
      <IonContent>
        {isLoading ? (
          <Spinner isFull={false} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1 "></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                ادخال بيانات جديدة
              </p>
            </div>

            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  dismissCreateBuildingsWalletSingleModal(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            <Formik
              initialValues={{
                Title: "",
                Description: "",
                OwnerName: "",
                OwnerPhoneNumber: "",
                BuildingPrice: "",
                BuildingArea: "",
                BuildingDirection: "",
              }}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={async (values: any, actions) => {
                if (!isLoading) {
                  setIsLoading(true);
                  console.log("itemsDataS", itemsDataS);

                  let data: any = {
                    UserId: userInfo.id,
                    Title: values.Title,
                    Description: values.Description,
                    BuildingsWalleSingletId: Number(
                      itemsDataS.buildingsWalletTypesUserId
                    ),
                    BuildingsWalletParcelsId: Number(itemsDataS.id),
                    OwnerName: values.OwnerName,
                    OwnerPhoneNumber: values.OwnerPhoneNumber,
                    BuildingPrice: Number(values.BuildingPrice),
                    BuildingArea: Number(values.BuildingArea),
                    BuildingDirection: values.BuildingDirection,
                  };
                  const formData = new FormData();

                  if (dataDesc) {
                    console.log(dataDesc);
                    for (let i = 0; i < dataDesc.length; i++) {
                      formData.append(
                        `Descriptions[${i}].name`,
                        dataDesc[i] as any
                      );
                    }
                  }
                  if (values.file) {
                    for (let i = 0; i < values.file.length; i++) {
                      let checkFileResult = await onFileUploadValidationSize(
                        values.file[i],
                        6 * 1048576
                      );
                      if (!checkFileResult) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ` عفوا الملف  ${values.file[i].name}   حجم اكبر من 6 ميجا`
                        );
                        setIsLoading(false);
                        return;
                      }
                      const files: any = await convertToBase64(values.file[i]);
                      const regex = /data:.*base64,/;
                      const fileWithOut = files.replace(regex, "");

                      formData.append(
                        `AttachMents[${i}].File`,
                        fileWithOut as any
                      );
                      formData.append(
                        `AttachMents[${i}].Name`,
                        values.file[i].name as any
                      );
                    }
                  }

                  formData.append(`Data`, JSON.stringify(data) as any);

                  CreateBuildingsWalletSingleFunc(formData, token)
                    .then((ss: any) => {
                      console.log(ss);
                      if (ss.data) {
                        setIsLoading(false);
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          "تم الحفظ بنجاح"
                        );
                        dismissCreateBuildingsWalletSingleModal(
                          null,
                          "confirm"
                        );
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ss.data
                        );
                      }
                      setIsLoading(false);
                    })
                    .catch((e: any) => {
                      console.log(e);
                      setIsLoading(false);
                      if (e.response?.status == 401) {
                        window.localStorage.removeItem("persistantState");
                        window.location.assign(`/app/signIn`);
                      }
                      setErrorTitle("حدث خطأ");
                      setErrorMessage(
                        " لم تتم عملية الأضافة .. تأكد من المدخلات! أو أعد المحاولة"
                      );
                      openpErrorModal();
                    });
                }
              }}
              validate={validate}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
              }) => (
                <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center animate__animated animate__fadeInUpBig">
                  {isLoading ? (
                    <Spinner color={"primary"} size={"large"} />
                  ) : null}
                  <div className="w-full flex flex-col justify-start items-start ">
                    <p className="font-medium text-black text-2xl">
                      معلومات تهمك
                    </p>
                    <p className="font-light text-black text-base">
                      هذه المعلومات تساعد في حفظ معلوماتك بسرية تامة .. بياناتك
                      مشفرة بالكامل
                    </p>
                  </div>

                  <div className="relative w-full  mt-6">
                    <Field
                      type="text"
                      name="Title"
                      onChange={handleChange}
                      placeholder="العنوان"
                      className={
                        errors.Title && touched.Title
                          ? `${inputClassWithError}`
                          : `${inputClassWithOutError}`
                      }
                    />
                    <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      العنوان
                    </label>
                    {errors.Title ? (
                      <p className="text-xl font-bold text-redColor mt-2">
                        {errors.Title}
                      </p>
                    ) : null}
                  </div>

                  <div className="relative w-full  mt-6">
                    <Field
                      component="textarea"
                      rows="6"
                      name="Description"
                      onChange={handleChange}
                      placeholder="الوصف"
                      className={
                        errors.Description && touched.Description
                          ? `${inputClassWithError}`
                          : `${inputClassWithOutError}`
                      }
                    />
                    <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      الوصف
                    </label>
                    {errors.Description ? (
                      <p className="text-xl font-bold text-redColor mt-2">
                        {errors.Description}
                      </p>
                    ) : null}
                  </div>

                  {itemsDataS?.buildingsWalletTypesUsers
                    ?.buildingsWalletTypesId == 1 ? (
                    <p className="placeholder-paragraphColor mt-5 text-[#a8112a]">
                      عفوا لا يمكنك استخدام هذه الادوات - يمكنك الترقية للمحفظه
                      البرونزية لاستخدامها
                    </p>
                  ) : null}

                  {/* <div className="relative block pr-2 rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 w-full flow-root mt-6">
                    <div className="float-right">
                      <label className="text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100">
                        تفاصيل العقار
                      </label>
                    </div>

                    <div className="float-left">
                      <Button
                        theme={
                          itemsDataS?.buildingsWalletTypesUsers
                            ?.buildingsWalletTypesId == 2
                            ? "primary"
                            : ""
                        }
                        text={"ادخال"}
                        size={"small"}
                        onClick={() => {
                          if (
                            itemsDataS?.buildingsWalletTypesUsers
                              ?.buildingsWalletTypesId == 2
                          ) {
                            openDetailsAddModel();
                          }
                        }}
                      />
                    </div>
                  </div> */}

                  {dataDesc?.length > 0 ? (
                    <div className="mt-2 w-full rounded border-t-0 border-2 border-paragraphColor text-right p-2">
                      <IonList>
                        {dataDesc?.map((item: any, index: any) => {
                          return (
                            <div className="flow-root mb-2 border border-paragraphColor p-1">
                              <div className="float-right">
                                <IonItem key={index}>
                                  <IonLabel>{item}</IonLabel>
                                </IonItem>
                              </div>
                              <div className="float-left">
                                <Button
                                  theme={"danger"}
                                  text={"حذف"}
                                  size={"ssmall"}
                                  onClick={() => {
                                    handleRemoveLogos(index);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </IonList>
                    </div>
                  ) : null}

                  <div className="relative w-full mt-6">
                    <input
                      onChange={(e: any) => {
                        setFieldValue("file", e.currentTarget.files);
                      }}
                      type="file"
                      multiple
                      disabled={
                        itemsDataS?.buildingsWalletTypesUsers
                          ?.buildingsWalletTypesId == 1
                          ? true
                          : false
                      }
                      accept=".pdf"
                      className={`${inputClassWithOutError}`}
                      placeholder="الملفات"
                    />
                    <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      الملفات
                    </label>
                  </div>

                  <div className="w-full mt-8">
                    <Button
                      theme={"primary"}
                      text={"اضافة"}
                      size={"big"}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const DetailsAddModel = ({
  DetailsAddModelDismiss,
}: {
  DetailsAddModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const inputClassWithOutError =
    "block px-5 py-2.5 text-1xl  placeholder-paragraphColor w-full  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputClassWithError =
    "input-error block px-5 py-2.5  text-1xl  placeholder-paragraphColor w-full   bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputNeedAddIsOpenRef = useRef<HTMLIonInputElement>(null);

  return (
    <IonPage>
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
        <p className="font-bold text-primaryColor100 text-2xl">
          يرجى كتابة المحتوي
        </p>
        <div className="w-96 mt-4">
          <IonItem>
            <IonInput
              type="text"
              autofocus={true}
              className={
                inputNeedAddIsOpenRef.current?.value
                  ? `${inputClassWithOutError}`
                  : `${inputClassWithError}`
              }
              ref={inputNeedAddIsOpenRef}
            ></IonInput>
          </IonItem>
        </div>
        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
          <Button
            theme={"primary"}
            text={"حفظ"}
            size={"big"}
            onClick={() => {
              if (inputNeedAddIsOpenRef.current?.value) {
                DetailsAddModelDismiss(
                  inputNeedAddIsOpenRef.current?.value,
                  "confirm"
                );
              }
            }}
          />

          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              DetailsAddModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default CreateBuildingsWalletSingleModal;
