import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.646 3.646a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .707-.708L1.707 4l2.828-2.828a.5.5 0 0 0-.707-.708L.646 3.646Zm23.84-.146H1v1h23.486v-1Z"
      fill="#0031C4"
    />
  </svg>
)

export default SvgComponent
