import React from "react";
import { IonModal } from "@ionic/react";
import Button from "../../atoms/button";
import "./messageModal.styles.css";

interface Props {
  isOpen: any;
  errorTitle: String;
  errorMessage: String;
  onClickDoneButton: any;
}

const MessageModal: React.FC<Props> = ({
  isOpen,
  errorTitle,
  errorMessage,
  onClickDoneButton,
}: Props): JSX.Element => {
  return (
    <IonModal id="messageModal" isOpen={isOpen} trigger="open-custom-dialog">
      <div className="wrapper">
        <div className="w-full flex flex-col justify-start items-center py-2">
          <div className="w-4/6 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-xl mt-2 ">
              {errorTitle}
            </p>
            <p className="font-medium text-[#828282] text-base text-center">
              {errorMessage}
            </p>
          </div>
          <div className="mt-4 w-full flex justify-center items-center">
            <Button
              size={"medium"}
              theme={"primary"}
              text={"حسنا"}
              onClick={onClickDoneButton}
            />
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default MessageModal;
