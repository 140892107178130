import { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  useIonModal,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  callOutline,
  chevronBackOutline,
  createOutline,
  expandOutline,
  eye,
  filterOutline,
  linkOutline,
  trash,
  trashBin,
} from "ionicons/icons";
import {
  addFileToAdvertisementFunction,
  deleteAllFilesOfAdvertisementFunction,
  deleteFilesByIDOfAdvertisementFunction,
  getAdvertisementFilesFunction,
  getNhcIntegrationByAdLicenseNumberFunction,
  onFileUploadValidationSize,
} from "../../services/API";
import "./nhcAdvertisement.styles.css";
import { useSelector } from "react-redux";
import Button from "../../components/atoms/button";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import Spinner from "../../components/atoms/spinner";
import {
  convertToBase64,
  presentToastFunction,
} from "../../services/genericMethoud/generice";

const EditNhcIntegrationAdvistment = ({
  advertisementId,
  objectId,
  adLicenseNumber,
  addIDType,
  advertisement,
  onDismissdismisEditAdvertisementModal,
}: {
  advertisementId: any;
  objectId: any;
  addIDType: any;
  adLicenseNumber: any;
  advertisement: any;
  onDismissdismisEditAdvertisementModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [property] = useState<any>(advertisement);
  const [image, setImage] = useState<any>("");
  const [video, setVideo] = useState<any>("");
  const [customePrice, setCustomePrice] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [fileValue, setFileValue] = useState<any>();
  const [attachments, setAttachments] = useState<any>([]);
  const { token } = useSelector((state: any) => state.reducer);
  const [isLoading, setIsLoading] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  //model property.aNhcIntegrationResultDTO
  const [presentIamgeModal, onCloseImageDismiss] = useIonModal(
    ModalImagesExample,
    {
      image,
      onCloseImageDismiss: (data: string, role: string) =>
        onCloseImageDismiss(data, role),
    }
  );

  function openpImageModal() {
    presentIamgeModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  //model property
  const [presentDeleteModal, onCloseFilesDismiss] = useIonModal(
    ModalDeleteFiles,
    {
      ad_Id: advertisementId,
      token: token,
      Parcel_Cod: objectId,
      onCloseFilesDismiss: (data: string, role: string) =>
        onCloseFilesDismiss(data, role),
    }
  );

  function openpDeleteModal() {
    presentDeleteModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        getNhcIntegrationByAdLicenseNumberFunc();
      },
    });
  }

  //model video property.aNhcIntegrationResultDTO
  const [presentVideoModal, onCloseVideoDismiss] = useIonModal(
    ModalVideoExample,
    {
      video,
      onCloseVideoDismiss: (data: string, role: string) =>
        onCloseVideoDismiss(data, role),
    }
  );

  async function editAdv() {
    const formData = new FormData();
    console.log("fileValue", fileValue ?? undefined);
    formData.append(`NhcId`, advertisementId ?? undefined);
    formData.append(`ObjId`, objectId ?? undefined);
    formData.append(`customePrice`, customePrice.toString() ?? undefined);
    formData.append(`description`, description ?? undefined);

    if (fileValue) {
      for (let i = 0; i < fileValue.length; i++) {
        let checkFileResult = await onFileUploadValidationSize(
          fileValue[i],
          6 * 1048576
        );

        if (attachments?.length + fileValue?.length > 6) {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `عفوا لا يمكن اضافة اكثر من 6 مرفقات`
          );
          setIsLoading(false);
          let fileList: any = document.getElementById("addFileHTMLInput");
          fileList.value = "";
          return;
        }

        if (!checkFileResult) {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            ` عفوا الملف  ${fileValue[i].name}  حجم اكبر من 6 ميجا`
          );
          setIsLoading(false);
          return;
        }
        const files: any = await convertToBase64(fileValue[i]);
        const regex = /data:.*base64,/;
        const fileWithOut = files.replace(regex, "");

        formData.append(`AttachMents[${i}].File`, fileWithOut as any);
        formData.append(`AttachMents[${i}].Name`, fileValue[i].name as any);

        formData.append(`AttachMents[${i}].FileType`, fileValue[i].type as any);
      }
    }
    addFileToAdvertisementFunc(formData);
  }

  function openpVideoModal() {
    presentVideoModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  function addFileToAdvertisementFunc(form: any) {
    setIsLoading(true);
    addFileToAdvertisementFunction(form, token)
      .then((returnedData) => {
        setIsLoading(false);
        console.log("addFileToAdvertisementFunc", returnedData);
        getNhcIntegrationByAdLicenseNumberFunc();
        presentToastFunction(presentToast, "top", "success", `تم الحفظ بنجاح`);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        presentToastFunction(presentToast, "top", "success", `حدث خطأ`);
      });
  }

  function getNhcIntegrationByAdLicenseNumberFunc() {
    setIsLoading(true);
    console.log("before error");

    getNhcIntegrationByAdLicenseNumberFunction(adLicenseNumber, addIDType)
      .then((returnedData) => {
        setIsLoading(false);
        console.log("GetNhcIntegrationByAdLicenseNumber00", returnedData);
        console.log(
          "property.aNhcIntegrationResultDTO",
          property.aNhcIntegrationResultDTO
        );
        setAttachments(returnedData.data.data.nhcIntegration.attachFiles);
        let getCustomePrice =
          returnedData.data.data.nhcIntegration.customePrice;
        let customePrice =
          getCustomePrice == "" || getCustomePrice == null
            ? property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyPrice
            : getCustomePrice;
        setCustomePrice(customePrice);
        setDescription(returnedData.data.data.nhcIntegration.description);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }
  useEffect(() => {
    getNhcIntegrationByAdLicenseNumberFunc();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div
          id="ii"
          className="w-full h-full relative flex flex-col justify-start items-center"
        >
          {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="text-white text-2xl">تعديل الاعلان</p>
            </div>
            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  onDismissdismisEditAdvertisementModal(null, "cancle");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-8 pb-8">
            <div className="col-span-1 py-6 w-full flex flex-row justify-start items-center mt-2">
              <p className="text-primaryColor100 text-2xl">
                تفاصيل الاعلان لدي الهيئة العامه للعقار
              </p>
            </div>
            <div className="w-full flex flex-col justify-center items-center divide-y-2">
              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyType ? (
                <div className="w-full flex flex-row justify-between items-center p-2">
                  <div>
                    <p className="text-primaryColor100 text-xl">نوع العقار :</p>
                  </div>
                  <div>
                    <p
                      className="text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.propertyType
                      }
                    </p>
                  </div>
                </div>
              ) : null}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .location.region ? (
                <div className="w-full flex flex-row justify-between items-center p-2">
                  <div>
                    <p className="text-primaryColor100 text-xl">المنطقة :</p>
                  </div>
                  <div>
                    <p
                      className="text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.location.region
                      }
                    </p>
                  </div>
                </div>
              ) : null}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .location.city ? (
                <div className="w-full flex flex-row justify-between items-center p-2">
                  <div>
                    <p className="text-primaryColor100 text-xl">المدينة :</p>
                  </div>
                  <div>
                    <p
                      className="text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.location.city
                      }
                    </p>
                  </div>
                </div>
              ) : null}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .advertisementType ? (
                <div className="w-full flex flex-row justify-between items-center p-2">
                  <div>
                    <p className="text-primaryColor100 text-xl">
                      غرض الاعلان :
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.advertisementType
                      }
                    </p>
                  </div>
                </div>
              ) : null}

              <button
                onClick={() => {
                  if (moreDetails) {
                    setMoreDetails(false);
                  } else {
                    setMoreDetails(true);
                  }
                }}
                className="mt-5 bg-white h-12 w-12 flex ml-4 flex-col justify-center items-center rounded-full shadow-md drop-shadow-md"
              >
                <IonIcon
                  icon={expandOutline}
                  className="text-3xl text-primaryColor100"
                />
              </button>

              {moreDetails ? (
                <>
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.district ? (
                    <div className="w-full flex flex-row justify-between items-center p-2 border-none">
                      <div>
                        <p className="text-primaryColor100 text-xl">الحي :</p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.district
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.street ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          اسم الشارع :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.street
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.postalCode ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          الرمز البريدي :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.postalCode
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.buildingNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          رقم المبني :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.buildingNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.additionalNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          الرقم الإضافي :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.additionalNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.longitude ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          خط الطول :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.longitude
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.latitude ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          خط العرض :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.latitude
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyFace ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          واجهة العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyFace
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .planNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          رقم المخطط :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.planNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .theBordersAndLengthsOfTheProperty ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          حدود وأطوال العقار :
                        </p>
                      </div>
                      <div>
                        <p className="text-black text-xl">
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.theBordersAndLengthsOfTheProperty
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .complianceWithTheSaudiBuildingCode ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          مطابقة كود البناء السعودي :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.aNhcIntegrationResultDTO.body.result
                            .advertisement.complianceWithTheSaudiBuildingCode
                            ? "مطابق"
                            : "غير مطابق"}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .advertiserId ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          {" "}
                          رقم هوية المعلن:
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.advertiserId
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .adLicenseNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          رقم ترخيص الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.adLicenseNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .deedNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          رقم صك الملكية :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.deedNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .guaranteesAndTheirDuration ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          الضمانات ومدتها :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.guaranteesAndTheirDuration
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .obligationsOnTheProperty ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          الالتزامات علي العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.obligationsOnTheProperty
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .advertiserName ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          {" "}
                          اسم المعلن :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.advertiserName
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .brokerageAndMarketingLicenseNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          رقم رخصة الوساطة والتسويق العقاري :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.brokerageAndMarketingLicenseNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyType ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          نوع الغرف :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyType
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .isConstrained ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          وجود قيد ؟ :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.aNhcIntegrationResultDTO.body.result
                            .advertisement.isConstrained
                            ? "يوجد قيد"
                            : "لا يوجد قيد"}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .isPawned ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          وجود رهن؟ :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.aNhcIntegrationResultDTO.body.result
                            .advertisement.isPawned
                            ? "يوجد رهن"
                            : "لا يوجد رهن"}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .streetWidth ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          عرض الشارع :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.streetWidth
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyArea ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          مساحة العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyArea
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  <div className="w-full flex flex-row justify-between items-center p-2">
                    <div>
                      <p className="text-primaryColor100 text-xl">
                        سعر الوحدة/المتر الارض :
                      </p>
                    </div>
                    <div className="relative mt-6">
                      <input
                        onChange={(e: any) => {
                          console.log("CustomePrice", e);
                          console.log("currentTarget", e.currentTarget.value);
                          setCustomePrice(e.currentTarget.value);
                        }}
                        type="number"
                        className="mr-8 block px-5 w-40 placeholder-paragraphColor text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                        placeholder="ادخل السعر"
                        value={customePrice}
                        style={{ padding: "3px 6px" }}
                      />
                      <label className="absolute text-xl top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                        ر.س
                      </label>
                    </div>
                  </div>

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .numberOfRooms ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          عدد الغرف :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.numberOfRooms
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyAge ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          عمر العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyAge
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyUsages[0] ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          استخدام العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyUsages[0]
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyUtilities[0] ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          خدمات العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyUtilities[0]
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .creationDate ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          تاريخ إنشاء ترخيص الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.creationDate
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .endDate ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className="text-primaryColor100 text-xl">
                          تاريخ انتهاء ترخيص الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.endDate
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  <div className="w-full flex flex-row justify-between items-center p-2">
                    <div>
                      <p className="text-primaryColor100 text-xl">الوصف :</p>
                    </div>
                    <div className="relative mt-6">
                      <textarea
                        onChange={(e: any) => {
                          setDescription(e.currentTarget.value);
                        }}
                        className="mr-8 block px-5 w-64 placeholder-paragraphColor text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                        placeholder="ادخل الوصف"
                        value={description}
                        style={{ padding: "3px 6px" }}
                      />
                    </div>
                  </div>

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .phoneNumber ? (
                    <div className="w-full flex flex-col justify-center items-center">
                      <div className="w-full flex flex-row justify-between items-center p-2">
                        <div>
                          <p className="text-primaryColor100 text-xl">
                            رقم التواصل للمعلن :
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-primaryColor100  text-xl mr-2"
                            style={{ textAlign: "justify" }}
                          >
                            <a
                              href={`tel:${property.aNhcIntegrationResultDTO.body.result.advertisement.phoneNumber}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={callOutline} />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {property.nhcIntegration.ad_Views ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl ">
                          عدد المشاهدات :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-primaryColor100 text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.nhcIntegration.ad_Views}{" "}
                          <IonIcon
                            icon={eye}
                            style={{ marginBottom: "-4px" }}
                          />
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .qrCodeUrl ? (
                    <div className="w-full flex flex-col justify-center items-center">
                      <div className="w-full flex flex-row justify-between items-center p-2">
                        <div>
                          <p className="text-primaryColor100 text-xl">
                            رابط رمز الإستجابة السريع :
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-xl mr-2 text-primaryColor100"
                            style={{ textAlign: "justify" }}
                          >
                            <a
                              target="_blank"
                              href={`${property.aNhcIntegrationResultDTO.body.result.advertisement.qrCodeUrl}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={linkOutline} />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-span-1 w-full flex flex-row justify-start items-center mt-8 border-none">
                    <p className="text-primaryColor100 text-2xl">
                      الملفات المرفقة:
                    </p>
                  </div>
                  {attachments.length == 0 ? null : (
                    <div
                      className="mt-4 ml-5 px-4 py-1 text-2xl bg-redColor text-white rounded"
                      onClick={() => {
                        openpDeleteModal();
                      }}
                    >
                      حذف جميع المرفقات
                      <IonIcon
                        icon={trashBin}
                        style={{ marginTop: "2px", marginBottom: "-3px" }}
                      />
                      {/*  <Button
                         size="small"
                       text="حذف جميع الملفات"
                      theme={"danger"}
                       onClick={() => {
                            openpDeleteModal()
                       }}
                       /> */}
                    </div>
                  )}
                  <div className="relative w-full mt-6">
                    <input
                      id="addFileHTMLInput"
                      onChange={(e: any) => {
                        setFileValue(e.currentTarget.files);
                      }}
                      type="file"
                      multiple
                      accept=".gif,.jpg,.jpeg,.png,.MPG,video/mp4,video/x-m4v,video/quicktime,video/*"
                      className="block px-5 py-2.5 placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                      placeholder="الملف"
                    />
                    <label className="absolute text-xl top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      الملف
                    </label>
                  </div>
                  {attachments.length == 0 ? (
                    <div className="w-full bg-bkColor rounded border-[1px] border-paragraphColor flex flex-row justify-center items-center py-4 mt-4">
                      <p className="font-medium text-paragraphColor text-sm">
                        لا توجد ملفات على هذا العقار !
                      </p>
                    </div>
                  ) : (
                    <div className="w-full flex flex-col justify-center items-center mt-8">
                      {attachments.map((item: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="w-full flex flex-row justify-between items-center p-2"
                          >
                            <div>
                              <div className="flow-root py-2">
                                <div className="float-right w-52">
                                  <p className="text-black text-xl whitespace-nowrap overflow-auto">
                                    {item.name}
                                  </p>
                                </div>
                                <div className="float-right px-4 text-2xl bg-primaryColor100 text-white rounded mr-3">
                                  <IonIcon
                                    icon={eye}
                                    style={{
                                      marginTop: "2px",
                                      marginBottom: "-3px",
                                    }}
                                    onClick={() => {
                                      setIsLoading(true);
                                      getAdvertisementFilesFunction(
                                        item,
                                        objectId,
                                        token
                                      )
                                        .then((returnedData: any) => {
                                          if (
                                            returnedData.data.isImage == true
                                          ) {
                                            setImage(returnedData.data.file);
                                            openpImageModal();
                                            setIsLoading(false);
                                          } else {
                                            setVideo(returnedData.data.file);
                                            openpVideoModal();
                                            setIsLoading(false);
                                          }
                                        })
                                        .catch((e: any) => {
                                          console.log(e);
                                          setIsLoading(false);
                                          if (e.response.status == 401) {
                                            window.localStorage.removeItem(
                                              "persistantState"
                                            );
                                            window.location.assign(
                                              `/app/signIn`
                                            );
                                          }
                                        });
                                    }}
                                  />
                                  {/* <Button
                                    size="small"
                                    text="عرض"
                                    theme={"primary"}
                                    onClick={() => {
                                      setIsLoading(true);
                                      getAdvertisementFilesFunction(item, objectId, token)
                                        .then((returnedData: any) => {
                                          if (returnedData.data.isImage == true) {
                                            setImage(returnedData.data.file);
                                            openpImageModal();
                                            setIsLoading(false);
                                          } else {
                                            setVideo(returnedData.data.file);
                                            openpVideoModal()
                                            setIsLoading(false);
                                          }
                                        })
                                        .catch((e: any) => {
                                          console.log(e);
                                          setIsLoading(false);
                                          if (e.response.status == 401) {
                                            window.localStorage.removeItem("persistantState");
                                            window.location.assign(`/app/signIn`);
                                          }
                                        });
                                    }}
                                  /> */}
                                </div>
                                <div className="float-left px-4 text-2xl bg-redColor text-white rounded mr-3">
                                  <IonIcon
                                    icon={trash}
                                    style={{
                                      marginTop: "2px",
                                      marginBottom: "-3px",
                                    }}
                                    onClick={() => {
                                      presentAlert({
                                        cssClass: "",
                                        header: "تنبية",
                                        message: `<strong class="strongStyle">هل تريد الحذف </strong >`,
                                        buttons: [
                                          {
                                            text: "موافق",
                                            handler: (d) => {
                                              deleteFilesByIDOfAdvertisementFunction(
                                                item.id,
                                                token
                                              )
                                                .then((result: any) => {
                                                  console.log("result", result);
                                                  if (result.status == 200) {
                                                    setAttachments(
                                                      attachments.filter(
                                                        (i: any) =>
                                                          i.id !== item.id
                                                      )
                                                    );
                                                  }
                                                })
                                                .catch((e) => {
                                                  console.log(e);
                                                  if (
                                                    e.response.status == 401
                                                  ) {
                                                    window.localStorage.removeItem(
                                                      "persistantState"
                                                    );
                                                    window.location.assign(
                                                      `/app/signIn`
                                                    );
                                                  }
                                                });
                                            },
                                          },
                                          "الغاء",
                                        ],
                                        onDidDismiss: (e) =>
                                          console.log("did dismiss"),
                                      });
                                    }}
                                  />
                                  {/* <Button
                                    size="small"
                                    text="حذف"
                                    theme={"danger"} onClick={() => {
                                      presentAlert({
                                        cssClass: '',
                                        header: 'تنبية',
                                        message: `<strong class="strongStyle">هل تريد الحذف </strong >`,
                                        buttons: [{
                                          text: 'موافق', handler: (d) => {
                                            deleteFilesByIDOfAdvertisementFunction(item.id, token)
                                              .then((result: any) => {
                                                console.log("result", result);
                                                if (result.status == 200) {
                                                  setAttachments(attachments.filter((i: any) => (i.id !== item.id)))
                                                }
                                              }).catch((e) => {
                                                console.log(e);
                                                if (e.response.status == 401) {
                                                  window.localStorage.removeItem("persistantState");
                                                  window.location.assign(`/app/signIn`);
                                                }
                                              });
                                          }
                                        }, 'الغاء'],
                                        onDidDismiss: (e) => console.log('did dismiss'),
                                      })

                                    }}
                                  /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div
                    className="w-full mt-4 py-2 text-2xl text-center bg-primaryColor100 text-white rounded"
                    onClick={() => {
                      presentAlert({
                        cssClass: "animate__animated animate__fadeInDown",
                        header: "تنبية",
                        message: `<strong class="strongStyle">هل انت متأكد من تعديل الإعلان ؟ </strong >`,
                        buttons: [
                          {
                            text: "موافق",
                            handler: (d) => {
                              editAdv();
                            },
                          },
                          "الغاء",
                        ],
                        onDidDismiss: (e) => console.log("did dismiss"),
                      });
                    }}
                  >
                    تعديل الإعلان
                    <IonIcon
                      icon={createOutline}
                      style={{ marginTop: "2px", marginBottom: "-3px" }}
                    />
                    {/* <Button
                      theme={"primary"}
                      text={"تعديل الملفات"}
                      size={"big"}
                      onClick={editAdv}
                    /> */}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const ModalImagesExample = ({
  image,
  onCloseImageDismiss,
}: {
  image: any;
  onCloseImageDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>عرض الصورة</IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseImageDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <img src={image} className="w-full " />
      </IonContent>
    </IonContent>
  );
};

const ModalVideoExample = ({
  video,
  onCloseVideoDismiss,
}: {
  video: any;
  onCloseVideoDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>عرض الفيديو</IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseVideoDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <video autoPlay controls src={video} className="w-full"></video>
      </IonContent>
    </IonContent>
  );
};

const ModalDeleteFiles = ({
  ad_Id,
  token,
  Parcel_Cod,
  onCloseFilesDismiss,
}: {
  ad_Id: any;
  token: any;
  Parcel_Cod: any;
  onCloseFilesDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <div className="wrapper">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="text-primaryColor100 text-2xl whitespace-nowrap">
            هل انت متأكد من حذف جميع الملفات ؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  deleteAllFilesOfAdvertisementFunction(
                    ad_Id,
                    Parcel_Cod,
                    token
                  )
                    .then(() => {
                      onCloseFilesDismiss(null, "cancel");
                    })
                    .catch((e) => {
                      console.log(e);
                      if (e.response.status == 401) {
                        window.localStorage.removeItem("persistantState");
                        window.location.assign(`/app/signIn`);
                      }
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onCloseFilesDismiss(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default EditNhcIntegrationAdvistment;
