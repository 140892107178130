import { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  IonItem,
  IonList,
  useIonToast,
  IonRadioGroup,
  IonRadio,
  IonProgressBar,
  useIonAlert,
} from "@ionic/react";
import Button from "../../components/atoms/button";
import deleteSvg from "../../images/delete.svg";
import { Formik, Field } from "formik";
import { chevronBackOutline } from "ionicons/icons";
import {
  onFileUploadValidationSize,
  CreateNhcIntegration,
  deleteNhcIntegration,
  CheckNhcIntegration,
} from "../../services/API";
import { useSelector } from "react-redux";
import ErrorModal from "../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Request from "@arcgis/core/request";
import { arcgisServicesBaseMinistryUrl } from "../../configs/arcgisServices";
import Spinner from "../../components/atoms/spinner";
import {
  convertToBase64,
  getAdvertisementTypeId,
  presentToastFunction,
} from "../../services/genericMethoud/generice";
import {
  favoriteLandSymbol,
  requestOptions,
} from "../../services/genericMethoud/gisMethouds";
import Query from "@arcgis/core/rest/support/Query";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import "./nhcIntegration.style.css";

interface FileWithProgress {
  file: File;
  progress: number;
  url: string | null;
  prepared: boolean;
}

const AddPropertyNhcIntegration = ({
  dismisAddProperty,
}: {
  dismisAddProperty: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [ddIDType, setAddIDType] = useState("1");
  const [errorMessage, setErrorMessage] = useState("");
  const [parcel_Cod, setParcel_Cod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputClassWithOutError =
    "block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const [presentToast] = useIonToast();
  const [files, setFiles] = useState<FileWithProgress[]>([]);
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    console.log("files state updated", files);
  }, [files]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = Array.from(event.target.files || []);
    const maxFileSizeMB = 6;
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

    const newFiles: FileWithProgress[] = [];

    for (const file of selectedFiles) {
      if (file.size > maxFileSizeBytes) {
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          "لا يمكن ان يتخطي حجم المرفق 6 mb"
        );
        continue;
      }

      if (
        !file.type.match("image/jpeg") &&
        !file.type.match("image/jpg") &&
        !file.type.match("image/jpg") &&
        !file.type.match("image/jpe") &&
        !file.type.match("image/png") &&
        !file.type.match("image/gif") &&
        !file.type.match("video/*")
      ) {
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          "يجب رفع صورة او فيديو"
        );
        continue;
      }

      const fileWithProgress: FileWithProgress = {
        file,
        progress: 0,
        url: null,
        prepared: false,
      };

      const reader = new FileReader();

      const fileReadPromise = new Promise<{
        progress: number;
        url: string | null;
      }>((resolve, reject) => {
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const progress = e.loaded / e.total;
            fileWithProgress.progress = progress;
          }
        };

        reader.onloadend = () => {
          const url = URL.createObjectURL(file);
          resolve({ progress: 1, url });
        };

        reader.onerror = () => {
          reject(new Error("File reading has failed"));
        };
      });

      reader.readAsDataURL(file);

      try {
        const { progress, url } = await fileReadPromise;
        fileWithProgress.progress = progress;
        fileWithProgress.url = url;
        fileWithProgress.prepared = true;
      } catch (error) {
        console.error("Error reading file:", error);
      }

      newFiles.push(fileWithProgress);
      console.log("fileWithProgress", fileWithProgress);
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    console.log("handledFiles", files);

    // Reset the input value to allow selecting the same file again
    event.target.value = "";
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const validate = (values: any) => {
    let errors: any = {};

    if (!values.adLicenseNumber) {
      errors.adLicenseNumber = "يجب الإدخال";
      presentToastFunction(
        presentToast,
        "top",
        "danger",
        "يجب كتابة رقم ترخيص الاعلان بشكل صحيح"
      );
    }
    return errors;
  };

  function createFeatureGraphics(
    type: any,
    geometry: any,
    wiki: any,
    attr: any,
    symbolS: any
  ) {
    console.log("createFeatureGraphics ==>geometry", geometry);
    let fFeature: any;
    switch (type) {
      case "polygon":
        fFeature = new Polygon({
          rings: geometry,
          spatialReference: {
            wkid: wiki,
          },
        });
        break;
      case "point":
        fFeature = new Point({
          x: geometry.x,
          y: geometry.y,
          spatialReference: {
            wkid: wiki,
          },
        });
        break;
    }

    let fFeatureGraphic = new Graphic({
      geometry: fFeature,
      symbol: symbolS,
      attributes: attr,
    });
    return fFeatureGraphic;
  }

  function convertGeometryToGraphic(geometry: any, attributes: any) {
    setIsLoading(true);
    let x: number = geometry.centroid?.x;
    let y: number = geometry.centroid?.y;
    let pointGeometryG = {
      x: x,
      y: y,
    };
    let pPointgraphic: any = createFeatureGraphics(
      "point",
      pointGeometryG,
      4326,
      attributes,
      favoriteLandSymbol
    );
    console.log("pPointgraphic", pPointgraphic);
    return pPointgraphic;
  }

  let getFeatureObjectAndAdd = async (
    objectId: any,
    parcel_Status: any,
    addId: any,
    parcel_Cod: any
  ) => {
    console.log("getFeatureObjectAndAdd objectId", objectId);
    console.log("getFeatureObjectAndAdd parcel_Status", parcel_Status);

    try {
      let query = new Query({
        returnGeometry: true,
        objectIds: [objectId],
        outFields: ["*"],
      });

      let query_Land_parcels = new Query({
        returnGeometry: false,
        where: `Parcel_Cod in ('${parcel_Cod}') `,
        outFields: ["*"],
      });

      let featureLayer = new FeatureLayer({
        url: arcgisServicesBaseMinistryUrl + "0",
        outFields: ["*"],
      });
      let intersectingGraphics = await featureLayer.queryFeatures(query);
      let _Land_parcels = await window.F_Land_parcels.queryFeatures(
        query_Land_parcels
      );
      console.log(
        "intersectingGraphics.features",
        intersectingGraphics.features
      );
      console.log("_Land_parcels.features", _Land_parcels.features);
      let deleteFeatures: any = [];
      debugger;
      if (_Land_parcels.features.length > 0) {
        _Land_parcels.features.forEach((element: any) => {
          debugger;
          deleteFeatures.push({ objectId: element.attributes.OBJECTID });
          console.log(
            "element.attributes.parcel_Status",
            element.attributes.Parcel_Status
          );
          debugger;
          if (
            parcel_Status == "مبنى للبيع" &&
            (element.attributes.Parcel_Status == "ارض للبيع" ||
              element.attributes.Parcel_Status == "ايجار واستثمار" ||
              element.attributes.Parcel_Status == "مبنى مختلط" ||
              element.attributes.Parcel_Status == "مبنى للبيع")
          ) {
            parcel_Status = "مبنى مختلط";
          } else if (
            parcel_Status == "ارض للبيع" &&
            (element.attributes.Parcel_Status == "مبنى للبيع" ||
              element.attributes.Parcel_Status == "ايجار واستثمار" ||
              element.attributes.Parcel_Status == "مبنى مختلط" ||
              element.attributes.Parcel_Status == "ارض للبيع")
          ) {
            parcel_Status = "مبنى مختلط";
          } else if (
            parcel_Status == "ايجار واستثمار" &&
            (element.attributes.Parcel_Status == "مبنى للبيع" ||
              element.attributes.Parcel_Status == "ارض للبيع" ||
              element.attributes.Parcel_Status == "مبنى مختلط" ||
              element.attributes.Parcel_Status == "ايجار واستثمار")
          ) {
            parcel_Status = "مبنى مختلط";
          }
        });
      }

      if (deleteFeatures.length > 0) {
        window.F_Land_parcels.applyEdits({
          deleteFeatures: deleteFeatures,
        }).then((result: any) => {
          if (result.deleteFeatureResults[0].error != null) {
            presentToastFunction(
              presentToast,
              "top",
              "danger",
              `حدث خطأ حاول مره اخري `
            );
            return;
          }
        });
      }

      const attributes: any = {};
      attributes["Parcel_Status"] = parcel_Status;
      attributes["Land_Numbe"] =
        intersectingGraphics.features[0].attributes.SUBDIVISIONPARCELNUMBER;
      attributes["Parcel_Cod"] =
        intersectingGraphics.features[0].attributes.OBJECTID;
      attributes["NK"] =
        intersectingGraphics.features[0].attributes.CITYNAME_AR;
      attributes["DK"] =
        intersectingGraphics.features[0].attributes.DISTRICTNAME_AR;
      attributes["ZK"] =
        intersectingGraphics.features[0].attributes.REGIONNAME_AR;

      const addFeature = new Graphic({
        geometry: intersectingGraphics.features[0].geometry,
        attributes: attributes,
      });

      window.F_Land_parcels.applyEdits({ addFeatures: [addFeature] })
        .then(async (response: any) => {
          console.log("response", response);
          if (response.addFeatureResults[0].error == null) {
            presentToastFunction(
              presentToast,
              "top",
              "success",
              "تم الحفظ بنجاح"
            );
            window.F_Land_parcels.refresh();
            window.mapviews.goTo(addFeature);
            //#region F_Land_parcelsHeatmap
            let query_F_Land_parcelsHeatmap = new Query({
              returnGeometry: false,
              where: `Parcel_Cod in ('${parcel_Cod}') `,
              outFields: ["*"],
            });
            let getF_Land_parcelsHeatmap =
              await window.F_Land_parcelsHeatmap.queryFeatures(
                query_F_Land_parcelsHeatmap
              );
            console.log("getF_Land_parcelsHeatmap", getF_Land_parcelsHeatmap);
            if (getF_Land_parcelsHeatmap.features.length == 0) {
              let getTypeId = getAdvertisementTypeId(parcel_Status);
              console.log("getAdvertisementTypeId(parcel_Status)", getTypeId);

              attributes["BaseLandType"] = getTypeId;

              let pointGrphicLand_parcelsHeatmap = convertGeometryToGraphic(
                intersectingGraphics.features[0].geometry,
                attributes
              );
              console.log("pointGrphic", pointGrphicLand_parcelsHeatmap);

              window.F_Land_parcelsHeatmap.applyEdits({
                addFeatures: [pointGrphicLand_parcelsHeatmap],
              }).then(async (response: any) => {
                console.log("response", response);
                if (response.addFeatureResults[0].error == null) {
                  console.log(
                    "response==>F_Land_parcelsHeatmap",
                    response.addFeatureResults[0]
                  );
                  window.F_Land_parcelsHeatmap.refresh();
                }
              });
            }
            //#endregion
            setIsLoading(false);
          } else {
            presentToastFunction(
              presentToast,
              "top",
              "danger",
              "خطأ في الحفظ حاول مره اخري"
            );
            deleteNhcIntegration(addId, token)
              .then(() => {
                presentToastFunction(
                  presentToast,
                  "top",
                  "success",
                  "تم رجوع البيانات للوضع الطبيعي من فضلك اعد المحاوله"
                );
              })
              .catch((e) => {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  "خطا اثناء رجوع البيانات للوضع الطبيعي من فضلك اعد المحاوله"
                );
              });
          }
        })
        .catch((e: any) => {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            "خطأ في الحفظ حاول مره اخري"
          );
          deleteNhcIntegration(addId, token)
            .then(() => {
              presentToastFunction(
                presentToast,
                "top",
                "success",
                "تم رجوع البيانات للوضع الطبيعي من فضلك اعد المحاوله"
              );
            })
            .catch((e) => {
              presentToastFunction(
                presentToast,
                "top",
                "danger",
                "خطا اثناء رجوع البيانات للوضع الطبيعي من فضلك اعد المحاوله"
              );
            });
        });
    } catch (error) {
      console.log("getFeatureObjectAndAdd error", error);
      presentToastFunction(
        presentToast,
        "top",
        "danger",
        "خطأ في الحفظ حاول مره اخري"
      );
      deleteNhcIntegration(addId, token)
        .then(() => {
          presentToastFunction(
            presentToast,
            "top",
            "success",
            "تم رجوع البيانات للوضع الطبيعي من فضلك اعد المحاوله"
          );
        })
        .catch((e) => {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            "خطا اثناء رجوع البيانات للوضع الطبيعي من فضلك اعد المحاوله"
          );
        });
    }
  };

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  async function ApplyDataFromAQAR(values: any) {
    console.log("values", values);
    if (!isLoading) {
      setIsLoading(true);
      console.log("ddIDType", ddIDType);
      let data: any = {
        IdType: ddIDType,
        AdLicenseNumber: values.adLicenseNumber,
        CommRegistrationNo:
          values.CommRegistrationNo == "" ? "0" : values.CommRegistrationNo,
      };
      const formData = new FormData();

      formData.append(`Data`, JSON.stringify(data) as any);
      if (values.file.length > 6) {
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          `عفوا لا يمكن رفع اكثر من 6 مرفقات`
        );
        return;
      }

      CheckNhcIntegration(formData, token)
        .then(async (ss: any) => {
          console.log("CheckNhcIntegration", ss);
          if (ss.data.resultStatus == 200) {
            console.log("CheckNhcIntegration.data", ss.data);
            let result = ss.data.data.aNhcIntegrationResultDTO.body.result;
            console.log("CheckNhcIntegration.result", result);

            let latitude = result.advertisement.location.latitude;
            let longitude = result.advertisement.location.longitude;
            if (
              latitude == null ||
              longitude == null ||
              latitude == "" ||
              longitude == ""
            ) {
              presentToastFunction(
                presentToast,
                "top",
                "danger",
                "عفوا يرجي تحديث البيانات الجغرافية للاعلان الخاص بك من خلال موقع هيئة العقار"
              );
              return;
            }
            let propertyType = result.advertisement.propertyType;
            let advertisementType = result.advertisement.advertisementType;
            console.log("CheckNhcIntegration.data==> latitude", latitude);
            console.log("CheckNhcIntegration.data==> longitude", longitude);

            let queryOption: any = requestOptions(
              "",
              false,
              true,
              "esriSpatialRelIntersects",
              `${longitude},${latitude}`,
              "4326",
              "",
              ``,
              "esriGeometryPoint"
            );
            Request(
              arcgisServicesBaseMinistryUrl + "0/query",
              queryOption
            ).then(async (response: any) => {
              if (response.data.features.length > 0) {
                console.log(
                  "response result===>attributes",
                  response.data.features.attributes
                );
                console.log(
                  "response result==>geometry",
                  response.data.features.geometry
                );

                /*////////////////////////////////////////////////*/

                let plot = response.data.features[0];
                console.log("response result===>attributes", plot.attributes);
                console.log("response result==>geometry", plot.geometry);

                let graphic = featureGeometry(
                  "polygon",
                  plot.geometry.rings,
                  "4326"
                );

                console.log("response result==>graphic", graphic);

                let getRegion = await getIntersectWithGraphics(
                  graphic,
                  arcgisServicesBaseMinistryUrl + "4/query"
                );

                console.log("getRegion", getRegion);

                //for test only will remove after test
                if (getRegion == null || getRegion.length < 0) {
                  alert("عفوا القطعة غير متقاطعة مع اي منطقة");
                }
                let region =
                  getRegion[0].attributes.REGIONNAME_AR == null
                    ? ""
                    : getRegion[0].attributes.REGIONNAME_AR;

                /*////////////////////////////////////////////////*/

                let neighbourhood_Name =
                  plot.attributes.DISTRICTNAME_AR == null
                    ? ""
                    : plot.attributes.DISTRICTNAME_AR;
                let districts_Name =
                  plot.attributes.DISTRICTNAME_AR == null
                    ? ""
                    : plot.attributes.DISTRICTNAME_AR;
                let city_Name =
                  plot.attributes.CITYNAME_AR == null
                    ? ""
                    : plot.attributes.CITYNAME_AR;
                let objectId = plot.attributes.OBJECTID;
                setParcel_Cod(plot.attributes.OBJECTID);
                let landId =
                  plot.attributes.SUBDIVISIONPARCELNUMBER == null
                    ? ""
                    : plot.attributes.SUBDIVISIONPARCELNUMBER;

                debugger;
                let typeOfProperty = "";
                if (
                  propertyType == "دور" ||
                  propertyType == "شقة" ||
                  propertyType == "فيلا" ||
                  propertyType == "شقَّة صغيرة (استوديو)" ||
                  propertyType == "غرفة" ||
                  propertyType == "استراحة" ||
                  propertyType == "مجمع" ||
                  propertyType == "برج" ||
                  propertyType == "معرض" ||
                  propertyType == "مكتب" ||
                  propertyType == "مستودع" ||
                  propertyType == "كشك" ||
                  propertyType == "سينما" ||
                  propertyType == "فندق" ||
                  propertyType == "مواقف سيارات" ||
                  propertyType == "ورشة" ||
                  propertyType == "صراف" ||
                  propertyType == "مصنع" ||
                  propertyType == "مدرسة" ||
                  propertyType == "مستشفى، مركز صحي" ||
                  propertyType == "محطة كهرباء" ||
                  propertyType == "برج اتصالات" ||
                  propertyType == "محطة" ||
                  propertyType == "مزرعة" ||
                  propertyType == "عمارة"
                ) {
                  if (advertisementType == "بيع") {
                    typeOfProperty = "مبنى للبيع";
                  } else {
                    typeOfProperty = "ايجار واستثمار";
                  }
                } else if (advertisementType == "إيجار") {
                  typeOfProperty = "ايجار واستثمار";
                }

                if (
                  typeOfProperty == "" ||
                  typeOfProperty == null ||
                  typeOfProperty == undefined
                ) {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `خطأ في معرفه تصنيف العقار المدخل`
                  );
                  return;
                }

                console.log("values", values);
                console.log("typeOfProperty", typeOfProperty);
                console.log("city_Name", city_Name);
                console.log("districts_Name", districts_Name);
                console.log("neighbourhood_Name", neighbourhood_Name);
                if (!isLoading) {
                  setIsLoading(true);
                  console.log("ddIDType", ddIDType);
                  let data: any = {
                    LandTypeDesc: typeOfProperty,
                    IdType: ddIDType == "" ? "1" : ddIDType,
                    AdLicenseNumber: values.adLicenseNumber,
                    LandId: landId,
                    objectId: objectId,
                    CommRegistrationNo:
                      values.CommRegistrationNo == ""
                        ? "0"
                        : values.CommRegistrationNo,
                    Zone: neighbourhood_Name,
                    City: city_Name,
                    District: districts_Name,
                    /*////////////////////////////////*/
                    Region: region,
                    description: values.description,
                    /*////////////////////////////////*/
                  };
                  const formData = new FormData();
                  if (values.file) {
                    for (let i = 0; i < values.file.length; i++) {
                      let checkFileResult = await onFileUploadValidationSize(
                        values.file[i],
                        6 * 1048576
                      );
                      if (!checkFileResult) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ` عفوا الملف  ${values.file[i].name}   حجم اكبر من 6 ميجا`
                        );
                        return;
                      }
                      const files: any = await convertToBase64(values.file[i]);
                      const regex = /data:.*base64,/;
                      const fileWithOut = files.replace(regex, "");

                      formData.append(
                        `AttachMents[${i}].File`,
                        fileWithOut as any
                      );
                      formData.append(
                        `AttachMents[${i}].Name`,
                        values.file[i].name as any
                      );
                    }
                  }

                  formData.append(`Data`, JSON.stringify(data) as any);

                  CreateNhcIntegration(formData, token)
                    .then(async (ss: any) => {
                      console.log(ss);
                      if (ss.data.resultStatus == 200) {
                        console.log("CreateNhcIntegration.data", ss.data);
                        await getFeatureObjectAndAdd(
                          objectId,
                          typeOfProperty,
                          ss.data.id,
                          data.objectId
                        );
                        dismisAddProperty(null, "cancel");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ss.data.message
                        );
                      }
                    })
                    .catch((e: any) => {
                      console.log(e);
                      if (e.response?.status == 401) {
                        window.localStorage.removeItem("persistantState");
                        window.location.assign(`/app/signIn`);
                      }
                      setErrorTitle("حدث خطأ");
                      setErrorMessage(
                        " لم تتم عملية الأضافة .. تأكد من المدخلات! أو أعد المحاولة"
                      );
                      openpErrorModal();
                    });
                }
              } else {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  "لا يمكن العثور علي القطعه المعلن عنها-برجاء مراجعه الاحداثيات بموقع هيئة العقار"
                );
              }
            });
          } else {
            presentToastFunction(
              presentToast,
              "top",
              "danger",
              ss.data.message
            );
          }
        })
        .catch((e: any) => {
          console.log(e);
          if (e.response?.status == 401) {
            window.localStorage.removeItem("persistantState");
            window.location.assign(`/app/signIn`);
          }
          setErrorTitle("حدث خطأ");
          setErrorMessage(
            " لم تتم عملية الأضافة .. تأكد من المدخلات! أو أعد المحاولة"
          );
          openpErrorModal();
        });
    }
  }

  /*//////////////////////////////////////////////////////////////////////////*/

  let getIntersectWithGraphics = async (geometry: any, url: string) => {
    let query = new Query({
      returnGeometry: true,
      geometry: geometry,
      outFields: ["*"],
      spatialRelationship: "intersects",
    });
    let featureLayer = new FeatureLayer({
      url: url,
      outFields: ["*"],
    });
    let intersectingGraphics = await featureLayer.queryFeatures(query);
    return intersectingGraphics.features;
  };
  function featureGeometry(type: any, features: any, wKid: any) {
    let obj: any = {
      type: type,
      spatialReference: {
        wkid: wKid,
      },
    };
    switch (type) {
      case "polyline":
        obj["paths"] = features;
        break;
      case "polygon":
        obj["rings"] = features;
    }
    return obj;
  }

  return (
    <IonPage>
      <IonContent>
        <div className="w-full flex flex-col justify-start items-center bg-white">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                اضافة اعلان جديد
              </p>
            </div>
            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  dismisAddProperty(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              adLicenseNumber: "",
              idType: ddIDType,
              parcel_Cod: 0,
              landTypeDesc: "",
              LandId: "",
              objectId: 0,
              CommRegistrationNo: "",
              Zone: "",
              City: "",
              District: "",
              discription: "",
            }}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={async (values: any, actions) => {
              debugger;
              values.file = files.map((e) => e.file);
              await ApplyDataFromAQAR(values);
              setIsLoading(false);
            }}
            validate={validate}
          >
            {({ handleSubmit }) => (
              <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center">
                {isLoading ? (
                  <Spinner color={"primary"} size={"large"} />
                ) : null}
                <div className="w-full flex flex-col justify-start items-start mt-4 animate__animated animate__fadeInBottomRight">
                  <p className="font-medium text-black text-2xl">
                    بيانات الاعلان
                  </p>
                </div>

                <div className="relative w-full mt-2">
                  <IonList className="animate__animated animate__fadeInBottomRight">
                    <IonRadioGroup
                      value={ddIDType}
                      className="flex flex-nowrap"
                      onIonChange={(ev) => {
                        console.log(
                          "Current value:",
                          JSON.stringify(ev.detail.value)
                        );
                        setAddIDType(ev.detail.value);
                      }}
                    >
                      <IonItem className="w-40">
                        <IonRadio key={1} value={"1"}></IonRadio>
                        <label className="mr-2 text-xl">رقم الهوية</label>
                      </IonItem>
                      <IonItem className="w-60">
                        <IonRadio key={2} value={"2"}>
                          {" "}
                        </IonRadio>
                        <label className="mr-2 text-xl">
                          رقم الموحد للمنشات
                        </label>
                      </IonItem>
                    </IonRadioGroup>
                  </IonList>
                </div>

                {ddIDType == "2" ? (
                  <>
                    <div className="relative w-full mt-6">
                      <Field
                        type="text"
                        name="CommRegistrationNo"
                        disabled={false}
                        className={`${inputClassWithOutError}`}
                      />
                      <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                        رقم السجل التجاري
                      </label>
                    </div>
                  </>
                ) : null}

                <div className="relative w-full mt-6 animate__animated animate__fadeInBottomLeft">
                  <Field
                    type="text"
                    name="adLicenseNumber"
                    disabled={false}
                    className={`${inputClassWithOutError}`}
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                    رقم ترخيص الاعلان
                  </label>

                  {ddIDType == "2" ? (
                    <>
                      <div className="relative w-full mt-6">
                        <label className="absolute text-xl font-bold z-10 bg-white text-paragraphColor -translate-y-6 ">
                          يبدأ ب 72
                        </label>
                      </div>
                    </>
                  ) : (
                    <div className="relative w-full mt-6">
                      <label className="absolute text-xl font-bold z-10 bg-white text-paragraphColor -translate-y-6 ">
                        يبدأ ب 71
                      </label>
                    </div>
                  )}
                </div>

                <div className="w-full flex flex-col justify-start items-start mt-4 animate__animated animate__bounceInUp">
                  <p className="font-medium text-black text-2xl">
                    رفع المرفقات
                  </p>
                  <span className="text-paragraphColor mr-2">
                    يمكن الرفع الى 6 مرفقات
                  </span>
                </div>
                <div className="w-full overflow-x-auto mt-2">
                  <div className="flex space-x-4">
                    {files.map((fileWithProgress, index) => (
                      <div
                        key={index}
                        className="relative flex-shrink-0 mx-1 border-2 border-solid border-paragraphColor rounded-lg"
                      >
                        {fileWithProgress.file.type.startsWith("image/") ? (
                          <img
                            src={fileWithProgress.url || ""}
                            alt="thumbnail"
                            className="w-28 h-28 object-cover"
                          />
                        ) : (
                          <video
                            src={fileWithProgress.url || ""}
                            controls
                            className="w-28 h-28 object-cover"
                          />
                        )}
                        <button
                          onClick={() => {
                            removeFile(index);
                          }}
                          className="absolute top-0 right-0 rounded-full p-1 border-1 text-xl"
                        >
                          <IonIcon
                            className="bg-whiteBlueColor rounded"
                            icon={deleteSvg}
                          />
                        </button>

                        {!fileWithProgress.prepared && (
                          <IonProgressBar
                            value={fileWithProgress.progress}
                            className="absolute bottom-0 left-0 right-0"
                          />
                        )}
                      </div>
                    ))}
                    {files.length < 6 && (
                      <div className="w-28 h-28 border-2 border-dotted border-paragraphColor rounded-md flex items-center justify-center flex-shrink-0 animate__animated animate__bounceInUp">
                        <input
                          type="file"
                          multiple
                          accept="image/*, video/*"
                          onChange={(event) => {
                            handleFileChange(event);
                            // setFieldValue("file", files.map((e) => e.file))
                          }}
                          className="opacity-0"
                          style={{ width: "40px", marginLeft: "-35px" }}
                        />
                        <span className="text-6xl text-paragraphColor">+</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative w-full mt-6">
                  <Field
                    as="textarea"
                    name="description"
                    disabled={false}
                    className={`${inputClassWithOutError}`}
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                    الوصف
                  </label>
                </div>

                {/* <div className="relative w-full  mt-6">
                  <input
                    onChange={(e: any) => {
                      console.log('file:', e.currentTarget.files)

                      setFieldValue("file", e.currentTarget.files);
                    }}
                    type="file"
                    multiple
                    accept=".gif,.jpg,.jpeg,.png,.MPG,video/mp4,video/x-m4v,video/quicktime,video/*"
                    className={`${inputClassWithOutError}`}
                    placeholder="الملف"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    اضافه ملفات
                  </label>

                </div> */}
                <div className="w-full mt-6 animate__animated animate__bounceInUp">
                  <Button
                    theme={"primary"}
                    text={"اضافة الاعلان"}
                    size={"big"}
                    onClick={() => {
                      presentAlert({
                        cssClass: "animate__animated animate__fadeInDown",
                        header: "تنبية",
                        message: `<strong class="strongStyle">هل انت متأكد من اضافة الإعلان ؟ </strong >`,
                        buttons: [
                          {
                            text: "موافق",
                            role: "confirm",
                            handler: () => {
                              handleSubmit();
                            },
                          },
                          {
                            text: "الغاء",
                            role: "cancel",
                            handler: () => {
                              console.log("Alert canceled");
                            },
                          },
                        ],
                        onDidDismiss: (e) => console.log("did dismiss"),
                      });
                    }}
                    // onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddPropertyNhcIntegration;
