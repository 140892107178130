import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { App, URLOpenListenerEvent } from "@capacitor/app";

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slugLocation = event.url.split("app/").pop();
      const slugDetails = event.url.split("app/d").pop();
      const slugEstate = event.url.split("app/estateBrokers").pop();
      const sluggCode = event.url.split("app/gCode").pop();
      if (slugLocation) {
        setTimeout(() => {
          history.push(`/app/${slugLocation}`);
        }, 4000);
      } else if (slugDetails) {
        setTimeout(() => {
          history.push(`/app/d/${slugDetails}`);
        }, 4000);
      } else if (slugEstate) {
        setTimeout(() => {
          history.push(`/app/estateBrokers/${slugEstate}`);
        }, 4000);
      } else if (sluggCode) {
        setTimeout(() => {
          history.push(`/app/gCode/${sluggCode}`);
        }, 4000);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
