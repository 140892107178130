const shareOnWhatsAppFunction = (parcel_Cod: any, message: any, isMap: boolean) => {

  let url = ""
  if (isMap) {
    url = `https://api.whatsapp.com/send?text=${message} 
     thki.sa/app/${parcel_Cod}`;
  } else {
    url = `https://api.whatsapp.com/send?text=${message} 
     thki.sa/app/d/${parcel_Cod}`;
  }

  window.open(url, "_blank");
};
export default shareOnWhatsAppFunction;