import {
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import Button from "../../components/atoms/button";
import { useEffect, useRef, useState } from "react";
import {
  AddPhoneAndMailFunction,
  GetPhoneAndMailFunction,
  SendOTPToPhoneNumber,
} from "../../services/API";
import { useSelector } from "react-redux";
import {
  isObject,
  presentToastFunction,
} from "../../services/genericMethoud/generice";
import Spinner from "../../components/atoms/spinner";
import MessageModal from "../../components/molecules/messageModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { responseCodes } from "../../services/API/responseCodes";

const AddPhoneNumberEmail = ({
  AddPhoneNumberEmailModelDismiss,
}: {
  AddPhoneNumberEmailModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const inputClassWithOutError =
    "block px-5 py-2.5 text-1xl  placeholder-paragraphColor w-full   bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputAddPhoneIsOpenRef = useRef<HTMLIonInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [phone, setPhone] = useState("");
  const inputAddEmailIsOpenRef = useRef<HTMLIonInputElement>(null);
  let { userInfo } = useSelector((state: any) => state.reducer);
  const { token } = useSelector((state: any) => state.reducer);
  const [presentToast] = useIonToast();

  //model property
  const [presentModalSendOTP, onModalSendOTPDismiss] = useIonModal(
    ModalSendOTP,
    {
      PhoneNumber: inputAddPhoneIsOpenRef.current?.value,
      UserId: userInfo.id,
      Email: currentEmail,
      onModalSendOTPDismiss: (data: string, role: string) =>
        onModalSendOTPDismiss(data, role),
    }
  );

  function openModalSendOTP() {
    presentModalSendOTP({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        console.log("ev", ev);
        if (ev.detail.role === "confirm") {
          AddPhoneNumberEmailModelDismiss();
          setIsLoading(false);
          presentToastFunction(
            presentToast,
            "top",
            "success",
            `تم الحفظ بنجاح`
          );
        }
      },
    });
  }

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    GetPhoneAndMailFunc();
  });

  function SaveEmailAndPhoneFunc(
    PhoneNumber: any,
    UserId: any,
    Email: any,
    oTPCode: any
  ) {
    setIsLoading(true);
    AddPhoneAndMailFunction(PhoneNumber, UserId, Email, oTPCode)
      .then((returnedData) => {
        setIsLoading(false);
        console.log("SaveEmailAndPhoneFunc", returnedData);
        if (
          returnedData.data.responseCode == responseCodes.OK &&
          returnedData.data.responseMessage == "send OTP Success"
        ) {
          presentToastFunction(
            presentToast,
            "top",
            "success",
            `تم ارسال كود التحقق لجوالك بنجاح`
          );
          openModalSendOTP();
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `خطأ من فضلك اعد المحاوله`
          );
        }
      })
      .catch((e: any) => {
        setIsLoading(false);
        console.log(e);
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          `خطأ اثناء الحفظ:  ${e}`
        );
      });
  }

  function GetPhoneAndMailFunc() {
    GetPhoneAndMailFunction(userInfo.id, token)
      .then((returnedData) => {
        console.log("GetPhoneAndMailFunc", returnedData);
        setPhone(returnedData.data.dateSet.phoneNumber);
        setEmail(returnedData.data.dateSet.email);
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          `خطأ اثناء استرجاع بيانات المستخدم:  ${e}`
        );
      });
  }

  function SaveEmailAndPhone(phone: any, email: any) {
    console.log("phone", phone);
    console.log("email", email);
    let isEmailValid = validateEmail(email);
    console.log(isEmailValid);
    if (isEmailValid != null && phone) {
      SaveEmailAndPhoneFunc(phone, userInfo.id, email, 0);
    } else {
      presentToastFunction(
        presentToast,
        "top",
        "danger",
        `من فضلك ادخل بيانات الهاتف والبريد الاكتروني صحيح`
      );
    }
  }

  return (
    <IonPage>
      {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
        <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap my-3">
          يرجى تحديث رقم الجوال والبريد الإلكتروني
        </p>
        <div className="w-full mt-2 mb-4 mr-8">
          <h2>جوالك المسجل بالنظام</h2>
          <span className="text-2xl text-redColor">
            {phone == "" ? "فارغ" : phone}{" "}
          </span>
          <h2>البريد الإلكتروني المسجل بالنظام</h2>
          <span className="text-2xl text-redColor">
            {email == "" ? "فارغ" : email}{" "}
          </span>
        </div>

        <div className="w-full mt-2 px-4">
          <IonItem>
            <IonInput
              type="number"
              autofocus={true}
              maxlength={10}
              placeholder="مثال: 0534542111"
              className={`${inputClassWithOutError}`}
              ref={inputAddPhoneIsOpenRef}
            ></IonInput>
            <label className="absolute mt-1 font-bold top-2 z-10  pt-2 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
              رقم الجوال
            </label>
          </IonItem>
        </div>

        <div className="w-full mt-4 px-4">
          <IonItem>
            <IonInput
              type="email"
              placeholder="مثال: asas@mail.com"
              className={`${inputClassWithOutError}`}
              ref={inputAddEmailIsOpenRef}
            ></IonInput>
            <label className="absolute mt-1 font-bold top-2 z-10 pt-2 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
              البريد الإلكتروني
            </label>
          </IonItem>
        </div>
        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
          <Button
            theme={"primary"}
            text={"تحديث"}
            size={"big"}
            onClick={() => {
              debugger;
              if (inputAddPhoneIsOpenRef.current?.value) {
                let currentEmail: any;
                if (inputAddEmailIsOpenRef.current?.value) {
                  currentEmail = inputAddEmailIsOpenRef.current?.value;
                } else {
                  currentEmail = email;
                }
                setCurrentEmail(currentEmail);
                console.log("currentEmail", currentEmail);

                SaveEmailAndPhone(
                  inputAddPhoneIsOpenRef.current?.value,
                  currentEmail
                );
              } else {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  ` يرجي ادخال رقم الجوال`
                );
              }
            }}
          />
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              AddPhoneNumberEmailModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

const ModalSendOTP = ({
  PhoneNumber,
  UserId,
  Email,
  onModalSendOTPDismiss,
}: {
  PhoneNumber: any;
  UserId: any;
  Email: any;
  onModalSendOTPDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const otpCodeRef = useRef<HTMLIonInputElement>(null);
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const [messageTitle] = useState("");
  const [message] = useState("");
  const [presentToast] = useIonToast();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="font-bold text-primaryColor100 text-2xl whitespace-nowrap pt-5 text-center">
            {" "}
            ادخال رمز التحقق
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem>
          <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
            رمز التحقق
          </label>
          <IonInput
            className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
            ref={otpCodeRef}
          />
        </IonItem>
        <div className="w-full pt-5 grid grid-cols-2 gap-4">
          <Button
            theme={"primary"}
            text={"موافق"}
            size={"big"}
            onClick={() => {
              console.log("PhoneNumber", PhoneNumber);
              console.log("UserId", UserId);
              console.log("Email", Email);
              console.log(
                "Number(otpCodeRef.current?.value)",
                Number(otpCodeRef.current?.value)
              );
              if (Number(otpCodeRef.current?.value) > 0) {
                AddPhoneAndMailFunction(
                  PhoneNumber,
                  UserId,
                  Email,
                  Number(otpCodeRef.current?.value)
                )
                  .then((returnedData) => {
                    console.log("returnedData", returnedData);
                    if (
                      returnedData.data.responseCode == responseCodes.OK &&
                      returnedData.data.responseMessage == "add Success"
                    ) {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "success",
                        `تم التحقق بنجاح`
                      );
                      onModalSendOTPDismiss("", "confirm");
                    } else if (
                      returnedData.data.responseCode == responseCodes.Error &&
                      returnedData.data.responseMessage == "your OTP not valid"
                    ) {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "danger",
                        `يرجى ادخال كود التحقق الصحيح`
                      );
                    } else {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "danger",
                        `خطأ اثناء التحقق, حاول مره ثانيه`
                      );
                    }
                  })
                  .catch((e: any) => {
                    console.log(e.response.data);
                    if (isObject(e.response.data.errors)) {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "danger",
                        `خطي في الحفظ`
                      );
                    }
                  });
              } else {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `ادخل كود التحقق`
                );
              }
            }}
          />
          <Button
            theme={"warning"}
            text={"ارسل الكود مره اخري"}
            size={"big"}
            onClick={() => {
              SendOTPToPhoneNumber(PhoneNumber).then((data) => {
                if (data.data.result) {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "success",
                    `تم ارسال كود التحقق`
                  );
                } else {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `خطئ عند ارسال الكود حاول مره ثانية`
                  );
                }
              });
            }}
          />
        </div>

        <div className="pt-5">
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              onModalSendOTPDismiss(null, "cancel");
            }}
          />
        </div>
      </IonContent>
      <MessageModal
        isOpen={messageModalIsOpen}
        errorTitle={messageTitle}
        errorMessage={message}
        onClickDoneButton={() => {
          setMessageModalIsOpen(false);
        }}
      />
    </IonPage>
  );
};

export default AddPhoneNumberEmail;
