import { IonButton, IonContent, useIonModal } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useEffect, useState } from "react";
import Spinner from "../../atoms/spinner";
import { getAdvertisementTypeWithImage } from "../../../services/genericMethoud/generice";
import { useSelector } from "react-redux";
import PropertyForRent_Image from "../../../images/propertyForRent.png";
import PropertyForSale_Image from "../../../images/propertyForSale.png";
import LandForSale_Image from "../../../images/landForSale.png";
import AddPropertyNhcIntegration from "../../../screens/nhcIntegration";

const PropertyTypesModal = ({
  baseLandType,
  propertyNumber,
  objectId,
  parcel_Cod,
  latitude,
  longitude,
  city_Name,
  districts_Name,
  neighbourhood_Name,
  dismissPropertyTypesModal,
}: {
  baseLandType: any;
  propertyNumber: any;
  objectId: any;
  parcel_Cod: any;
  latitude: any;
  longitude: any;
  rings: any;
  city_Name: any;
  districts_Name: any;
  neighbourhood_Name: any;
  dismissPropertyTypesModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [propertyTypes, setpropertyTypes] = useState([{}]);
  const [ProperteItem, setProperteItem] = useState([{}]);
  const [isLoading] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const { userInfo } = useSelector((state: any) => state.reducer);

  //model property
  const [presentAddProperty, dismisAddProperty] = useIonModal(
    AddPropertyNhcIntegration,
    {
      baseLandType: baseLandType,
      propertyNumber: propertyNumber,
      objectId: objectId,
      parcel_Cod: parcel_Cod,
      latitude: latitude,
      longitude: longitude,
      typeOfProperty: ProperteItem,
      city_Name: city_Name,
      districts_Name: districts_Name,
      neighbourhood_Name: neighbourhood_Name,
      dismisAddProperty: (data: string, role: string) =>
        dismisAddProperty(data, role),
    }
  );

  function openpAddPropertyModal() {
    presentAddProperty({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  useEffect(() => {
    if (userInfo?.roles?.some((r: any) => ["user"].includes(r))) {
      setUserRole(true);
    }
    setpropertyTypes(
      getAdvertisementTypeWithImage(
        PropertyForRent_Image,
        PropertyForSale_Image,
        LandForSale_Image
      )
    );
  }, [neighbourhood_Name]);

  return (
    <IonContent>
      {userRole ? (
        <>
          <div className="flex flex-col justify-start items-end">
            <IonButton
              color="danger"
              onClick={() => {
                dismissPropertyTypesModal(null, "cancel");
              }}
            >
              {" "}
              اغلاق
            </IonButton>
          </div>
          <div className="flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl">
              إختار نوع العقار الذي ترغب في اضافته
            </p>

            {isLoading ? <Spinner color={"warning"} size={"large"} /> : null}

            <div className="w-full grid grid-cols-4 mt-6  mr-6 gap-2 ">
              {propertyTypes.map((item: any, index: any) => (
                <div
                  key={index}
                  onClick={() => {
                    openpAddPropertyModal();
                    setProperteItem(item.name);
                    dismissPropertyTypesModal(null, "cancel");
                  }}
                  className="flex flex-col justify-center items-center border-paragraphColor border-2 rounded py-2"
                  style={{ backgroundColor: `${item.color}` }}
                >
                  <img src={item.image} className="w-8" />
                  <p className="font-bold text-primaryColor100 mt-2 text-sm whitespace-nowrap">
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-start items-end">
            <IonButton
              color="danger"
              onClick={() => {
                dismissPropertyTypesModal(null, "cancel");
              }}
            >
              {" "}
              اغلاق
            </IonButton>
          </div>
          <div className="flex flex-col justify-start items-center mt-5">
            <p className="font-bold text-primaryColor100 text-2xl">
              عفوا لايمكنك اضافة اعلان
            </p>
            <p className="font-bold text-primaryColor100 text-2xl">
              يرجى الدخول بحساب مستخدم
            </p>
          </div>
        </>
      )}
    </IonContent>
  );
};

export default PropertyTypesModal;
