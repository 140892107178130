import { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  IonSearchbar,
  useIonToast,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonButton,
} from "@ionic/react";
import {
  arrowBack,
  arrowForward,
  chevronBackOutline,
  trash,
} from "ionicons/icons";
import { useSelector } from "react-redux";
import {
  deleteFavoriteLandFunction,
  getFavoriteLandFunction,
} from "../../services/API";
import Spinner from "../../components/atoms/spinner";
import Button from "../../components/atoms/button";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ErrorModal from "../../components/molecules/errorModal";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import neighborhoodSvg from "../../images/neighborhood.svg";
import emptySvg from "../../images/empty.svg";
import navigateSvg from "../../images/navigate.svg";
import citySvg from "../../assets/uicons/svg/fi-ts-city.svg";
import lastPageSvg from "../../images/lastPage.svg";
import firstPageSvg from "../../images/firstPage.svg";
import Joyride from "react-joyride";

const CreateFavoriteLand = ({
  CreateFavoriteLandDismiss,
  id,
}: {
  CreateFavoriteLandDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
  id?: any;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<any>();
  const [acualData, setAcualData] = useState<any>({});

  const [presentdeleteModal, dismissdeleteModal] = useIonModal(DeleteModal, {
    itemsData: item,
    onDismissdismissdeleteModal: (data: string, role: string) =>
      dismissdeleteModal(data, role),
  });

  function openDeleteModal() {
    presentdeleteModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getFavoriteLandData();
        }
      },
    });
  }

  const handleFavoriteLand = (filter: any) => {
    if (filter) {
      const newAdds = acualData.filter(function (str: any) {
        return str.land_Numbe?.toString()?.includes(filter?.toString());
      });
      setData(newAdds);
      setCurrentPage(1);
    } else {
      getFavoriteLandData();
    }
  };

  function getFavoriteLandData() {
    console.log(id);

    getFavoriteLandFunction(id ?? userInfo.id, token)
      .then((returnedData) => {
        console.log(returnedData);
        setData(returnedData.data);
        setAcualData(returnedData.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".search-bar",
      content: "حقل البحث: يستخدم للبحث عن الوحدات المفضلة.",
      placement: "auto" as const,
      disableBeacon: true,
    },
    {
      target: ".list",
      content: "قائمة الوحدات المفضلة: يتم فيه عرض قائمة من الوحدات المفضلة.",
    },
    {
      target: ".slide",
      content:
        "قائمة التحكم: يمكنك سحب البطاقة يمينًا او يسارًا لعرض عناصر التجكم.",
    },
    {
      target: ".pagination-controls",
      content:
        "التحكم في الصفحات: يمكنك التنقل بين الصفحات ومن الصفحة السابقة إلى الصفحةالتالية.",
    },
  ]);

  useEffect(() => {
    let favLandsOpenedFirstTime = window.localStorage.getItem(
      "favLandsOpenedFirstTime"
    );

    if (favLandsOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }

    getFavoriteLandData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 5;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(data.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <IonPage>
      {!isLoading && run && (
        <Joyride
          steps={steps}
          run={run}
          continuous={run}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          showSkipButton={false}
          hideBackButton={true}
          hideCloseButton={true}
          disableScrolling={false}
          showProgress={true}
          locale={{
            back: "الرجوع",
            close: "اغلاق",
            last: "إنهاء",
            next: "التالي",
            skip: "تخطي",
          }}
          callback={(data) => {
            console.log(data);
            if (data.action == "update" && data.index == 2) {
              let ionItemSliding =
                document.getElementsByTagName("ion-item-sliding")[0];
              if (ionItemSliding) {
                ionItemSliding.open("end");
              }
            }
            if (data.status == "finished" || data.action == "reset") {
              window.localStorage.setItem("favLandsOpenedFirstTime", "true");
            }
            setRun(false);
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <IonContent>
        {isLoading ? (
          <Spinner isFull={true} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                الوحدات المفضلة
              </p>
            </div>
            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  CreateFavoriteLandDismiss(null, "cancel");
                  if (id) window.location.assign(window.location.pathname);
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            <div className="w-full flex flex-col justify-start items-center px-2 search-bar">
              <IonSearchbar
                placeholder="البحث برقم القطعة"
                onIonChange={(ev) => handleFavoriteLand(ev.detail.value)}
              ></IonSearchbar>
            </div>
            {currentCards.length > 0 ? (
              currentCards.map((item: any, index) => {
                return (
                  <IonList className="list slide">
                    <IonItemSliding>
                      {!id && (
                        <IonItemOptions
                          side="end"
                          style={{ width: "auto", textAlign: "center" }}
                        ></IonItemOptions>
                      )}
                      <IonItem lines="none">
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e1e1e1",
                            width: "19em",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 my-2 ${
                            !isLoading && "animate__animated animate__fadeInUp"
                          }`}
                        >
                          <div className="h-full flex flex-col col-span-3 justify-start items-start">
                            <p className="font-bold text-primaryColor100 text-xl">
                              {item.district_Name}
                            </p>

                            {item.city_Name && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={citySvg}
                                  className={`font-bold text-primaryColor100 ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-primaryColor100 text-lg mr-2">
                                  المدينة:{" "}
                                  <span className="text-black">
                                    {item.city_Name}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.neighbourhood_Name && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={neighborhoodSvg}
                                  className={`font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-lg mr-2">
                                  الحي:{" "}
                                  <span className="text-black">
                                    {item.neighbourhood_Name}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="h-full flex flex-col col-span-2 justify-center items-end">
                            {item.land_Numbe && (
                              <div className="flex flex-col justify-end items-center">
                                <p className="font-bold text-primaryColor55 text-xl">
                                  رقم القطعة
                                </p>
                                <p className="text-black text-xl">
                                  {item.land_Numbe}
                                </p>
                              </div>
                            )}
                            <div className="flex flex-row justify-start items-center mt-4 ml-5">
                              <IonIcon
                                size="large"
                                icon={navigateSvg}
                                className={`text-primaryColor100 ${
                                  !isLoading &&
                                  "animate__animated animate__fadeInLeftBig"
                                }`}
                                onClick={() => {
                                  setItem(item);
                                  console.log(item);
                                  window.location.assign(
                                    `/app/${item.parcel_Cod}`
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </IonItem>
                      {!id && (
                        <IonItemOptions
                          side="end"
                          style={{ width: "auto", textAlign: "center" }}
                        >
                          <IonItemOption
                            color="none"
                            onClick={() => {
                              setItem(item);
                              openDeleteModal();
                            }}
                            style={{ fontSize: "19px", width: "3em" }}
                          >
                            <IonIcon
                              slot="top"
                              icon={trash}
                              className="text-2xl text-redColor"
                            ></IonIcon>
                            حذف
                          </IonItemOption>
                        </IonItemOptions>
                      )}
                    </IonItemSliding>
                  </IonList>
                );
              })
            ) : (
              <>
                <div className="pt-5">
                  <p className="text-xl font-bold text-redColor mt-2">
                    عفوا لا يوجد بيانات
                  </p>
                </div>
                <div className="pt-5">
                  <img
                    src={emptySvg}
                    className="animate__animated animate__jello animate__infinite"
                    height={150}
                    width={150}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {currentCards.length > 0 && (
          <div
            className={`pagination-buttons pagination-controls ${
              !isLoading && "animate__animated animate__fadeInDown"
            }`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <IonButton
              onClick={handleFirstPage}
              style={{ maxWidth: "50px" }}
              disabled={currentPage === 1}
            >
              <IonIcon icon={firstPageSvg} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <IonIcon icon={arrowForward} />
            </IonButton>
            {getPageNumbers().map((pageNumber) => (
              <IonButton
                style={{ maxWidth: "40px" }}
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </IonButton>
            ))}
            <IonButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ maxWidth: "50px" }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              <IonIcon icon={lastPageSvg} />
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

const DeleteModal = ({
  itemsData,
  onDismissdismissdeleteModal,
}: {
  itemsData: any;
  onDismissdismissdeleteModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  async function deleteFavoriteLandFeature(parcelCode: any) {
    setIsLoading(true);
    console.log("deleteFavoriteLandFeature parcelCode", parcelCode);

    let getFeatures = await window.F_FavoriteLand.queryFeatures({
      where: `ParcelCode=N'${parcelCode}'`,
      outFields: ["*"],
    });
    console.log("deleteFavoriteLandFeature getFeatures", getFeatures);

    let deleteFeatures: any = [];
    if (getFeatures.features.length > 0) {
      getFeatures.features.forEach((element: any) => {
        deleteFeatures.push({ objectId: element.attributes.OBJECTID });
      });
    }
    console.log("deleteFeatures", deleteFeatures);

    if (deleteFeatures.length > 0) {
      window.F_FavoriteLand.applyEdits({ deleteFeatures: deleteFeatures }).then(
        (result: any) => {
          console.log("delete result", result);
          if (result.deleteFeatureResults[0].error == null) {
            presentToastFunction(
              presentToast,
              "top",
              "success",
              "تمت الحذف بنجاح"
            );
            window.F_FavoriteLand.refresh();
            setIsLoading(false);
          }
        }
      );
    } else {
      setIsLoading(false);
      setErrorTitle("خطأ في تحميل البيانات");
      setErrorMessage("يرجى اختيار القطعه ");
      openpErrorModal();
    }
  }

  return (
    <IonContent>
      <div className="wrapper">
        <div className="flex flex-col justify-start items-center py-6 animate__animated animate__bounceInUp">
          <p className="font-bold text-primaryColor100 text-2xl">
            هل انت متأكد من الحذف ؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            {isLoading ? (
              <Spinner
                isFull={false}
                cssExtra={"absolute top-2"}
                color={"primary"}
                size={"large"}
              />
            ) : null}

            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  setIsLoading(true);
                  console.log("itemsData", itemsData);

                  deleteFavoriteLandFunction(itemsData.id, token)
                    .then(async (result: any) => {
                      console.log("deleteFavoriteLandFunction", result);
                      setIsLoading(true);
                      if (result.data.data) {
                        await deleteFavoriteLandFeature(itemsData.parcel_Cod);
                        onDismissdismissdeleteModal(null, "confirm");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `عفوا لم يتم الحذف`
                        );
                      }
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setErrorTitle("خطأ ");
                      setErrorMessage("يرحي المحاوله مره ثانيه!");
                      openpErrorModal();
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onDismissdismissdeleteModal(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};

export default CreateFavoriteLand;
