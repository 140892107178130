import { IonInput, IonItem, IonPage, useIonToast } from "@ionic/react";
import Button from "../../components/atoms/button";
import { useRef } from "react";
import { SendMessagesToGetLogo } from "../../services/API";
import { useSelector } from "react-redux";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import { responseCodes } from "../../services/API/responseCodes";

const NeedToAddModel = ({
  userId,
  NeedToAddModelDismiss,
}: {
  userId: any;
  IsReport: any;
  IsLogoMessage: any;
  NeedToAddModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const inputNeedAddIsOpenRef = useRef<HTMLIonInputElement>(null);
  const { token } = useSelector((state: any) => state.reducer);
  const [presentToast] = useIonToast();

  return (
    <IonPage>
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
        <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap animate__animated animate__bounceInDown">
          يرجى كتابة محتوي الرسالة
        </p>
        <div className="w-96 mt-4 animate__animated animate__bounceInDown">
          <IonItem>
            <IonInput
              type="text"
              autofocus={true}
              className="block px-4 py-2.5 text-2xl placeholder-primaryColor100 w-full bg-transparent rounded border-2 border-paragraphColor appearance-none focus:outline-none focus:ring-0 focus:border-primaryColor100"
              ref={inputNeedAddIsOpenRef}
            ></IonInput>
          </IonItem>
        </div>
        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4 animate__animated animate__bounceInUp">
          <Button
            theme={"primary"}
            text={"إرسال"}
            size={"big"}
            onClick={() => {
              if (inputNeedAddIsOpenRef.current?.value) {
                SendMessagesToGetLogo(
                  inputNeedAddIsOpenRef.current?.value,
                  userId,
                  false,
                  true,
                  token
                )
                  .then((result: any) => {
                    if (result) {
                      console.log("result", result);
                      if (result.data.responseCode == responseCodes.OK) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `${result.data.responseMessage}`
                        );
                        NeedToAddModelDismiss();
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `${result.data.responseMessage}`
                        );
                      }
                    }
                  })
                  .catch((err) => {
                    presentToastFunction(
                      presentToast,
                      "top",
                      "danger",
                      `لم يتم الإرسال:  ${err}`
                    );
                  });
              }
            }}
          />
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              NeedToAddModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default NeedToAddModel;
