import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPage,
  IonSearchbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteLogosDataFunction,
  editLogosDataIsActiveFunction,
  getLogoslogoAddFunction,
} from "../../services/API";
import Spinner from "../../components/atoms/spinner";
import deactivateSvg from "../../images/deactivateUser.svg";
import activateSvg from "../../images/ActivateUser.svg";
import emptySvg from "../../images/empty.svg";
import idBadgeSvg from "../../assets/uicons/svg/fi-ts-id-badge.svg";
import answerAltSvg from "../../assets/uicons/svg/fi-ts-answer-alt.svg";
import textHeightSvg from "../../assets/uicons/svg/fi-ts-text-height.svg";
import textWidthSvg from "../../assets/uicons/svg/fi-ts-text-width.svg";
import lastPageSvg from "../../images/lastPage.svg";
import firstPageSvg from "../../images/firstPage.svg";
import navigateSvg from "../../images/navigate.svg";
import {
  chevronBackOutline,
  personCircleOutline,
  calendarOutline,
  trash,
  arrowBack,
  arrowForward,
} from "ionicons/icons";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import moment from "moment";
import Joyride from "react-joyride";

const LogosList = ({
  disLogosListModal,
}: {
  disLogosListModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [data, setData] = useState<any>([]);
  const [acualData, setAcualData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<any>();
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();

  const handleSearchName = (name: any) => {
    if (name) {
      const newUsers = acualData.filter(function (str: any) {
        return str.logoName.includes(name);
      });
      setData(newUsers);
      setCurrentPage(1);
    } else {
      GetLogoAddList();
    }
  };

  function editActiveLogosData(isActive: boolean) {
    if (item) {
      let model: any;
      if (isActive) {
        model = {
          Id: item.id,
          IsActive: true,
        };
      } else {
        model = {
          Id: item.id,
          IsActive: false,
        };
      }
      editLogosDataIsActiveFunction(model, token)
        .then((returnedData) => {
          console.log(returnedData);
          if (data.id == model.id) {
            GetLogoAddList();
          } else {
            presentToastFunction(
              presentToast,
              "top",
              "danger",
              `لم يتم تنفيذ العملية`
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function DeleteLogosData() {
    // setIsLoading(true);
    if (item) {
      deleteLogosDataFunction(item.id, token)
        .then((returnedData) => {
          GetLogoAddList();
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }

  function GetLogoAddList() {
    console.log("aaaa", isLoading);

    // setIsLoading(true)
    console.log("aaaa", isLoading);
    getLogoslogoAddFunction(token)
      .then((returnedData) => {
        setData(returnedData.data);
        setAcualData(returnedData.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".search-bar",
      content: "حقل البحث: يستخدم للبحث عن العلامات التجارية.",
      placement: "auto" as const,
      disableBeacon: true,
    },
    {
      target: ".list",
      content:
        "قائمة العلامات التجارية: يتم فيه عرض قائمة من العلامات التجارية.",
    },
    {
      target: ".slide",
      content:
        "قائمة التحكم: يمكنك سحب البطاقة يمينًا او يسارًا لعرض عناصر التجكم.",
    },
    {
      target: ".pagination-controls",
      content:
        "التحكم في الصفحات: يمكنك التنقل بين الصفحات ومن الصفحة السابقة إلى الصفحةالتالية.",
    },
  ]);

  useEffect(() => {
    let logosListOpenedFirstTime = window.localStorage.getItem(
      "logosListOpenedFirstTime"
    );

    if (logosListOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }

    GetLogoAddList();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 2;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(data.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <IonPage>
      {!isLoading && run && (
        <Joyride
          steps={steps}
          run={run}
          continuous={run}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          showSkipButton={false}
          hideBackButton={true}
          hideCloseButton={true}
          disableScrolling={false}
          showProgress={true}
          locale={{
            back: "الرجوع",
            close: "اغلاق",
            last: "إنهاء",
            next: "التالي",
            skip: "تخطي",
          }}
          callback={(data) => {
            console.log(data);
            if (data.action == "update" && data.index == 2) {
              let ionItemSliding =
                document.getElementsByTagName("ion-item-sliding")[0];
              if (ionItemSliding) {
                ionItemSliding.open("end");
              }
            }
            if (data.status == "finished" || data.action == "reset") {
              window.localStorage.setItem("logosListOpenedFirstTime", "true");
            }
            setRun(false);
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <IonContent>
        <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
          {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                العلامات التجارية
              </p>
            </div>

            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  disLogosListModal(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4 search-bar">
            <IonSearchbar
              placeholder="البحث بالاسم"
              onIonChange={(ev) => handleSearchName(ev.detail.value)}
            ></IonSearchbar>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            {currentCards.length > 0 ? (
              currentCards.map((item: any, index: any) => {
                return (
                  <IonList className="list slide">
                    <IonItemSliding>
                      <IonItemOptions
                        side="start"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            presentAlert({
                              cssClass: "animate__animated animate__fadeInDown",
                              header: "تنبية",
                              message: `<strong class="strongStyle">هل انت متأكد من ${
                                item.isActive ? "تعطيل" : "تنشيط"
                              } المستخدم ؟</strong >`,
                              buttons: [
                                {
                                  text: "موافق",
                                  handler: (d) => {
                                    setIsLoading(true);
                                    setItem(item);
                                    item.isActive
                                      ? editActiveLogosData(false)
                                      : editActiveLogosData(true);
                                  },
                                },
                                "الغاء",
                              ],
                              onDidDismiss: (e) => console.log("did dismiss"),
                            });
                          }}
                          style={{ fontSize: "19px", width: "3em" }}
                        >
                          <IonIcon
                            slot="top"
                            icon={item.isActive ? deactivateSvg : activateSvg}
                          ></IonIcon>
                          {item.isActive ? "تعطيل" : "تنشيط"}
                        </IonItemOption>
                      </IonItemOptions>

                      <IonItem lines="none">
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e1e1e1",
                            width: "19em",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 my-2 ${
                            !isLoading && "animate__animated animate__fadeInUp"
                          }`}
                        >
                          <div className="h-full flex flex-col col-span-3 justify-start items-start">
                            {item.id && (
                              <div className="flex flex-row justify-start items-center">
                                <IonIcon
                                  icon={idBadgeSvg}
                                  className={`text-darkGreen100 font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-darkGreen100 text-lg mr-2">
                                  الكود:{" "}
                                  <span className="text-black">{item.id}</span>
                                </p>
                              </div>
                            )}

                            {item.logoName && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={personCircleOutline}
                                  className={`text-darkGreen50 font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-darkGreen50 text-lg mr-2 leading-5 w-36">
                                  الاسم:{" "}
                                  <span className="text-black">
                                    {item.logoName}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.description && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={answerAltSvg}
                                  className={`text-indigo font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-indigo text-lg mr-2 leading-5 w-36">
                                  الوصف:{" "}
                                  <span className="text-black">
                                    {item.description}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.height && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={textHeightSvg}
                                  className={`text-tan font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <br />
                                <p className="text-tan text-lg mr-2">
                                  الارتفاع:{" "}
                                  <span className="text-black">
                                    {item.height}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.width && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={textWidthSvg}
                                  className={`text-darkGreen100 font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <br />
                                <p className="text-darkGreen100 text-lg mr-2">
                                  العرض:{" "}
                                  <span className="text-black">
                                    {item.width}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.createdLogoDate && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={calendarOutline}
                                  className={`text-darkGreen50 font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <br />
                                <p className="text-darkGreen50 text-lg mr-2">
                                  الإنشاء:{" "}
                                  <span className="text-black">
                                    {moment(item.createdLogoDate).format("LL")}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.expireLogoDate && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={calendarOutline}
                                  className={`text-indigo font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <br />
                                <p className="text-indigo text-lg mr-2">
                                  الإنتهاء:{" "}
                                  <span className="text-black">
                                    {moment(item.expireLogoDate).format("LL")}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="h-full flex flex-col col-span-2 justify-center items-end mt-20">
                            <div className="flex flex-row justify-start items-center">
                              <IonIcon
                                icon={navigateSvg}
                                className={`text-primaryColor100 ${
                                  !isLoading &&
                                  "animate__animated animate__fadeInLeftBig"
                                }`}
                                style={{ fontSize: "40px" }}
                                onClick={() => {
                                  setItem(item);
                                  window.location.assign(
                                    `/app/${item.parcelObjId}`
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </IonItem>

                      <IonItemOptions
                        side="end"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setItem(item);
                            presentAlert({
                              cssClass: "animate__animated animate__fadeInDown",
                              header: "تنبية",
                              message: `<strong class="strongStyle">هل تريد الحذف ؟ </strong >`,
                              buttons: [
                                {
                                  text: "موافق",
                                  handler: (d: any) => {
                                    setItem(item);
                                    DeleteLogosData();
                                  },
                                },
                                "الغاء",
                              ],
                              onDidDismiss: (e: any) =>
                                console.log("did dismiss"),
                            });
                          }}
                          style={{ fontSize: "19px", width: "3em" }}
                        >
                          <IonIcon
                            slot="top"
                            icon={trash}
                            className="text-2xl text-redColor"
                          ></IonIcon>
                          حذف
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  </IonList>
                );
              })
            ) : (
              <>
                <div className="pt-5">
                  <p className="text-xl font-bold text-redColor mt-2">
                    عفوا لا يوجد بيانات
                  </p>
                </div>
                <div className="pt-5">
                  <img
                    src={emptySvg}
                    className="animate__animated animate__jello animate__infinite"
                    height={150}
                    width={150}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {currentCards.length > 0 && (
          <div
            className={`pagination-buttons pagination-controls ${
              !isLoading && "animate__animated animate__fadeInDown"
            }`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <IonButton
              onClick={handleFirstPage}
              style={{ maxWidth: "50px" }}
              disabled={currentPage === 1}
            >
              <IonIcon icon={firstPageSvg} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <IonIcon icon={arrowForward} />
            </IonButton>
            {getPageNumbers().map((pageNumber) => (
              <IonButton
                style={{ maxWidth: "40px" }}
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </IonButton>
            ))}
            <IonButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ maxWidth: "50px" }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              <IonIcon icon={lastPageSvg} />
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default LogosList;
