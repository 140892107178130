import {
  IonContent,
  useIonToast,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonFooter,
  IonPage,
  IonSearchbar,
  IonButton,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  useIonModal,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Spinner from "../../components/atoms/spinner";
import emptySvg from "../../images/empty.svg";
import payServiceSvg from "../../assets/uicons/svg/fi-ts-payroll-check.svg";
import maBalanceSvg from "../../assets/uicons/svg/fi-ts-calculator-money.svg";
import {
  arrowBack,
  arrowForward,
  calendarOutline,
  chevronBackOutline,
  colorWandOutline,
  trash,
} from "ionicons/icons";
import {
  deleteNhcIntegration,
  getAllNhcIntegrationFunction,
  getNhcIntegrationByAdLicenseNumberFunction,
} from "../../services/API";
import { useSelector } from "react-redux";
import lastPageSvg from "../../images/lastPage.svg";
import firstPageSvg from "../../images/firstPage.svg";
import editSvg from "../../images/editSvg.svg";
import navigateSvg from "../../images/navigate.svg";
import citySvg from "../../assets/uicons/svg/fi-ts-city.svg";
import districtSvg from "../../images/district.svg";
import neighborhoodSvg from "../../images/neighborhood.svg";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import EditNhcIntegrationAdvistment from "../editNhcIntegrationAdvistment";
import Graphic from "@arcgis/core/Graphic";
import Request from "@arcgis/core/request";
import Polygon from "@arcgis/core/geometry/Polygon";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Point from "@arcgis/core/geometry/Point";
import { arcgisServicesBaseMinistryUrl } from "../../configs/arcgisServices";
import {
  requestOptions,
  selectedSymbol,
} from "../../services/genericMethoud/gisMethouds";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Query from "@arcgis/core/rest/support/Query";
import Button from "../../components/atoms/button";
import ErrorModal from "../../components/molecules/errorModal";
import moment from "moment";

const AllAdvertisements = ({
  onDismissAllAdvertisements,
}: {
  onDismissAllAdvertisements: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  let { token } = useSelector((state: any) => state.reducer);

  const [allAdvertisements, setAllAdvertisements]: any = useState([]);
  const [actualAllAdvertisements, setActualAllAdvertisements]: any = useState();

  const [expiredAdvertisements, setExpiredAdvertisements]: any = useState([]);
  const [actualExpiredAdvertisements, setActualExpiredAdvertisements]: any =
    useState();

  const [item, setItem] = useState<any>({});
  const [advItem, setAdvItemData] = useState<any>({});

  const { userInfo } = useSelector((state: any) => state.reducer);
  const [isLoading, setIsLoading] = useState(false);

  const [currentAllAdvertisements, setCurrentAllAdvertisements] =
    useState("allAdvertisements");

  let [presentToast] = useIonToast();
  let graphicsLayer: GraphicsLayer = new GraphicsLayer();

  function getAllAdvertisements() {
    setIsLoading(true);
    setAllAdvertisements(null);
    setActualAllAdvertisements(null);
    getAllNhcIntegrationFunction(token).then((result: any) => {
      if (result.status == 200) {
        console.log("aaaaaaaaaaaaaa==>", result);
        setAllAdvertisements(
          result?.data?.sort((a: any, b: any) => b.id - a.id)
        );
        setActualAllAdvertisements(result?.data);
      }
      setIsLoading(false);
    });
  }

  function getExpiredAdvertisements(dataToFilter = actualAllAdvertisements) {
    setIsLoading(true);
    const NowDate = new Date().toJSON().split("T")[0];

    const expiredAdvertisements = dataToFilter.filter((current: any) => {
      const endDate = current?.endAddDate?.split("T")[0];
      return endDate && endDate != "0001-01-01" && endDate <= NowDate;
    });
    setExpiredAdvertisements(expiredAdvertisements);
    setActualExpiredAdvertisements(expiredAdvertisements);
    setIsLoading(false);
  }

  useEffect(() => {
    getAllAdvertisements();
    console.log(userInfo);
    getParcelsCount();
    // contentRef.current?.scrollToTop(500);
  }, []);

  const handleAllAdvertisements = (filter: any) => {
    if (filter) {
      const newAdds = actualAllAdvertisements.filter(function (str: any) {
        return str.adLicenseNumber.toString().includes(filter);
      });

      if (!newAdds || newAdds.length == 0) {
        setAllAdvertisements("emptyAfterSearch");
        return;
      }
      setAllAdvertisements(newAdds);
      setCurrentPage(1);
    } else {
      getAllAdvertisements();
    }
    debugger;
  };

  const handleExpiredAdvertisements = (filter: any) => {
    if (filter) {
      const newAdds = actualExpiredAdvertisements.filter(function (str: any) {
        return str.adLicenseNumber.toString().includes(filter);
      });

      if (!newAdds || newAdds.length == 0) {
        setExpiredAdvertisements("emptyAfterSearch");
        return;
      }
      setExpiredAdvertisements(newAdds);
      setCurrentPage(1);
    } else {
      getExpiredAdvertisements();
    }
    debugger;
  };

  const [presentEditAdvertisementModal, dismissEditAdvertisementModal] =
    useIonModal(EditNhcIntegrationAdvistment, {
      advertisementId: item.id,
      objectId: item.objectId,
      adLicenseNumber: item.adLicenseNumber,
      addIDType: item.idType,
      advertisement: advItem,
      onDismissdismisEditAdvertisementModal: (data: string, role: string) =>
        dismissEditAdvertisementModal(data, role),
    });

  function openEditAdvertisementModal(adLicenseNumber: any, idType: any) {
    console.log("idType", idType);

    getNhcIntegrationByAdLicenseNumberFunction(adLicenseNumber, idType).then(
      (result) => {
        console.log("getNhcIntegrationByAdLicenseNumberFunction==>", result);
        setIsLoading(false);
        if (result?.data?.message) {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `${result?.data?.message}`
          );
        } else {
          setAdvItemData(result.data.data);
          presentEditAdvertisementModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
              getAllAdvertisements();
            },
          });
        }
      }
    );
  }

  //#region zoom to objId
  function createFeatureGraphics(
    type: any,
    geometry: any,
    wiki: any,
    attr: any,
    symbolS: any
  ) {
    console.log("createFeatureGraphics ==>geometry", geometry);
    let fFeature: any;
    switch (type) {
      case "polygon":
        fFeature = new Polygon({
          rings: geometry,
          spatialReference: {
            wkid: wiki,
          },
        });
        break;
      case "point":
        fFeature = new Point({
          x: geometry.x,
          y: geometry.y,
          spatialReference: {
            wkid: wiki,
          },
        });
        break;
    }

    let fFeatureGraphic = new Graphic({
      geometry: fFeature,
      symbol: symbolS,
      attributes: attr,
    });
    return fFeatureGraphic;
  }
  let zoomToLayer = (type: any, geometry: any, wiki: any, ZoomLevel: any) => {
    graphicsLayer.removeAll();
    let fGetGraphic: any = createFeatureGraphics(
      type,
      geometry,
      wiki,
      "",
      selectedSymbol
    );
    graphicsLayer.add(fGetGraphic);

    if (ZoomLevel) {
      let opts = {
        duration: 5000,
      };
      window.mapviews.goTo(
        {
          target: fGetGraphic,
          zoom: 17,
        },
        opts
      );
    } else {
      let gr: any = graphicsLayer.graphics;
      window.mapviews.goTo(gr.items[0].geometry.extent.expand(2));
    }
  };

  let getGraphicFromObj = (obj: any) => {
    let queryOption: any = {
      responseType: "json",
      query: {
        f: "json",
        where: `OBJECTID in (${obj}) `,
        returnCountOnly: false,
        outFields: "*",
        returnDistinctValues: false,
        returnGeometry: true,
      },
    };
    Request(arcgisServicesBaseMinistryUrl + "0/query", queryOption).then(
      (response: any) => {
        if (response.data.features.length >= 0) {
          zoomToLayer(
            "polygon",
            response.data.features[0].geometry.rings[0],
            4326,
            false
          );
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `يرجى التاكد من رقم القطعة`
          );
        }
      }
    );
    setIsLoading(false);
  };
  //#endregion

  // const [ToBottom, setToBottom]: any = useState(true);
  // const contentRef = createRef<HTMLIonContentElement>();

  // const scrollToBottomAndTop = () => {
  //   if (ToBottom) {
  //     contentRef.current?.scrollToBottom(500);
  //     setToBottom(!ToBottom);
  //   } else {
  //     contentRef.current?.scrollToTop(500);
  //     setToBottom(!ToBottom);
  //   }
  // };

  const [presentdeleteModal, dismissdeleteModal] = useIonModal(DeleteModal, {
    itemsData: item,
    allData: allAdvertisements,
    onDismissdismissdeleteModal: (data: string, role: string) =>
      dismissdeleteModal(data, role),
  });

  function openDeleteModal() {
    presentdeleteModal({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        getAllAdvertisements();
      },
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards =
    currentAllAdvertisements == "allAdvertisements"
      ? allAdvertisements?.slice(indexOfFirstCard, indexOfLastCard)
      : expiredAdvertisements?.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages =
    currentAllAdvertisements == "allAdvertisements"
      ? Math.ceil(allAdvertisements?.length / cardsPerPage)
      : Math.ceil(expiredAdvertisements?.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const [isArcServerWorks, setIsArcServerWorks] = useState(false);

  const getParcelsCount = () => {
    let queryOption: any = requestOptions(
      "",
      true,
      false,
      "",
      "",
      "",
      "",
      `1=1`
    );
    Request(arcgisServicesBaseMinistryUrl + "0/query", queryOption).then(
      (response: any) => {
        if (response.data) {
          setIsArcServerWorks(true);
        }
      }
    );
  };

  return (
    <IonPage>
      <>
        {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}

        {currentAllAdvertisements === "allAdvertisements" && (
          // <IonContent ref={contentRef}>
          <IonContent>
            <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
              <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                <div className="col-span-1 "></div>
                <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                  <p className="font-bold text-white text-2xl whitespace-nowrap">
                    جميع الإعلانات
                  </p>
                </div>
                <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                  <button
                    onClick={() => {
                      onDismissAllAdvertisements(null, "cancel");
                    }}
                    className="flex flex-col justify-center items-center"
                  >
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-white"
                    />
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-center p-4">
                <div className="w-full flex flex-col justify-start items-center px-2 search-bar">
                  <IonSearchbar
                    placeholder="البحث برقم الإعلان"
                    onIonChange={(ev) =>
                      handleAllAdvertisements(ev.detail.value)
                    }
                  ></IonSearchbar>
                </div>
                {allAdvertisements &&
                allAdvertisements != "emptyAfterSearch" &&
                allAdvertisements.length > 0 ? (
                  <>
                    {currentCards.map((item: any, index: any) => {
                      return (
                        <IonList className="list slide">
                          <IonItemSliding>
                            <IonItemOptions
                              side="start"
                              style={{ width: "auto", textAlign: "center" }}
                            >
                              <IonItemOption
                                color="none"
                                onClick={() => {
                                  setItem(item);
                                  openEditAdvertisementModal(
                                    item.adLicenseNumber,
                                    item.idType
                                  );
                                }}
                                style={{ fontSize: "19px", width: "3em" }}
                              >
                                <IonIcon slot="top" icon={editSvg}></IonIcon>
                                تعديل
                              </IonItemOption>
                            </IonItemOptions>
                            <IonItem lines="none" key={index}>
                              <div
                                style={{
                                  border: "1px solid #e1e1e1",
                                  width: "19em",
                                  borderRadius: "10px",
                                }}
                                className="shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 mb-4 animate__animated animate__fadeInUp"
                              >
                                <div className="h-full flex flex-col col-span-3 justify-start items-start">
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    <span>رقم الاعلان: </span>(
                                    {item.adLicenseNumber})
                                  </p>
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    {item.landTypeDesc}
                                  </p>

                                  {item.city && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={citySvg}
                                        className="font-bold text-primaryColor100 animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-primaryColor100 text-lg mr-2">
                                        المدينة:{" "}
                                        <span className="text-black">
                                          {item.city}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.zone && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={districtSvg}
                                        className="font-bold text-darkGreen100 animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-darkGreen100 text-lg mr-2">
                                        الحي :
                                        <span className="text-black">
                                          {item.zone}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.propertyType && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={neighborhoodSvg}
                                        className="font-bold animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-lg mr-2">
                                        النوع:{" "}
                                        <span className="text-black">
                                          {item.propertyType}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.creationAddDate && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={calendarOutline}
                                        className="font-bold animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-lg mr-2">
                                        الانشاء:{" "}
                                        <span className="text-black">
                                          {moment(item.creationAddDate).format(
                                            "L"
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.endAddDate && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={calendarOutline}
                                        className="font-bold animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-lg mr-2">
                                        الانتهاء:{" "}
                                        <span className="text-black">
                                          {moment(item.endAddDate).format("L")}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.id && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={colorWandOutline}
                                        className="font-bold animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-lg mr-2">
                                        معرف ذكي:
                                        <span className="text-black">
                                          {item.id}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="h-full flex flex-col col-span-2 justify-center items-end">
                                  {item.landId && (
                                    <div className="flex flex-col justify-end items-center">
                                      <p className="font-bold text-primaryColor55 text-xl">
                                        رقم القطعة
                                      </p>
                                      <p className="text-black text-xl">
                                        {item.landId}
                                      </p>
                                    </div>
                                  )}
                                  <div className="flex flex-row justify-start items-center mt-4 ml-5">
                                    <IonIcon
                                      size="large"
                                      icon={navigateSvg}
                                      className="text-primaryColor100 text-xl animate__animated animate__fadeInLeftBig"
                                      onClick={() => {
                                        setItem(item);
                                        getGraphicFromObj(item.objectId);
                                        onDismissAllAdvertisements(
                                          null,
                                          "cancel"
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </IonItem>

                            <IonItemOptions
                              side="end"
                              style={{ width: "auto", textAlign: "center" }}
                            >
                              <IonItemOption
                                color="none"
                                onClick={() => {
                                  if (!isArcServerWorks) {
                                    presentToastFunction(
                                      presentToast,
                                      "top",
                                      "danger",
                                      `عفوًا بيانات قطع الاراضي غير متاحه حاليًا`
                                    );
                                    return;
                                  }

                                  setItem(item);
                                  openDeleteModal();
                                }}
                                style={{ fontSize: "19px", width: "3em" }}
                              >
                                <IonIcon
                                  slot="top"
                                  icon={trash}
                                  className="text-2xl text-redColor"
                                ></IonIcon>
                                حذف
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        </IonList>
                      );
                    })}
                    {currentCards.length > 0 && (
                      <div
                        className={`pagination-buttons pagination-controls${
                          !isLoading && "animate__animated animate__fadeInDown"
                        }`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <IonButton
                          onClick={handleFirstPage}
                          style={{ maxWidth: "50px" }}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={firstPageSvg} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={arrowForward} />
                        </IonButton>
                        {getPageNumbers().map((pageNumber) => (
                          <IonButton
                            style={{ maxWidth: "40px" }}
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </IonButton>
                        ))}
                        <IonButton
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          style={{ maxWidth: "50px" }}
                        >
                          <IonIcon icon={arrowBack} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handleLastPage}
                          disabled={currentPage === totalPages}
                        >
                          <IonIcon icon={lastPageSvg} />
                        </IonButton>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                    <div className="pt-5">
                      <p className="text-3xl font-bold text-redColor mt-2">
                        {allAdvertisements == "emptyAfterSearch"
                          ? "لا توجد نتائج للبحث"
                          : "لا توجد اعلانات في الوقت الحالي"}
                      </p>
                    </div>
                    <div className="pt-5 px-32">
                      <img
                        src={emptySvg}
                        className="animate__animated animate__jello animate__infinite"
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                )}
                {/* <IonFab
                    slot="fixed"
                    vertical="bottom"
                    horizontal="end"
                    className="fixed bottom-20 rounded-full shadow-lg transition duration-300 z-50"
                  >
                    <IonFabButton size="small" onClick={scrollToBottomAndTop}>
                      <IonIcon icon={ToBottom ? arrowDown : arrowUp}></IonIcon>
                    </IonFabButton>
                  </IonFab> */}
              </div>
            </div>
          </IonContent>
        )}

        {currentAllAdvertisements === "expiredAdvertisements" && (
          <IonContent>
            <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
              <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                <div className="col-span-1 "></div>
                <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                  <p className="font-bold text-white text-2xl whitespace-nowrap">
                    الإعلانات المنتهية
                  </p>
                </div>
                <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                  <button
                    onClick={() => onDismissAllAdvertisements(null, "cancel")}
                    className="flex flex-col justify-center items-center"
                  >
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-white"
                    />
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-center p-4">
                <div className="w-full flex flex-col justify-start items-center px-2">
                  <IonSearchbar
                    placeholder="البحث رقم الإعلان"
                    onIonChange={(ev) =>
                      handleExpiredAdvertisements(ev.detail.value)
                    }
                  ></IonSearchbar>
                </div>
                {expiredAdvertisements &&
                expiredAdvertisements != "emptyAfterSearch" &&
                expiredAdvertisements.length > 0 ? (
                  <>
                    {currentCards.map((item: any, index: any) => {
                      return (
                        <IonList className="expiredAds">
                          <IonItemSliding>
                            <IonItemOptions
                              side="start"
                              style={{ width: "auto", textAlign: "center" }}
                            >
                              <IonItemOption
                                color="none"
                                onClick={() => {
                                  setItem(item);
                                  openEditAdvertisementModal(
                                    item.adLicenseNumber,
                                    item.idType
                                  );
                                }}
                                style={{ fontSize: "19px", width: "3em" }}
                              >
                                <IonIcon slot="top" icon={editSvg}></IonIcon>
                                تعديل
                              </IonItemOption>
                            </IonItemOptions>
                            <IonItem lines="none" key={index}>
                              <div
                                style={{
                                  border: "1px solid #e1e1e1",
                                  width: "19em",
                                  borderRadius: "10px",
                                }}
                                className="shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 mb-4 animate__animated animate__fadeInUp"
                              >
                                <div className="h-full flex flex-col col-span-3 justify-start items-start">
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    <span>رقم الاعلان: </span>(
                                    {item.adLicenseNumber})
                                  </p>
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    {item.landTypeDesc}
                                  </p>

                                  {item.city && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={citySvg}
                                        className="font-bold text-primaryColor100 animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-primaryColor100 text-lg mr-2">
                                        المدينة:{" "}
                                        <span className="text-black">
                                          {item.city}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.zone && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={districtSvg}
                                        className="font-bold text-darkGreen100 animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-darkGreen100 text-lg mr-2">
                                        الحي :
                                        <span className="text-black">
                                          {item.zone}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {item.propertyType && (
                                    <div className="flex flex-row justify-start items-center mt-1">
                                      <IonIcon
                                        icon={neighborhoodSvg}
                                        className="font-bold animate__animated animate__fadeInRightBig"
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p className="text-lg mr-2">
                                        النوع:{" "}
                                        <span className="text-black">
                                          {item.propertyType}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="h-full flex flex-col col-span-2 justify-center items-end">
                                  {item.landId && (
                                    <div className="flex flex-col justify-end items-center">
                                      <p className="font-bold text-primaryColor55 text-xl">
                                        رقم القطعة
                                      </p>
                                      <p className="text-black text-xl">
                                        {item.landId}
                                      </p>
                                    </div>
                                  )}
                                  <div className="flex flex-row justify-start items-center mt-4 ml-5">
                                    <IonIcon
                                      size="large"
                                      icon={navigateSvg}
                                      className="text-primaryColor100 text-xl animate__animated animate__fadeInLeftBig"
                                      onClick={() => {
                                        setItem(item);
                                        getGraphicFromObj(item.objectId);
                                        onDismissAllAdvertisements(
                                          null,
                                          "cancel"
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="flex flex-col justify-end items-center">
                                    <p className="font-bold text-redColor text-xl">
                                      اعلان منتهي
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </IonItem>

                            <IonItemOptions
                              side="end"
                              style={{ width: "auto", textAlign: "center" }}
                            >
                              <IonItemOption
                                color="none"
                                onClick={() => {
                                  if (!isArcServerWorks) {
                                    presentToastFunction(
                                      presentToast,
                                      "top",
                                      "danger",
                                      `عفوًا بيانات قطع الاراضي غير متاحه حاليًا`
                                    );
                                    return;
                                  }

                                  setItem(item);
                                  openDeleteModal();
                                }}
                                style={{ fontSize: "19px", width: "3em" }}
                              >
                                <IonIcon
                                  slot="top"
                                  icon={trash}
                                  className="text-2xl text-redColor"
                                ></IonIcon>
                                حذف
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        </IonList>
                      );
                    })}
                    {currentCards.length > 0 && (
                      <div
                        className={`pagination-buttons`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <IonButton
                          onClick={handleFirstPage}
                          style={{ maxWidth: "50px" }}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={firstPageSvg} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={arrowForward} />
                        </IonButton>
                        {getPageNumbers().map((pageNumber) => (
                          <IonButton
                            style={{ maxWidth: "40px" }}
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </IonButton>
                        ))}
                        <IonButton
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          style={{ maxWidth: "50px" }}
                        >
                          <IonIcon icon={arrowBack} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handleLastPage}
                          disabled={currentPage === totalPages}
                        >
                          <IonIcon icon={lastPageSvg} />
                        </IonButton>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                    <div className="pt-5">
                      <p className="text-3xl font-bold text-redColor mt-2">
                        {allAdvertisements == "emptyAfterSearch"
                          ? "لا توجد نتائج للبحث"
                          : "لا توجد اعلانات منتهية في الوقت الحالي"}
                      </p>
                    </div>
                    <div className="pt-5 px-32">
                      <img
                        src={emptySvg}
                        className="animate__animated animate__jello animate__infinite"
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                )}
                {/* <IonFab
                    slot="fixed"
                    vertical="bottom"
                    horizontal="end"
                    className="fixed bottom-20 rounded-full shadow-lg transition duration-300 z-50"
                  >
                    <IonFabButton size="small" onClick={scrollToBottomAndTop}>
                      <IonIcon icon={ToBottom ? arrowDown : arrowUp}></IonIcon>
                    </IonFabButton>
                  </IonFab> */}
              </div>
            </div>
          </IonContent>
        )}

        <IonFooter translucent={true}>
          <IonSegment value={currentAllAdvertisements}>
            <IonSegmentButton
              className="animate__animated animate__rubberBand"
              value="expiredAdvertisements"
              onClick={() => {
                setCurrentAllAdvertisements("expiredAdvertisements");
                getExpiredAdvertisements();
                handleFirstPage();
              }}
            >
              <IonLabel
                className="text-primaryColor100"
                style={{ textWrap: "wrap" }}
              >
                الإعلانات المنتهية
              </IonLabel>
              <IonIcon
                icon={maBalanceSvg}
                className="text-primaryColor100"
              ></IonIcon>
            </IonSegmentButton>

            <IonSegmentButton
              className="animate__animated animate__rubberBand"
              value="allAdvertisements"
              onClick={() => {
                setCurrentAllAdvertisements("allAdvertisements");
                handleFirstPage();
              }}
            >
              <IonLabel
                className="text-primaryColor100"
                style={{ textWrap: "wrap" }}
              >
                جميع الإعلانات
              </IonLabel>
              <IonIcon
                icon={payServiceSvg}
                className="text-primaryColor100"
              ></IonIcon>
            </IonSegmentButton>
          </IonSegment>
        </IonFooter>
      </>
    </IonPage>
  );
};

const DeleteModal = ({
  itemsData,
  allData,
  onDismissdismissdeleteModal,
}: {
  itemsData: any;
  allData: any;
  onDismissdismissdeleteModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  let getFeatureObjectAndAdd = async (itemData: any) => {
    try {
      console.log("allData", allData);
      console.log("getFeatureObjectAndAdd itemData", itemData);

      const currentAdds = allData.filter(function (str: any) {
        return str.objectId.toString().includes(itemData.objectId);
      });
      console.log("getFeatureObjectAndAdd currentAdds", currentAdds);

      let objectId: any;
      let parcel_Status: any = [];
      let actualParcel_Status: any = [];
      if (currentAdds.length > 1) {
        currentAdds.forEach((element: any) => {
          console.log("element", element);
          objectId = element.objectId;
          if (itemData.id != element.id) {
            parcel_Status.push(element.landTypeDesc);
          }
        });

        const containsAll = (needles: any, haystack: any) =>
          needles.every(Set.prototype.has, new Set(haystack));

        let containsAll_1 = containsAll(
          ["مبنى للبيع", "ارض للبيع"],
          parcel_Status
        );
        let containsAll_2 = containsAll(
          ["مبنى للبيع", "ايجار واستثمار"],
          parcel_Status
        );
        let containsAll_3 = containsAll(
          ["ارض للبيع", "ايجار واستثمار"],
          parcel_Status
        );
        let containsAll_4 = containsAll(
          ["مبنى للبيع", "ارض للبيع", "ايجار واستثمار"],
          parcel_Status
        );

        if (containsAll_1 || containsAll_2 || containsAll_3 || containsAll_4) {
          actualParcel_Status = "مبنى مختلط";
        } else {
          actualParcel_Status = parcel_Status[0];
        }

        console.log("containsAll_1", containsAll_1);
        console.log("containsAll_2", containsAll_2);
        console.log("containsAll_3", containsAll_3);
        console.log("containsAll_4", containsAll_4);
      }

      console.log("getFeatureObjectAndAdd objectId", objectId);
      console.log("getFeatureObjectAndAdd parcel_Status", parcel_Status);
      console.log(
        "getFeatureObjectAndAdd actualParcel_Status",
        actualParcel_Status
      );

      let query_Land_parcels = new Query({
        returnGeometry: false,
        where: `Parcel_Cod in ('${itemData.objectId}') `,
        outFields: ["*"],
      });

      let _Land_parcels = await window.F_Land_parcels.queryFeatures(
        query_Land_parcels
      );
      console.log("_Land_parcels.features", _Land_parcels.features);
      let deleteFeatures: any = [];

      if (_Land_parcels.features.length > 0) {
        _Land_parcels.features.forEach((element: any) => {
          deleteFeatures.push({ objectId: element.attributes.OBJECTID });
        });
      }

      if (deleteFeatures.length > 0) {
        window.F_Land_parcels.applyEdits({
          deleteFeatures: deleteFeatures,
        }).then((result: any) => {
          console.log("delete result", result);
          if (result.deleteFeatureResults[0].error == null) {
            presentToastFunction(
              presentToast,
              "top",
              "success",
              "تم الحذف بنجاح"
            );
            window.F_Land_parcels.refresh();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setErrorTitle("خطأ في تحميل البيانات");
            setErrorMessage("يرجى اختيار القطعه");
            openpErrorModal();
          }
        });
      }

      if (currentAdds.length > 1) {
        let query = new Query({
          returnGeometry: true,
          objectIds: [objectId],
          outFields: ["*"],
        });

        let featureLayer = new FeatureLayer({
          url: arcgisServicesBaseMinistryUrl + "0",
          outFields: ["*"],
        });
        let intersectingGraphics = await featureLayer.queryFeatures(query);
        console.log(
          "intersectingGraphics.features",
          intersectingGraphics.features
        );

        const attributes: any = {};
        attributes["Parcel_Status"] = actualParcel_Status;
        attributes["Land_Numbe"] =
          intersectingGraphics.features[0].attributes.SUBDIVISIONPARCELNUMBER;
        attributes["Parcel_Cod"] =
          intersectingGraphics.features[0].attributes.OBJECTID;
        attributes["NK"] =
          intersectingGraphics.features[0].attributes.CITYNAME_AR;
        attributes["DK"] =
          intersectingGraphics.features[0].attributes.DISTRICTNAME_AR;
        attributes["ZK"] =
          intersectingGraphics.features[0].attributes.REGIONNAME_AR;

        const addFeature = new Graphic({
          geometry: intersectingGraphics.features[0].geometry,
          attributes: attributes,
        });

        window.F_Land_parcels.applyEdits({ addFeatures: [addFeature] })
          .then((response: any) => {
            console.log("response", response);
            if (response.addFeatureResults[0].error == null) {
              presentToastFunction(
                presentToast,
                "top",
                "success",
                "تم الحذف بنجاح"
              );
              window.F_Land_parcels.refresh();
              setIsLoading(false);
            } else {
              presentToastFunction(
                presentToast,
                "top",
                "danger",
                "خطأ في الحذف حاول مره اخري"
              );
            }
          })
          .catch((e: any) => {
            presentToastFunction(
              presentToast,
              "top",
              "danger",
              "خطأ في الحذف حاول مره اخري"
            );
          });
      }

      //getF_Land_parcelsHeatmap
      if (currentAdds.length == 1) {
        let deleteHeatmapFeatures: any = [];
        let getF_Land_parcelsHeatmap =
          await window.F_Land_parcelsHeatmap.queryFeatures(query_Land_parcels);
        console.log(
          "getF_Land_parcelsHeatmap",
          getF_Land_parcelsHeatmap.features
        );
        if (getF_Land_parcelsHeatmap.features.length > 0) {
          deleteHeatmapFeatures.push({
            objectId: getF_Land_parcelsHeatmap.features[0].attributes.OBJECTID,
          });
          console.log("deleteHeatmapFeatures", deleteHeatmapFeatures);

          window.F_Land_parcelsHeatmap.applyEdits({
            deleteFeatures: deleteHeatmapFeatures,
          }).then((result: any) => {
            console.log("delete F_Land_parcelsHeatmap result", result);
            if (result.deleteFeatureResults[0].error == null) {
              presentToastFunction(
                presentToast,
                "top",
                "success",
                "تم الحذف بنجاح"
              );
              window.F_Land_parcelsHeatmap.refresh();
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setErrorTitle("خطأ في تحميل البيانات");
              setErrorMessage("يرجى اختيار القطعه ");
              openpErrorModal();
            }
          });
        }
      }
    } catch (error) {
      console.log("getFeatureObjectAndAdd error", error);
      presentToastFunction(
        presentToast,
        "top",
        "danger",
        "خطأ في الحذف حاول مره اخري"
      );
    }
  };

  return (
    <IonContent>
      {isLoading ? (
        <Spinner
          isFull={false}
          cssExtra={"absolute top-2"}
          color={"primary"}
          size={"large"}
        />
      ) : null}
      <div className="wrapper animate__animated animate__fadeInDown">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl">
            هل انت متأكد من حذف الأعلان ؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  setIsLoading(true);

                  deleteNhcIntegration(itemsData.id, token)
                    .then(async () => {
                      setIsLoading(true);
                      //delete gis data
                      getFeatureObjectAndAdd(itemsData);
                      onDismissdismissdeleteModal(null, "cancel");
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setErrorTitle("خطأ  ");
                      setErrorMessage("يرحي المحاوله مره ثانيه!");
                      openpErrorModal();
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onDismissdismissdeleteModal(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-12 animate__animated animate__fadeInDown text-center">
        <IonIcon
          className="rounded text-redColor animate__animated animate__rubberBand animate__infinite"
          icon={trash}
          style={{ fontSize: "150px" }}
        />
      </div>
    </IonContent>
  );
};

export default AllAdvertisements;
