import React, { useState, useEffect } from "react";
import { IonContent, IonIcon, IonPage, useIonToast } from "@ionic/react";
import Spinner from "../../components/atoms/spinner";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { chevronBackOutline } from "ionicons/icons";
import { AsynPointsEarnedCounterByUserIDFunc } from "../../services/API";
import { presentToastFunction } from "../../services/genericMethoud/generice";
/* eslint-disable no-restricted-globals */
const PointsEarned: React.FC = () => {
  const history = useHistory();
  const location = history.location;
  const pointsEarnedLinkCode = new URLSearchParams(location.search).get("code");
  const [pointCodeDetails, setPointCodeDetails] = useState("");
  const { token } = useSelector((state: any) => state.reducer);
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();
  const [video] = useState<any>("");

  useEffect(() => {
    if (pointsEarnedLinkCode !== null) { //for mobile
      console.log(pointsEarnedLinkCode);
      setPointCodeDetails(pointsEarnedLinkCode)
    }

  }, [pointsEarnedLinkCode]);

  function asynPoint() {
    setIsLoading(true);
    AsynPointsEarnedCounterByUserIDFunc(pointCodeDetails, token)
      .then((returnedData) => {
        console.log("asyPointsEarnedCounterByUserID", returnedData);
        if (returnedData.data.result == "codeIsUsed") {
          presentToastFunction(presentToast, "top", "danger", `عفوا الكود تم استخدامة من قبل`);
        } else if (returnedData.data.result) {
          presentToastFunction(presentToast, "top", "success", `تم استخدام الكود بنجاح`);
        } else {
          presentToastFunction(presentToast, "top", "danger", `عفوا خطئ - يرجى المحاولة`);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  return (
    <IonPage>
      <IonContent>
        <div className="flex flex-col justify-start items-center w-full h-full">
          {isLoading ? (
            <Spinner isFull={false} color={"primary"} size={"large"} />
          ) : null}
          <div className="w-full flex flex-col justify-start items-center bg-primaryColor100">
            <div className="w-full grid grid-cols-2 pt-4">
              <div className="col-span-1 py-6 px-6 flex flex-row justify-start items-center">
                <p className="text-[white] text-2xl">
                  منصة ذكي التفاعلية
                </p>
              </div>
              <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
                <button
                  onClick={() => {
                    history.push(`/app`);
                  }}
                  className="flex flex-col justify-center items-center"
                >
                  <IonIcon
                    icon={chevronBackOutline}
                    className="text-2xl text-white"
                  />
                  <span className="text-paragraphColor text-xl">   الذهاب للصفحة الرئيسية
                  </span>
                </button>
              </div>
            </div>

          </div>
          <div className="p-2">
            <p className="mt-2 mb-2 text-2xl ">فيديو تعريفي عن المنصة</p>
            <p className="mt-2 mb-2 text-1xl mt-2">يرجي الانتظار لمشاهده الفيديو كامل حتي تتمكن من اضافة 10 نقاط للوسيط العقاري</p>
            <video autoPlay onEnded={() => asynPoint()} controls src={"data:video/mp4;base64," + video} className="w-full " >
              الفيديو لا يدعم متصفحك يرجى تغير المتصفح
            </video>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PointsEarned;
