import { useEffect, useRef, useState } from "react";
import { IonContent, IonPage, IonIcon, useIonModal } from "@ionic/react";
import {
  chevronBackOutline,
  logOutOutline,
  logoAppleAppstore,
  logoGooglePlaystore,
  personRemoveOutline,
  cashOutline,
  easelOutline,
  newspaperOutline,
  idCardOutline,
  megaphone,
  diamond,
  cog,
  heart,
  build,
  business,
  ribbon,
  chatboxEllipses,
  keypad,
  alertCircle,
  diceOutline,
} from "ionicons/icons";
import "./style.css";
import Logo from "../../images/user.svg";
import contactUsSvg from "../../images/contactUs.svg";
import paymentSvg from "../../assets/uicons/svg/fi-ts-payroll-check.svg";
import allPaymentsSvg from "../../assets/uicons/svg/fi-ts-payroll-calendar.svg";
import editProfile from "../editProfile/index";
import editIcon from "../../images/edit.svg";
import { useSelector } from "react-redux";
import Request from "@arcgis/core/request";
import {
  getAllNhcIntegrationFunction,
  getAPPViewsFunction,
  getNhcIntegrationByUserIdFunction,
  GetProfileImageFunction,
} from "../../services/API";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ContactUs from "../contactUs";
import MyAdvertisements from "../myAdvertisements";
import { arcgisServicesBaseMinistryUrl } from "../../configs/arcgisServices";
import SignOutModel from "../signUp/signOutModel";
import Spinner from "../../components/atoms/spinner";
import { requestOptions } from "../../services/genericMethoud/gisMethouds";
import ChangeRoles from "../changeRoles";
import NeedToAddModel from "./needToAdd";
import WaittingApprovalAdds from "../waittingApprovalAdds";
import LogosList from "../LogosList";
import CreateFavoriteLand from "../createFavoriteLand";
import FavoriteLandListControlModal from "../favoriteLandListControl";
import BuildingsWalletModel from "../BuildingsWallet/BuildingsWalletList";
import DeleteUserModel from "../signUp/deleteUser";
import AddPhoneNumberEmail from "./addPhoneNumberEmail";
import NeedToAddReportModel from "./needToAddReport";
import DocsReportModel from "./docsReportModel";
import { IsWebApp } from "../../configs/GenericConfig";
import thkiPayment from "../thkiPayment";
import allPaymentsModal from "../allPaymentsModal/allPaymentsModal";
import AllAdvertisements from "../allAdvertisements/allAdvertisements";

const Profile = ({
  history,
  onDismiss,
}: {
  history: any;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [parcelCount, setparcelCount] = useState(0);
  const [addsCount, seAddsCount] = useState(0);
  const [appViews, setAppViews] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [propertyAdvertisements, setPropertyAdvertisements] = useState<any>([]);
  const [allAdvertisements, setAllAdvertisements] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isUserWithAdmin, setIsUserWithAdmin] = useState<boolean>(false);
  const [isOperation, setIsOperation] = useState<boolean>(false);
  const { token } = useSelector((state: any) => state.reducer);

  useEffect(() => {
    setIsLoading(true);

    GetProfileImageFunction(userInfo.id, token)
      .then((returnedData) => {
        if (returnedData.data.result != "notfound")
          setProfileImage(returnedData.data.result);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });

    if (
      userInfo?.roles?.includes("admin") &&
      userInfo?.roles?.includes("user") &&
      !userInfo?.roles?.includes("operation")
    ) {
      setIsUserWithAdmin(true);
    }
    if (
      userInfo?.roles?.includes("admin") &&
      !userInfo?.roles?.includes("user") &&
      !userInfo?.roles?.includes("operation")
    ) {
      setIsAdmin(true);
    }
    if (
      userInfo?.roles?.includes("user") &&
      !userInfo?.roles?.includes("admin") &&
      !userInfo?.roles?.includes("operation")
    ) {
      setIsUser(true);
    }
    if (
      userInfo?.roles?.includes("operation") &&
      !userInfo?.roles?.includes("admin") &&
      !userInfo?.roles?.includes("user")
    ) {
      setIsOperation(true);
    }

    getParcelsCount();
    getAPPViewsFunction()
      .then((result) => {
        seAddsCount(result.data.addsCount);
        setAppViews(result.data.appViews);
        setUsersCount(result.data.usersCount);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
    getNhcIntegrationByUserIdFunction(userInfo.id, token)
      .then((returnedData) => {
        console.log("getNhcIntegrationByUserIdFunction==>", returnedData);
        setPropertyAdvertisements(returnedData.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (isUserWithAdmin || isAdmin) {
      getAllNhcIntegrationFunction(token)
        .then((returnedData) => {
          console.log("getAllNhcIntegrationFunction ==>", returnedData);
          setAllAdvertisements(returnedData.data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
  }, [isAdmin, isUserWithAdmin]);

  const getParcelsCount = () => {
    let queryOption: any = requestOptions(
      "",
      true,
      false,
      "",
      "",
      "",
      "",
      `1=1`
    );
    Request(arcgisServicesBaseMinistryUrl + "0/query", queryOption).then(
      (response: any) => {
        if (response.data) {
          setparcelCount(response.data.count);
        }
      }
    );
  };

  const navigationList = [
    {
      label: "تعديل بيانات الحساب",
      path: "/editProfile",
    },
    {
      label: "جميع الاعلانات لدي ذكي",
      path: "/allAdvertisements",
      Icon: diceOutline,
    },
    {
      label: "اعلاناتي",
      path: "/myAdvertisements",
      Icon: megaphone,
    },
    {
      label: "محفظة الوسيط ",
      path: "/CreateBuildingsWallet",
      Icon: diamond,
    },
    {
      label: "دفع رسوم المنصة",
      path: "/PaymentRedirect",
      Icon: paymentSvg,
    },
    {
      label: "جميع عمليات الدفع لدي ذكي",
      path: "/allPayments",
      Icon: allPaymentsSvg,
    },
    {
      label: "تحكم بيانات المستخدمين",
      path: "/changeRoles",
      Icon: cog,
    },
    {
      label: "العلامات التجارية",
      path: "/logoList",
      Icon: easelOutline,
    },
    {
      label: "الوحدات المفضلة",
      path: "/CreateFavoriteLand",
      Icon: heart,
    },
    {
      label: "تحكم الوحدات المفضلة",
      path: "/FavoriteLandListControl",
      Icon: build,
    },
    {
      label: "الموافقة علي الاعلانات",
      path: "/waitingAccept",
      Icon: business,
    },
    {
      label: "طلب إضافة الشعار - العلامة التجارية",
      path: "/needAdd",
      Icon: ribbon,
    },
    {
      label: "تواصل معنا - تقديم شكوى",
      path: "/needAddReport",
      Icon: chatboxEllipses,
    },
    // {
    //   label: "دليل المستخدم",
    //   path: "/docsReport",
    //   Icon: newspaperOutline,
    // },
    {
      label: "تحديث رقم الجوال و البريد الإلكتروني",
      path: "/AddPhoneNumberEmail",
      Icon: idCardOutline,
    },
  ];

  const navigationList2 = [
    {
      label: "اتصل بنا",
      path: "/contactUs",
      Icon: contactUsSvg,
    },
    {
      label: "الرسوم",
      path: "/thkiPayment",
      Icon: cashOutline,
    },
    {
      label: "سياسة  الملكية / الخصوصية / الاستخدام",
      path: "/thkiPolicy",
      Icon: keypad,
    },
    // {
    //   label: "تعليمات هيئة العقار",
    //   path: "/requirmentsToHiad",
    //   Icon: alertCircle,
    // },
    {
      label: "تطبيقنا على جوجل بلاي",
      path: "/googlePlay",
      Icon: logoGooglePlaystore,
    },
    {
      label: "تطبيقنا على أبل استور",
      path: "/appleStore",
      Icon: logoAppleAppstore,
    },
    {
      label: "تسجيل الخروج",
      path: "/logout",
      Icon: logOutOutline,
    },
    {
      label: "حذف الحساب",
      path: "/deleteAccount",
      Icon: personRemoveOutline,
    },
  ];

  const navigationList2Mobile = [
    {
      label: "اتصل بنا",
      path: "/contactUs",
      Icon: contactUsSvg,
    },
    {
      label: "الرسوم",
      path: "/thkiPayment",
      Icon: cashOutline,
    },
    {
      label: "سياسة  الملكية / الخصوصية / الاستخدام",
      path: "/thkiPolicy",
      Icon: keypad,
    },
    {
      label: "تسجيل الخروج",
      path: "/logout",
      Icon: logOutOutline,
    },
    {
      label: "حذف الحساب",
      path: "/deleteAccount",
      Icon: personRemoveOutline,
    },
  ];

  const [present, dismiss] = useIonModal(ContactUs, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  function openContactUsModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [editProfilePresent, editProfileDismiss] = useIonModal(editProfile, {
    editProfileDismiss: (data: string, role: string) =>
      editProfileDismiss(data, role),
  });

  function openeditProfileModal() {
    editProfilePresent({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [thkipaymentPresent, thkipaymentDismiss] = useIonModal(thkiPayment, {
    thkipaymentDismiss: (data: string, role: string) =>
      thkipaymentDismiss(data, role),
  });

  function openthkiPaymentModal() {
    thkipaymentPresent({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [SignOutModelPresent, SignOutModelDismiss] = useIonModal(SignOutModel, {
    SignOutModelDismiss: (data: string, role: string) =>
      SignOutModelDismiss(data, role),
  });

  function openSignOutModel() {
    SignOutModelPresent({
      animated: true,
      breakpoints: [0.4, 1, 1, 0.5, 1],
      initialBreakpoint: 0.3,
      // backdropBreakpoint: 0.6,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [DeleteUserModelPresent, DeleteUserModelDismiss] = useIonModal(
    DeleteUserModel,
    {
      userId: userInfo.id,
      token: token,
      DeleteUserModelDismiss: (data: string, role: string) =>
        DeleteUserModelDismiss(data, role),
    }
  );

  function deleteUserModelModel() {
    DeleteUserModelPresent({
      animated: true,
      breakpoints: [0.4, 1, 1, 0.5, 1],
      initialBreakpoint: 0.3,
      // backdropBreakpoint: 0.6,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [NeedToAddModelPresent, NeedToAddModelDismiss] = useIonModal(
    NeedToAddModel,
    {
      userId: userInfo.id,
      NeedToAddModelDismiss: (data: string, role: string) =>
        NeedToAddModelDismiss(data, role),
    }
  );

  function NeedToAddModelFunc() {
    NeedToAddModelPresent({
      animated: true,
      breakpoints: [0.4, 1, 1, 0.5, 1],
      initialBreakpoint: 0.6,
      // backdropBreakpoint: 0.6,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [NeedToAddReportModelPresent, NeedToAddReportModelDismiss] =
    useIonModal(NeedToAddReportModel, {
      userId: userInfo.id,
      NeedToAddReportModelDismiss: (data: string, role: string) =>
        NeedToAddReportModelDismiss(data, role),
    });

  function NeedToAddReportModelFunc() {
    NeedToAddReportModelPresent({
      animated: true,
      breakpoints: [0.4, 1, 1, 0.5, 1],
      initialBreakpoint: 0.6,
      // backdropBreakpoint: 0.6,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  // const [docsReportModelPresent, docsReportModelDismiss] = useIonModal(
  //   DocsReportModel,
  //   {
  //     docsReportModelDismiss: (data: string, role: string) =>
  //       docsReportModelDismiss(data, role),
  //   }
  // );

  // function docsReportModelFunc() {
  //   docsReportModelPresent({
  //     animated: true,
  //     breakpoints: [0.6, 1, 1, 0.5, 1],
  //     initialBreakpoint: 0.8,
  //     // backdropBreakpoint: 0.8,
  //     onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
  //       if (ev.detail.role === "confirm") {
  //       }
  //     },
  //   });
  // }

  const [AddPhoneNumberEmailModelPresent, AddPhoneNumberEmailModelDismiss] =
    useIonModal(AddPhoneNumberEmail, {
      AddPhoneNumberEmailModelDismiss: (data: string, role: string) =>
        AddPhoneNumberEmailModelDismiss(data, role),
    });

  function AddPhoneNumberEmailModelFunc() {
    AddPhoneNumberEmailModelPresent({
      animated: true,
      breakpoints: [0.4, 1, 1, 0.5, 1],
      initialBreakpoint: 0.6,
      // backdropBreakpoint: 0.6,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [BuildingsWalletPresent, BuildingsWalletModelDismiss] = useIonModal(
    BuildingsWalletModel,
    {
      BuildingsWalletModelDismiss: (data: string, role: string) =>
        BuildingsWalletModelDismiss(data, role),
    }
  );

  function CreateBuildingsWalletModelFunc() {
    BuildingsWalletPresent({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [CreateFavoriteLandPresent, CreateFavoriteLandDismiss] = useIonModal(
    CreateFavoriteLand,
    {
      CreateFavoriteLandDismiss: (data: string, role: string) =>
        CreateFavoriteLandDismiss(data, role),
    }
  );

  function openCreateFavoriteLandModal() {
    CreateFavoriteLandPresent({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentMyAdvertisements, disMissmyAdvertisements] = useIonModal(
    MyAdvertisements,
    {
      onDismissMyAdvertisements: (data: string, role: string) =>
        disMissmyAdvertisements(data, role),
    }
  );

  function openMyAdvertisementsModal() {
    presentMyAdvertisements({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentAllAdvertisements, disMissAllAdvertisements] = useIonModal(
    AllAdvertisements,
    {
      onDismissAllAdvertisements: (data: string, role: string) =>
        disMissAllAdvertisements(data, role),
    }
  );

  function openAllAdvertisementsModal() {
    presentAllAdvertisements({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentWaittingApprovalAdds, disMissmyWaittingApprovalAdds] =
    useIonModal(WaittingApprovalAdds, {
      disMissmyWaittingApprovalAdds: (data: string, role: string) =>
        disMissmyWaittingApprovalAdds(data, role),
    });

  function openWaittingApprovalAdds() {
    presentWaittingApprovalAdds({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentdOpenRoles, disOpenRolesModal] = useIonModal(ChangeRoles, {
    disOpenRolesModal: (data: string, role: string) =>
      disOpenRolesModal(data, role),
  });

  function openRolesModal() {
    presentdOpenRoles({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentdLogosList, disLogosListModal] = useIonModal(LogosList, {
    disLogosListModal: (data: string, role: string) =>
      disLogosListModal(data, role),
  });

  function openLogosListModal() {
    presentdLogosList({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentFavoriteLandListControl, disFavoriteLandListControlModal] =
    useIonModal(FavoriteLandListControlModal, {
      disFavoriteLandListControlModal: (data: string, role: string) =>
        disFavoriteLandListControlModal(data, role),
    });

  function openFavoriteLandListControlModal() {
    presentFavoriteLandListControl({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentAllPaymentsModal, disAllPaymentsModal] = useIonModal(
    allPaymentsModal,
    {
      disAllPaymentsModal: (data: string, role: string) =>
        disAllPaymentsModal(data, role),
    }
  );

  function openAllPaymentsModal() {
    presentAllPaymentsModal({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  // const [presentPaymentRedirect, disPaymentRedirectModal] = useIonModal(
  //   PaymentRedirectModal,
  //   {
  //     disPaymentRedirectModal: (data: string, role: string) =>
  //       disPaymentRedirectModal(data, role),
  //   }
  // );

  // function openPaymentRedirectModal() {
  //   presentPaymentRedirect({
  //     onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
  //       if (ev.detail.role === "confirm") {
  //       }
  //     },
  //   });
  // }

  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
        <div
          className="w-full flex flex-col justify-start items-center bg-primaryColor100 upperPart animate__animated animate__swing"
          style={{
            border: "1px solid transparent",
            borderRadius: "30px 30px 20px 20px",
          }}
        >
          <div className="w-full grid grid-cols-3">
            <div className="col-span-1 py-6 px-6 flex flex-row justify-start items-center">
              <button
                onClick={() => {
                  openeditProfileModal();
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon icon={editIcon} className="text-2xl text-white" />
              </button>
            </div>
            <div className="col-span-1 py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                الحساب الشخصي
              </p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  onDismiss(inputRef.current?.value, "confirm");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-5/6 flex flex-col justify-start items-center pt-4 animate__animated animate__backInRight">
            <div className="w-full flex flex-row justify-start items-start px-8">
              <div className="h-20 w-20 p-[2px] border-[1px] rounded shadow">
                <img
                  className=""
                  src={profileImage ?? Logo}
                  style={{
                    height: "-webkit-fill-available",
                    width: "-webkit-fill-available",
                  }}
                />
              </div>
              <div className="flex flex-col mr-6">
                <p className="text-white text-xl">
                  {userInfo.fullName == ""
                    ? "لا يوجد اسم كامل"
                    : userInfo.fullName}
                </p>
                <p className="font-normal text-white">{userInfo.userName}</p>
              </div>
            </div>

            <hr className="w-full mt-4" />
            <div className="w-full flex flex-row justify-start items-center pt-4 px-8">
              <p className="font-medium text-paragraphColor text-2xl">
                إحصائيات المنصة
              </p>
            </div>

            <div className="w-full grid grid-cols-4 py-6 text-center animate__animated animate__backInUp">
              <div className="flex flex-col justify-center items-center">
                <p className="font-normal text-white">{addsCount}</p>
                <p className="font-normal text-white text-2xl">اعلانات</p>
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="font-normal text-white">{parcelCount}</p>
                <p className="font-normal text-white text-2xl">قطع</p>
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="font-normal text-white">{appViews}</p>
                <p className="font-normal text-white text-2xl">مشاهدة</p>
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="font-normal text-white">{usersCount}</p>
                <p className="font-normal text-white text-2xl">عضو</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-center">
          <div className="w-full flex flex-row justify-start items-center pt-4 px-8">
            <p className="font-medium text-paragraphColor text-2xl">
              المعلومات الشخصية
            </p>
          </div>
          <div className="w-5/6 flex flex-col justify-center items-center">
            {navigationList.map((item: any, index) => {
              if (isUserWithAdmin) {
                return item.path == "/changeRoles" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openRolesModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/logoList" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openLogosListModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/FavoriteLandListControl" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openFavoriteLandListControlModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/myAdvertisements" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openMyAdvertisementsModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center icoonn">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <span className="badge">
                        {propertyAdvertisements.length}
                      </span>

                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                          {/* <div className="float-left">

                                {
                                  propertyAdvertisements.length == 0 ? (
                                    <p className="font-bold text-primaryColor100 text-xl">
                                      (لا توجد اعلانات)
                                    </p>
                                  ) : (
                                    <p className="font-bold text-primaryColor100 text-xl">
                                      ( {propertyAdvertisements.length}
                                      <span style={{ visibility: "hidden" }}>.</span> اعلانات)
                                    </p>
                                  )
                                }
                              </div> */}
                          <div></div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/CreateFavoriteLand" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openCreateFavoriteLandModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/needAdd" ? (
                  <button
                    key={index}
                    onClick={() => {
                      NeedToAddModelFunc();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/needAddReport" ? (
                  <button
                    key={index}
                    onClick={() => {
                      NeedToAddReportModelFunc();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/CreateBuildingsWallet" ? (
                  <button
                    key={index}
                    onClick={() => {
                      CreateBuildingsWalletModelFunc();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/PaymentRedirect" ? (
                  <>
                    {isLoading ? (
                      <Spinner color={"primary"} size={"large"} />
                    ) : null}
                    <button
                      key={index}
                      onClick={() => {
                        history.push("/app/PaymentRedirect");
                        onDismiss(inputRef.current?.value, "confirm");
                      }}
                      className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <IonIcon
                          icon={item.Icon}
                          className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                        />
                        <p className="font-medium text-darkBlueColor text-xl mr-4">
                          <div className="flow-root">
                            <div className="float-right"> {item.label}</div>
                          </div>
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start items-center"
                        style={{ marginLeft: "-5px" }}
                      >
                        <IonIcon
                          icon={chevronBackOutline}
                          className="text-2xl text-darkBlueColor"
                        />
                      </div>
                    </button>
                  </>
                ) : item.path == "/allAdvertisements" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openAllAdvertisementsModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center icoonn">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <span className="badge">{allAdvertisements.length}</span>

                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                          {/* <div className="float-left">

                                {
                                  propertyAdvertisements.length == 0 ? (
                                    <p className="font-bold text-primaryColor100 text-xl">
                                      (لا توجد اعلانات)
                                    </p>
                                  ) : (
                                    <p className="font-bold text-primaryColor100 text-xl">
                                      ( {propertyAdvertisements.length}
                                      <span style={{ visibility: "hidden" }}>.</span> اعلانات)
                                    </p>
                                  )
                                }
                              </div> */}
                          <div></div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/allPayments" ? (
                  <>
                    {isLoading ? (
                      <Spinner color={"primary"} size={"large"} />
                    ) : null}
                    <button
                      key={index}
                      onClick={() => {
                        openAllPaymentsModal();
                        onDismiss(inputRef.current?.value, "confirm");
                      }}
                      className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <IonIcon
                          icon={item.Icon}
                          className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                        />
                        <p className="font-medium text-darkBlueColor text-xl mr-4">
                          <div className="flow-root">
                            <div className="float-right"> {item.label}</div>
                          </div>
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start items-center"
                        style={{ marginLeft: "-5px" }}
                      >
                        <IonIcon
                          icon={chevronBackOutline}
                          className="text-2xl text-darkBlueColor"
                        />
                      </div>
                    </button>
                  </>
                ) : null;
                // : item.path == "/AddPhoneNumberEmail" ? (
                //   <button key={index}
                //     onClick={() => {
                //       AddPhoneNumberEmailModelFunc();
                //     }}
                //     className="w-full flex flex-row justify-between items-center mt-5"
                //   >
                //     <div className="flex flex-row justify-start items-center">
                //       <IonIcon
                //         icon={item.Icon}
                //         className="text-white bg-[#0875fe] border-1 rounded p-1"
                //       />
                //       <p className="font-medium text-darkBlueColor text-xl mr-4">

                //         <div className="flow-root">
                //           <div className="float-right">  {item.label}
                //             <span style={{ visibility: "hidden" }}>..</span>
                //           </div>
                //         </div>
                //       </p>
                //     </div>
                //     <div className="flex flex-row justify-start items-center">
                //       <IonIcon
                //         icon={chevronBackOutline}
                //         className="text-2xl text-darkBlueColor"
                //       />
                //     </div>
                //   </button>
                // )

                // : item.path == "/docsReport" ? (
                //   <button
                //     key={index}
                //     onClick={() => {
                //       docsReportModelFunc();
                //     }}
                //     className="w-full flex flex-row justify-between items-center mt-5 text-xl pb-1 animate__animated animate__backInLeft"
                //   >
                //     <div className="flex flex-row justify-start items-center">
                //       <IonIcon
                //         icon={item.Icon}
                //         className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                //       />
                //       <p className="font-medium text-darkBlueColor text-xl mr-4">
                //         <div className="flow-root">
                //           <div className="float-right">
                //             {" "}
                //             {item.label}
                //             <span style={{ visibility: "hidden" }}>..</span>
                //           </div>
                //         </div>
                //       </p>
                //     </div>
                //     <div
                //       className="flex flex-row justify-start items-center"
                //       style={{ marginLeft: "-5px" }}
                //     >
                //       <IonIcon
                //         icon={chevronBackOutline}
                //         className="text-2xl text-darkBlueColor"
                //       />
                //     </div>
                //   </button>
                // )
              }

              if (isAdmin) {
                return item.path == "/changeRoles" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openRolesModal();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/logoList" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openLogosListModal();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/FavoriteLandListControl" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openFavoriteLandListControlModal();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/allPayments" ? (
                  <>
                    {isLoading ? (
                      <Spinner color={"primary"} size={"large"} />
                    ) : null}
                    <button
                      key={index}
                      onClick={() => {
                        openAllPaymentsModal();
                        onDismiss(inputRef.current?.value, "confirm");
                      }}
                      className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <IonIcon
                          icon={item.Icon}
                          className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                        />
                        <p className="font-medium text-darkBlueColor text-xl mr-4">
                          <div className="flow-root">
                            <div className="float-right"> {item.label}</div>
                          </div>
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start items-center"
                        style={{ marginLeft: "-5px" }}
                      >
                        <IonIcon
                          icon={chevronBackOutline}
                          className="text-2xl text-darkBlueColor"
                        />
                      </div>
                    </button>
                  </>
                ) : item.path == "/allAdvertisements" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openAllAdvertisementsModal();
                      onDismiss(inputRef.current?.value, "confirm");
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center icoonn">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <span className="badge">{allAdvertisements.length}</span>

                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                          {/* <div className="float-left">

                                {
                                  propertyAdvertisements.length == 0 ? (
                                    <p className="font-bold text-primaryColor100 text-xl">
                                      (لا توجد اعلانات)
                                    </p>
                                  ) : (
                                    <p className="font-bold text-primaryColor100 text-xl">
                                      ( {propertyAdvertisements.length}
                                      <span style={{ visibility: "hidden" }}>.</span> اعلانات)
                                    </p>
                                  )
                                }
                              </div> */}
                          <div></div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : null;
              }

              if (isUser) {
                return item.path == "/myAdvertisements" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openMyAdvertisementsModal();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center icoonn">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <span className="badge">
                        {propertyAdvertisements.length}
                      </span>

                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                          {/*<div className="float-left">

                              {
                                propertyAdvertisements.length == 0 ? (
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    (لا توجد اعلانات)
                                  </p>
                                ) : (
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    ( {propertyAdvertisements.length}
                                    <span style={{ visibility: "hidden" }}>..</span> اعلانات)
                                  </p>
                                )
                              }
                            </div> */}
                          <div></div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/needAdd" ? (
                  <button
                    key={index}
                    onClick={() => {
                      NeedToAddModelFunc();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/needAddReport" ? (
                  <button
                    key={index}
                    onClick={() => {
                      NeedToAddReportModelFunc();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/CreateBuildingsWallet" ? (
                  <button
                    key={index}
                    onClick={() => {
                      CreateBuildingsWalletModelFunc();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/CreateFavoriteLand" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openCreateFavoriteLandModal();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right">
                            {" "}
                            {item.label}
                            <span style={{ visibility: "hidden" }}>..</span>
                          </div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : item.path == "/PaymentRedirect" ? (
                  <>
                    {isLoading ? (
                      <Spinner color={"primary"} size={"large"} />
                    ) : null}
                    <button
                      key={index}
                      onClick={() => {
                        history.push("/app/PaymentRedirect");
                        onDismiss(inputRef.current?.value, "confirm");
                      }}
                      className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <IonIcon
                          icon={item.Icon}
                          className="text-white bg-[#0875fe] border-1 rounded p-1 text-2xl"
                        />
                        <p className="font-medium text-darkBlueColor text-xl mr-4">
                          <div className="flow-root">
                            <div className="float-right"> {item.label}</div>
                          </div>
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start items-center"
                        style={{ marginLeft: "-5px" }}
                      >
                        <IonIcon
                          icon={chevronBackOutline}
                          className="text-2xl text-darkBlueColor"
                        />
                      </div>
                    </button>
                  </>
                ) : null;
                // : item.path == "/AddPhoneNumberEmail" ? (
                //   <button key={index}
                //     onClick={() => {
                //       AddPhoneNumberEmailModelFunc()
                //     }}
                //     className="w-full flex flex-row justify-between items-center mt-5"
                //   >
                //     <div className="flex flex-row justify-start items-center">
                //       <IonIcon
                //         icon={item.Icon}
                //         className="text-white bg-[#0875fe] border-1 rounded p-1"
                //       />
                //       <p className="font-medium text-darkBlueColor text-xl mr-4">

                //         <div className="flow-root">
                //           <div className="float-right">  {item.label}
                //             <span style={{ visibility: "hidden" }}>..</span>
                //           </div>
                //         </div>
                //       </p>
                //     </div>
                //     <div className="flex flex-row justify-start items-center">
                //       <IonIcon
                //         icon={chevronBackOutline}
                //         className="text-2xl text-darkBlueColor"
                //       />
                //     </div>
                //   </button>
                // )

                // : item.path == "/docsReport" ? (
                //   <button
                //     key={index}
                //     onClick={() => {
                //       docsReportModelFunc();
                //     }}
                //     className="w-full flex flex-row justify-between items-center mt-5 text-xl pb-1 animate__animated animate__backInLeft"
                //   >
                //     <div className="flex flex-row justify-start items-center">
                //       <IonIcon
                //         icon={item.Icon}
                //         className="text-white bg-[#3ac357] border-1 rounded p-1 text-2xl"
                //       />
                //       <p className="font-medium text-darkBlueColor text-xl mr-4">
                //         <div className="flow-root">
                //           <div className="float-right">
                //             {" "}
                //             {item.label}
                //             <span style={{ visibility: "hidden" }}>..</span>
                //           </div>
                //         </div>
                //       </p>
                //     </div>
                //     <div
                //       className="flex flex-row justify-start items-center"
                //       style={{ marginLeft: "-5px" }}
                //     >
                //       <IonIcon
                //         icon={chevronBackOutline}
                //         className="text-2xl text-darkBlueColor"
                //       />
                //     </div>
                //   </button>
                // )
              }

              if (isOperation) {
                return item.path == "/waitingAccept" ? (
                  <button
                    key={index}
                    onClick={() => {
                      openWaittingApprovalAdds();
                    }}
                    className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInLeft"
                  >
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        icon={item.Icon}
                        className="text-white bg-[#2dace4] border-1 rounded p-1 text-2xl"
                      />
                      <p className="font-medium text-darkBlueColor text-xl mr-4">
                        <div className="flow-root">
                          <div className="float-right"> {item.label}</div>
                        </div>
                      </p>
                    </div>
                    <div
                      className="flex flex-row justify-start items-center"
                      style={{ marginLeft: "-5px" }}
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-darkBlueColor"
                      />
                    </div>
                  </button>
                ) : null;
              }
            })}
          </div>
          <hr className="w-5/6 border-paragraphColor border-[0.1px] mt-4 " />
          <div className="w-full flex flex-row justify-start items-center pt-4 px-8">
            <p className="font-medium text-paragraphColor text-2xl">
              معلومات عامة
            </p>
          </div>

          <div className="w-5/6 flex flex-col justify-center items-center">
            {IsWebApp ? (
              <>
                {navigationList2.map((item: any, index) => {
                  let icon = document.getElementsByTagName("ion-icon");
                  let googlePlay = icon.namedItem("/googlePlay");
                  googlePlay?.classList.replace("bg-[#2dace4]", "bg-[#8e8d92]");
                  let appleStore = icon.namedItem("/appleStore");
                  appleStore?.classList.replace("bg-[#2dace4]", "bg-[#8e8d92]");
                  let logout = icon.namedItem("/logout");
                  logout?.classList.replace("bg-[#2dace4]", "bg-redColor");
                  let deleteAccount = icon.namedItem("/deleteAccount");
                  deleteAccount?.classList.replace(
                    "bg-[#2dace4]",
                    "bg-redColor"
                  );
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (item.path == "/contactUs") {
                          openContactUsModal();
                        }
                        if (item.path == "/thkiPayment") {
                          openthkiPaymentModal();
                        }
                        if (item.path == "/signUpToHiad") {
                          window.open(
                            "https://eservices.rega.gov.sa",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                        if (item.path == "/needAdd") {
                          NeedToAddModelFunc();
                        }
                        if (item.path == "/googlePlay") {
                          window.open(
                            "https://thki.sa/android/",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                        if (item.path == "/appleStore") {
                          window.open(
                            "https://thki.sa/ios",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                        // if (item.path == "/requirmentsToHiad") {
                        //   window.open(
                        //     "https://rega.gov.sa:4433//UploadImages/213024185قرار%20مجلس%20الإدارة%20بشأن%20ضوابط%20الإعلانات%20العقارية.pdf",
                        //     "_blank",
                        //     "noopener,noreferrer"
                        //   );
                        // }
                        if (item.path == "/thkiPolicy") {
                          window.open(
                            "https://thki.sa/app/thkiPolicy",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                        if (item.path == "/logout") {
                          openSignOutModel();
                        }
                        if (item.path == "/deleteAccount") {
                          deleteUserModelModel();
                        }
                      }}
                      className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInRight"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <IonIcon
                          icon={item.Icon}
                          className="text-white bg-[#2dace4] border-1 rounded p-1 text-2xl"
                          name={item.path}
                        />
                        <p className="font-medium text-darkBlueColor text-xl mr-4">
                          {item.label}
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start items-center"
                        style={{ marginLeft: "-5px" }}
                      >
                        <IonIcon
                          icon={chevronBackOutline}
                          className="text-2xl text-darkBlueColor"
                        />
                      </div>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {navigationList2Mobile.map((item: any, index) => {
                  let icon = document.getElementsByTagName("ion-icon");
                  let googlePlay = icon.namedItem("/googlePlay");
                  googlePlay?.classList.replace("bg-[#2dace4]", "bg-[#8e8d92]");
                  let appleStore = icon.namedItem("/appleStore");
                  appleStore?.classList.replace("bg-[#2dace4]", "bg-[#8e8d92]");
                  let logout = icon.namedItem("/logout");
                  logout?.classList.replace("bg-[#2dace4]", "bg-redColor");
                  let deleteAccount = icon.namedItem("/deleteAccount");
                  deleteAccount?.classList.replace(
                    "bg-[#2dace4]",
                    "bg-redColor"
                  );
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (item.path == "/contactUs") {
                          openContactUsModal();
                        }
                        if (item.path == "/thkiPayment") {
                          openthkiPaymentModal();
                        }
                        if (item.path == "/signUpToHiad") {
                          window.open(
                            "https://eservices.rega.gov.sa",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                        if (item.path == "/needAdd") {
                          NeedToAddModelFunc();
                        }
                        // if (item.path == "/requirmentsToHiad") {
                        //   window.open(
                        //     "https://rega.gov.sa:4433//UploadImages/213024185قرار%20مجلس%20الإدارة%20بشأن%20ضوابط%20الإعلانات%20العقارية.pdf",
                        //     "_blank",
                        //     "noopener,noreferrer"
                        //   );
                        // }
                        if (item.path == "/thkiPolicy") {
                          window.open(
                            "https://thki.sa/app/thkiPolicy",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                        if (item.path == "/logout") {
                          openSignOutModel();
                        }
                        if (item.path == "/deleteAccount") {
                          deleteUserModelModel();
                        }
                      }}
                      className="w-full flex flex-row justify-between items-center mt-5 border-b border-solid border-paragraphColor text-xl pb-1 animate__animated animate__backInRight"
                    >
                      <div className="flex flex-row justify-start items-center">
                        <IonIcon
                          icon={item.Icon}
                          className="text-white bg-[#2dace4] border-1 rounded p-1 text-2xl"
                          name={item.path}
                        />
                        <p className="font-medium text-darkBlueColor text-xl mr-4">
                          {item.label}
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start items-center"
                        style={{ marginLeft: "-5px" }}
                      >
                        <IonIcon
                          icon={chevronBackOutline}
                          className="text-2xl text-darkBlueColor"
                        />
                      </div>
                    </button>
                  );
                })}
              </>
            )}

            <div className="mt-4">
              <span style={{ visibility: "hidden" }}>..........</span>
              <span style={{ visibility: "hidden" }}>..........</span>
              <span style={{ visibility: "hidden" }}>..........</span>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
