import { IonContent } from "@ionic/react";
import ErrorImage from "../../../images/error_image.svg";
import Button from "../../atoms/button";
import "./errorModal.styles.css";

const ErrorModal = ({
  errorTitle,
  errorMessage,
  dismissErrorModal,
}: {
  errorTitle:any,
  errorMessage:any,
  dismissErrorModal: (data?: string | null | undefined | number, role?: string) => void;
}) => {
    return (
    <IonContent id="errorModal"  >
      <div className="wrapper">
        <div className="w-full flex flex-col justify-start items-center py-8">
          <img className="h-16" src={ErrorImage} />
          <div className="w-4/6 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-xl mt-4 ">
              {errorTitle}
            </p>
            <p className="font-medium text-[#828282] text-base text-center">
              {errorMessage}
            </p>
          </div>
          <div className="mt-4 w-full flex justify-center items-center">
            <Button
              size={"medium"}
              theme={"primary"}
              text={"حسنا"}
              onClick={()=>{
                dismissErrorModal(null,"cancel")
              }}
            />
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default ErrorModal;
