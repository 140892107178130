import { createStore, combineReducers } from "redux";
import reducer from "../reducer";

const rootReducer = combineReducers({ reducer: reducer });

const saveToLocalStorage = (state) => {
  const serialisedState = JSON.stringify(state);
  window.localStorage.setItem("persistantState", serialisedState);
};

const loadFromLocalStorage = () => {
  const serialisedState =window.localStorage.getItem("persistantState");
  if (serialisedState === null) return undefined;
  return JSON.parse(serialisedState);
};

const store = createStore(rootReducer, loadFromLocalStorage());

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
