import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import OnBoarding from "./screens/onBoarding";
import SignIn from "./screens/signIn";
import SignUp from "./screens/signUp";
import PropertyLinks from "./screens/propertyLinks";
import Home from "./screens/home";
import Settings from "./screens/settings";
import ChangePhoneNumber from "./screens/changePhoneNumber";
import AppUrlListener from "./services/appUrlListener";
import esriConfig from "@arcgis/core/config.js";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "./assets/uicons/css/uicons-thin-straight.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import { setLocale } from "@arcgis/core/intl";

/* Tailwind styles */
import "./theme/tailwind.css";

/* Arcgis styles */
import "@arcgis/core/assets/esri/themes/light/main.css";
import ThkiPolicy from "./screens/thkiPolicy";
import { useEffect } from "react";
import esriId from "@arcgis/core/identity/IdentityManager";
import { arcgisServicesBaseUrl } from "./configs/arcgisServices";
import { getArcGISTokenFunction } from "./services/API";
import { HelmetProvider } from "react-helmet-async";
import PointsEarned from "./screens/PointsEarned";
import ContactUs from "./screens/contactUs-public";
import * as urlUtils from "@arcgis/core/core/urlUtils";
import isDev from "./services/genericMethoud/gisMethouds";
import PaymentRedirect from "./screens/PaymentRedirect/PaymentRedirect";
import "animate.css";

//TODO: make sure change to  "./assets" in production mode
//TODO: development mode to  "/assets"  in development mode

esriConfig.assetsPath = isDev() ? "/assets" : "./assets";
// esriConfig.assetsPath = "/assets";     //in development mode
//esriConfig.apiKey = "AAPK87d59d55d9a14d1d8f25eb126e523b6dTQjo2uHPYI9WhDcFiAJLlF1u5Jz7TECRA9Iz7fBRRQ7vQBVa_1912ZmE7SyJbV3Z";

setLocale("ar");

setupIonicReact();
const App: React.FC = () => {
  function generateGISToken(token: any) {
    esriId.registerToken({
      server: arcgisServicesBaseUrl,
      token: token,
      ssl: true,
    });
  }

  useEffect(() => {
    getArcGISTokenFunction().then((result: any) => {
      generateGISToken(result.data.token);
    });
    esriConfig.request.proxyUrl = "https://thki.sa/EsriProxy/proxy.ashx";
    esriConfig.request.timeout = 300000;

    urlUtils.addProxyRule({
      urlPrefix: "https://stgisudp.momra.gov.sa",
      proxyUrl: "https://thki.sa/EsriProxy/proxy.ashx",
    });
    var toolbarContainer = document
      .querySelectorAll(".toolbar-container")
      .forEach((el) => {
        console.log(el);
      });
  }, []);

  //for web
  return (
    <IonApp>
      <HelmetProvider>
        <IonReactRouter>
          <AppUrlListener></AppUrlListener>
          <IonRouterOutlet>
            <Home />
            <Route exact path="/app/onBoarding">
              <OnBoarding />
            </Route>
            <Route exact path="/app/signIn">
              <SignIn />
            </Route>
            <Route exact path="/app/signUp">
              <SignUp />
            </Route>
            <Route exact path="/app/d:name?">
              <PropertyLinks />
            </Route>
            <Route exact path="/app/estateBrokers:id?">
              <Home />
            </Route>
            <Route exact path="/app/PaymentRedirect">
              <PaymentRedirect />
            </Route>
            <Route exact path="/app/settings">
              <Settings />
            </Route>
            <Route exact path="/app/gCode:code?">
              <PointsEarned />
            </Route>
            <Route exact path="/app/thkiPolicy">
              <ThkiPolicy />
            </Route>
            <Route exact path="/app/changePhoneNumber">
              <ChangePhoneNumber />
            </Route>
            <Route exact path="/app/ContactUs">
              <ContactUs />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </HelmetProvider>
    </IonApp>
  );
};
export default App;
