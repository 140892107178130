import { useState, useEffect, useRef } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  useIonToast,
  IonList,
  IonLabel,
  IonItem,
  IonInput,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import Button from "../../../components/atoms/button";
import Spinner from "../../../components/atoms/spinner";
import {
  deleteFilesByBuildingsWalletParcelFunction,
  dowmloadFilesBuildingsWalletBase64Function,
  EditCreateBuildingsWalletSingleFunc,
  GetBuildingsWalletByIdFunc,
  onFileUploadValidationSize,
} from "../../../services/API";
import {
  convertToBase64,
  downloadPDF,
  presentToastFunction,
} from "../../../services/genericMethoud/generice";
import ErrorModal from "../../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { Field, Formik } from "formik";

const EditBuildingsWalletSingleModal = ({
  itemsDataS,
  itemsDataSCurrent,
  dismissEditBuildingsWalletSingleModal,
}: {
  itemsDataS: any;
  itemsDataSCurrent: any;
  dismissEditBuildingsWalletSingleModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [dataDesc, setDataDesc] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [presentToast] = useIonToast();
  const inputClassWithError =
    "input-error block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputClassWithOutError =
    "block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const [attachments, setAttachments] = useState<any>([]);
  const [image, setImage] = useState<any>("");
  const [presentAlert] = useIonAlert();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentDetailsAddModel, DetailsAddModelDismiss] = useIonModal(
    DetailsAddModel,
    {
      DetailsAddModelDismiss: (data: string, role: string) =>
        DetailsAddModelDismiss(data, role),
    }
  );

  function openDetailsAddModel() {
    presentDetailsAddModel({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.3,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          console.log("ev", ev.detail.data);
          if (dataDesc) {
            setDataDesc((rowData: any) => [...rowData, ev.detail.data]);
          } else {
            setDataDesc(ev.detail.data);
          }
          console.log(dataDesc);
        }
      },
    });
  }

  const validate = (values: any) => {
    let errors: any = {};
    if (!values.Title) {
      errors.Title = "يجب الإدخال";
    }
    console.log(errors);

    return errors;
  };

  const handleRemoveLogos = (id: any) => {
    const newlogos = dataDesc.filter((getId: any, index: any) => index !== id);
    setDataDesc(newlogos);
  };

  //model property
  const [presentIamgeModal, onCloseImageDismiss] = useIonModal(
    ModalImagesExample,
    {
      image,
      onCloseImageDismiss: (data: string, role: string) =>
        onCloseImageDismiss(data, role),
    }
  );

  function openpImageModal() {
    presentIamgeModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  function getBuildingsWalletByPacelFuncData() {
    GetBuildingsWalletByIdFunc(itemsDataS.id, token)
      .then((returnedData: any) => {
        if (returnedData.data) {
          console.log("getCurrent", itemsDataSCurrent);
          setAttachments(itemsDataSCurrent.buildingsWalletAttachFiles);
          if (itemsDataSCurrent.buildingsWalletDescriptions) {
            //get desc
            let arr: any = [];
            itemsDataSCurrent.buildingsWalletDescriptions.forEach(
              (element: any) => {
                arr.push(element.name);
              }
            );
            setDataDesc(arr);
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    console.log("itemsDataS", itemsDataS);

    getBuildingsWalletByPacelFuncData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        {isLoading ? (
          <Spinner isFull={false} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center ">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl">تعديل البيانات</p>
            </div>

            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  dismissEditBuildingsWalletSingleModal(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            <Formik
              initialValues={{
                Title: itemsDataSCurrent.title,
                Description: itemsDataSCurrent.description,
                OwnerName: itemsDataSCurrent.ownerName,
                OwnerPhoneNumber: itemsDataSCurrent.ownerPhoneNumber,
                BuildingPrice: itemsDataSCurrent.buildingPrice,
                BuildingArea: itemsDataSCurrent.buildingArea,
                BuildingDirection: itemsDataSCurrent.buildingDirection,
              }}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={async (values: any) => {
                if (!isLoading) {
                  setIsLoading(true);
                  console.log("itemsDataS", itemsDataS);

                  let data: any = {
                    UserId: userInfo.id,
                    Title: values.Title,
                    Description: values.Description,
                    BuildingsWalleSingletId: Number(itemsDataSCurrent.id),
                    BuildingsWalleUsertId: Number(
                      itemsDataS.buildingsWalletTypesUserId
                    ),
                    BuildingsWalletParcelsId: Number(itemsDataS.id),
                    OwnerName: values.OwnerName,
                    OwnerPhoneNumber: values.OwnerPhoneNumber,
                    BuildingPrice: Number(values.BuildingPrice),
                    BuildingArea: Number(values.BuildingArea),
                    BuildingDirection: values.BuildingDirection,
                  };
                  const formData = new FormData();

                  if (dataDesc) {
                    console.log(dataDesc);
                    for (let i = 0; i < dataDesc.length; i++) {
                      formData.append(
                        `Descriptions[${i}].name`,
                        dataDesc[i] as any
                      );
                    }
                  }

                  if (values.file) {
                    for (let i = 0; i < values.file.length; i++) {
                      if (values.file[i].name.split(".").pop() !== "pdf") {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ` عفوا يجب ان يكون الملف من نوع .pdf`
                        );
                        setIsLoading(false);
                        return;
                      }

                      if (attachments?.length + values.file.length > 6) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `عفوا لا يمكن اضافة اكثر من 6 مرفقات`
                        );
                        setIsLoading(false);
                        let fileList: any =
                          document.getElementById("addFileHTMLInput");
                        fileList.value = "";
                        return;
                      }

                      let checkFileResult = await onFileUploadValidationSize(
                        values.file[i],
                        6 * 1048576
                      );

                      if (!checkFileResult) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ` عفوا الملف  ${values.file[i].name}   حجم اكبر من 6 ميجا`
                        );
                        setIsLoading(false);
                        return;
                      }

                      const files: any = await convertToBase64(values.file[i]);
                      const regex = /data:.*base64,/;
                      const fileWithOut = files.replace(regex, "");

                      formData.append(
                        `AttachMents[${i}].File`,
                        fileWithOut as any
                      );
                      formData.append(
                        `AttachMents[${i}].Name`,
                        values.file[i].name as any
                      );
                    }
                  }

                  formData.append(`Data`, JSON.stringify(data) as any);

                  EditCreateBuildingsWalletSingleFunc(formData, token)
                    .then((ss: any) => {
                      console.log(ss);
                      if (ss.data) {
                        setIsLoading(false);
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          "تم الحفظ بنجاح"
                        );
                        dismissEditBuildingsWalletSingleModal(null, "confirm");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          ss.data
                        );
                      }
                      setIsLoading(false);
                    })
                    .catch((e: any) => {
                      console.log(e);
                      setIsLoading(false);
                      if (e.response?.status == 401) {
                        window.localStorage.removeItem("persistantState");
                        window.location.assign(`/app/signIn`);
                      }
                      setErrorTitle("حدث خطأ");
                      setErrorMessage(
                        " لم تتم عملية الأضافة .. تأكد من المدخلات! أو أعد المحاولة"
                      );
                      openpErrorModal();
                    });
                }
              }}
              validate={validate}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
              }) => (
                <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center animate__animated animate__fadeInUpBig">
                  {isLoading ? (
                    <Spinner color={"primary"} size={"large"} />
                  ) : null}
                  <div className="w-full flex flex-col justify-start items-start ">
                    <p className="font-medium text-black text-2xl">
                      معلومات تهمك
                    </p>
                    <p className="font-light text-black text-base">
                      هذه المعلومات تساعد في حفظ معلوماتك بسرية تامة .. بياناتك
                      مشفرة بالكامل
                    </p>
                  </div>

                  <div className="relative w-full  mt-6">
                    <Field
                      type="text"
                      name="Title"
                      onChange={handleChange}
                      className={
                        errors.Title && touched.Title
                          ? `${inputClassWithError}`
                          : `${inputClassWithOutError}`
                      }
                    />
                    <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      العنوان
                    </label>
                    {errors.Title ? (
                      <p className="text-xl font-bold text-redColor mt-2">
                        {errors.Title}
                      </p>
                    ) : null}
                  </div>

                  <div className="relative w-full  mt-6">
                    <Field
                      component="textarea"
                      rows="6"
                      name="Description"
                      onChange={handleChange}
                      className={
                        errors.Description && touched.Description
                          ? `${inputClassWithError}`
                          : `${inputClassWithOutError}`
                      }
                    />
                    <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      الوصف
                    </label>
                    {errors.Description ? (
                      <p className="text-xl font-bold text-redColor mt-2">
                        {errors.Description}
                      </p>
                    ) : null}
                  </div>

                  {itemsDataS?.buildingsWalletTypesUsers
                    ?.buildingsWalletTypesId == 1 ? (
                    <p className="placeholder-paragraphColor mt-5 text-[#a8112a]">
                      عفوا لا يمكنك استخدام هذه الادوات - يمكنك الترقية للمحفظه
                      البرونزية لاستخدامها
                    </p>
                  ) : null}

                  {/* <div className="relative block p-2 rounded border-2 border-paragraphColor appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 w-full flow-root mt-6">
                    <div className="float-right">
                      <label className="text-xl font-bold top-2 z-10 bg-white px-2 text-primaryColor100">
                        تفاصيل العقار
                      </label>
                    </div>

                    <div className="float-left">
                      <Button
                        theme={
                          itemsDataS?.buildingsWalletTypesUsers
                            ?.buildingsWalletTypesId == 2
                            ? "primary"
                            : ""
                        }
                        text={"ادخال"}
                        size={"small"}
                        onClick={() => {
                          if (
                            itemsDataS?.buildingsWalletTypesUsers
                              ?.buildingsWalletTypesId == 2
                          ) {
                            openDetailsAddModel();
                          }
                        }}
                      />
                    </div>
                  </div> */}

                  {dataDesc?.length > 0 ? (
                    <div className="mt-2 w-full rounded border-t-0 border-2 border-paragraphColor text-right p-2">
                      <IonList>
                        {dataDesc?.map((item: any, index: any) => {
                          return (
                            <div className="flow-root mt-2 border border-paragraphColor p-1">
                              <div className="float-right">
                                <IonItem key={index}>
                                  <IonLabel>{item}</IonLabel>
                                </IonItem>
                              </div>
                              <div className="float-left">
                                <Button
                                  theme={"danger"}
                                  text={"حذف"}
                                  size={"ssmall"}
                                  onClick={() => {
                                    handleRemoveLogos(index);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </IonList>
                    </div>
                  ) : null}

                  <div className="relative w-full mt-4">
                    <input
                      onChange={(e: any) => {
                        setFieldValue("file", e.currentTarget.files);
                      }}
                      id="addFileHTMLInput"
                      type="file"
                      multiple
                      accept=".pdf"
                      disabled={
                        itemsDataS?.buildingsWalletTypesUsers
                          ?.buildingsWalletTypesId == 1
                          ? true
                          : false
                      }
                      className={`${inputClassWithOutError}`}
                      placeholder="الملفات"
                    />
                    <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                      الملفات
                    </label>
                  </div>

                  <div className="relative w-full">
                    <div className="col-span-1 w-full flex flex-row justify-start items-center mt-4">
                      <p className="font-bold text-primaryColor100 text-2xl mb-2">
                        الملفات المرفقة :
                      </p>
                    </div>
                    {attachments?.length == 0 ? (
                      <div className="w-full bg-bkColor mb-4 rounded border-[1px] border-paragraphColor flex flex-row justify-center items-center py-4 mt-4">
                        <p className="font-medium text-paragraphColor text-sm">
                          لا توجد ملفات على هذا العقار !
                        </p>
                      </div>
                    ) : (
                      <div className="w-full flex flex-col justify-center items-center mb-6">
                        {attachments?.map((item: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className="w-full flex flex-row justify-between items-center mt-2"
                            >
                              <div>
                                <p className="font-bold text-black text-xl">
                                  {item.name.split("-")[1].substring(0, 12)}
                                </p>
                              </div>
                              <div>
                                <div className="float-right px-2">
                                  <Button
                                    size="ssmall"
                                    text="عرض"
                                    theme={"primary"}
                                    onClick={() => {
                                      setIsLoading(true);
                                      dowmloadFilesBuildingsWalletBase64Function(
                                        item.name,
                                        item.type,
                                        token
                                      )
                                        .then((returnedData: any) => {
                                          console.log("file", returnedData);
                                          if (
                                            returnedData.data.isImage == true
                                          ) {
                                            setImage(returnedData.data.file);
                                            openpImageModal();
                                            setIsLoading(false);
                                          } else {
                                            downloadPDF(returnedData.data.file);
                                            setIsLoading(false);
                                          }
                                        })
                                        .catch((e: any) => {
                                          console.log(e);
                                          setIsLoading(false);
                                        });
                                    }}
                                  />
                                </div>

                                <div className="float-left px-2 ">
                                  <Button
                                    size="ssmall"
                                    text="حذف"
                                    theme={"danger"}
                                    onClick={() => {
                                      presentAlert({
                                        cssClass: "",
                                        header: "تنبية",
                                        message: `<strong class="strongStyle">هل تريد الحذف ؟.. الملف سيتم حذفة نهائيأ </strong >`,
                                        buttons: [
                                          {
                                            text: "موافق",
                                            handler: () => {
                                              deleteFilesByBuildingsWalletParcelFunction(
                                                item.id,
                                                userInfo.id,
                                                token
                                              )
                                                .then((result: any) => {
                                                  console.log("result", result);
                                                  if (result.data) {
                                                    setAttachments(
                                                      attachments.filter(
                                                        (i: any) =>
                                                          i.id !== item.id
                                                      )
                                                    );
                                                    presentToastFunction(
                                                      presentToast,
                                                      "top",
                                                      "success",
                                                      " يرجي الضغط علي تعديل لحفظ بياناتك"
                                                    );
                                                  }
                                                })
                                                .catch((e) => {
                                                  console.log(e);
                                                  if (
                                                    e.response.status == 401
                                                  ) {
                                                    window.localStorage.removeItem(
                                                      "persistantState"
                                                    );
                                                    window.location.assign(
                                                      `/app/signIn`
                                                    );
                                                  }
                                                });
                                            },
                                          },
                                          "الغاء",
                                        ],
                                        onDidDismiss: () =>
                                          console.log("did dismiss"),
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="w-full mt-4 mb-6 ">
                    <Button
                      theme={"primary"}
                      text={"تعديل"}
                      size={"big"}
                      onClick={() => {
                        presentAlert({
                          cssClass: "",
                          header: "تنبية",
                          message: `<strong class="strongStyle">هل أنت متأكد من تعديل بيانات المحفظة ؟</strong >`,
                          buttons: [
                            {
                              text: "موافق",
                              handler: () => {
                                handleSubmit();
                              },
                            },
                            "الغاء",
                          ],
                          onDidDismiss: (e) => console.log("did dismiss"),
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const DetailsAddModel = ({
  DetailsAddModelDismiss,
}: {
  DetailsAddModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const inputClassWithOutError =
    "block px-5 py-2.5 text-1xl  placeholder-paragraphColor w-full  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputClassWithError =
    "input-error block px-5 py-2.5  text-1xl  placeholder-paragraphColor w-full   bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputNeedAddIsOpenRef = useRef<HTMLIonInputElement>(null);

  return (
    <IonPage>
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
        <p className="font-bold text-primaryColor100 text-2xl">
          يرجى كتابة المحتوي
        </p>
        <div className="w-96 mt-4">
          <IonItem>
            <IonInput
              type="text"
              autofocus={true}
              className={
                inputNeedAddIsOpenRef.current?.value
                  ? `${inputClassWithOutError}`
                  : `${inputClassWithError}`
              }
              ref={inputNeedAddIsOpenRef}
            ></IonInput>
          </IonItem>
        </div>
        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
          <Button
            theme={"primary"}
            text={"حفظ"}
            size={"big"}
            onClick={() => {
              if (inputNeedAddIsOpenRef.current?.value) {
                DetailsAddModelDismiss(
                  inputNeedAddIsOpenRef.current?.value,
                  "confirm"
                );
              }
            }}
          />
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              DetailsAddModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

const ModalImagesExample = ({
  image,
  onCloseImageDismiss,
}: {
  image: any;
  onCloseImageDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>عرض الصورة</IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseImageDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <img src={image} className="w-full " />
      </IonContent>
    </IonContent>
  );
};

export default EditBuildingsWalletSingleModal;
