import {
  IonContent,
  useIonToast,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonFooter,
  IonPage,
  IonSearchbar,
  IonButton,
  useIonModal,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Spinner from "../../components/atoms/spinner";
import emptySvg from "../../images/empty.svg";
import payServiceSvg from "../../assets/uicons/svg/fi-ts-payroll-check.svg";
import maBalanceSvg from "../../assets/uicons/svg/fi-ts-calculator-money.svg";
import previousPaymentsSvg from "../../assets/uicons/svg/fi-ts-payroll-calendar.svg";
import {
  arrowBack,
  arrowForward,
  checkmarkCircleOutline,
  chevronBackOutline,
  closeCircleOutline,
  reloadOutline,
} from "ionicons/icons";
import {
  GetPaymentChargersByUser,
  GetPaymentOrdersByUser,
  GetPaymentTypes,
  PaymentCompleted,
} from "../../services/API";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import lastPageSvg from "../../images/lastPage.svg";
import firstPageSvg from "../../images/firstPage.svg";
import { chargerDates } from "./chargerDates";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import Joyride from "react-joyride";
import "./paymentRedirect.css";
import serviceDetailsModal from "./serviceDetailsModal";

const PaymentRedirect: React.FC = () => {
  let { token } = useSelector((state: any) => state.reducer);
  const [urlId, setUrlId]: any = useState();

  const [paymentTypes, setpaymentTypes]: any = useState([]);
  const [acualPaymentTypes, setAcualPaymentTypes]: any = useState();

  const [item, setItem]: any = useState();

  const [previousPayments, setPreviousPayments]: any = useState([]);
  const [actualPreviousPayments, setActualPreviousPayments]: any = useState();

  const [maBalance, setMaBalance]: any = useState([]);
  const [actualMaBalance, setActualMaBalance]: any = useState();

  const [purchaseResponse, setPurchaseResponse]: any = useState();

  const { userInfo } = useSelector((state: any) => state.reducer);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPaymentService, setCurrentPaymentService] =
    useState("payService");

  let [presentToast] = useIonToast();
  const history = useHistory();

  function getPreviousPayments() {
    setIsLoading(true);
    setPreviousPayments(null);
    setActualPreviousPayments(null);
    GetPaymentOrdersByUser(userInfo?.id, token).then((result: any) => {
      if (result.status == 200) {
        console.log("NEW NEW NEW==>", result);
        setPreviousPayments(result?.data);
        setActualPreviousPayments(result?.data);
      }
      setIsLoading(false);
    });
  }

  function getPaymentTypes() {
    setIsLoading(true);
    setpaymentTypes(null);
    setAcualPaymentTypes(null);

    GetPaymentTypes(token).then((result: any) => {
      if (result.status === 200 && result.data) {
        // Call the helper function to order the data
        const orderedData = orderByTypes(result.data);

        console.log("Ordered data==>", orderedData);
        setpaymentTypes(orderedData); // Set ordered data
        setAcualPaymentTypes(orderedData);
      }
      setIsLoading(false);
    });
  }

  function getPurchaseResponse(id: string) {
    setIsLoading(true);
    setPurchaseResponse(null);
    PaymentCompleted(id, token).then((result: any) => {
      if (result.status == 200) {
        console.log("aaaaaaaaaaaaaa==>", result);
        setPurchaseResponse(result?.data);
      }
      setIsLoading(false);
    });
  }

  function getMaBalance() {
    setIsLoading(true);
    setMaBalance(null);
    setActualMaBalance(null);
    GetPaymentChargersByUser(userInfo.id, token).then((result: any) => {
      if (result.status == 200) {
        console.log("aaaaaaaaaaaaaa==>", result);
        setMaBalance(result?.data);
        setActualMaBalance(result?.data);
      }
      setIsLoading(false);
    });
  }

  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".search-bar",
      content: "حقل البحث: يستخدم للبحث عن الخدمات.",
      placement: "auto" as const,
      disableBeacon: true,
    },
    {
      target: ".buyList",
      content:
        "عمليات الدفع: يتم فيها عرض قائمة من عمليات الدفع، يمكنك اختيار عملية ومن ثم تابع عملية الدفع.",
    },
    {
      target: ".myList",
      content:
        "قائمة الخدمات الخاصة بك: يتم فيها عرض قائمة من الخدمات الحالية الخاصة بك.",
    },
    {
      target: ".pagination-controls",
      content:
        "التحكم في الصفحات: يمكنك التنقل بين الصفحات ومن الصفحة السابقة إلى الصفحةالتالية.",
    },
    {
      target: ".previousTrialList",
      content:
        "قائمة محاولات الدفع السابقة: يتم فيها عرض قائمة من محاولات الدفع التي قمت بها سابقًا.",
    },
    {
      target: ".recheckPayment",
      content:
        "زر اعادة التحقق من الدفع: بالضغط عليه يتم اعادة التحقق من دفع تلك الخدمة.",
      placement: "right" as const,
    },
  ]);

  useEffect(() => {
    let paymentRedirectOpenedFirstTime = window.localStorage.getItem(
      "paymentRedirectOpenedFirstTime"
    );

    if (paymentRedirectOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }

    getPaymentTypes();
    console.log(userInfo);

    // contentRef.current?.scrollToTop(500);

    let urlId = window.location.href.split("tap_id=")[1];
    // setIsLoading(true);
    if (urlId) {
      setUrlId(urlId);
      getPurchaseResponse(urlId);
      setCurrentPaymentService("purchaseResponse");
      console.log(urlId);
    }
  }, [urlId]);

  const [presentServiceDetailsModal, dismissServiceDetailsModal] = useIonModal(
    serviceDetailsModal,
    {
      itemsData: item,
      onDismissServiceDetailsModal: (data: string, role: string) =>
        dismissServiceDetailsModal(data, role),
    }
  );

  function openServiceDetailsModal() {
    presentServiceDetailsModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.8,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          setIsLoading(true);
          handlePaymentService("none");
        }
      },
    });
  }

  function handlePaymentService(item: any): void {
    setIsLoading(false);
    if (item == "none") {
      let cards = document.querySelectorAll(`.cards`);
      cards.forEach((card: any) => {
        card.setAttribute(
          "style",
          "border: 1px solid #e1e1e1; width: 24em; border-radius: 10px!important"
        );
      });
      setIsLoading(false);
      return;
    }

    let cards = document.querySelectorAll(`.cards`);
    cards.forEach((card: any) => {
      card.setAttribute(
        "style",
        "border: 1px solid #e1e1e1; width: 24em; border-radius: 10px!important"
      );
    });

    let card = document.getElementsByClassName(`card-${item.id}`)[0];
    card.setAttribute(
      "style",
      "border: 3px solid #0031c4; width: 24em; border-radius: 10px!important"
    );
    setItem(item);
    openServiceDetailsModal();
    setIsLoading(false);
  }

  const handlePaymentTypes = (filter: any) => {
    if (filter) {
      const newAdds = acualPaymentTypes.filter(function (str: any) {
        return (
          str.name.toString().includes(filter) ||
          str.amount.toString().includes(filter)
        );
      });

      if (!newAdds || newAdds.length == 0) {
        setpaymentTypes("emptyAfterSearch");
        return;
      }
      setpaymentTypes(newAdds);
      setCurrentPage(1);
    } else {
      getPaymentTypes();
    }
    debugger;
  };

  const handlePreviousPayments = (filter: any) => {
    if (filter) {
      const newAdds = actualPreviousPayments.filter(function (str: any) {
        return (
          str.paymentType.name.toString().includes(filter) ||
          str.paymentType.amount.toString().includes(filter) ||
          str.createdDate.split("T")[0].toString().includes(filter)
        );
      });
      console.log(newAdds);

      if (!newAdds || newAdds.length == 0) {
        setPreviousPayments("emptyAfterSearch");
        return;
      }
      setPreviousPayments(newAdds);
      setCurrentPage(1);
    } else {
      getPreviousPayments();
    }
    debugger;
  };

  const handleMaBalance = (filter: any) => {
    if (filter) {
      const newAdds = actualMaBalance.filter(function (str: any) {
        return (
          str.paymentType.name.toString().includes(filter) ||
          str.paymentType.amount.toString().includes(filter) ||
          str.expireDate.split("T")[0].toString().includes(filter)
        );
      });
      console.log(newAdds);

      if (!newAdds || newAdds.length == 0) {
        setPreviousPayments("emptyAfterSearch");
        return;
      }
      setMaBalance(newAdds);
      setCurrentPage(1);
    } else {
      getMaBalance();
    }
    debugger;
  };

  // const [ToBottom, setToBottom]: any = useState(true);
  // const contentRef = createRef<HTMLIonContentElement>();

  // const scrollToBottomAndTop = () => {
  //   if (ToBottom) {
  //     contentRef.current?.scrollToBottom(500);
  //     setToBottom(!ToBottom);
  //   } else {
  //     contentRef.current?.scrollToTop(500);
  //     setToBottom(!ToBottom);
  //   }
  // };

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards =
    currentPaymentService == "payService"
      ? paymentTypes?.slice(indexOfFirstCard, indexOfLastCard)
      : currentPaymentService == "maBalanceService"
      ? maBalance?.slice(indexOfFirstCard, indexOfLastCard)
      : previousPayments?.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages =
    currentPaymentService == "payService"
      ? Math.ceil(paymentTypes?.length / cardsPerPage)
      : currentPaymentService == "maBalanceService"
      ? Math.ceil(maBalance?.length / cardsPerPage)
      : Math.ceil(previousPayments?.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const typesOrder = [7, 8, 6]; // Define the custom order as an array for easier processing

  // Helper function to sort data according to typesOrder
  function orderByTypes(data: any[]): any[] {
    const orderedData: any[] = [];
    const addedIds = new Set(); // Set to track added IDs

    // First, add items that match the typesOrder
    for (const id of typesOrder) {
      const item = data.find((item) => item.id === id);
      if (item) {
        orderedData.push(item); // Add the item in the specified order
        addedIds.add(item.id); // Track that this ID has been added
      }
    }

    // Now, add the remaining items that weren't included in typesOrder
    data.forEach((item) => {
      if (!addedIds.has(item.id)) {
        orderedData.push(item); // Add remaining items to the end
      }
    });

    return orderedData; // Return the ordered array
  }

  return (
    <IonPage>
      <>
        {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
        {!isLoading && run && (
          <Joyride
            steps={steps}
            run={run}
            continuous={run}
            disableCloseOnEsc={true}
            disableOverlayClose={true}
            showSkipButton={false}
            hideBackButton={true}
            hideCloseButton={true}
            disableScrolling={false}
            showProgress={true}
            locale={{
              back: "الرجوع",
              close: "اغلاق",
              last: "إنهاء",
              next: "التالي",
              skip: "تخطي",
            }}
            callback={(data) => {
              console.log(data);
              if (
                data.action == "next" &&
                data.index == 1 &&
                data.lifecycle == "complete"
              ) {
                setCurrentPaymentService("maBalanceService");
                getMaBalance();
                handleFirstPage();
              }
              if (
                data.action == "next" &&
                data.lifecycle == "complete" &&
                data.index == 3
              ) {
                setCurrentPaymentService("previousPayments");
                getPreviousPayments();
                handleFirstPage();
              }

              if (data.status == "finished" || data.action == "reset") {
                window.localStorage.setItem(
                  "paymentRedirectOpenedFirstTime",
                  "true"
                );
                setRun(false);
              }
            }}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
          />
        )}

        {currentPaymentService === "payService" && (
          // <IonContent ref={contentRef}>
          <IonContent>
            <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
              <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                <div className="col-span-1 "></div>
                <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                  <p className="font-bold text-white text-2xl whitespace-nowrap">
                    شراء خدمة
                  </p>
                </div>
                <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                  <button
                    onClick={() => history.push("/app")}
                    className="flex flex-col justify-center items-center"
                  >
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-white"
                    />
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-center p-4">
                <div className="w-full flex flex-col justify-start items-center px-2 search-bar">
                  <IonSearchbar
                    placeholder="البحث بإسم او سعر الخدمة"
                    onIonChange={(ev) => handlePaymentTypes(ev.detail.value)}
                  ></IonSearchbar>
                </div>
                {paymentTypes &&
                paymentTypes != "emptyAfterSearch" &&
                paymentTypes.length > 0 ? (
                  <>
                    {currentCards.map((item: any, index: any) => {
                      return (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e1e1e1",
                            width: "24em",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 mb-4 animate__animated animate__fadeInUp buyList cards card-${item.id}`}
                          itemID={item?.id}
                          onClick={() => {
                            setIsLoading(true);
                            handlePaymentService(item);
                          }}
                        >
                          <div className="h-full flex flex-col col-span-3 justify-start items-start">
                            <p className="font-bold text-primaryColor100 text-xl">
                              <span>الإسم: </span>
                              {item.name}
                            </p>
                            <p className="font-bold text-primaryColor100 text-xl">
                              <span>مدة الخدمة: </span>
                              {item.chargerDates == chargerDates.yearly
                                ? "سنوية"
                                : item.chargerDates == chargerDates.Monthly
                                ? "شهرية"
                                : "مرة واحدة لكل عملية شراء"}
                            </p>
                            {item.description && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <p className="text-lg font-bold text-primaryColor100">
                                  الوصف:{" "}
                                  <span className="text-black font-normal">
                                    {item.description}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="h-full flex flex-col col-span-2 justify-center items-end">
                            {item.amount && (
                              <div className="flex flex-col justify-end items-center">
                                <p className="font-bold text-primaryColor100 text-xl">
                                  السعر
                                </p>
                                <p className="text-tan text-xl">
                                  {item.amount}
                                </p>
                                <p className="text-tan text-xl">ريال</p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {currentCards.length > 0 && (
                      <div
                        className={`pagination-buttons  ${
                          !isLoading && "animate__animated animate__fadeInDown"
                        }`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <IonButton
                          onClick={handleFirstPage}
                          style={{ maxWidth: "50px" }}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={firstPageSvg} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={arrowForward} />
                        </IonButton>
                        {getPageNumbers().map((pageNumber) => (
                          <IonButton
                            style={{ maxWidth: "40px" }}
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </IonButton>
                        ))}
                        <IonButton
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          style={{ maxWidth: "50px" }}
                        >
                          <IonIcon icon={arrowBack} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handleLastPage}
                          disabled={currentPage === totalPages}
                        >
                          <IonIcon icon={lastPageSvg} />
                        </IonButton>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                    <div className="pt-5">
                      <p className="text-3xl font-bold text-redColor mt-2">
                        {paymentTypes == "emptyAfterSearch"
                          ? "لا توجد نتائج للبحث"
                          : "لا توجد خدمات متاحة حاليًا"}
                      </p>
                    </div>
                    <div className="pt-5 px-32">
                      <img
                        src={emptySvg}
                        className="animate__animated animate__jello animate__infinite"
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                )}
                {/* <IonFab
                  slot="fixed"
                  vertical="bottom"
                  horizontal="end"
                  className="fixed bottom-20 rounded-full shadow-lg transition duration-300 z-50"
                >
                  <IonFabButton size="small" onClick={scrollToBottomAndTop}>
                    <IonIcon icon={ToBottom ? arrowDown : arrowUp}></IonIcon>
                  </IonFabButton>
                </IonFab> */}
              </div>
            </div>
          </IonContent>
        )}

        {currentPaymentService === "maBalanceService" && (
          // <IonContent ref={contentRef}>
          <IonContent>
            <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
              <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                <div className="col-span-1 "></div>
                <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                  <p className="font-bold text-white text-2xl whitespace-nowrap">
                    رصيدي من الخدمات
                  </p>
                </div>
                <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                  <button
                    onClick={() => history.push("/app")}
                    className="flex flex-col justify-center items-center"
                  >
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-white"
                    />
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-center p-4">
                <div className="w-full flex flex-col justify-start items-center px-2">
                  <IonSearchbar
                    placeholder="البحث بالإسم او السعر او تاريخ الإنتهاء"
                    onIonChange={(ev) => handleMaBalance(ev.detail.value)}
                  ></IonSearchbar>
                </div>
                {maBalance &&
                maBalance != "emptyAfterSearch" &&
                maBalance.length > 0 ? (
                  <>
                    {currentCards.map((item: any, index: any) => {
                      return (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e1e1e1",
                            width: "24em",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 mb-4 myList`}
                        >
                          <div className="h-full flex flex-col col-span-3 justify-start items-start myList">
                            <p className="font-bold text-primaryColor100 text-xl">
                              <span>الإسم: </span>
                              {item.paymentType.name}
                            </p>
                            <p className="font-bold text-primaryColor100 text-xl">
                              <span>مدة الخدمة: </span>
                              {item.paymentType.chargerDates ==
                              chargerDates.yearly
                                ? "سنوية"
                                : item.paymentType.chargerDates ==
                                  chargerDates.Monthly
                                ? "شهرية"
                                : "مرة واحدة لكل عملية شراء"}
                            </p>

                            {item.countOfCharger > 0 ? (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <p className="text-lg font-bold text-primaryColor100">
                                  عدد المرات المتبقية في الخدمة:{" "}
                                  <span className="text-black font-normal">
                                    {item.countOfCharger}
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <p className="text-lg font-bold text-primaryColor100">
                                  تاريخ الإنتهاء:{" "}
                                  <span className="text-black font-normal">
                                    {item.expireDate
                                      .split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="h-full flex flex-col col-span-2 justify-center items-end">
                            {item.paymentType.amount && (
                              <div className="flex flex-col justify-end items-center">
                                <p className="font-bold text-primaryColor100 text-xl">
                                  السعر
                                </p>
                                <p className="text-tan text-xl">
                                  {item.paymentType.amount}
                                </p>
                                <p className="text-tan text-xl">ريال</p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {currentCards.length > 0 && (
                      <div
                        className={`pagination-buttons pagination-controls`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <IonButton
                          onClick={handleFirstPage}
                          style={{ maxWidth: "50px" }}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={firstPageSvg} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          <IonIcon icon={arrowForward} />
                        </IonButton>
                        {getPageNumbers().map((pageNumber) => (
                          <IonButton
                            style={{ maxWidth: "40px" }}
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            disabled={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </IonButton>
                        ))}
                        <IonButton
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          style={{ maxWidth: "50px" }}
                        >
                          <IonIcon icon={arrowBack} />
                        </IonButton>
                        <IonButton
                          style={{ maxWidth: "50px" }}
                          onClick={handleLastPage}
                          disabled={currentPage === totalPages}
                        >
                          <IonIcon icon={lastPageSvg} />
                        </IonButton>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                    <div className="pt-5">
                      <p className="text-3xl font-bold text-redColor mt-2">
                        {paymentTypes == "emptyAfterSearch"
                          ? "لا توجد نتائج للبحث"
                          : "لا توجد خدمات مسجله لديك"}
                      </p>
                    </div>
                    <div className="pt-5 px-32">
                      <img
                        src={emptySvg}
                        className="animate__animated animate__jello animate__infinite"
                        height={150}
                        width={150}
                      />
                    </div>
                  </div>
                )}
                {/* <IonFab
                  slot="fixed"
                  vertical="bottom"
                  horizontal="end"
                  className="fixed bottom-20 rounded-full shadow-lg transition duration-300 z-50"
                >
                  <IonFabButton size="small" onClick={scrollToBottomAndTop}>
                    <IonIcon icon={ToBottom ? arrowDown : arrowUp}></IonIcon>
                  </IonFabButton>
                </IonFab> */}
              </div>
            </div>
          </IonContent>
        )}

        {currentPaymentService === "previousPayments" && (
          <>
            <IonContent>
              <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
                <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                  <div className="col-span-1"></div>
                  <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                    <p className="font-bold text-white text-2xl whitespace-nowrap">
                      المدفوعات السابقة
                    </p>
                  </div>
                  <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                    <button
                      onClick={() => history.push("/app")}
                      className="flex flex-col justify-center items-center"
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-white"
                      />
                    </button>
                  </div>
                </div>
                <div className="w-full flex flex-col justify-start items-center p-4">
                  <div className="w-full flex flex-col justify-start items-center px-2">
                    <IonSearchbar
                      placeholder="البحث بإسم او سعر او تاريخ الخدمة"
                      onIonChange={(ev) =>
                        handlePreviousPayments(ev.detail.value)
                      }
                      className="text-lg"
                    ></IonSearchbar>
                  </div>

                  {previousPayments &&
                  previousPayments != "emptyAfterSearch" &&
                  previousPayments.length > 0 ? (
                    <>
                      {currentCards.map((item: any, index: any) => {
                        return (
                          <div
                            key={index}
                            style={{
                              border: "1px solid #e1e1e1",
                              width: "24em",
                              borderRadius: "10px",
                            }}
                            className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 mb-4 previousTrialList`}
                          >
                            <div className="h-full flex flex-col col-span-3 justify-start items-start">
                              <p className="font-bold text-primaryColor100 text-xl">
                                <span>الإسم: </span>
                                {item.paymentType.name}
                              </p>
                              <p className="font-bold text-primaryColor100 text-xl">
                                <span>مدة الخدمة: </span>
                                {item.paymentType.chargerDates ==
                                chargerDates.yearly
                                  ? "سنوية"
                                  : item.paymentType.chargerDates ==
                                    chargerDates.Monthly
                                  ? "شهرية"
                                  : "مرة واحدة لكل عملية شراء"}
                              </p>

                              {item.createdDate && (
                                <div className="flex flex-row justify-start items-center mt-1">
                                  <p className="text-lg font-bold text-primaryColor100">
                                    تاريخ الشراء:{" "}
                                    <span className="text-black font-normal">
                                      {item.createdDate
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                    </span>
                                  </p>
                                </div>
                              )}

                              {item?.status && (
                                <div className="flex flex-row justify-start items-center mt-1">
                                  <p className="text-lg font-bold text-primaryColor100">
                                    حالة الدفع:{" "}
                                    <span className="text-red-600 font-normal">
                                      {item?.status == "CAPTURED" ||
                                      item?.status == "already paid"
                                        ? "تم الدفع بنجاح"
                                        : item?.status == "INITIATED"
                                        ? "لم يتم استكمال عملية الدفع"
                                        : "لم يتم الدفع"}
                                    </span>
                                  </p>
                                </div>
                              )}
                            </div>

                            <div className="h-full flex flex-col col-span-2 justify-center items-end">
                              {item.paymentType.amount && (
                                <div className="flex flex-col justify-end items-center">
                                  <p className="font-bold text-primaryColor100 text-xl">
                                    السعر
                                  </p>
                                  <p className="text-tan text-xl">
                                    {item.paymentType.amount}
                                  </p>
                                  <p className="text-tan text-xl">ريال</p>
                                </div>
                              )}

                              {item?.status &&
                                item.chargeId &&
                                item?.status != "CAPTURED" &&
                                item?.status != "already paid" && (
                                  <div className="flex flex-row justify-start items-center mt-4 recheckPayment scale-x-[-1]">
                                    <IonIcon
                                      icon={reloadOutline}
                                      className={`text-primaryColor100 text-4xl ${
                                        !isLoading &&
                                        "animate__animated animate__fadeInLeftBig"
                                      }`}
                                      onClick={() => {
                                        setIsLoading(true);
                                        history.push(
                                          `/app/PaymentRedirect?tap_id=${item.chargeId}`
                                        );
                                        setUrlId(item.chargeId);
                                        setCurrentPaymentService(
                                          "purchaseResponse"
                                        );
                                        setIsLoading(false);
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      })}
                      {currentCards.length > 0 && (
                        <div
                          className={`pagination-buttons ${
                            !isLoading &&
                            "animate__animated animate__fadeInDown"
                          }`}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                          }}
                        >
                          <IonButton
                            onClick={handleFirstPage}
                            style={{ maxWidth: "50px" }}
                            disabled={currentPage === 1}
                          >
                            <IonIcon icon={firstPageSvg} />
                          </IonButton>
                          <IonButton
                            style={{ maxWidth: "50px" }}
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                          >
                            <IonIcon icon={arrowForward} />
                          </IonButton>
                          {getPageNumbers().map((pageNumber) => (
                            <IonButton
                              style={{ maxWidth: "40px" }}
                              key={pageNumber}
                              onClick={() => handlePageChange(pageNumber)}
                              disabled={currentPage === pageNumber}
                            >
                              {pageNumber}
                            </IonButton>
                          ))}
                          <IonButton
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            style={{ maxWidth: "50px" }}
                          >
                            <IonIcon icon={arrowBack} />
                          </IonButton>
                          <IonButton
                            style={{ maxWidth: "50px" }}
                            onClick={handleLastPage}
                            disabled={currentPage === totalPages}
                          >
                            <IonIcon icon={lastPageSvg} />
                          </IonButton>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                      <div className="pt-5">
                        <p className="text-3xl font-bold text-redColor mt-2">
                          {previousPayments == "emptyAfterSearch"
                            ? "لا توجد نتائج للبحث"
                            : "لا توجد مدفوعات سابقة"}
                        </p>
                      </div>
                      <div className="pt-5 px-32">
                        <img
                          src={emptySvg}
                          className="animate__animated animate__jello animate__infinite"
                          height={150}
                          width={150}
                        />
                      </div>
                    </div>
                  )}
                  {/* <IonFab
                  slot="fixed"
                  vertical="bottom"
                  horizontal="end"
                  className="fixed bottom-20 rounded-full shadow-lg transition duration-300 z-50"
                >
                  <IonFabButton size="small" onClick={scrollToBottomAndTop}>
                    <IonIcon icon={ToBottom ? arrowDown : arrowUp}></IonIcon>
                  </IonFabButton>
                </IonFab> */}
                </div>{" "}
              </div>
            </IonContent>
          </>
        )}

        {currentPaymentService === "purchaseResponse" && (
          // <IonContent ref={contentRef}>
          <IonContent>
            <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
              <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                <div className="col-span-1 "></div>
                <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                  <p className="font-bold text-white text-2xl whitespace-nowrap">
                    التحقق من عملية الدفع
                  </p>
                </div>
                <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                  <button
                    onClick={() => history.push("/app")}
                    className="flex flex-col justify-center items-center"
                  >
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-white"
                    />
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-center p-4">
                {(purchaseResponse?.status == "CAPTURED" ||
                  purchaseResponse?.status == "already paid") && (
                  <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                    <div className="pt-5 px-32">
                      <IonIcon
                        icon={checkmarkCircleOutline}
                        className="text-8xl text-primaryColor100"
                      />
                    </div>
                    <div className="pt-5">
                      <p className="text-3xl font-bold text-primaryColor100 mt-2">
                        تمت عملية الدفع بنجاح
                      </p>
                    </div>
                  </div>
                )}
                {purchaseResponse &&
                  purchaseResponse?.status != "CAPTURED" &&
                  purchaseResponse?.status != "already paid" && (
                    <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
                      <div className="pt-5 px-32">
                        <IonIcon
                          icon={closeCircleOutline}
                          className="text-8xl text-redColor"
                        />
                      </div>
                      <div className="pt-5">
                        <p className="text-3xl font-bold text-redColor mt-2">
                          عذرًا لم تتم عملية الدفع بنجاح
                        </p>
                      </div>
                      <div
                        className="flex flex-row justify-start mt-2"
                        dir="ltr"
                      >
                        <IonButton
                          size={"default"}
                          color={"primary"}
                          className="rounded-lg mt-5 w-full mr-2 text-2xl"
                          onClick={() => {
                            getPurchaseResponse(urlId);
                          }}
                        >
                          التحقق مره اخري من عملية الدفع
                          <IonIcon
                            icon={reloadOutline}
                            className="ml-2 text-4xl text-white scale-x-[-1]"
                          />
                        </IonButton>
                      </div>
                    </div>
                  )}
                {/* <IonFab
                  slot="fixed"
                  vertical="bottom"
                  horizontal="end"
                  className="fixed bottom-20 rounded-full shadow-lg transition duration-300 z-50"
                >
                  <IonFabButton size="small" onClick={scrollToBottomAndTop}>
                    <IonIcon icon={ToBottom ? arrowDown : arrowUp}></IonIcon>
                  </IonFabButton>
                </IonFab> */}
              </div>
            </div>
          </IonContent>
        )}

        <IonFooter translucent={true}>
          <IonSegment value={currentPaymentService}>
            <IonSegmentButton
              hidden={!urlId}
              className="animate__animated animate__rubberBand"
              value="purchaseResponse"
              onClick={() => {
                setCurrentPaymentService("purchaseResponse");
                getPurchaseResponse(urlId);
              }}
            >
              <IonLabel
                className="text-primaryColor100"
                style={{ textWrap: "wrap" }}
              >
                التحقق من الدفع
              </IonLabel>
              <IonIcon
                icon={payServiceSvg}
                className="text-primaryColor100"
              ></IonIcon>
            </IonSegmentButton>

            <IonSegmentButton
              className="animate__animated animate__rubberBand"
              value="previousPayments"
              onClick={() => {
                setCurrentPaymentService("previousPayments");
                handleFirstPage();
                getPreviousPayments();
              }}
            >
              <IonLabel
                className="text-primaryColor100"
                style={{ textWrap: "wrap" }}
              >
                المدفوعات السابقة
              </IonLabel>
              <IonIcon
                icon={previousPaymentsSvg}
                className="text-primaryColor100"
              ></IonIcon>
            </IonSegmentButton>

            <IonSegmentButton
              className="animate__animated animate__rubberBand"
              value="maBalanceService"
              onClick={() => {
                setCurrentPaymentService("maBalanceService");
                getMaBalance();
                handleFirstPage();
              }}
            >
              <IonLabel
                className="text-primaryColor100"
                style={{ textWrap: "wrap" }}
              >
                رصيدي من الخدمات
              </IonLabel>
              <IonIcon
                icon={maBalanceSvg}
                className="text-primaryColor100"
              ></IonIcon>
            </IonSegmentButton>

            <IonSegmentButton
              className="animate__animated animate__rubberBand"
              value="payService"
              onClick={() => {
                setCurrentPaymentService("payService");
                handleFirstPage();
              }}
            >
              <IonLabel
                className="text-primaryColor100"
                style={{ textWrap: "wrap" }}
              >
                شراء خدمة
              </IonLabel>
              <IonIcon
                icon={payServiceSvg}
                className="text-primaryColor100"
              ></IonIcon>
            </IonSegmentButton>
          </IonSegment>
        </IonFooter>
      </>
    </IonPage>
  );
};

// const serviceDetailsModal = ({
//   itemsData,
//   onDismissServiceDetailsModal,
// }: {
//   itemsData: any;
//   onDismissServiceDetailsModal: (
//     data?: string | null | undefined | number,
//     role?: string
//   ) => void;
// }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [openApplePay, setOpenApplePay] = useState(false);
//   let { token } = useSelector((state: any) => state.reducer);
//   const { userInfo } = useSelector((state: any) => state.reducer);
//   const [presentToast] = useIonToast();

//   function CreateInvoice(idService: any) {
//     setIsLoading(true);
//     CreateCharge(idService, token).then((result: any) => {
//       if (result.status == 200) {
//         console.log("aaaaaaaaaaaaaa==>", result);
//         if (result.data.url) {
//           window.location.href = result.data.url;
//         } else {
//           presentToastFunction(
//             presentToast,
//             "top",
//             "danger",
//             `عفوا حدث خطأ اثناء تحضير عملية الدفع`
//           );
//         }
//       }
//       setIsLoading(false);
//     });
//   }

//   return (
//     <IonContent>
//       <div className="wrapper animate__animated animate__fadeInDown">
//         <div className="flex flex-col justify-start items-center py-6">
//           <p className="font-bold text-primaryColor100 text-2xl">
//             الخدمة المختارة
//           </p>
//           <div className="px-10 py-5 bg-white rounded-lg shadow-lg">
//             {isLoading ? (
//               <Spinner
//                 isFull={false}
//                 cssExtra={"absolute top-2"}
//                 color={"primary"}
//                 size={"large"}
//               />
//             ) : null}

//             <div className="mb-8 text-xl">
//               <table className="border-2 text-center">
//                 <thead>
//                   <tr>
//                     <th className="py-2 px-4 border-2">الخدمة</th>
//                     <th className="py-2 px-4 border-2">العدد</th>
//                     <th className="py-2 px-4 border-2">المدة</th>
//                     <th className="py-2 px-4 border-2">السعر</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td className="py-2 px-4 border-2">{itemsData.name}</td>
//                     <td className="py-2 px-4 border-2">1</td>
//                     <td className="py-2 px-4 border-2">
//                       {itemsData.chargerDates == chargerDates.yearly
//                         ? "سنوية"
//                         : itemsData.chargerDates == chargerDates.Monthly
//                         ? "شهرية"
//                         : "مرة واحدة لكل عملية شراء"}
//                     </td>
//                     <td className="py-2 px-4 border-2 text-tan">
//                       {itemsData.amount} ريال
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//             <div className="flex justify-end">
//               <div className="text-right">
//                 <h3 className="text-2xl font-semibold text-primaryColor100">
//                   الإجمالي
//                 </h3>
//                 <p className="text-xl font-bold text-center text-tan">
//                   {itemsData.amount} ريال
//                 </p>
//               </div>
//             </div>
//             <div className="flex flex-row justify-start" dir="ltr">
//               <IonButton
//                 size={"default"}
//                 color={"light"}
//                 className="border border-primaryColor100 color-white rounded-lg mt-5 w-full mr-2 text-xl"
//                 onClick={() => {
//                   CreateInvoice(itemsData.id);
//                 }}
//               >
//                 Pay with <IonIcon icon={visaSvg} className="ml-2 text-6xl" /> <IonIcon icon={mastercardSvg} className="ml-2 text-6xl" />
//               </IonButton>
//             </div>
//             {isPlatform("ios") && (
//               <>
//                 <div className="flex flex-row justify-start" dir="ltr">
//                   <IonButton
//                     size={"default"}
//                     color={"light"}
//                     className="border border-primaryColor100 color-white rounded-lg mt-5 w-full mr-2 text-xl"
//                     onClick={() => {
//                       setOpenApplePay(true);
//                     }}
//                   >
//                     Pay with{" "}
//                     <IonIcon
//                       icon={applePaySvg}
//                       className="ml-2 text-6xl text-primaryColor100"
//                     />
//                   </IonButton>
//                 </div>
//                 {openApplePay && (
//                   <ApplePayButton
//                     // The public Key provided by Tap
//                     publicKey={"pk_test_LgwtnhOJ0fibTZl5urF2sxPH"}
//                     // The environment of the SDK (Development or Live)
//                     environment={Environment.Development}
//                     // Enable debug mode
//                     debug
//                     merchant={{
//                       // The merchant domain name
//                       domain: "http://thki.sa",
//                       // The merchant identifier provided by Tap
//                       id: "6116136",
//                     }}
//                     transaction={{
//                       // The amount to be charged
//                       amount: itemsData.amount,
//                       // The currency of the amount
//                       currency: "SAR",
//                     }}
//                     // The scope of the SDK (TapToken or AppleToken)
//                     scope={Scope.TapToken}
//                     acceptance={{
//                       // Supported networks for the Apple Pay button
//                       supportedBrands: [
//                         SupportedNetworks.Mada,
//                         SupportedNetworks.Visa,
//                         SupportedNetworks.MasterCard,
//                       ],
//                       supportedCards: ["DEBIT", "CREDIT"],
//                       supportedCardsWithAuthentications: ["3DS", "EMV"],
//                     }}
//                     // The billing contact information
//                     customer={{
//                       id: `${userInfo.userName.split(".")[0]}`,
//                       name: [
//                         {
//                           lang: Locale.AR,
//                           first: userInfo.fullName.split(" ")[0],
//                           last: userInfo.fullName.split(" ").pop(),
//                           middle: userInfo.fullName.split(" ")[1],
//                         },
//                       ],
//                       contact: {
//                         email: userInfo.email,
//                         phone: {
//                           countryCode: "00966",
//                           number: userInfo.phoneNumber,
//                         },
//                       },
//                     }}
//                     // Interface settings for styling the button
//                     interface={{
//                       // The locale of the Apple Pay button (EN or AR)
//                       locale: Locale.EN,
//                       // The theme of the Apple Pay button (light or dark)
//                       theme: ThemeMode.LIGHT,
//                       // The type of the Apple Pay button (BUY)
//                       type: ButtonType.BUY,
//                       // The border style of the button (curved or straight)
//                       edges: Edges.CURVED,
//                     }}
//                     // Optional callback functions
//                     onCancel={() => console.log("Payment cancelled")}
//                     onError={(err) => console.error("Error:", err)}
//                     onSuccess={async (token) => {
//                       // Handle successful payment
//                       console.log("Payment successful. Token:", token);
//                     }}
//                     onReady={() => {
//                       console.log("Button is ready");
//                     }}
//                     onClick={() => {
//                       console.log("Button clicked");
//                     }}
//                   />
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//       <div></div>
//     </IonContent>
//   );
// };

export default PaymentRedirect;
