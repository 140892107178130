import { IonPage, useIonToast } from "@ionic/react";
import Button from "../../components/atoms/button";
import { deleteAccountFunction } from "../../services/API";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import { responseCodes } from "../../services/API/responseCodes";

const DeleteUserModel = ({
  userId,
  token,
  DeleteUserModelDismiss,
}: {
  userId: any;
  token: any;
  DeleteUserModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [presentToast] = useIonToast();

  function removeAccount() {
    deleteAccountFunction(token)
      .then((returnedData: any) => {
        console.log("returnedData", returnedData);
        if (returnedData.data.responseCode == responseCodes.OK) {
          presentToastFunction(
            presentToast,
            "top",
            "success",
            `تم الحذف بنجاح`
          );
          window.localStorage.removeItem("persistantState");
          window.location.assign(`/app/signIn`);
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `لم يتم تنفيذ العملية`
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return (
    <IonPage>
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
        <p className="font-bold text-primaryColor100 text-2xl">
          هل ترغب بحذف حسابك نهائيا ؟
        </p>

        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
          <Button
            theme={"danger"}
            text={"موافق"}
            size={"big"}
            onClick={() => {
              removeAccount();
            }}
          />
          <Button
            theme={"primary"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              DeleteUserModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default DeleteUserModel;
