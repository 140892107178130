import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonModal,
  IonToolbar,
  IonTitle,
  IonHeader,
  isPlatform,
  IonInput,
  IonItem,
  useIonModal,
  useIonToast,
  IonProgressBar,
} from "@ionic/react";
import Logo_White from "../../images/logo_white.svg";
import SignIn_Background from "../../images/signIn_Background.svg";
import Button from "../../components/atoms/button";
import { logoAppleAppstore, logoGooglePlaystore } from "ionicons/icons";
import { Formik, Field } from "formik";
import * as yup from "yup";
import {
  ForgotPasswordFunc,
  loginCallBackNafazFunction,
  loginNafazFunction,
  SendOTPToPhoneNumber,
  signInFunction,
} from "../../services/API";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorImage from "../../images/error_image.svg";
import arrowLeft from "../../assets/uicons/svg/fi-ts-arrow-left.svg";
import {
  setToken,
  changeToAuthenticated,
  setUserInfo,
  setSignInAsVisitor,
  setOtherData,
} from "../../services/redux/actions";
import MessageModal from "../../components/molecules/messageModal";
import ErrorModal from "../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import SignInModel from "./signInModel";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import Spinner from "../../components/atoms/spinner";
import { IsWebApp } from "../../configs/GenericConfig";
import { responseCodes } from "../../services/API/responseCodes";

const validationSchema = yup.object().shape({
  userName: yup.string(),
  password: yup.string(),
  IqamaId: yup.string(),
});

const SignIn: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setSignInAsVisitorFunction = (returnedData: Object) =>
    dispatch(setSignInAsVisitor(returnedData));
  const [presentToast] = useIonToast();
  const location = history.location;

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [isNafazModalIsOpen, setIsNafazModalIsOpen] = useState(true);
  const [nafazRandom, setNafazRandom] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfos, setUserInfos] = useState();
  const [userValues, setUserValues] = useState<any>({});
  const setTokenFunction = (returnedData: String) =>
    dispatch(setToken(returnedData));
  const changeToAuthenticatedFunction = () => dispatch(changeToAuthenticated());
  const setUserInfoFunction = (returnedData: Object) =>
    dispatch(setUserInfo(returnedData));
  const setOtherDataFunction = (returnedData: Object) =>
    dispatch(setOtherData(returnedData));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("location.pathname", location.pathname);
    console.log("isNafazModalIsOpen", isNafazModalIsOpen);
    console.log("isPlatform('android') ", isPlatform("android"));
    console.log("isPlatform('ios') ", isPlatform("ios"));
    console.log("isPlatform('mobileweb') ", isPlatform("mobileweb"));
    console.log("IsWebApp ", IsWebApp);
  }, [isNafazModalIsOpen]);

  function getTokenBySignInFromNafaz(returnedDataI: any) {
    let returnedData = JSON.parse(returnedDataI);
    console.log("getTokenBySignIn returnedDataI", returnedData);
    if (
      returnedData.data?.responseCode == responseCodes.OK ||
      returnedData.data?.result.responseCode == responseCodes.OK
    ) {
      const token = returnedData.data.dateSet.token;
      const userInfo = returnedData.data.dateSet;
      setTokenFunction(token);
      setUserInfoFunction(userInfo);
      setOtherDataFunction({
        identificationNumber: "Null",
        advertiserNumber: "Null",
      });
      changeToAuthenticatedFunction();
      window.location.assign(`/app/home`);
    }
    setIsLoading(false);
  }

  function getTokenBySignIn(returnedDataI: any) {
    let returnedData = JSON.parse(returnedDataI);
    console.log("getTokenBySignIn returnedDataI", returnedData);
    if (
      returnedData.data?.responseCode == responseCodes.OK ||
      returnedData.data?.result.responseCode == responseCodes.OK
    ) {
      const token = returnedData.data.result.dateSet.token;
      const userInfo = returnedData.data.result.dateSet;
      setTokenFunction(token);
      setUserInfoFunction(userInfo);
      setOtherDataFunction({
        identificationNumber: "Null",
        advertiserNumber: "Null",
      });
      changeToAuthenticatedFunction();
      window.location.assign(`/app/home`);
    }
  }

  function loginNafaz() {
    let personId = localStorage.getItem("IqamaId");
    localStorage.removeItem("BuildingsWalletOpenedFirstTime");
    localStorage.removeItem("usersControlOpenedFirstTime");
    localStorage.removeItem("favLandsOpenedFirstTime");
    localStorage.removeItem("favLandControlOpenedFirstTime");
    localStorage.removeItem("logosListOpenedFirstTime");
    localStorage.removeItem("advertOpenedFirstTime");
    localStorage.removeItem("allPaymentsOpenedFirstTime");
    localStorage.removeItem("paymentRedirectOpenedFirstTime");
    localStorage.removeItem("estateBrokerOpenedFirstTime");
    console.log("loginNafaz personId", personId);
    if (personId) {
      loginNafazFunction(personId).then((result: any) => {
        console.log("loginNafazFunction data", result);
        if (result.data.responseCode != responseCodes.Pending) {
          setIsLoading(true);
        }
        if (result.data.responseCode == responseCodes.OK) {
          let status = result.data.dateSet.status;
          if (status && status == "REJECTED") {
            setIsLoading(false);
            presentToastFunction(
              presentToast,
              "top",
              "danger",
              `${result.data.responseMessage}`
            );
            return;
          }
          setUserInfos(result.data.dateSet);
          if (result.data?.dateSet?.token) {
            localStorage.setItem("returnedDataI", JSON.stringify(result));
            let returnedDataI = localStorage.getItem("returnedDataI");
            getTokenBySignInFromNafaz(returnedDataI);
            presentToastFunction(
              presentToast,
              "top",
              "success",
              "تم تسجيل دخولك بنجاح"
            );
          }
        } else if (result.data.responseCode == responseCodes.Reject) {
          setIsLoading(false);
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            result.data.responseMessage
          );
          setIsNafazModalIsOpen(true);
        } else if (result.data.responseCode == responseCodes.ReGenerate) {
          setIsLoading(false);
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            result.data.responseMessage
          );
          setIsNafazModalIsOpen(true);
        } else if (result.data.responseCode == responseCodes.Error) {
          setIsLoading(false);
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `لا يمكن العثور على أي سجل لمعرف هذا الشخص أو حدث خطأ في التحقق`
          );
        } else if (result.data.responseCode == responseCodes.Pending) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            result.data.responseMessage
          );
        }
        setIsLoading(false);
      });
    }
  }

  useEffect(() => {
    if (!isNafazModalIsOpen) {
      const completeNafaz = setInterval(loginNafaz, 2000);
      return () => clearInterval(completeNafaz);
    }
  }, [isNafazModalIsOpen]);

  //model property
  const [presentModalSendOTP, onModalSendOTPDismiss] = useIonModal(
    ModalSendOTP,
    {
      userInfos: userInfos,
      userValues: userValues,
      onModalSendOTPDismiss: (data: string, role: string) =>
        onModalSendOTPDismiss(data, role),
    }
  );

  function openModalSendOTP() {
    presentModalSendOTP({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.8,
      // backdropBreakpoint: 0.8,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [presentModalForgetPassword, onModalForgetPasswordDismiss] =
    useIonModal(ModalForgetPassword, {
      userInfos: userInfos,
      userValues: userValues,
      onModalForgetPasswordDismiss: (data: string, role: string) =>
        onModalForgetPasswordDismiss(data, role),
    });

  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        <Formik
          initialValues={{
            userName: "",
            password: "",
            IqamaId: "",
          }}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={(values, actions) => {
            if (isNafazModalIsOpen) {
              if (!values.IqamaId) {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `رقم الهوية مطلوب`
                );
                return;
              }
              setIsLoading(true);
              console.log("values", values);
              setUserValues(values);
              console.log("currentPersonId", values.IqamaId);
              localStorage.removeItem("IqamaId");
              localStorage.setItem("IqamaId", values.IqamaId);
              loginCallBackNafazFunction(values.IqamaId).then((result) => {
                console.log("result", result.data.nafazResult);

                let dataNafaz = JSON.parse(result.data.nafazResult);
                if (
                  dataNafaz?.message == "NAFATH: THERE IS AN ACTIVE TRANSACTION"
                ) {
                  setIsLoading(false);
                  setErrorTitle("تنبيه");
                  setErrorMessage(
                    "توجد عملية تسجيل بالفعل داخل تطبيق النفاذ بانتظار الموافقة"
                  );
                  setErrorModalIsOpen(true);
                }
                debugger;
                if (dataNafaz?.random) {
                  setNafazRandom(dataNafaz?.random);
                  setIsNafazModalIsOpen(false);
                  setIsLoading(false);
                } else {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `خطئ من النفاذ الوطني  ${dataNafaz?.message}`
                  );
                  setIsLoading(false);
                }
                console.log("loginCallBackNafazFunction result", dataNafaz);
              });
            } else {
              if (!values.userName || !values.password) {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `اسم المستخدم وكلمة السر مطلوبه`
                );
                return;
              }
              signInFunction(values)
                .then((returnedData) => {
                  setIsLoading(false);
                  setUserInfos(returnedData.data.result.dateSet);
                  if (returnedData.data?.result?.dateSet?.token) {
                    localStorage.setItem(
                      "returnedDataI",
                      JSON.stringify(returnedData)
                    );
                    let returnedDataI = localStorage.getItem("returnedDataI");
                    getTokenBySignIn(returnedDataI);
                  } else if (
                    returnedData.data?.result.responseCode ==
                      responseCodes.Error &&
                    returnedData.data?.result.responseMessage ==
                      "send OTP Success"
                  ) {
                    openModalSendOTP();
                  } else {
                    setErrorTitle("خطأ في تسجيل الدخول");
                    setErrorMessage("اسم المستخدم او الرقم السري غير صحيح !");
                    setErrorModalIsOpen(true);
                  }
                })
                .catch((e) => {
                  setIsLoading(false);
                  actions.setSubmitting(false);
                  setErrorTitle("خطأ في تسجيل الدخول");
                  setErrorMessage("اسم المستخدم او الرقم السري غير صحيح !");
                  setErrorModalIsOpen(true);
                });
            }
          }}
          validationSchema={validationSchema}
        >
          {({ handleChange, handleSubmit, values, isSubmitting, errors }) => (
            <div className="w-full  flex flex-col justify-between items-center ">
              {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
              <div className="w-full py-5 relative bg-primaryColor100 flex flex-col justify-center items-center rounded-lg">
                <div className=" absolute flex flex-row justify-center items-center bottom-0 w-full">
                  <img className="w-full " src={SignIn_Background} />
                </div>
                <div className="w-26 pt-4">
                  <img src={Logo_White} />
                </div>
                <p className="font-bold text-white text-3xl mt-4">
                  مرحباً بك في منصة ذكى
                </p>
                <p className="font-medium text-white text-xl mt-2">
                  قم بتسجيل الدخول لتتمتع بمميزات منصة ذكى التفاعلية
                </p>
              </div>
              <div className="flow-root mt-4">
                <div className="float-right">
                  <label
                    className={`${
                      isNafazModalIsOpen
                        ? `text-primaryColor100`
                        : `text-paragraphColor`
                    } text-3xl font-bold`}
                    onClick={() => {
                      setIsNafazModalIsOpen(true);
                    }}
                  >
                    تسجيل الدخول خلال النفاذ
                  </label>
                </div>
              </div>

              {isNafazModalIsOpen ? (
                <>
                  <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center">
                    <p className="font-medium text-paragraphColor text-xl mt-1">
                      قم بتسجيل الدخول باستخدام النفاذ الوطني
                    </p>
                    <div className="relative w-full  mt-6">
                      <Field
                        type="number"
                        name="IqamaId"
                        className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                        placeholder="ادخل رقم الهوية الوطنية"
                      />
                      <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                        رقم الهوية الوطنية
                      </label>
                      {errors.IqamaId ? (
                        <p className="text-xl font-bold text-redColor mt-2">
                          {errors.IqamaId}
                        </p>
                      ) : null}
                    </div>
                    <div className="w-full mt-4">
                      <Button
                        theme={"primary"}
                        text={"دخول"}
                        size={"big"}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center">
                  <IonToolbar className="bg-transparent">
                    <IonProgressBar
                      type="indeterminate"
                      className="bg-transparent"
                    ></IonProgressBar>
                  </IonToolbar>
                  <div className="w-full mt-4">
                    <Button
                      theme={"primary"}
                      text={"اعادة تسجيل الدخول"}
                      size={"big"}
                      onClick={() => {
                        setIsNafazModalIsOpen(true);
                      }}
                    />
                  </div>

                  <div className="mt-5 text-xl text-center">
                    يمكنك اختيار هذا الرقم خلال عملية تسجيلك من النفاذ الوطني
                  </div>
                  <div className="nafazRandomClass mt-4">{nafazRandom}</div>
                  <div className="mt-4 text-xl text-redColor text-center">
                    بمجرد اختيار الرقم سيتم اكمال التحقق وتسجيل دخولك
                  </div>
                  {/* <div style={{ paddingTop: "10px" }}>
                    <Button
                      theme={"primary"}
                      text={"تم اكمال العمليه من خلال النفاذ"}
                      size={"big"}
                      onClick={loginNafaz}
                    />
                  </div> */}
                </div>
              )}
            </div>
          )}
        </Formik>
        {isNafazModalIsOpen ? (
          <>
            <div className="w-full flex items-center items-center justify-center text-center px-4 mt-16">
              <Button
                theme={"paragraphColor"}
                text={"الدخول كزائر"}
                size={"medium"}
                cssClassExtra={
                  "w-80 h-16 border border-primaryColor100 rounded-md text-center"
                }
                onClick={() => {
                  setSignInAsVisitorFunction(true);
                  history.push("/app");
                }}
                Icon={
                  <IonIcon
                    icon={arrowLeft}
                    className="text-primaryColor100 text-4xl"
                  />
                }
              />
            </div>

            <div className="w-full mt-12 flex flex-row justify-center items-center mb-4">
              {IsWebApp ? (
                <>
                  <div className="ml-2">
                    <Button
                      theme={"paragraphColor"}
                      size={"medium"}
                      cssClassExtra={
                        "w-20 h-12 border border-primaryColor100 rounded-md text-center"
                      }
                      onClick={() => {
                        window.open(
                          "https://thki.sa/android/",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                      Icon={
                        <IonIcon
                          icon={logoGooglePlaystore}
                          className="text-4xl text-primaryColor100 "
                        />
                      }
                    />
                  </div>

                  <div>
                    <Button
                      theme={"paragraphColor"}
                      size={"medium"}
                      cssClassExtra={
                        "w-20 h-12 border border-primaryColor100 rounded-md text-center"
                      }
                      onClick={() => {
                        window.open(
                          "https://thki.sa/ios",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                      Icon={
                        <IonIcon
                          icon={logoAppleAppstore}
                          className="text-4xl text-primaryColor100"
                        />
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </IonContent>
      <IonModal
        id="errorModal"
        isOpen={errorModalIsOpen}
        trigger="open-custom-dialog"
      >
        <div className="wrapper">
          <div className="flex flex-col justify-start items-center py-4">
            <img className="h-12" src={ErrorImage} />
            <p className="font-bold text-primaryColor100 text-2xl mt-4 whitespace-nowrap">
              {errorTitle}
            </p>
            <p className="font-medium text-[#828282] text-xl mt-0 text-center">
              {errorMessage}
            </p>
            <div className="mt-4 w-full flex justify-center items-center">
              <Button
                size={"medium"}
                theme={"primary"}
                text={"حسنا"}
                onClick={() => {
                  setErrorModalIsOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default SignIn;

const ModalSendOTP = ({
  userInfos,
  userValues,

  onModalSendOTPDismiss,
}: {
  userInfos: any;
  userValues: any;
  onModalSendOTPDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const [messageTitle] = useState("");
  const [message] = useState("");
  const dispatch = useDispatch();
  const setTokenFunction = (returnedData: String) =>
    dispatch(setToken(returnedData));
  const changeToAuthenticatedFunction = () => dispatch(changeToAuthenticated());
  const setUserInfoFunction = (returnedData: Object) =>
    dispatch(setUserInfo(returnedData));
  const setOtherDataFunction = (returnedData: Object) =>
    dispatch(setOtherData(returnedData));
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();

  //model signin
  const [presentSignInModel, onSignInModelismiss] = useIonModal(SignInModel, {
    onSignInModelismiss: (data: string, role: string) =>
      onSignInModelismiss(data, role),
  });

  function getTokenBySignIn(valuesI: any, returnedDataI: any) {
    let returnedData = JSON.parse(returnedDataI);
    if (
      returnedData.data?.responseCode == responseCodes.OK ||
      returnedData.data?.result.responseCode == responseCodes.OK
    ) {
      const token = returnedData.data.result.dateSet.token;
      const userInfo = returnedData.data.result.dateSet;
      setTokenFunction(token);
      setUserInfoFunction(userInfo);
      setOtherDataFunction({
        identificationNumber: "Null",
        advertiserNumber: "Null",
      });
      changeToAuthenticatedFunction();
      window.location.assign(`/app/home`);
    }
  }

  function openSignInModel() {
    presentSignInModel({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.6,
      // backdropBreakpoint: 0.6,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.data == "confirm") {
          setIsLoading(true);
          let valuesI = localStorage.getItem("valuesI");
          let returnedDataI = localStorage.getItem("returnedDataI");
          getTokenBySignIn(valuesI, returnedDataI);
        } else {
          onSignInModelismiss();
        }
      },
    });
  }

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.4,
      // backdropBreakpoint: 0.4,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {isLoading ? (
            <Spinner
              color={"primary"}
              cssExtra={"absolute top-2"}
              size={"large"}
            />
          ) : null}
          <IonTitle className="font-bold text-primaryColor100 text-2xl whitespace-nowrap pt-5">
            {" "}
            ادخال رمز الدخول
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem>
          <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
            رمز الدخول
          </label>
          <IonInput
            type="number"
            autofocus={true}
            className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
            ref={inputRef}
          ></IonInput>
        </IonItem>

        <div className="pt-5">
          <Button
            theme={"primary"}
            text={"موافق"}
            size={"big"}
            onClick={() => {
              setIsLoading(true);
              let values = {
                userName: userValues.userName,
                password: userValues.password,
                OTPCode: inputRef.current?.value,
              };
              signInFunction(values)
                .then((returnedData) => {
                  setIsLoading(false);
                  if (
                    returnedData.data?.result.responseCode ==
                      responseCodes.Error &&
                    returnedData.data?.result.responseMessage ==
                      "your OTP is expired and send OTP Success"
                  ) {
                    setErrorTitle("خطأ في البيانات");
                    setErrorMessage(
                      "الكود منتهي الصلاحية وتم ارسال كود اخر للتفعيل !"
                    );
                    openpErrorModal();
                  } else if (
                    returnedData.data?.result.responseCode ==
                      responseCodes.Error &&
                    returnedData.data?.result.responseMessage ==
                      "your OTP not valid"
                  ) {
                    setErrorTitle("خطأ في البيانات");
                    setErrorMessage("الكود غير صحيح !");
                    openpErrorModal();
                  } else if (
                    returnedData.data?.result.responseCode ==
                      responseCodes.OK &&
                    returnedData.data?.result.responseMessage == "login valid"
                  ) {
                    let valuesI = localStorage.getItem("valuesI");
                    let returnedDataI = localStorage.getItem("returnedDataI");
                    if (valuesI != null) {
                      localStorage.removeItem("valuesI");
                    }
                    if (returnedDataI != null) {
                      localStorage.removeItem("returnedDataI");
                    }
                    localStorage.setItem("valuesI", JSON.stringify(values));
                    localStorage.setItem(
                      "returnedDataI",
                      JSON.stringify(returnedData)
                    );
                    openSignInModel();
                  }
                })
                .catch((e) => {
                  setIsLoading(false);
                  setErrorTitle("خطأ في تسجيل الدخول");
                  setErrorMessage("اسم المستخدم او الرقم السري غير صحيح !");
                  openpErrorModal();
                });
            }}
          />
        </div>

        <div className="pt-5">
          <Button
            theme={"warning"}
            text={"ارسل الكود مره اخري"}
            size={"big"}
            onClick={() => {
              let values = {
                phoneNumber: userInfos.phoneNumber,
              };
              SendOTPToPhoneNumber(values).then((data) => {
                if (data.data.result) {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "success",
                    `تم ارسال كود التحقق`
                  );
                } else {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `خطئ عند ارسال الكود حاول مره ثانية`
                  );
                }
              });
            }}
          />
        </div>

        <div className="pt-5">
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              onModalSendOTPDismiss(null, "cancel");
            }}
          />
        </div>
      </IonContent>
      <MessageModal
        isOpen={messageModalIsOpen}
        errorTitle={messageTitle}
        errorMessage={message}
        onClickDoneButton={() => {
          setMessageModalIsOpen(false);
        }}
      />
    </IonPage>
  );
};

const ModalForgetPassword = ({
  userInfos,
  userValues,
  onModalForgetPasswordDismiss,
}: {
  userInfos: any;
  userValues: any;
  onModalForgetPasswordDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const phoneNumberRef = useRef<HTMLIonInputElement>(null);
  const otpCodeRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);
  const passwordConfirmRef = useRef<HTMLIonInputElement>(null);
  const [sendCodeAgain, setResendCodeAgain] = useState(true);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const [oTPCode, SetOTPCode] = useState(false);
  const [changeMobile, SetIsChangeMobile] = useState(true);
  const [messageTitle] = useState("");
  const [allNames, setAllNames] = useState([]);
  const [currentUserInfo, setCurrentUserInfo] = useState<any>();
  const [message] = useState("");
  const dispatch = useDispatch();
  const setTokenFunction = (returnedData: String) =>
    dispatch(setToken(returnedData));
  const changeToAuthenticatedFunction = () => dispatch(changeToAuthenticated());
  const setUserInfoFunction = (returnedData: Object) =>
    dispatch(setUserInfo(returnedData));
  const setOtherDataFunction = (returnedData: Object) =>
    dispatch(setOtherData(returnedData));
  const [presentToast] = useIonToast();
  const [fullNameColor, setFullNameColor] = useState(-1);

  //model signin
  const [presentSignInModel, onSignInModelismiss] = useIonModal(SignInModel, {
    onSignInModelismiss: (data: string, role: string) =>
      onSignInModelismiss(data, role),
  });

  function getTokenBySignIn(valuesI: any, returnedDataI: any) {
    let returnedData = JSON.parse(returnedDataI);
    if (
      returnedData.data?.responseCode == responseCodes.OK ||
      returnedData.data?.result.responseCode == responseCodes.OK
    ) {
      const token = returnedData.data.result.dateSet.token;
      const userInfo = returnedData.data.result.dateSet;
      setTokenFunction(token);
      setUserInfoFunction(userInfo);
      setOtherDataFunction({
        identificationNumber: "Null",
        advertiserNumber: "Null",
      });
      changeToAuthenticatedFunction();
      window.location.assign(`/app/home`);
    }
  }

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.4,
      // backdropBreakpoint: 0.4,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  function setFullNameFunc(item: any) {
    presentToastFunction(
      presentToast,
      "top",
      "success",
      `تم اختيار (${item.fullName})`
    );
    setCurrentUserInfo(item);
  }
  setTimeout(() => {
    setResendCodeAgain(false);
  }, 300000); // 5 minists   ** minutes to milliseconds

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="font-bold text-primaryColor100 text-2xl whitespace-nowrap pt-5">
            {" "}
            نسيت كلمة السر
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div>
          <IonItem>
            <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
              يرجى ادخال رقم الجوال
            </label>
            <IonInput
              type="number"
              onIonChange={(ev) => {
                setAllNames([]);
                SetIsChangeMobile(true);
              }}
              autofocus={true}
              className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
              ref={phoneNumberRef}
            ></IonInput>
          </IonItem>
          <label className="  font-bold top-2 z-10  pt-2 bg-white  px-2  -translate-y-6 right-10">
            مثال: 0534542111
          </label>
        </div>

        {oTPCode ? (
          <>
            <div>
              <IonItem className="pt-2">
                <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                  كود التحقق
                </label>
                <IonInput
                  type="number"
                  autofocus={true}
                  className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                  ref={otpCodeRef}
                ></IonInput>
              </IonItem>
            </div>

            <div>
              <IonItem className="pt-2">
                <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                  كلمة السر الجديدة
                </label>
                <IonInput
                  type="password"
                  autofocus={true}
                  className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                  ref={passwordRef}
                ></IonInput>
              </IonItem>

              <IonItem className="pt-2">
                <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                  تاكيد كلمة السر
                </label>
                <IonInput
                  type="password"
                  autofocus={true}
                  className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                  ref={passwordConfirmRef}
                ></IonInput>
              </IonItem>
            </div>
          </>
        ) : null}

        {(allNames.length > 0 && !oTPCode) || !changeMobile ? (
          <div className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600">
            <label className="font-bold  top-2 z-10 pb-2 pt-2 text-primaryColor100  px-2   -translate-y-6 right-10">
              يرجى اختيار اسمك:-
            </label>
            <ol className="pt-4">
              {allNames.map((item: any, index: any) => {
                return (
                  <li
                    key={index}
                    style={{
                      background:
                        fullNameColor === index ? "lightblue" : "white",
                    }}
                    onClick={() => {
                      setFullNameFunc(item);
                      setFullNameColor(index);
                    }}
                  >
                    {item.fullName}
                  </li>
                );
              })}
            </ol>
          </div>
        ) : null}

        <div className="pt-5">
          <Button
            theme={"primary"}
            text={"موافق"}
            size={"big"}
            onClick={() => {
              if (!currentUserInfo && allNames.length == 0) {
                let values = {
                  userId: "",
                  newPassword: "",
                  phoneNumber: Number(phoneNumberRef.current?.value),
                  oTPCode: 0,
                };
                if (!values.phoneNumber) {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `يرجى ادخال رقم الجوال`
                  );
                } else {
                  ForgotPasswordFunc(values)
                    .then((returnedData) => {
                      if (returnedData.data.responseCode == responseCodes.OK) {
                        setAllNames(returnedData.data.dateSet);
                        SetIsChangeMobile(true);
                      } else if (
                        returnedData.data.responseCode == responseCodes.Error
                      ) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `${returnedData.data.responseMessage}`
                        );
                      }
                    })
                    .catch((e) => {
                      setErrorTitle("خطأ في تسجيل الدخول");
                      setErrorMessage("اسم المستخدم او الرقم السري غير صحيح !");
                      openpErrorModal();
                    });
                }
              } else if (currentUserInfo && allNames.length > 0 && !oTPCode) {
                //send otp
                let values = {
                  userId: currentUserInfo.userId,
                  newPassword: "",
                  phoneNumber: 0,
                  oTPCode: 0,
                };
                console.log(currentUserInfo.userId);
                ForgotPasswordFunc(values)
                  .then((returnedData) => {
                    console.log(returnedData);
                    if (
                      returnedData.data.result.responseCode == responseCodes.OK
                    ) {
                      SetOTPCode(true);
                      presentToastFunction(
                        presentToast,
                        "top",
                        "success",
                        `${returnedData.data.result.responseMessage}`
                      );
                    }
                  })
                  .catch((e) => {
                    setErrorTitle("خطأ في تسجيل الدخول");
                    setErrorMessage("اسم المستخدم او الرقم السري غير صحيح !");
                    openpErrorModal();
                  });
              } else if (
                phoneNumberRef?.current?.value != "" &&
                currentUserInfo == undefined &&
                currentUserInfo == undefined
              ) {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `يرجى اختيار الاسم`
                );
              } else if (
                otpCodeRef.current?.value == "" &&
                currentUserInfo.userId
              ) {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `يرجى ادخال كود التحقق`
                );
              } else if (
                (passwordRef.current?.value == "" ||
                  passwordConfirmRef.current?.value == "") &&
                currentUserInfo.userId
              ) {
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `يرجى ادخال كلمة السر الجديدة والتاكيد عليها`
                );
              } else if (otpCodeRef.current?.value != "") {
                if (
                  passwordRef.current?.value !=
                  passwordConfirmRef.current?.value
                ) {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `كلمة السر غير متطابقة مع التاكد من كلمة السر`
                  );
                } else {
                  let values = {
                    userId: currentUserInfo.userId,
                    newPassword: passwordRef.current?.value,
                    phoneNumber: 0,
                    oTPCode: Number(otpCodeRef.current?.value),
                  };
                  ForgotPasswordFunc(values)
                    .then((returnedData) => {
                      console.log(returnedData);
                      if (returnedData.data.responseCode == responseCodes.OK) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `${returnedData.data.responseMessage}`
                        );
                        onModalForgetPasswordDismiss(null, "cancel");
                      } else if (
                        returnedData.data.result.responseCode ==
                          responseCodes.Error &&
                        returnedData.data.result.responseMessage ==
                          "your OTP not valid"
                      ) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `يرجى ادخال كود التحقق الصحيح`
                        );
                      } else if (
                        returnedData.data.result.responseCode ==
                          responseCodes.Error &&
                        returnedData.data.result.responseMessage ==
                          "your OTP is expired and send OTP Success"
                      ) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `تم ارسال كود التحقق`
                        );
                      }
                    })
                    .catch((e) => {
                      setErrorTitle("خطئ ");
                      setErrorMessage("خطئ في الحفظ ! " + e);
                      openpErrorModal();
                    });
                }
              }
            }}
          />
        </div>

        <div className="pt-5">
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              onModalForgetPasswordDismiss(null, "cancel");
            }}
          />
        </div>
      </IonContent>
      <MessageModal
        isOpen={messageModalIsOpen}
        errorTitle={messageTitle}
        errorMessage={message}
        onClickDoneButton={() => {
          setMessageModalIsOpen(false);
        }}
      />
    </IonPage>
  );
};
