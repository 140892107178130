import { IonContent, IonPage, IonIcon } from "@ionic/react";
import Logo_White from "../../images/logo_white.svg";
import SignIn_Background from "../../images/signIn_Background.svg";
import {
  chevronBackOutline
} from "ionicons/icons";

const ThkiPayment = ({
  thkipaymentDismiss,
}: {
  thkipaymentDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        <div className="w-full flex flex-col justify-start items-center bg-primaryColor100">
          <div className="w-full grid grid-cols-3">
            <div className="col-span-1 py-6 px-6 flex flex-row justify-start items-center"></div>
            <div className="col-span-1  py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl">الرسوم</p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center z-50">
              <button
                onClick={() => {
                  thkipaymentDismiss(null, 'cancel')
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full py-10 relative bg-primaryColor100 flex flex-col justify-center items-center">
            <div className=" absolute flex flex-row justify-center items-center bottom-0 w-full">
              <img className="w-full" src={SignIn_Background} />
            </div>
            <div className="w-26">
              <img src={Logo_White} />
            </div>
            <p className="font-bold text-white text-4xl mt-4">ذكي</p>
          </div>
        </div>
        <div className="w-full py-4 flex flex-col justify-start items-center">

          <div className="w-10/12  flex flex-col justify-center items-start animate__animated animate__slideInLeft">

            <p className="font-medium text-darkBlueColor text-2xl">
              تأجير العقار : يتم دفع رسم قدره (50 ريال) عقب الاستفادة من الخدمة.
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              بيع العقار : يتم دفع رسم قدره (350 ريال) عقب الاستفادة من الخدمة
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              طلب العقار : يتم دفع رسم قدره (100 ريال) عقب الاستفادة من الخدمة
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              وضع شعار على الموقع : يتم دفع رسم سنوي قدره (500 ريال)
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              الأرض المضيئة : يتم دفع رسم يومي قدره (300 ريال).
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              ترقية محفظة الوسيط : يتم دفع رسم سنوي قدره (500 ريال)
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              إبراز المرافق والخدمات كأيقونات : يتم دفع رسم قدره (50 ريال) لهذه الخدمة
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              الإشعار برسالة نصية : يتم دفع رسم قدره (50 ريال) للإشعار برسالة نصية عند عرض عقار مطلوب
            </p>
          </div>
          <hr className="w-10/12 border-paragraphColor border-[0.1px] my-4" />

          <div className="w-10/12  flex flex-col justify-center items-start animate__animated animate__slideInRight">
            <p className="font-bold text-primaryColor100 text-xl">
              الحساب البنكي
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">SA 169 000 010 006 086 191 725</p>
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default ThkiPayment;
