import { IonButton, IonIcon } from "@ionic/react";
import {
  alarmOutline,
  analyticsOutline,
  swapHorizontalOutline,
  swapVerticalOutline,
  timerOutline,
} from "ionicons/icons"; // Import any icons you need
import "./style.css";
import { useEffect, useState } from "react";
import Spinner from "../../components/atoms/spinner";

const TrackingModals = ({ mode, data, onDataChange, onClose }: any) => {
  let [isLoading, setIsLoading] = useState(false);
  let [totalKilometers, setTotalKilometers]: any = useState();
  let [travelTime, setTravelTime]: any = useState();
  let [arrivalTime, setArrivalTime]: any = useState();

  // Example of sending data back to parent
  const sendDataToParent = (data: any) => {
    onDataChange(data); // Call the callback function with data
  };

  const formatDistance = (
    distance: number = data.routeResult.attributes.Total_Kilometers
  ) => {
    if (distance < 1) {
      const meters = distance * 1000;
      return { distance: meters?.toFixed(0), scale: "متر" }; // Show meters without decimals
    }
    return { distance: distance?.toFixed(2), scale: "كم" }; // Show kilometers with 2 decimal places
  };

  const formatTravelTime = (
    timeInMinutes: number = data.routeResult.attributes.Total_TravelTime
  ) => {
    if (timeInMinutes < 60) {
      return { time: timeInMinutes?.toFixed(0), scale: "دقيقة" }; // Show minutes without decimals
    }

    if (timeInMinutes >= 1440) {
      // 1440 minutes = 24 hours
      const days = timeInMinutes / 1440;
      return { time: days?.toFixed(1), scale: "يوم" }; // Show days with 1 decimal place
    }

    const hours = timeInMinutes / 60;
    return { time: hours?.toFixed(2), scale: "ساعة" }; // Show hours with 2 decimal places
  };

  const calculateArrivalTime = (
    totalTravelTimeInMinutes: number = data.routeResult.attributes
      .Total_TravelTime
  ) => {
    const currentTime = new Date(); // Get the current time
    const arrivalTime = new Date(
      currentTime.getTime() + totalTravelTimeInMinutes * 60 * 1000
    ); // Add travel time in milliseconds (minutes to ms)

    // Format arrival time in HH:MM format
    const hours = arrivalTime?.getHours()?.toString()?.padStart(2, "0");
    const minutes = arrivalTime?.getMinutes()?.toString()?.padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setIsLoading(true);
    console.log("darta", data);
    if (!data) {
      setIsLoading(true);
    } else {
      setTotalKilometers(formatDistance());
      setTravelTime(formatTravelTime());
      setArrivalTime(calculateArrivalTime());
      setIsLoading(false);
    }

    if (data) {
      console.log("totalKilometers", totalKilometers);
      console.log("arrivalTime", arrivalTime);
      console.log("travelTime", travelTime);
    }
  }, [data]);

  return (
    <>
      {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
      {data && (
        <>
          {mode == "driving" && (
            <div className="top-right-modal">
              <div className="text-center">
                <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>
                  <span className="text-primaryColor100">
                    {totalKilometers?.distance} {totalKilometers?.scale}
                  </span>
                </p>
                <p
                  className="text-darkBlue"
                  style={{ fontSize: "18px", margin: 0 }}
                >
                  {data?.landAddress?.split("|")[2] ??
                    data?.landAddress?.split("|")[1] ??
                    data?.landAddress?.split("|")[0]}
                </p>
                <IonIcon
                  icon={swapVerticalOutline}
                  className="text-primaryColor100"
                  style={{ marginTop: "5px", fontSize: "20px", color: "green" }}
                />
              </div>
            </div>
          )}

          <div className="absolute bottom-0 mb-8 pb-14 px-4 w-full">
            <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-md mx-auto">
              <div
                className="flex justify-between items-center"
                style={{ justifyContent: mode == "driving" ? "left" : "null" }}
              >
                {mode == "tracking" && (
                  <h1 className="font-bold text-darkBlue text-xl w-64">
                    {data?.landAddress}
                  </h1>
                )}
                <button
                  className="text-2xl font-bold text-redColor"
                  onClick={onClose}
                >
                  ✕
                </button>
              </div>

              {mode == "tracking" && (
                <div className="mt-4">
                  <IonButton
                    color="primary"
                    expand="block"
                    size="large"
                    onClick={() => {
                      sendDataToParent("startDriving");
                    }}
                  >
                    {/* <IonIcon icon={carSportOutline} slot="start" /> */}
                    {travelTime?.time} {travelTime?.scale}
                    <IonIcon icon={swapHorizontalOutline} slot="start" />
                  </IonButton>
                </div>
              )}

              <div className="mt-4 flex justify-between">
                <div className="text-center">
                  <IonIcon icon={timerOutline} size="large" />
                  <p className="text-lg font-semibold text-primaryColor100">
                    {travelTime?.time}
                  </p>
                  <p className="text-gray-400">{travelTime?.scale}</p>
                </div>

                <div className="text-center text-6xl text-gray-300">|</div>

                <div className="text-center">
                  <IonIcon icon={alarmOutline} size="large" />
                  <p className="text-lg font-semibold text-primaryColor100">
                    {arrivalTime}
                  </p>
                  <p className="text-gray-400">الوصول</p>
                </div>

                <div className="text-center text-6xl text-gray-300">|</div>

                <div className="text-center">
                  <IonIcon icon={analyticsOutline} size="large" />
                  <p className="text-lg font-semibold text-primaryColor100">
                    {totalKilometers?.distance}
                  </p>
                  <p className="text-gray-400">{totalKilometers?.scale}</p>
                </div>
              </div>

              {/* <div className="mt-6 flex justify-between">
            <IonButton color="medium" size="small">
              Guides
            </IonButton>
            <IonButton color="medium" size="small">
              <IonIcon icon={shareOutline} slot="start" />
              Share
            </IonButton>
          </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TrackingModals;
