export const arcgisServicesBaseUrl =
  "https://services6.arcgis.com/bnYnvAfPpPZFZXYA/ArcGIS/rest/services/Layers_/FeatureServer/";
  
  export const arcgisServicesBaseMinistryUrl =
  "https://stgisudp.momra.gov.sa/server/rest/services/OutsideOrganizataion/THAKI_MOMRA/MapServer/";
    
  export const vectorTileServerMinistryUrl =
  "https://stgisudp.momra.gov.sa/server/rest/services/Hosted/Public_Basemap/VectorTileServer";  

  export const hybridServerMinistryUrl =
  "https://stgisudp.momra.gov.sa/server/rest/services/Hosted/Public_Basemap_Hybrid/VectorTileServer";
  
  export const arcgisServicesLandUrl =
  "https://thki.sa/arcgis/rest/services/LandData/MapServer/"; 
  
  export const arcgisServicesLandFeatureUrl =
  "https://thki.sa/arcgis/rest/services/LandData/FeatureServer/";
  
  export const arcgisServicesLandToken =
  "49VR9P1NKvy9RxOihu06xMihqgWkvpw8_iEjqB-yXUElKKZ0EdTlTEsEvHrX6eK7"; 
  
  export const LocalarcgisServicesLandToken =
  "gnd9T5h_63B12gR7xcIeAURqqR18bNxpfFd19bNMD7VJoX7Pl6ggo9lVr4-yM2-k";

  export const arcgisServicesUrl =
  "/"; 
  
  export const routeParamsApiKey = "AAPK87d59d55d9a14d1d8f25eb126e523b6dTQjo2uHPYI9WhDcFiAJLlF1u5Jz7TECRA9Iz7fBRRQ7vQBVa_1912ZmE7SyJbV3Z";
