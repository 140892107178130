
export const requestOptions = (
  body?: any,
  returnCountOnly?: boolean,
  returnGeometry?: boolean,
  spatialRel?: string,
  geometry?: any,
  inSR?: string,
  outSR?: string,
  where?: string,
  geometryType?: string
) => {
  return {
    responseType: "json",
    body: body,
    query: {
      f: "json",
      returnCountOnly: returnCountOnly,
      outFields: "*",
      where: where,
      returnGeometry: returnGeometry,
      spatialRel: spatialRel,
      geometry: geometry,
      inSR: inSR,
      outSR: outSR,
      geometryType: geometryType,
    },
  };
};

export const pictureSymbolFunction = (
  file: any,
  width: String,
  height: String,
) => {
  return {
    type: "picture-marker",
    url: file,
    width: width,
    height: height,
    contentType: 'image/svg+xml',
    angle: 0,
  };
};


export const selectedSymbol = {
  type: "simple-fill",
  color: "rgba(90, 232, 181, 0.3)",
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};


export const favoriteLandSymbol = {
  type: "simple-marker",
  style: "circle",
  color: "red",
  size: "50px",
  outline: {
    color: [255, 255, 0],
    width: 2
  }
};

export const selectedSymbolElshamalya = {
  type: "simple-fill",
  color: "rgba(119, 218, 236, 0.3)",
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};


export const featureGeometry = (type: any, features: any, wKid: any) => {
  let obj: any = {
    type: type,
    spatialReference: {
      wkid: wKid,
    }
  };
  switch (type) {
    case 'polyline':
      obj['paths'] = features;
      break;
    case 'polygon':
      obj['rings'] = features;
  }
  return obj;
}


const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export default function isDev(): boolean {
  return development;
}