import { useState, useEffect } from "react";
import { IonContent, IonPage, IonIcon, useIonModal, IonSearchbar } from "@ionic/react";
import { location, resize, business, chevronBackOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import {
  GetAddLandDetailsWaittingApprovalFunction
} from "../../services/API";
import Spinner from "../../components/atoms/spinner";
import Button from "../../components/atoms/button";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ApprovalAdvertisement from "../ApprovalAdvertisement";

const WaittingApprovalAdds = ({
  disMissmyWaittingApprovalAdds,
}: {
  disMissmyWaittingApprovalAdds: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<any>({});
  const [acualData, setAcualData] = useState<any>({});
  const { token } = useSelector((state: any) => state.reducer);

  const [presentApprovalAdvertisementModal, dismissApprovalAdvertisementModal] = useIonModal(ApprovalAdvertisement, {
    itemData: item,
    dismissApprovalAdvertisementModal: (data: string, role: string) => dismissApprovalAdvertisementModal(data, role),
  });

  function openApprovalAdvertisementModal() {
    presentApprovalAdvertisementModal({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        getAddData()
      },
    });
  }

  const handleAdds = (filter: any) => {
    if (filter) {
      const newAdds = acualData.filter(function (str: any) { return str.ad_Id.toString().includes(filter); });
      setData(newAdds);
    } else {
      getAddData();
    }
  };

  function getAddData() {
    GetAddLandDetailsWaittingApprovalFunction(token)
      .then((returnedData) => {
        setData(returnedData.data);
        setAcualData(returnedData.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getAddData()
  }, []);

  return (
    <IonPage>
      <IonContent>
        {isLoading ? (
          <Spinner isFull={false} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center ">
          <div className="w-full grid grid-cols-3">
            <div className="col-span-1 "></div>
            <div className="col-span-1  py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-primaryColor100 text-2xl">
                قائمة الإعلانات
              </p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  disMissmyWaittingApprovalAdds(null, 'cancel')
                }}
                className="bg-white flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-primaryColor100"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            <div className="w-full flex flex-col justify-start items-center px-4">
              <IonSearchbar placeholder="البحث برقم الاعلان" onIonChange={(ev) => handleAdds(ev.detail.value)}></IonSearchbar>
            </div>
            {data.length > 0 ? (data.map((item: any, index) => {
              return (
                <div key={index}
                  style={{ width: "23em", border: "1px solid #0031c4" }}
                  className="w-full flex flex-row justify-between items-center rounded-md border-primaryColor100 p-4 shadow drop-shadow"
                >
                  <div className="h-full flex flex-col col-span-3 justify-start items-start">
                    <p className="font-bold text-primaryColor100 text-xl">
                      <span>رقم الاعلان: </span>
                      ({item.ad_Id})
                    </p>
                    <p className="font-bold text-primaryColor100 text-xl">
                      {item.landType.name}
                    </p>
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        size="small"
                        icon={location}
                        className="text-sm text-paragraphColor"
                      />
                      <p className="font-bold text-black mr-2">
                        {item.addTitle}
                      </p>
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        size="small"
                        icon={business}
                        className="text-sm text-paragraphColor"
                      />
                      <p className="font-bold text-black text-sm mr-2">
                        {item.land_Number}
                      </p>
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <IonIcon
                        size="small"
                        icon={resize}
                        className="text-sm text-paragraphColor"
                      />
                      <p className="font-bold text-black text-sm mr-2">
                        {item.the_Space} - متر مربع
                      </p>
                    </div>
                  </div>
                  <div className="h-full flex flex-col col-span-2 justify-center items-end">
                    <div className="flex flex-col justify-end items-center " >
                      <p className="font-bold text-paragraphColor text-xl">
                        رقم القطعة
                      </p>
                      <p className="font-bold text-black text-xl">
                        {item.land_Number}
                      </p>
                    </div>
                    <div className="w-full justify-end flex flex-row mt-2">
                      <Button
                        size="small"
                        text="التفاصيل"
                        theme={"warning"}
                        onClick={() => {
                          setItem(item);
                          openApprovalAdvertisementModal()
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })) :
              <div className="pt-5">
                <p className="text-xl font-bold text-redColor mt-2">
                  عفوا لا يوجد اعلانات
                </p>
              </div>
            }
          </div>
        </div>
      </IonContent>

    </IonPage>
  );
};

export default WaittingApprovalAdds;


