import React from "react";
import { IonContent, IonPage } from "@ionic/react";

const ThkiPolicy: React.FC = () => {

  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        <h1 style={{ fontWeight: 'bold', paddingTop: 10, textDecorationLine: "underline", fontSize: "25px" }}>سياسة الملكية الفكرية : </h1>
        <h2 style={{ fontWeight: 'bold' }}>التعاريف وتوضيحات:</h2>
        <p>
          الإشارة بإستخدام كلمة (الغير) أو (الكيان):
          يمكن تعريف الغير بالأفراد أو الشركات أو المؤسسات بكافة المجالات سواءًا كانت حكومية أو خاصة أو عامة ، يمكن الإشارة لمنصة ذكي التفاعلية بـ (نحن) أو (لنا) وكل ما يندرج تحت هذه الكلمتين في الإستخدامات اللغوية أو ما يقابلها باللغات الأخرى مثل الإنجليزية .
        </p>


        <h2 style={{ fontWeight: 'bold' }}>الطرف الأول:</h2>
        <p>صاحب الحق ويعرف كذلك بمالك حقوق الملكية الفكرية</p>

        <h2 style={{ fontWeight: 'bold' }}>الطرف الثالث:</h2>
        <p>هو أي شخص أو كيان لا يملك حقوق إعلانات عقارية على المنصة ويمكنه الإطلاع عليها والتواصل مع أصحابها أو المفوضين عليها.
        </p>

        <h2 style={{ fontWeight: 'bold' }}>المحتوى المطروح بالمنصة:</h2>
        <p>
          هي النصوص أو الأرقام أو المعلومات أو البيانات أو الرسائل العامة أو السرية الموجهة لنا أو الصور التي تعيد المنصة معالجتها أو توليدها بأي شكل من الأشكال على خوادمها من دون التعارض مع حق نشر الإعلانات العقارية الأصلية المنسوبة لأصحابها من المعلنين لدينا حيث أن الإعلان ينسب لصاحبه أما محتواه المعالج لدينا فهو حق لنا ويمكن إستخدامه بأي شكل من الأشكال, على سبيل المثال وليس الحصر, يمكن تحليله أو إعادة معالجته بنظم الذكاء الإصطناعي أو إستخدامه لكتابة التقارير أو بيعها أو بيع نتائجه أو نتائج عدة محتويات مجمعة أو نشرها من دون الإضرار بصاحب الإعلان بأي شكل من الأشكال أو نسب حقوقه لنا.
        </p>


        <h2 style={{ fontWeight: 'bold' }}>تعريف سياسة حقوق الملكية الفكرية:</h2>
        <p>
          حقوق الملكية الفكرية تعرف بكونها مصطلح عام وجد لحماية ماينسب من حقوق لأصحابها أو ملاكها من أفراد أو شركات أو مؤسسات خاصةً كانت أو حكومية; على سبيل المثال وليس الحصر, براءة الإختراع أو حقوق النشر أو العلامات التجارية. يمكن إعتبار حقوق الملكية الفكرية حق قانوني مستحدث لحفظ, على سبيل المثال وليس الحصر, الإبتكارات أو المؤلفات أو المقالات أو الصور في كافة المجالات كالأدبية أو العلمية أو الصناعية أو الصناعية, أو الإعلانات لعقارات مملوكة أو حاصل صاحبها على تفويض عقاري حصري أو حقوق نشر غير حصرية أو كل ما يقاس على ماتم ذكره وتمنع الغير من إنتهاكه أو نسبه له. يعنى بسياسة حقوق الملكية الفكرية أيضًا منح الحق بشكل دائم أو مؤقت للغير بأي شكل من الأشكال التي توثق موافقة صاحب الحق (الطرف الأول) بإستخدام حقه للطرف الثاني.
        </p>

        <h2 style={{ fontWeight: 'bold' }}>سياسة حقوق الملكية الفكرية:</h2>
        <p>
          تتبع هذه السياسة شروط وضوابط و اللوائح المطبقة في المملكة العربية السعودية المتعلقة بحقوق الملكية الفكرية بدون أي تعارض معها, كما أنها لا تتعارض أيضًا مع أي إتفاقيات عالمية قامت المملكة العربية السعودية بالإنضمام لها أو الإتفاق مع مضمونها. تمنع سياسة حقوق الملكية الفكرية لدينا من سرقة أو إنتهاك أي علامة تجارية (عالمية أو محلية) أو أسم تجاري أو إنتحال شخصية ذات شهرة شخصية أو إعلانات مملوكة أو مفوض لها بشكل حصري أو غير حصري. يحق لنا أن نزيل كل ماينتهك سياسة الملكية الفكرية من المنصة ويحق لنا منع من إنتهكها أو تعدى عليها إستخدام المنصة بشكل مؤقت أو دائم ،
          أي محتوى يطرح في منصة ذكي التفاعلية بشكله هو حق لنا ويمنع نقله لأي منصة أخرى أو أي وسيلة من وسائل التواصل الإجتماعي أو تسويقه من قبل أي طرف ثالث إطلع عليه ولا يملك حقوقه, لكن يمكن نشره من خلال وسائل التواصل بدون نسب حقوق الإعلان له ،
          أسم منصة ذكي التفاعلية وشعارها منسوبان لهذه المنصة ولا نسمح بإستخدامهما مطلقًا أو نسبهما لأي جهة أخرى أو فرد أو مؤسسة أو شركة من دون العودة لنا وأخذ الإذن منا. كما أن إستخدامهما يعد إنتحال لهوية المنصة ، هذه المنصة تابعة لمؤسسة ذكي لنظم المعلومات الجيومكانية، وجميع محتويات المنصة من برامج ومعلومات تعد محمية بالكامل طبقا لأنظمة المملكة العربية السعودية، لا يجوز لك بيع أو ترخيص أو تأجير أو تعديل أو نسخ أو استنساخ أو تحميل أو إعلان أو نقل أو توزيع أو العرض بصورة علنية أو تحرير أو إنشاء أعمال مشتقة من أي مواد أو محتويات من هذه المنصة للجمهور أو لأغراض تجارية، دون الحصول على الموافقة الخطية المسبقة من إدارة المنصة. يمنع منعاً باتاً أي تعديل أي من محتويات المنصة، كما أن الرسومات والصور في هذه البوابة محمية بموجب الأنظمة، ولا يجوز استنساخها أو استغلالها بأية طريقة كانت، دون موافقة خطية مسبقة من إدارة المنصة ،
          يحق لنا الزيادة أو التعديل أو الحذف على هذه اللائحة في أي وقت أو زمان من دون الرجوع للزوار أو الأعضاء أو أي شخص أو مؤسسة أو شركة تستخدم هذه المنصة
        </p>


        <h1 style={{ fontWeight: 'bold', paddingTop: 10, textDecorationLine: "underline", fontSize: "25px" }}>سياسة حقوق الملكية الفكرية : </h1>
        <p>
          تتبع هذه السياسة شروط وضوابط و اللوائح المطبقة في المملكة العربية السعودية المتعلقة بحقوق الملكية الفكرية بدون أي تعارض معها, كما أنها لا تتعارض أيضًا مع أي إتفاقيات عالمية قامت المملكة العربية السعودية بالإنضمام لها أو الإتفاق مع مضمونها. تمنع سياسة حقوق الملكية الفكرية لدينا من سرقة أو إنتهاك أي علامة تجارية (عالمية أو محلية) أو أسم تجاري أو إنتحال شخصية ذات شهرة شخصية أو إعلانات مملوكة أو مفوض لها بشكل حصري أو غير حصري. يحق لنا أن نزيل كل ماينتهك سياسة الملكية الفكرية من المنصة ويحق لنا منع من إنتهكها أو تعدى عليها إستخدام المنصة بشكل مؤقت أو دائم ،
          أي محتوى يطرح في منصة ذكي التفاعلية بشكله هو حق لنا ويمنع نقله لأي منصة أخرى أو أي وسيلة من وسائل التواصل الإجتماعي أو تسويقه من قبل أي طرف ثالث إطلع عليه ولا يملك حقوقه, لكن يمكن نشره من خلال وسائل التواصل بدون نسب حقوق الإعلان له ،
          أسم منصة ذكي التفاعلية وشعارها منسوبان لهذه المنصة ولا نسمح بإستخدامهما مطلقًا أو نسبهما لأي جهة أخرى أو فرد أو مؤسسة أو شركة من دون العودة لنا وأخذ الإذن منا. كما أن إستخدامهما يعد إنتحال لهوية المنصة ، هذه المنصة تابعة لمؤسسة ذكي لنظم المعلومات الجيومكانية، وجميع محتويات المنصة من برامج ومعلومات تعد محمية بالكامل طبقا لأنظمة المملكة العربية السعودية، لا يجوز لك بيع أو ترخيص أو تأجير أو تعديل أو نسخ أو استنساخ أو تحميل أو إعلان أو نقل أو توزيع أو العرض بصورة علنية أو تحرير أو إنشاء أعمال مشتقة من أي مواد أو محتويات من هذه المنصة للجمهور أو لأغراض تجارية، دون الحصول على الموافقة الخطية المسبقة من إدارة المنصة. يمنع منعاً باتاً أي تعديل أي من محتويات المنصة، كما أن الرسومات والصور في هذه البوابة محمية بموجب الأنظمة، ولا يجوز استنساخها أو استغلالها بأية طريقة كانت، دون موافقة خطية مسبقة من إدارة المنصة ،
          يحق لنا الزيادة أو التعديل أو الحذف على هذه اللائحة في أي وقت أو زمان من دون الرجوع للزوار أو الأعضاء أو أي شخص أو مؤسسة أو شركة تستخدم هذه المنصة
        </p>

        <h1 style={{ fontWeight: 'bold', paddingTop: 10, textDecorationLine: "underline", fontSize: "25px" }}>شروط وأحكام الاستخدام : </h1>
        <p>
          منصة ذكي التفاعلية منصة إلكترونية تقدم خدمة عرض العقارات للبيع أو الإيجار أو الاستثمار ويشار إليها بـ ( المنصة ) .
        </p>

        <h2 style={{ fontWeight: 'bold' }}>أولاً: باستخدام هذه المنصة يقر المستخدم ويتعهد بالآتي:</h2>
        <p>
          1. عدم عرض عقار ليس مملوكاً له أو لا يملك ترخيصاً بشأنه.
          2.
          عدم توفير أو تحميل ملفات على هذه المنصة تحتوي على فيروسات أو بيانات تالفة.
          3. عدم نشر أو إعلان أو توزيع أو تعميم مواد أو معلومات تحتوي تشويهاً للسمعة أو انتهاكاً للأنظمة، أو مواد إباحية، أو بذيئة، أو مخالفة للآداب العامة، أو أي مواد أو معلومات غير نظامية من خلال المنصة. 4. عدم استخدام أي وسيلة أو برنامج أو إجراء لاعتراض أو محاولة اعتراض التشغيل الصحيح للمنصة.
          5. عدم القيام بأي إجراء يفرض حملا غير معقول أو كبير بصورة غير مناسبة على البنية للمنصة.
        </p>


        <h2 style={{ fontWeight: 'bold' }}>ثانياً: الحق في معاينة أو تعديل أو تصحيح البيانات الشخصية • لأي مستخدم يزود المنصة بمعلوماته الشخصية الحق في معاينة أو تعديل أو تصحيح معلوماته الشخصية. :</h2>
        <h2 style={{ fontWeight: 'bold' }}>ثالثاً: التواصل </h2>
        <p>
          1. يوافق المستخدم على أن جميع الإشعارات أو الرسائل التوعوية والترويجية الخاصة بالمنصة سيتم إرسالها إلى المستخدم سواء الكترونيـا أو ورقيا أو نشرها من خلال المنصة أو عبر الرسائل النصية القصيرة أو أي وسيلة أخرى تحددها إدارة المنصة، ويتم العمل بموجبها من تاريخ إرسالها او نشرها في موقع الخدمة الإلكتروني.
          2. يقوم المستخدم بالاتصال على رقم الدعم الفني الموضح في المنصة في حال وجود مشكلة أو خلل ما في الخدمة أو استفسار أو اقتراح لتحسين مستوى الخدمة، وعلى المستخدم اتباع التعليمات المقدمة من الدعم الفني لمعالجة الامر.
          3. لا تعتبر أي مراسلات مستلمة من ادارة المنصة ما لم يصل إلى المستخدم إخطار سواء كان إلكترونياً أو خطياً يؤكد استلام المراسلات أو الإخطارات.
          4. يلتزم المستخدم بتزويد إدارة المنصة بأي تغيير أو تعديل في عنوانه أو معلوماته وتعتبر أي تعليمات أو مراسلات مبلغة للمستخدم ومستلمه منه حال عدم التزامه بذلك.
        </p>

        <h2 style={{ fontWeight: 'bold' }}>رابعاً: جمع واستخدام المعلومات الإلكترونية  </h2>
        <p>
          1. تعمل الخوادم المشغلة للمنصة – تلقائياً – على التقاط عنوان بروتوكول الإنترنت الخاص بك عند زيارتك للمنصة (عنوان بروتوكول الإنترنت الخاص بك هو الرقم الخاص بجهاز الكمبيوتر الذي تستخدمه، والذي يسمح للأجهزة الأخرى الموصولة بشبكة الإنترنت بتحديد وجهة البيانات الصادرة عنها، وجمع بعض المعلومات مثل نوعية المتصفح ومحرك البحث ولكن من دون التعرف على هويتك الشخصية.
          2. إن استخدام المنصة لبروتوكول الإنترنت الخاص بك يساعدنا على تشخيص المشكلات التي تحدث في السيرفرات الخاصة بنا، وعلى إجراء الإحصاء اللازم لقياس استخدام المنصة (عدد الزوار ولغة جهاز الكمبيوتر الذي تستخدمه)، ونحن لا نسمح لأي جهة خارج إطار الفريق الفني للمنصة بالاطلاع على بروتوكول الإنترنت الخاص بك. خامساً: حماية المعلومات الشخصية لن تكون معلومات المستخدم الشخصية متاحة إلا لموظفي المنصة الذين يحتاجون إلى الاطلاع عليها والمصرح لهم بذلك. ولن تكون تلك المعلومات متاحة لاطلاع الجمهور من غير موافقته، ولن يتم تبادل، أو تداول، أي من تلك المعلومات أو بيعها ألي طرف آخر من غير موافقة المستخدم المسبقة. ولا السماح بالوصول إلى هذه المعلومات إلا للمؤهلين والمحترفين الذين يتولون تقديم خدمات الهيئة السعودية للملكية الفكرية المتسقة مع تعاملاتك مع المنصة.
        </p>


        <h1 style={{ fontWeight: 'bold', paddingTop: 10, textDecorationLine: "underline", fontSize: "25px" }}>سياسة الخصوصية : </h1>
        <h2 style={{ fontWeight: 'bold' }}>أولاً : الحماية من الفيروسات تبذل إدارة المنصة كل الجهود لفحص واختبار محتويات هذه المنصة في كل مراحل الإنتاج، وننصحك بأن تقوم دائماً بتشغيل برنامج مضاد للفيروسات على كل المواد التي يتم إنزالها من المنصة، ونحن لا نعتبر مسؤولين عن أي خسارة أو انقطاع أو تلف لبياناتك أو جهاز الحاسب لديك، والذي قد يحدث أثناء الاتصال بهذه المنصة، أو عند استخدام مادة واردة من هذه المنصة.   </h2>
        <h2 style={{ fontWeight: 'bold' }}>ثانياً : إخلاء المسؤولية إن بعض المعلومات قد تتغير في المنصة، وبالتالي لا تتحمل إدارة المنصة أي مسئولية أو تبعات قد تنتج إثر الاستفادة من المعلومات الواردة عليها، بما في ذلك: • دقة وصحة المعلومات. • الفاعلية على عدم الخرق. • الاستمرارية أو الصلاحية. • ملائمة محتويات المنصة . • خلو المنصة أو السيرفر من الفيروسات أو العناصر الضارة.   </h2>
        <h2 style={{ fontWeight: 'bold' }}>ثالثاً : حدود المسؤولية استخدام هذه المنصة يظل على مسؤولية المستخدم الخاصة، والهيئة ليست مسئولة بأي حال من الأحوال عن أية خسارة أو ضرر من أي نوع قد تتكبده بسبب استخدام أو زيارة، أو اعتماد المستخدم على أي بيان أو رأي أو إعلان في المنصة ، أو ينجم عن أي تأخير في التشغيل، أو تعثر الاتصال، أو مشاكل الدخول إلى شبكة الإنترنت، أو أعطال المعدات، أو البرامج، أو سلوك أو أفكار أي شخص يدخل إلى هذه المنصة. وبهذا يقر ويوافق المستخدم على أن الوسيلة الحصرية والوحيدة لعلاج أي ضرر أو خسارة قد تحدث نتيجة دخوله أو استخدامه لهذه المنصة هي الامتناع عن استخدامها أو الدخول إليها أو عدم الاستمرار في ذلك أو طلب المساعدة من الدعم الفني للمنصة.</h2>
        <h2 style={{ fontWeight: 'bold' }}>رابعاً : المرجعية القضائية يتعهد المستخدم بالخضوع للجهات القضائية بالمملكة العربية السعودية، فيما يتعلق بجميع المطالبات والخلافات التي تنشأ عن استخدامه لهذه للمنصة.</h2>
        <h2 style={{ fontWeight: 'bold' }}>خامساً : التنازل عن المطالبات إن المنصة بكامل ما فيها من معلومات وخدمات ومواد ووظائف، أو التي يمكن الوصول إليها من خلال البوابة تُوفر الاستخدام” كما هي” و”كما هي متاحة” دون أي إقرار أو وعود أو ضمانات من أي نوع. ولا يمكن للهيئة أن تضمن أو أن تتحمل المسؤولية عن أي انقطاعات أو أخطاء أو تجاوزات قد تنشأ عن استخدام هذه البوابة أو محتوياتها أو أي موقع يرتبط بها، سواء كان ذلك بعلمها أو دون علمها.</h2>
        <h2 style={{ fontWeight: 'bold' }}>سادساً : التعويض يقر المستخدم بعدم مطالبة المنصة بالتعويض أو اتخاذ أي إجراء ضدها أو أي من إداراتها أو أي جهات أو موظفين أو وكلاء يكونون مسؤولين عن إدارة أو صيانة أو تحديث أو تقديم المنصة، ولا تتحمل إدارة المنصة أي من الالتزامات والمسؤوليات التي قد تطرأ عن أي إخلال من جانبك ببنود وشروط الاستخدام، أو أي من الأنظمة السارية سواء في المملكة العربية السعودية أو المكان الذي تقيم فيه.</h2>
        <h2 style={{ fontWeight: 'bold' }}>سابعاً : إنهاء الاستخدام يجوز لإدارة المنصة وحسب تقديرها إنهاء أو تقييد أو إيقاف حقك في الدخول إلى المنصة واستخدامها دون إشعار ولأي سبب، بما في ذلك مخالفة شروط وبنود الاستخدام أو أي سلوك غير نظامي أو مضراً بالآخرين، وفي حال الإنهاء، فإنه لن يكون مصرحاً لك بالدخول إلى هذه المنصة. </h2>
        <h2 style={{ fontWeight: 'bold' }}>ثامناً : التعديلات نحتفظ بالحق في تعديل بنود وشروط هذه السياسة إن لزم الأمر ومتى كان ذلك ملائماً.</h2>


      </IonContent>

    </IonPage>

  );
};

export default ThkiPolicy;

