import { useState, useEffect } from "react";
import { IonContent, IonPage, IonIcon, useIonModal } from "@ionic/react";

import Button from "../../components/atoms/button";
import { chevronBackOutline } from "ionicons/icons";
import ad2 from "../../images/ad2.svg";
import Spinner from "../../components/atoms/spinner";
import noImageSvg from "../../images/noImage.svg";
// import { Route, useHistory, useLocation } from "react-router";
import { getNhcIntegrationByAdLicenseNumberFunction, getNhcIntegrationByObjIdFunction } from "../../services/API";
import { useSelector, useDispatch } from "react-redux";
import { setSignInAsVisitor } from "../../services/redux/actions";
import PropertyTypesModal from "../../components/molecules/propertyTypesModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { handleStyleButtonByLandTypes } from "../../services/genericMethoud/generice";
import NhcAdvertisement from "../nhcAdvertisement";

const Property = ({
  baseLandType,
  propertyNumber,
  objectId,
  parcel_Cod,
  latitude,
  longitude,
  dismissproperty,
  neighbourhood_Name,
  city_Name,
  sUBDIVISIONPLAN_ID,
  rings
}: {
  baseLandType: any,
  propertyNumber: any,
  objectId: any,
  parcel_Cod: any,
  latitude: any,
  longitude: any,
  neighbourhood_Name: any,
  districts_Name: any,
  city_Name: any,
  sUBDIVISIONPLAN_ID: any,
  rings: any
  dismissproperty: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [gettypeOfProperty] = useState(0);
  const [data, setData] = useState([]);
  const [itItemData, setItemData] = useState();

  const [adLicenseNumber, setadLicenseNumber] = useState();
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { authenticationState } = useSelector((state: any) => state.reducer);
  const setSignInAsVisitorFunction = (returnedData: Object) =>
    dispatch(setSignInAsVisitor(returnedData));

  //model property
  const [presentPropertyTypesModal, dismissPropertyTypesModal] = useIonModal(PropertyTypesModal, {
    baseLandType: baseLandType,
    propertyNumber: propertyNumber,
    objectId: objectId,
    parcel_Cod: parcel_Cod,
    latitude: latitude,
    longitude: longitude,
    typeOfProperty: gettypeOfProperty,
    rings: rings,
    dismissPropertyTypesModal: (data: string, role: string) => dismissPropertyTypesModal(data, role),
  });

  function openpPropertyTypesModal() {
    presentPropertyTypesModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.3,
      // backdropBreakpoint: 0.3,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
        }
      },
    });
  }

  async function getDataByByAdLicenseNumber(addNum: any, addIDType: any) {
    console.log("getDataByByAdLicenseNumber==>addNum", addNum);
    console.log("getDataByByAdLicenseNumber==>addIDType", addIDType);

    let result = await getNhcIntegrationByAdLicenseNumberFunction(addNum, addIDType);
    setIsLoading(false);
    console.log("GetNhcIntegrationByAdLicenseNumber", result)
    setItemData(result.data.data);
    console.log("GetNhcIntegrationByAdLicenseNumber setItemData==>", result.data.data)
    presentAdvertisementFunc();
  }



  const [presentAdvertisementModal, onDismissAdvertisement] = useIonModal(NhcAdvertisement, {
    objectId: objectId,
    adLicenseNumber: adLicenseNumber,
    advertisement: itItemData,
    onDismissAdvertisement: (data: string, role: string) => onDismissAdvertisement(data, role),
  });

  function presentAdvertisementFunc() {
    console.log("presentAdvertisementFunc==>itItemData", itItemData);
    if (itItemData == null && itItemData == undefined) {
      setTimeout(() => {
        console.log("again presentAdvertisementFunc==>itItemData", itItemData);
        presentAdvertisementModal({

          onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            if (ev.detail.role === 'confirm') {
            }
          },
        });
      }, 2100);
    } else {
      presentAdvertisementModal({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          if (ev.detail.role === 'confirm') {
          }
        },
      });
    }
  }

  const handleImageStyle = (condition: any) => {
    if (condition) {
      return {
        border: "white  solid",
        borderWidth: "2px 2px 2px 2px",
        width: "100%",
        height: "100%",
        maxHeight: "140px"
      }
    }
  }


  useEffect(() => {
    console.log('the itItemData has changed', itItemData)
  }, [itItemData])

  useEffect(() => {
    getNhcIntegrationByObjIdFunction(objectId)
      .then((returnedData: any) => {
        console.log("returnedData", returnedData.data);
        setData(returnedData.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        setErrorTitle("خطأ في تحميل البيانات");
        setErrorMessage("حدث خطأ في تحميل بيانات العقار المختار !");
        setErrorModalIsOpen(true);
        console.log(error);
      });
  }, []);
  return (
    <IonPage>

      <IonContent>
        <div className="flex flex-col justify-start items-center w-full h-full">
          {isLoading ? (
            <Spinner isFull={true} color={"primary"} size={"large"} />
          ) : null}

          <div className="w-full flex flex-col justify-start items-center bg-primaryColor100 rounded-lg">
            <div className="w-full grid grid-cols-3 border-b-2 border-white rounded-lg">
              <div className="col-span-1 py-4 px-6 flex flex-row justify-start items-center">

              </div>
              <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                <p className="font-bold text-white text-2xl"> بيانات القطعة
                </p>
              </div>
              <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                <button
                  onClick={() => {
                    dismissproperty()
                  }}
                  className="flex flex-col justify-center items-center"
                >
                  <IonIcon
                    icon={chevronBackOutline}
                    className="text-2xl text-white"
                  />
                </button>
              </div>
            </div>

            <div className="w-5/6 flex flex-row justify-between py-5 ">

              <div className="flex flex-col justify-center items-end">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    قطعة
                  </p>
                  <p className="font-bold text-white text-xl">
                    {propertyNumber}
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center items-end">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    المخطط
                  </p>
                  <p className="font-bold text-white text-xl whitespace-nowrap">
                    {sUBDIVISIONPLAN_ID}
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center items-end">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    حي
                  </p>
                  <p className="font-bold text-white text-xl">
                    {neighbourhood_Name}
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center items-end">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    مدينة </p>
                  <p className="font-bold text-white text-xl">
                    {city_Name}
                  </p>
                </div>
              </div>

            </div>

          </div>
          <div className="w-full flex flex-col justify-start items-start pt-4 px-8">
            <p className="font-medium text-primaryColor100 text-2xl">
              اعلانات القطعة
            </p>
          </div>
          <div className="h-full w-full flex flex-col justify-start items-center px-8">
            {data.length == 0 ? (<img src={ad2} className="w-20" />) : null}

            {authenticationState == "authenticated" ? (
              data.length == 0 ? (
                <p className="font-bold text-paragraphColor text-xl">
                  قم باضافة اعلان للقطعة ليظهر هنا
                </p>
              ) : (
                <div className="grid grid-cols-1 gap-2 w-full">
                </div>
              )
            ) : (
              <p className="font-bold text-paragraphColor  text-xl">
                قم بتسجيل الدخول لاضافة اعلان
              </p>
            )}
            <div className="mt-4 w-full flex justify-center items-center">
              {authenticationState == "authenticated" ? (
                data.length == 0 ? (
                  <Button
                    theme={"primary"}
                    text={"اضافة اعلان"}
                    size={"small"}
                    onClick={() => {
                      openpPropertyTypesModal();
                    }}
                  />
                ) : null
              ) : (
                <Button
                  theme={"outline"}
                  text={"تسجيل الدخول"}
                  size={"small"}
                  onClick={() => {
                    setSignInAsVisitorFunction(false);
                    window.location.assign(`/app/signIn`);
                  }}
                />
              )}
            </div>

            {data.length == 0 ? (
              <p className="font-bold text-primaryColor100 mt-4 text-2xl">
              </p>
            ) : <div className="grid grid-cols-1 gap-2 w-full">
              {data.map((item: any, index) => {
                return (
                  <div key={index}
                    onClick={async () => {
                      setadLicenseNumber(item.adLicenseNumber);
                      await getDataByByAdLicenseNumber(item.adLicenseNumber, item.idType);
                    }}
                    className="w-full rounded border-2 border-[#f4f6ff] py-2 px-2 mt-2 flex flex-row mb-3 shadow-md"
                  >
                    <div className="basis-1/4" >
                      <img src={item.image ?? noImageSvg} className="w-20 h-20 object-cover border-solid border-2 border-transparent rounded" />
                    </div>

                    <div className="basis-3/4 mr-1 bordrer-solid border-2 rounded" style={handleStyleButtonByLandTypes(item.landTypeDesc)}>

                      <p className="font-bold text-primaryColor100 text-xl mr-2">
                        {item.propertyType} : {item.landTypeDesc}
                      </p>

                      <p className="text-black mr-2">
                        رقم الاعلان : {item.adLicenseNumber}
                      </p>

                      <p className="text-redColor mr-2">
                          {item.propertyType}
                      </p>

                    </div>


                  </div>
                )
              })}
            </div>}
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Property;
