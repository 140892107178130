export class PointLogosDTO {
    id!: number ;
    longitude!: string;
    latitude!: string;
    parcelObjId!: string;
    LogoFile!: string;
    logoFileName!: string;
    isActive!: boolean;
    type!: string;
    zoomLevel?: number;
    width?: number;
    height?: number;
    logoName?: string;
    description?: string;
}