import { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  useIonModal,
  useIonToast,
  IonProgressBar,
} from "@ionic/react";
import {
  callOutline,
  chevronBackOutline,
  eye,
  linkOutline,
  reloadCircleOutline,
  reloadOutline,
  shareOutline,
  videocam,
} from "ionicons/icons";
import {
  getAdvertisementFilesFunction,
  getNhcIntegrationByAdLicenseNumberFunction,
} from "../../services/API";
import "./nhcAdvertisement.styles.css";
import { useSelector } from "react-redux";
import propertyServicesSvg from "../../images/propertyServices.svg";
import propertyAgeSvg from "../../images/propertyAge.svg";
import propertyTypeSvg from "../../images/propertyType.svg";
import guestRoomSvg from "../../images/guestRoom.svg";
import buildingMeasurementSvg from "../../images/buildingMeasurement.svg";
import Button from "../../components/atoms/button";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import Spinner from "../../components/atoms/spinner";
import QRCode from "react-qr-code";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-rotate.css";
import LightGallery from "lightgallery/react";
import lgVideo from "lightgallery/plugins/video";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgRotate from "lightgallery/plugins/rotate";
import lgPager from "lightgallery/plugins/pager";
import { addWatermarkToImage } from "./waterMark";
import { getVideoThumbnail } from "./videoToThumbnail";
import { Clipboard } from "@capacitor/clipboard";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import { NumberWithCommas } from "../../configs/GenericFunc";

const NhcAdvertisement = ({
  objectId,
  adLicenseNumber,
  advertisement,
  onDismissAdvertisement,
}: {
  objectId: any;
  adLicenseNumber: any;
  advertisement: any;
  onDismissAdvertisement: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [property, setProperty] = useState<any>(advertisement);
  const [image, setImage] = useState<any>("");
  const [video, setVideo] = useState<any>("");
  const { token } = useSelector((state: any) => state.reducer);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  let [presentToast] = useIonToast();
  let [landAddress, setLandAddress] = useState("");

  //model property.aNhcIntegrationResultDTO
  const [presentIamgeModal, onCloseImageDismiss] = useIonModal(
    ModalImagesExample,
    {
      image,
      onCloseImageDismiss: (data: string, role: string) =>
        onCloseImageDismiss(data, role),
    }
  );

  function openpImageModal() {
    presentIamgeModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  //model video property.aNhcIntegrationResultDTO
  const [presentVideoModal, onCloseVideoDismiss] = useIonModal(
    ModalVideoExample,
    {
      video,
      onCloseVideoDismiss: (data: string, role: string) =>
        onCloseVideoDismiss(data, role),
    }
  );

  function openpVideoModal() {
    presentVideoModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  async function fetchFiles() {
    setIsLoadingFiles(true);
    const newFiles = [];
    setFiles([]);
    for (const fileId of property.nhcIntegration.attachFiles) {
      try {
        const fileData = await getAdvertisementFilesFunction(
          fileId,
          objectId,
          token
        );
        const file = {
          isImage: fileData.data.isImage,
          file: fileData.data.isImage
            ? await addWatermarkToImage(fileData.data.file)
            : fileData.data.file,
          thumbnail: fileData.data.isImage
            ? fileData.data.file
            : await getVideoThumbnail(fileData.data.file, 200),
        };
        newFiles.push(file);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    }
    setFiles(newFiles);
    console.log("newFiles", newFiles);

    setIsLoadingFiles(false);
  }

  useEffect(() => {
    setIsLoading(true);
    console.log("idType", advertisement.nhcIntegration.idType);
    console.log("adLicenseNumber", adLicenseNumber);
    getNhcIntegrationByAdLicenseNumberFunction(
      adLicenseNumber,
      advertisement.nhcIntegration.idType
    )
      .then((returnedData) => {
        setIsLoading(false);
        console.log("GetNhcIntegrationByAdLicenseNumber00", returnedData);
        console.log(
          "property.aNhcIntegrationResultDTO",
          property.aNhcIntegrationResultDTO
        );
        setProperty(returnedData.data.data);
        setLandAddress(`${
          property.aNhcIntegrationResultDTO.body.result.advertisement.location
            .region ?? "__"
        } | 
          ${
            property.aNhcIntegrationResultDTO.body.result.advertisement.location
              .city ?? "__"
          } | 
          ${
            property.aNhcIntegrationResultDTO.body.result.advertisement.location
              .district ?? "__"
          } | المخطط 
          ${
            property.aNhcIntegrationResultDTO.body.result.advertisement
              .planNumber ?? "__"
          } | القطعة
          ${
            property.aNhcIntegrationResultDTO.body.result.advertisement
              .landNumber ?? "__"
          }`);
        console.log("property", property);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });

    setTimeout(async () => {
      await fetchFiles();
    }, 2000);
  }, []);

  const [isExpanded, setIsExpanded] = useState({
    location: false,
    basicPropInformation: false,
    internPropDetails: false,
    propLegalStatus: false,
    propFinancialValue: false,
    AdvertiserInformation: false,
    location_DocInfo: false,
    AdvertisementDates_Licensing: false,
  });

  return (
    <IonPage>
      <IonContent>
        <div
          id="ii"
          className="w-full h-full relative flex flex-col justify-start items-center bg-white"
        >
          {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1 py-4 px-6 flex flex-row justify-center items-center">
              {property.nhcIntegration.objectId &&
                property.nhcIntegration.objectId != 0 &&
                property.nhcIntegration.objectId != " " && (
                  <button
                    onClick={() => {
                      setIsLoading(true);
                      Clipboard.write({
                        string: `https://thki.sa/app/${property.nhcIntegration.objectId}`,
                      });
                      setIsLoading(false);
                      presentToastFunction(
                        presentToast,
                        "top",
                        "success",
                        `تم نسخ رابط الإعلان بنجاح`
                      );
                    }}
                    className="flex flex-col justify-center items-center"
                  >
                    <IonIcon
                      icon={shareOutline}
                      className="text-2xl text-white font-bold"
                    />
                  </button>
                )}
            </div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl">تفاصيل الاعلان</p>
            </div>
            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  onDismissAdvertisement(null, "cancle");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>

          <div
            className={`col-span-1 w-full flex flex-row justify-start items-center mt-4 mr-2 ${
              !isLoading && "animate__animated animate__backInLeft"
            }`}
          >
            <p className=" text-primaryColor100 text-2xl">الملفات المرفقة :</p>
          </div>
          {isLoadingFiles && (
            <IonToolbar className="bg-transparent">
              <IonProgressBar
                type="indeterminate"
                className="bg-transparent"
              ></IonProgressBar>
            </IonToolbar>
          )}
          {property.nhcIntegration.attachFiles.length == 0 ? (
            <div
              className={`w-80 bg-bkColor rounded border-[1px] border-paragraphColor flex flex-row justify-center items-center py-4 mt-4 ${
                !isLoading && "animate__animated animate__backInLeft"
              }`}
            >
              <p className="font-medium text-paragraphColor text-sm">
                لا توجد ملفات على هذا العقار !
              </p>
            </div>
          ) : (
            <div
              className={`w-full flex flex-col justify-center items-center border-none mr-2 ml-2 ${
                !isLoading &&
                property.nhcIntegration.attachFiles.length > 0 &&
                "animate__animated animate__backInLeft"
              }`}
            >
              {!isLoading && !isLoadingFiles && (
                <h2 className="w-full mr-2 text-base text-redColor">
                  في حالة وجود مشكله في المرفقات الرجاء الضغط هنا:{" "}
                  <IonIcon
                    className="text-xl text-primaryColor100"
                    icon={reloadOutline}
                    onClick={async () => await fetchFiles()}
                  />
                </h2>
              )}

              <div className="w-full overflow-x-auto mt-2">
                <div className="flex space-x-3">
                  <div className="relative flex-shrink-0">
                    <LightGallery
                      speed={500}
                      plugins={[
                        lgVideo,
                        lgZoom,
                        lgThumbnail,
                        lgRotate,
                        lgPager,
                      ]}
                      controls={true}
                      zoom={true}
                      mobileSettings={{
                        controls: true,
                        showCloseIcon: true,
                        showZoomInOutIcons: true,
                        download: true,
                        zoom: true,
                      }}
                    >
                      {files.map((item: any, index: any) => {
                        document
                          .querySelector(".lg-react-element")
                          ?.classList.add("flex", "space-x-3");
                        return (
                          <div
                            key={index}
                            className="gallery-item ml-3 border-2 border-solid border-paragraphColor rounded-lg"
                            data-src={item.isImage ? item.file : undefined}
                            data-video={
                              item.isImage
                                ? undefined
                                : `{"source": [{"src":"${item.file}", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}`
                            }
                          >
                            {item.isImage == true ? (
                              <img
                                src={item.file || ""}
                                alt={
                                  property.nhcIntegration.attachFiles[
                                    index
                                  ].name
                                    .split(".")[0]
                                    .split("-")[1]
                                }
                                className="w-28 h-28 object-cover"
                              />
                            ) : (
                              <div
                                className="relative w-28 h-28"
                                data-video={
                                  item.isImage
                                    ? undefined
                                    : `{"source": [{"src":"${item.file}", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}`
                                }
                              >
                                <img
                                  src={item.thumbnail}
                                  className="w-28 h-28 object-cover"
                                  alt={
                                    property.nhcIntegration.attachFiles[
                                      index
                                    ].name
                                      .split(".")[0]
                                      .split("-")[1]
                                  }
                                />

                                <IonIcon
                                  icon={videocam}
                                  className="absolute z-10 w-12 h-12 inset-0 m-auto text-primaryColor100"
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </LightGallery>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="w-full flex flex-col justify-start items-center px-8 pb-8">
            <div
              className={`col-span-1 py-4 w-full flex flex-row justify-start items-center mt-2 ${
                !isLoading && "animate__animated animate__jello"
              }`}
            >
              <p className=" text-primaryColor100 text-2xl">
                تفاصيل الاعلان لدى الهيئة العامة للعقار
              </p>
            </div>

            {/* <div
              className={`grid grid-cols-2 gap-6 my-4 w-full ${!isLoading && "animate__animated animate__fadeInRightBig"
                }`}
            >
              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyType && (
                  <div className="flex flex-col items-center justify-center text-center min-h-[100px]">
                    <IonIcon icon={propertyTypeSvg} className="text-5xl mb-2" />
                    <div className="text-2xl text-[#262626]">
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.propertyType
                      }
                    </div>
                    <div className="text-xl text-[#737373]">نوع العقار</div>
                  </div>
                )}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyAge && (
                  <div className="flex flex-col items-center justify-center text-center min-h-[100px]">
                    <IonIcon icon={propertyAgeSvg} className="text-5xl mb-2" />
                    <div className="text-2xl text-[#262626]">
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.propertyAge
                      }
                    </div>
                    <div className="text-xl text-[#737373]">عمر العقار</div>
                  </div>
                )}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .numberOfRooms && (
                  <div className="flex flex-col items-center justify-center text-center min-h-[100px]">
                    <IonIcon icon={guestRoomSvg} className="text-5xl mb-2" />
                    <div className="text-2xl text-[#262626]">
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.numberOfRooms
                      }
                    </div>
                    <div className="text-xl text-[#737373]">الغرف</div>
                  </div>
                )}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyArea && (
                  <div className="flex flex-col items-center justify-center text-center min-h-[100px]">
                    <IonIcon
                      icon={buildingMeasurementSvg}
                      className="text-5xl mb-2"
                    />
                    <div className="text-2xl text-[#262626]">
                      {
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.propertyArea
                      }{" "}
                      <span className="text-xl text-[#737373]">متر مربع</span>
                    </div>
                    <div className="text-xl text-[#737373]">مساحة العقار</div>
                  </div>
                )}

              {property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyUtilities[0] && (
                  <div className="flex flex-col items-center justify-center text-center min-h-[100px]">
                    <IonIcon
                      icon={propertyServicesSvg}
                      className="text-5xl mb-2"
                    />
                    <div className="text-2xl text-[#262626] mr-2 w-60">
                      {property.aNhcIntegrationResultDTO.body.result.advertisement.propertyUtilities
                        .map((item: any) => item)
                        .join(" | ")}
                    </div>
                    <div className="text-xl text-[#737373]">خدمات العقار</div>
                  </div>
                )}
            </div> */}
            {landAddress && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  موقع العقار
                </h2>
                <div
                  id="landAddress"
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out max-h-screen opacity-100`}
                >
                  <div className="w-full flex flex-row items-center p-2">
                    <div>
                      <p className="text-primaryColor100 text-xl">الموقع: </p>
                    </div>
                    <div>
                      <p className="text-black text-xl mr-2">{landAddress}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(property.aNhcIntegrationResultDTO.body.result.advertisement
              .advertisementType ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyUsages[0] ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyType ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyAge ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyArea ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyFace ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .streetWidth ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .location.street) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  معلومات العقار الأساسية
                </h2>
                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out ${
                    isExpanded.basicPropInformation
                      ? "max-h-screen opacity-100"
                      : "max-h-24 overflow-hidden opacity-50 "
                  }`}
                >
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .advertisementType ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          غرض الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.advertisementType
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyUsages[0] ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          استخدام العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.aNhcIntegrationResultDTO.body.result.advertisement.propertyUsages
                            .map((item: any) => item)
                            .join(" | ")}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyType ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          نوع العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyType
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyAge ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          عمر العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyAge
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyArea ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          مساحة العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyArea
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyFace ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          واجهة العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.propertyFace
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .streetWidth ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          عرض الشارع :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.streetWidth
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.street ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          اسم الشارع :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.street
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 text-primaryColor100 font-bold text-center bg-[#f8f8f8]">
                  <button
                    onClick={() =>
                      setIsExpanded((prevState) => ({
                        ...prevState,
                        basicPropInformation: !prevState.basicPropInformation,
                      }))
                    }
                  >
                    {isExpanded.basicPropInformation
                      ? "إظهار أقل"
                      : "عرض البيانات كاملة"}
                  </button>
                </div>
              </div>
            )}

            {(property.aNhcIntegrationResultDTO.body.result.advertisement
              .numberOfRooms ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyUtilities[0]) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  تفاصيل العقار الداخلية
                </h2>
                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out max-h-screen opacity-100`}
                >
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .numberOfRooms ? (
                    <div className="w-full flex flex-row justify-between items-center p-2 border-none">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          عدد الغرف :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.numberOfRooms
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .propertyUtilities[0] ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl whitespace-nowrap	">
                          خدمات العقار :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.aNhcIntegrationResultDTO.body.result.advertisement.propertyUtilities
                            .map((item: any) => item)
                            .join(" | ")}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            <div
              className={`w-full my-4 p-4 border rounded shadow-lg ${
                !isLoading && "animate__animated animate__fadeInUpBig"
              }`}
            >
              <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                حالة العقار القانونية
              </h2>
              <div
                className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out ${
                  isExpanded.propLegalStatus
                    ? "max-h-screen opacity-100"
                    : "max-h-24 overflow-hidden opacity-50 "
                }`}
              >
                {property.aNhcIntegrationResultDTO.body.result.advertisement
                  .obligationsOnTheProperty ? (
                  <div className="w-full flex flex-row justify-between items-center p-2">
                    <div>
                      <p className=" text-primaryColor100 text-xl">
                        الالتزامات علي العقار :
                      </p>
                    </div>
                    <div>
                      <p
                        className=" text-black text-xl mr-2"
                        style={{ textAlign: "justify" }}
                      >
                        {
                          property.aNhcIntegrationResultDTO.body.result
                            .advertisement.obligationsOnTheProperty
                        }
                      </p>
                    </div>
                  </div>
                ) : null}

                <div className="w-full flex flex-row justify-between items-center p-2">
                  <div>
                    <p className=" text-primaryColor100 text-xl">وجود رهن؟ :</p>
                  </div>
                  <div>
                    <p
                      className=" text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {property.aNhcIntegrationResultDTO.body.result
                        .advertisement.isPawned
                        ? "يوجد رهن"
                        : "لا يوجد رهن"}
                    </p>
                  </div>
                </div>

                {property.aNhcIntegrationResultDTO.body.result.advertisement
                  .guaranteesAndTheirDuration ? (
                  <div className="w-full flex flex-row justify-between items-center p-2">
                    <div>
                      <p className=" text-primaryColor100 text-xl">
                        الضمانات ومدتها :
                      </p>
                    </div>
                    <div>
                      <p
                        className=" text-black text-xl mr-2"
                        style={{ textAlign: "justify" }}
                      >
                        {
                          property.aNhcIntegrationResultDTO.body.result
                            .advertisement.guaranteesAndTheirDuration
                        }
                      </p>
                    </div>
                  </div>
                ) : null}

                <div className="w-full flex flex-row justify-between items-center p-2">
                  <div>
                    <p className=" text-primaryColor100 text-xl">
                      وجود قيد ؟ :
                    </p>
                  </div>
                  <div>
                    <p
                      className=" text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {property.aNhcIntegrationResultDTO.body.result
                        .advertisement.isConstrained
                        ? "يوجد قيد"
                        : "لا يوجد قيد"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-2 text-primaryColor100 font-bold text-center bg-[#f8f8f8]">
                <button
                  onClick={() =>
                    setIsExpanded((prevState) => ({
                      ...prevState,
                      propLegalStatus: !prevState.propLegalStatus,
                    }))
                  }
                >
                  {isExpanded.propLegalStatus
                    ? "إظهار أقل"
                    : "عرض البيانات كاملة"}
                </button>
              </div>
            </div>

            {(property.nhcIntegration.customePrice ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .propertyPrice) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-2xl text-tan font-bold mb-2 text-center">
                  القيمة المالية للعقار
                </h2>
                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out max-h-screen opacity-100`}
                >
                  <div className="w-full flex flex-row justify-between items-center p-2 ">
                    <div>
                      <p className=" text-tan text-xl">
                        سعر الوحدة/المتر الارض :
                      </p>
                    </div>
                    <div>
                      <p
                        className="text-tan text-xl mr-2"
                        style={{ textAlign: "justify" }}
                      >
                        {NumberWithCommas(
                          property?.nhcIntegration?.customePrice
                        ) ??
                          NumberWithCommas(
                            property?.aNhcIntegrationResultDTO?.body?.result
                              ?.advertisement?.propertyPrice
                          )}{" "}
                        ر.س
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(property.aNhcIntegrationResultDTO.body.result.advertisement
              .advertiserName ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .adLicenseNumber ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .brokerageAndMarketingLicenseNumber ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .phoneNumber ||
              property.nhcIntegration.user.phoneNumber) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  معلومات المعلن
                </h2>

                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out ${
                    isExpanded.AdvertiserInformation
                      ? "max-h-screen opacity-100"
                      : !isExpanded.AdvertiserInformation ||
                        property.aNhcIntegrationResultDTO.body.result
                          .advertisement.phoneNumber ||
                        property.nhcIntegration.user.phoneNumber
                      ? "max-h-28 overflow-hidden opacity-75 "
                      : "max-h-28 overflow-hidden opacity-50 "
                  }`}
                >
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .advertiserName ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl whitespace-nowrap">
                          {" "}
                          اسم المعلن :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.advertiserName
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .phoneNumber || property.nhcIntegration.user.phoneNumber ? (
                    <div className="w-full flex flex-col justify-center items-center p-2">
                      <div className="w-full flex flex-row justify-between items-center">
                        <div>
                          <p className=" text-primaryColor100 text-xl">
                            رقم التواصل للمعلن :
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-primaryColor100  text-3xl mr-2"
                            style={{ textAlign: "justify" }}
                          >
                            <a
                              href={`tel:${
                                property.aNhcIntegrationResultDTO.body.result
                                  .advertisement.phoneNumber ??
                                property.nhcIntegration.user.phoneNumber
                              }`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={callOutline} />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .adLicenseNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          رقم ترخيص الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.adLicenseNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .brokerageAndMarketingLicenseNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          رقم رخصة الوساطة والتسويق العقاري :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.brokerageAndMarketingLicenseNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="mt-2 text-primaryColor100 font-bold text-center bg-[#f8f8f8]">
                  <button
                    onClick={() =>
                      setIsExpanded((prevState) => ({
                        ...prevState,
                        AdvertiserInformation: !prevState.AdvertiserInformation,
                      }))
                    }
                  >
                    {isExpanded.AdvertiserInformation
                      ? "إظهار أقل"
                      : "عرض البيانات كاملة"}
                  </button>
                </div>
              </div>
            )}

            {(property.aNhcIntegrationResultDTO.body.result.advertisement
              .deedNumber ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .location.buildingNumber ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .location.postalCode ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .location.additionalNumber) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  معلومات الموقع والتوثيق
                </h2>
                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out ${
                    isExpanded.location_DocInfo
                      ? "max-h-screen opacity-100"
                      : "max-h-24 overflow-hidden opacity-50 "
                  }`}
                >
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .deedNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          رقم صك الملكية :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.deedNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.buildingNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          رقم المبني :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.buildingNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.postalCode ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          الرمز البريدي :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.postalCode
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .location.additionalNumber ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          الرقم الإضافي :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.location.additionalNumber
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 text-primaryColor100 font-bold text-center bg-[#f8f8f8]">
                  <button
                    onClick={() =>
                      setIsExpanded((prevState) => ({
                        ...prevState,
                        location_DocInfo: !prevState.location_DocInfo,
                      }))
                    }
                  >
                    {isExpanded.location_DocInfo
                      ? "إظهار أقل"
                      : "عرض البيانات كاملة"}
                  </button>
                </div>
              </div>
            )}

            {(property.aNhcIntegrationResultDTO.body.result.advertisement
              .creationDate ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .endDate ||
              property.aNhcIntegrationResultDTO.body.result.advertisement
                .qrCodeUrl) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  تواريخ وترخيص الإعلان
                </h2>
                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out ${
                    isExpanded.AdvertisementDates_Licensing
                      ? "max-h-screen opacity-100"
                      : "max-h-24 overflow-hidden opacity-50 "
                  }`}
                >
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .creationDate ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          تاريخ إنشاء ترخيص الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.creationDate
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .endDate ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          تاريخ انتهاء ترخيص الاعلان :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {
                            property.aNhcIntegrationResultDTO.body.result
                              .advertisement.endDate
                          }
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .qrCodeUrl ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div className="w-full flex flex-row justify-between items-center">
                        <div>
                          <p className=" text-primaryColor100 text-xl">
                            رابط رمز الإستجابة السريع :
                          </p>
                        </div>
                        <div>
                          <p
                            className="text-xl mr-2 text-primaryColor100"
                            style={{ textAlign: "justify" }}
                          >
                            <a
                              target="_blank"
                              href={`${property.aNhcIntegrationResultDTO.body.result.advertisement.qrCodeUrl}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={linkOutline} />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 text-primaryColor100 font-bold text-center bg-[#f8f8f8]">
                  <button
                    onClick={() =>
                      setIsExpanded((prevState) => ({
                        ...prevState,
                        AdvertisementDates_Licensing:
                          !prevState.AdvertisementDates_Licensing,
                      }))
                    }
                  >
                    {isExpanded.AdvertisementDates_Licensing
                      ? "إظهار أقل"
                      : "عرض البيانات كاملة"}
                  </button>
                </div>
              </div>
            )}

            {(property.aNhcIntegrationResultDTO.body.result.advertisement
              .complianceWithTheSaudiBuildingCode ||
              property.nhcIntegration.ad_Views) && (
              <div
                className={`w-full my-4 p-4 border rounded shadow-lg ${
                  !isLoading && "animate__animated animate__fadeInUpBig"
                }`}
              >
                <h2 className="text-xl text-primaryColor100 font-bold mb-2 text-center">
                  معلومات إضافية
                </h2>
                <div
                  className={`w-full flex border rounded-lg flex-col divide-y transition-all duration-300 ease-in-out max-h-screen opacity-100`}
                >
                  {property.aNhcIntegrationResultDTO.body.result.advertisement
                    .complianceWithTheSaudiBuildingCode ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl">
                          مطابقة كود البناء السعودي :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.aNhcIntegrationResultDTO.body.result
                            .advertisement.complianceWithTheSaudiBuildingCode
                            ? "مطابق"
                            : "غير مطابق"}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.nhcIntegration.description ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl "></p>
                      </div>
                      <div>
                        <p
                          className=" text-black text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.nhcIntegration.description}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {property.nhcIntegration.ad_Views ? (
                    <div className="w-full flex flex-row justify-between items-center p-2">
                      <div>
                        <p className=" text-primaryColor100 text-xl ">
                          عدد المشاهدات :
                        </p>
                      </div>
                      <div>
                        <p
                          className=" text-primaryColor100 text-xl mr-2"
                          style={{ textAlign: "justify" }}
                        >
                          {property.nhcIntegration.ad_Views}{" "}
                          <IonIcon
                            icon={eye}
                            style={{ marginBottom: "-4px" }}
                          />
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            {property.aNhcIntegrationResultDTO.body.result.advertisement
              .qrCodeUrl && (
              <div
                style={{
                  height: "auto",
                  margin: "0px 0px 0px 35px",
                  maxWidth: 64,
                  width: "100%",
                }}
                className={`border-none ${
                  !isLoading && "animate__animated animate__fadeInDownBig"
                }`}
              >
                <QRCode
                  size={256}
                  className="pt-5"
                  style={{ height: "auto", maxWidth: "150%", width: "150%" }}
                  value={
                    property.aNhcIntegrationResultDTO.body.result.advertisement
                      .qrCodeUrl
                  }
                  viewBox={`0 0 256 256`}
                />
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const ModalImagesExample = ({
  image,
  onCloseImageDismiss,
}: {
  image: any;
  onCloseImageDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-primaryColor100 text-xl">
            عرض الصورة
          </IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseImageDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <img src={image} className="w-full rounded" />
      </IonContent>
    </IonContent>
  );
};

const ModalVideoExample = ({
  video,
  onCloseVideoDismiss,
}: {
  video: any;
  onCloseVideoDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-primaryColor100 text-xl">
            عرض الفيديو
          </IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseVideoDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <video autoPlay controls src={video} className="w-full rounded"></video>
      </IonContent>
    </IonContent>
  );
};

export default NhcAdvertisement;
