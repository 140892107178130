import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.58 8.709v5.322c1.492-.321 2.362-1.07 2.416-2.499.064-1.528-.725-2.444-2.415-2.823ZM13.035 8.258c0 .604-.123 1.203-.362 1.76a4.59 4.59 0 0 1-1.03 1.493 4.75 4.75 0 0 1-1.54.996 4.864 4.864 0 0 1-1.816.347c-.346 0-.69-.036-1.029-.11.11.859.343 1.611.649 1.997a19.549 19.549 0 0 1 1.938 2.969c.346.694.622 1.42.823 2.166.473 1.492 1.68.746 2.583.73.137 0 .432-1.036.343-1.542-.21-1.218-.604-2.406-.926-3.62l1.849-.15V7.742h-1.513c.02.17.03.343.03.516ZM26.198 3.943c-.332-.203-.998.064-1.49.25-2.112.818-4.164 1.824-6.32 2.497-2.66.834-2.8.83-2.903 3.716v2.027c.13 2.81.243 2.81 2.844 3.628 2.205.693 4.324 1.69 6.482 2.5.433.166 1.134.432 1.33.247.333-.318.443-.953.45-1.46a767.2 767.2 0 0 0 0-11.945c-.007-.514-.083-1.28-.393-1.46ZM6.722 8.61V6.27h2.226v-.997H6.722V2.949H5.677v2.323H3.45v.997h2.226V8.61h1.045Z"
      fill="#fff"
    />
    <path
      d="M6.185 12.375a6.204 6.204 0 0 0 6.193-6.199v-.343c0-.116 0-.271-.039-.407a6.193 6.193 0 1 0-7.654 6.775 6.25 6.25 0 0 0 1.5.174Zm-1.568-1.21a5.226 5.226 0 1 1 6.765-4.442 5.236 5.236 0 0 1-5.197 4.684 4.964 4.964 0 0 1-1.554-.242h-.014Z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
