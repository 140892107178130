import { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  IonList,
  IonLabel,
  IonItem,
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import Button from "../../../components/atoms/button";
import Spinner from "../../../components/atoms/spinner";
import {
  dowmloadFilesBuildingsWalletBase64Function,
  GetBuildingsWalletByIdFunc,
} from "../../../services/API";
import { downloadPDF } from "../../../services/genericMethoud/generice";
import ErrorModal from "../../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { Formik } from "formik";

const DetailsBuildingsWalletSingleModel = ({
  itemsDataS,
  itemsDataSCurrent,
  dismissDetailsBuildingsWalletSingleModel,
}: {
  itemsDataS: any;
  itemsDataSCurrent: any;
  dismissDetailsBuildingsWalletSingleModel: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [dataDesc, setDataDesc] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorTitle] = useState("");
  const [errorMessage] = useState("");
  const [image, setImage] = useState<any>("");

  //model property
  const [presentIamgeModal, onCloseImageDismiss] = useIonModal(
    ModalImagesExample,
    {
      image,
      onCloseImageDismiss: (data: string, role: string) =>
        onCloseImageDismiss(data, role),
    }
  );

  function openpImageModal() {
    presentIamgeModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function getBuildingsWalletByPacelFuncData() {
    GetBuildingsWalletByIdFunc(itemsDataS.id, token)
      .then((returnedData: any) => {
        if (returnedData.data) {
          console.log("getCurrent", itemsDataSCurrent);
          setAttachments(itemsDataSCurrent.buildingsWalletAttachFiles);
          if (itemsDataSCurrent.buildingsWalletDescriptions) {
            //get desc
            let arr: any = [];
            itemsDataSCurrent.buildingsWalletDescriptions.forEach(
              (element: any) => {
                arr.push(element.name);
              }
            );
            setDataDesc(arr);
          }
        }

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getBuildingsWalletByPacelFuncData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        {isLoading ? (
          <Spinner isFull={false} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center select-text">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1 "></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl">عرض البيانات</p>
            </div>

            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  dismissDetailsBuildingsWalletSingleModel(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4 animate__animated animate__fadeInUpBig">
            <Formik
              initialValues={{
                Title: itemsDataSCurrent.title,
                OwnerName: itemsDataSCurrent.ownerName,
                OwnerPhoneNumber: itemsDataSCurrent.ownerPhoneNumber,
                BuildingPrice: itemsDataSCurrent.buildingPrice,
                BuildingArea: itemsDataSCurrent.buildingArea,
                BuildingDirection: itemsDataSCurrent.buildingDirection,
              }}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={async () => {}}
            >
              {() => (
                <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center">
                  {isLoading ? (
                    <Spinner color={"primary"} size={"large"} />
                  ) : null}
                  <div className="w-full flex flex-col justify-start items-start ">
                    <p className="font-medium text-black text-2xl">
                      معلومات تهمك
                    </p>
                    <p className="font-light text-black text-base">
                      هذه المحفظة تساعدك في حفظ بيانات عقاراتك ، بياناتك محفوظة
                      بسرية تامة
                    </p>
                  </div>

                  <div className="w-full flex flex-row justify-between items-center mt-6 ">
                    <div>
                      <p className="font-bold text-primaryColor100 text-xl">
                        العنوان:
                      </p>
                    </div>
                    <div>
                      <p
                        className="font-bold text-black text-xl mr-2 text-center"
                        style={{
                          textAlign: "justify",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {itemsDataSCurrent.title}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

                  <div className="w-full flex flex-row justify-between items-center mt-6 ">
                    <div>
                      <p className="font-bold text-primaryColor100 text-xl">
                        الوصف:
                      </p>
                    </div>
                    <div>
                      <p
                        className="font-bold text-black text-xl mr-2 text-center"
                        style={{
                          textAlign: "justify",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {itemsDataSCurrent.description}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

                  {itemsDataS?.buildingsWalletTypesUsers
                    ?.buildingsWalletTypesId == 1 ? (
                    <p className="placeholder-paragraphColor mt-2 text-[#a8112a]">
                      عفوا لا يمكنك استخدام هذه الادوات - يمكنك الترقية للمحفظه
                      البرونزية لاستخدامها
                    </p>
                  ) : null}

                  {/* <div className="relative block pr-2 rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 w-full flow-root mt-6">
                    <div className="float-right">
                      <label className="text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100">
                        تفاصيل العقار
                      </label>
                    </div>

                  </div> */}

                  {dataDesc?.length > 0 ? (
                    <div className="mt-2 w-full rounded border-2 border-t-0 border-paragraphColor text-right p-2">
                      <IonList inlist={true} lines="full">
                        {dataDesc?.map((item: any, index: any) => {
                          return (
                            <div className="flow-root mb-2 border border-paragraphColor p-1">
                              <div className="float-right">
                                <IonItem key={index}>
                                  <IonLabel>{item}</IonLabel>
                                </IonItem>
                              </div>
                            </div>
                          );
                        })}
                      </IonList>
                    </div>
                  ) : null}

                  <div className="relative w-full">
                    <div className="col-span-1 w-full flex flex-row justify-start items-center mt-4">
                      <p className="font-bold text-primaryColor100 text-2xl mb-2">
                        الملفات المرفقة :
                      </p>
                    </div>
                    {attachments?.length == 0 ? (
                      <div className="w-full bg-bkColor mb-6 rounded border-[1px] border-paragraphColor flex flex-row justify-center items-center py-4 mt-4">
                        <p className="font-medium text-paragraphColor text-sm">
                          لا توجد ملفات على هذا العقار !
                        </p>
                      </div>
                    ) : (
                      <div className="w-full flex flex-col justify-center items-center mb-6">
                        {attachments?.map((item: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className="w-full flex flex-row justify-between items-center mt-2"
                            >
                              <div>
                                <p className="font-bold text-black text-xl">
                                  {item.name.split("-")[1]}
                                </p>
                              </div>
                              <div>
                                <Button
                                  size="small"
                                  text="عرض"
                                  theme={"primary"}
                                  onClick={() => {
                                    setIsLoading(true);
                                    dowmloadFilesBuildingsWalletBase64Function(
                                      item.name,
                                      item.type,
                                      token
                                    )
                                      .then((returnedData: any) => {
                                        console.log("file", returnedData);
                                        if (returnedData.data.isImage == true) {
                                          setImage(returnedData.data.file);
                                          openpImageModal();
                                          setIsLoading(false);
                                        } else {
                                          downloadPDF(
                                            returnedData.data.file,
                                            item.name,
                                            returnedData.data.file
                                              .split(";")[0]
                                              .split(":")[1]
                                          );
                                          setIsLoading(false);
                                        }
                                      })
                                      .catch((e: any) => {
                                        console.log(e);
                                        setIsLoading(false);
                                      });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const ModalImagesExample = ({
  image,
  onCloseImageDismiss,
}: {
  image: any;
  onCloseImageDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>عرض الصورة</IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseImageDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <img src={image} className="w-full " />
      </IonContent>
    </IonContent>
  );
};

export default DetailsBuildingsWalletSingleModel;
