import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonIcon, useIonModal } from "@ionic/react";

import Button from "../../components/atoms/button";
import { chevronBackOutline } from "ionicons/icons";
import AdvertisementIcon from "../../components/atoms/icons/advertisement";
import ad2 from "../../images/ad2.svg";
import Spinner from "../../components/atoms/spinner";
// import { Route, useHistory, useLocation } from "react-router";
import { getNhcIntegrationByAdLicenseNumberFunction, getNhcIntegrationByObjIdFunction } from "../../services/API";
import { useSelector, useDispatch } from "react-redux";
import { setSignInAsVisitor } from "../../services/redux/actions";
import PropertyTypesModal from "../../components/molecules/propertyTypesModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import Request from "@arcgis/core/request";
import { useHistory } from "react-router-dom";

import { arcgisServicesBaseMinistryUrl } from "../../configs/arcgisServices";
import Graphic from "@arcgis/core/Graphic";
import { featureGeometry } from "../../services/genericMethoud/gisMethouds";
import { handleStyleButtonByLandTypes } from "../../services/genericMethoud/generice";
import NhcAdvertisement from "../nhcAdvertisement";

const PropertyLinks: React.FC = () => {
  const history = useHistory();
  const location = history.location;

  const parcel_CodFromWhatsApp = new URLSearchParams(location.search).get("name");
  const parcels_Url = arcgisServicesBaseMinistryUrl + "0/query";

  const dispatch = useDispatch();

  const [baseLandType] = useState();
  const [propertyNumber, setPropertyNumber] = useState();
  const [sUBDIVISIONPLAN_ID, setSUBDIVISIONPLAN_ID] = useState();
  const [latitude, setLatitude] = useState();
  const [objectId, setObjectId] = useState();
  const [parcel_Cod, setParcel_Cod] = useState();
  const [longitude, setLongitude] = useState();
  const [rings, setRings] = useState();
  const [title, setTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [adLicenseNumber, setadLicenseNumber] = useState();


  const [isLoading, setIsLoading] = useState(true);
  const [gettypeOfProperty] = useState(0);
  const [data, setData] = useState([]);
  const [itItemData, setItemData] = useState();
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [city_Name, setcity_Name] = useState("");
  const [districts_Name, setdistricts_Name] = useState("");
  const [neighbourhood_Name, setneighbourhood_Name] = useState("");

  const { authenticationState } = useSelector((state: any) => state.reducer);
  const setSignInAsVisitorFunction = (returnedData: Object) =>
    dispatch(setSignInAsVisitor(returnedData));


  useEffect(() => {
    if (parcel_CodFromWhatsApp !== null && !Number.isNaN(parcel_CodFromWhatsApp)) { //for mobile
      if (authenticationState != "authenticated" || authenticationState != "unAuthenticated") {
      }
      console.log("parcel_CodFromWhatsApp", parcel_CodFromWhatsApp);

      getFeatureFromParcel_Cod(parcel_CodFromWhatsApp);
    }
  }, [parcel_CodFromWhatsApp]);




  const getFeatureFromParcel_Cod = (parcel_Cod: any) => {
    let queryOption: any = {
      responseType: "json",
      query: {
        f: "json",
        where: `OBJECTID in (${parcel_Cod}) `,
        returnCountOnly: false,
        outFields: "*",
        returnDistinctValues: false,
        returnGeometry: true,
      },
    };
    Request(parcels_Url, queryOption).then((response: any) => {
      if (response.data.features.length >= 0) {
        console.log("response.data", response.data);
        let plot: any = response.data.features[0];
        console.log("plot", plot);

        setneighbourhood_Name(plot.attributes.DISTRICTNAME_AR == null ? "" : plot.attributes.DISTRICTNAME_AR);
        setdistricts_Name(plot.attributes.DISTRICTNAME_AR == null ? "" : plot.attributes.DISTRICTNAME_AR);
        setcity_Name(plot.attributes.CITYNAME_AR == null ? "" : plot.attributes.CITYNAME_AR);
        console.log("plot.attributes.CITYNAME_AR", plot.attributes.CITYNAME_AR);
        setSUBDIVISIONPLAN_ID(plot.attributes.SUBDIVISIONPLAN_ID == null ? "" : plot.attributes.SUBDIVISIONPLAN_ID);

        setObjectId(plot.attributes.OBJECTID);
        setParcel_Cod(plot.attributes.OBJECTID);
        let SUBDIVISIONPARCELNUMBER = plot.attributes.SUBDIVISIONPARCELNUMBER == null ? "" : plot.attributes.SUBDIVISIONPARCELNUMBER
        setPropertyNumber(SUBDIVISIONPARCELNUMBER);
        setRings(response.data.features[0].geometry.rings)

        let feature = featureGeometry("polygon", response.data.features[0].geometry.rings, 3857)
        let graphic: any = new Graphic({
          geometry: feature,
          attributes: response.data.features[0].attributes
        });

        //get busniss data
        setLongitude(graphic.geometry.centroid.longitude);
        setLatitude(graphic.geometry.centroid.latitude);
        setMetaDescription(`قطعة رقم ${SUBDIVISIONPARCELNUMBER} حي ${neighbourhood_Name}`)
        setTitle(`قطعة رقم ${SUBDIVISIONPARCELNUMBER} حي ${neighbourhood_Name}`)
        getNhcIntegrationByObjIdFunction(plot.attributes.OBJECTID)
          .then((returnedData: any) => {
            console.log('getNhcIntegrationByObjIdFunction returnedData', returnedData);
            setData(returnedData.data);
            setIsLoading(false);
          }).catch((e: any) => {
            setIsLoading(false);
            setErrorTitle("خطأ في تحميل البيانات");
            setErrorMessage("حدث خطأ في تحميل بيانات العقار المختار !");
            setErrorModalIsOpen(true);
            console.log(e);
          });
      }
    }).catch((e: any) => {
      setIsLoading(false);
      setErrorTitle("خطأ في تحميل البيانات");
      setErrorMessage("حدث خطأ في تحميل البيانات الجغرافيه المختار !");
      setErrorModalIsOpen(true);
      console.log(e);
    });
    setIsLoading(false);
  };


  //model property
  const [presentPropertyTypesModal, dismissPropertyTypesModal] = useIonModal(PropertyTypesModal, {
    baseLandType: baseLandType,
    propertyNumber: propertyNumber,
    objectId: objectId,
    parcel_Cod: parcel_Cod,
    latitude: latitude,
    longitude: longitude,
    typeOfProperty: gettypeOfProperty,
    rings: rings,
    dismissPropertyTypesModal: (data: string, role: string) => dismissPropertyTypesModal(data, role),
  });

  function openpPropertyTypesModal() {
    presentPropertyTypesModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.3,
      // backdropBreakpoint: 0.3,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
        }
      },
    });
  }

  async function getDataByByAdLicenseNumber(addNum: any, addIDType: any) {
    let result = await getNhcIntegrationByAdLicenseNumberFunction(addNum, addIDType);
    setIsLoading(false);
    console.log("GetNhcIntegrationByAdLicenseNumber", result)
    setItemData(result.data.data);
  }

  const [presentAdvertisementModal, onDismissAdvertisement] = useIonModal(NhcAdvertisement, {
    objectId: objectId,
    adLicenseNumber: adLicenseNumber,
    advertisement: itItemData,
    onDismissAdvertisement: (data: string, role: string) => onDismissAdvertisement(data, role),
  });

  function presentAdvertisementFunc() {
    console.log("presentAdvertisementFunc==>itItemData", itItemData);
    if (itItemData != null && itItemData != undefined) {
      setTimeout(() => {
        console.log("again presentAdvertisementFunc==>itItemData", itItemData);
        presentAdvertisementModal({
          onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            if (ev.detail.role === 'confirm') {
            }
          },
        });
      }, 2000);
    } else {
      presentAdvertisementModal({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          if (ev.detail.role === 'confirm') {
          }
        },
      });
    }
  }

  const handleImageStyle = (condition: any) => {
    if (condition) {
      return {
        border: "white  solid",
        borderWidth: "2px 2px 2px 2px",
        width: "100%",
        height: "100%",
      }
    }
  }



  return (
    <IonPage>

      <IonContent>
        <div className="flex flex-col justify-start items-center w-full h-full">
          {isLoading ? (
            <Spinner isFull={true} color={"primary"} size={"large"} />
          ) : null}

          <div className="w-full flex flex-col justify-start items-center bg-primaryColor100">
            <div className="w-full grid grid-cols-3">
              <div className="col-span-1 py-6 px-6 flex flex-row justify-start items-center">

                {authenticationState == "authenticated" ? (
                  <button
                    onClick={() => {
                      openpPropertyTypesModal()
                    }}
                    className="flex flex-col justify-center items-center"
                  >
                    <AdvertisementIcon className="text-white h-5" />
                  </button>
                ) : null}
              </div>
              <div className="col-span-1  py-6 flex flex-row justify-center items-center">
                <p className="font-bold text-white text-2xl"> بيانات القطعة
                </p>
              </div>
              <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
                <button
                  onClick={() => {
                    history.push(`/app/${parcel_Cod}`);
                  }}
                  className="flex flex-col justify-center items-center"
                >
                  <IonIcon
                    icon={chevronBackOutline}
                    className="text-2xl text-white"
                  />
                  <span className="text-paragraphColor text-xl">  الذهاب للموقع على الخريطة
                  </span>
                </button>
              </div>
            </div>
            <div className="w-5/6 flex flex-row justify-between py-5 ">

              <div className="w-full flex flex-col justify-center items-end">

                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    قطعة
                  </p>
                  <p className="font-bold text-white text-xl">
                    {propertyNumber}
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-col justify-center items-end p-2">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    المخطط
                  </p>
                  <p className="font-bold text-white text-xl">
                    {sUBDIVISIONPLAN_ID}
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-col justify-center items-end p-2">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    حي
                  </p>
                  <p className="font-bold text-white text-xl">
                    {neighbourhood_Name}
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-col justify-center items-end">
                <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                  <p className="font-bold text-paragraphColor text-xl">
                    مدينة                   </p>
                  <p className="font-bold text-white text-xl">
                    {city_Name}
                  </p>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full flex flex-col justify-start items-start pt-4 px-8">
            <p className="font-medium text-primaryColor100 text-2xl">
              اعلانات القطعة
            </p>
          </div>
          <div className="h-full w-full flex flex-col justify-start items-center px-8">
            {data.length == 0 ? (<img src={ad2} className="w-20" />) : null}


            {authenticationState == "authenticated" ? (
              data.length == 0 ? (
                <p className="font-bold text-paragraphColor  text-xl">
                  قم باضافة اعلان للقطعة ليظهر هنا
                </p>
              ) : (
                <div className="grid grid-cols-1 gap-2 w-full">
                </div>
              )
            ) : (
              <p className="font-bold text-paragraphColor  text-xl">
                قم بتسجيل الدخول لاضافة اعلان
              </p>
            )}
            <div className="mt-4 w-full flex justify-center items-center">
              {authenticationState == "authenticated" ? (
                data.length == 0 ? (
                  <Button
                    theme={"primary"}
                    text={"اضافة اعلان"}
                    size={"small"}
                    onClick={() => {
                      openpPropertyTypesModal();
                    }}
                  />
                ) : null
              ) : (
                <Button
                  theme={"outline"}
                  text={"تسجيل الدخول"}
                  size={"small"}
                  onClick={() => {
                    setSignInAsVisitorFunction(false);
                    window.location.assign(`/app/signIn`);
                  }}
                />
              )}
            </div>

            {data.length == 0 ? (
              <p className="font-bold text-primaryColor100 mt-4 text-2xl">
              </p>
            ) : <div className="grid grid-cols-1 gap-2 w-full">
              {data.map((item: any, index) => {
                return (
                  <div key={index}
                    onClick={async () => {
                      setadLicenseNumber(item.adLicenseNumber)
                      await getDataByByAdLicenseNumber(item.adLicenseNumber, item.idType)
                      setTimeout(() => {
                        presentAdvertisementFunc();
                      }, 1000);
                    }} style={handleStyleButtonByLandTypes(item.landTypeDesc)}
                    className="w-full bg-bkColor rounded border-[1px] border-paragraphColor flow-root  py-2 px-2 mt-2  mb-3 shadow-md"
                  >
                    <div className="float-right ">


                      <p className="font-bold text-black text-sm">
                        رقم الاعلان : {item.adLicenseNumber}
                      </p>

                      <p className="font-bold text-black" style={{ color: "red" }}>
                        نوع العقار : {item.propertyType}
                      </p>

                    </div>

                    <div className="float-left" >
                      <img src={item.image} style={handleImageStyle(item.image)} />
                    </div>
                  </div>
                )
              })}
            </div>}
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default PropertyLinks;
