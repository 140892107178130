import React from "react";
import { IonSpinner } from "@ionic/react";

interface Props {
  onClick: any;
  text?: String;
  theme: String;
  Icon?: JSX.Element;
  size?: String;
  isLoading?: Boolean;
  width?: String;
  cssClassExtra?: string;
}
const Button: React.FC<Props> = ({
  onClick,
  text,
  theme,
  Icon,
  size,
  isLoading,
  width,
  cssClassExtra,
  ...props
}: Props): JSX.Element => {
  return (
    <div
      style={{ cursor: "pointer" }}
      className={`${cssClassExtra} ${
        size == "big"
          ? "w-full py-3 px-4"
          : size == "medium"
          ? "py-2 px-12"
          : size == "small"
          ? "py-1 px-8"
          : size == "ssmall"
          ? "py-1 px-4"
          : size == "sssmall"
          ? "px-4"
          : ""
      } ${width ? width : ""} ${
        theme == "primary"
          ? "bg-primaryColor100"
          : theme == "warning"
          ? "bg-yellowColor"
          : theme == "danger"
          ? "bg-redColor"
          : theme == "darkGreen"
          ? "bg-darkGreen50"
          : theme == "outline"
          ? "bg-bkColor border-2 border-primaryColor100"
          : "bg-bkColor"
      }  flex flex-row justify-center items-center rounded shadow`}
      {...props}
      onClick={onClick}
    >
      {isLoading ? (
        <IonSpinner name="crescent" color={"light"} />
      ) : (
        <>
          {text ? (
            <p
              className={`font-bold text-xl ${
                theme == "primary"
                  ? "text-white"
                  : theme == "warning"
                  ? "text-white"
                  : theme == "danger"
                  ? "text-white"
                  : theme == "darkGreen"
                  ? "text-white"
                  : "text-primaryColor100"
              }`}
            >
              {text}
            </p>
          ) : null}
          {Icon ? (
            <div
              className={`flex flex-row justify-center items-center ${
                text ? "mr-8" : ""
              }`}
            >
              {Icon}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
export default Button;
