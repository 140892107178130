/**
 * Adds a logo watermark to a base64 image.
 * @param imageBase64 - The base64 string of the image.
 * @param logoBase64 - The base64 string of the logo to be used as a watermark.
 * @param logoOpacity - The opacity of the logo (0 to 1).
 * @param logoWidthPercent - The width of the logo as a percentage of the image width.
 * @param logoHeightPercent - The height of the logo as a percentage of the image height.
 * @returns A base64 string of the watermarked image.
 */
export async function addWatermarkToImage(
    imageBase64: string,
    logoBase64: string = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDM1IDMwIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTE3LjA0OTIgMjYuMTc0NEwxNy4wNTU3IDI2LjE3MTJMMzQuMDk4NSAxNi4zMzI5TDMyLjUyNjUgMTUuNTA5OEwxNy4wNDkyIDI0LjQzMTRMMS41NzUxNyAxNS41MDk4TDAgMTYuMzMyOUwxNy4wNDI4IDI2LjE3MTJMMTcuMDQ5MiAyNi4xNzQ0WiIgZmlsbD0iIzAwMDAwMCIvPgo8cGF0aCBkPSJNMTcuMDQ5MiAyOC4wMjcxTDEuNTc1MTcgMTkuMTA1NUwwIDE5LjkyODZMMTcuMDQyOCAyOS43NjY5TDE3LjA0OTIgMjkuNzcwMUwxNy4wNDkyIDI4LjAyNzFaIiBmaWxsPSIjMDAwMDAwIi8+CjxwYXRoIGQ9Ik0xMy42Mzc1IDcuNjQzODVDMTQuODk2NCA4LjM2MDQyIDE3LjA1NTggOS42OTk5NSAxNy4wNDkxIDIyLjYxMTFMMTcuMDU1NiAyMi42MDQ2TDMzLjk1OTUgMTIuNzc2TDE3LjA0MjcgMjIuNjA0NkwxNy4wNDkxIDIyLjYxMTFaIiBmaWxsPSIjMDAwMDAwIi8+Cjwvc3ZnPg==",
    logoOpacity: number = 0.5,
    logoWidthPercent: number = 5,
    logoHeightPercent: number = 5,
): Promise<string> {
    const image = new Image();
    const logo = new Image();

    image.src = imageBase64;
    logo.src = logoBase64;

    await new Promise<void>((resolve, reject) => {
        image.onload = () => resolve();
        logo.onload = () => resolve();
        image.onerror = (error) => reject(new Error(`Failed to load base image: ${error}`));
        logo.onerror = (error) => reject(new Error(`Failed to load logo image: ${error}`));
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('Failed to get 2D context');
    }

    canvas.width = image.width;
    canvas.height = image.height;

    const logoWidth = (logoWidthPercent / 100) * image.width;
    const logoHeight = (logoHeightPercent / 100) * image.height;

    ctx.drawImage(image, 0, 0);
    ctx.globalAlpha = logoOpacity;

    const padding = (25 / 100) * image.width;
    for (let y = 0; y < canvas.height; y += logoHeight + padding) {
        for (let x = 0; x < canvas.width; x += logoWidth + padding) {
            ctx.drawImage(logo, x, y, logoWidth, logoHeight);
        }
    }

    return canvas.toDataURL();
}
