import React, { useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon, useIonModal,
  IonHeader, IonTitle,
  IonToolbar, IonItem,
  IonInput,
  useIonToast
} from "@ionic/react";
import SignUp_Search_Person from "../../images/signUp_Search_Person.svg";
import SignUp_Background from "../../images/signUp_Background.svg";
import { chevronBackOutline } from "ionicons/icons";
import Button from "../../components/atoms/button";
import { Formik, Field, isObject } from "formik";
import * as yup from "yup";
import {
  SendOTPToPhoneNumber,
  signUpFunction
} from "../../services/API";
import MessageModal from "../../components/molecules/messageModal";
import { useHistory } from "react-router";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { createBrowserHistory } from "history";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import ModalSheet from "../../components/atoms/modalSheet";
import { responseCodes } from "../../services/API/responseCodes";


const validationSchema = yup.object().shape({
  firstName: yup.string().required("الأسم الكامل مطلوب !"),
  secondName: yup.string().required("الأسم الكامل مطلوب !"),
  LastName: yup.string().required("الأسم الكامل مطلوب !"),
  phoneNumber: yup.string().required("رقم الهاتف مطلوب !").max(9, "رقم الجول لا يزيد عن 10 ارقام").matches(/^\+?[0-9]\d{8,9}$/, " رقم الجول لايقل عن 10 ارقام ويبدأ رقم الجول ب 05"),
  email: yup.string().email("البريد الألكتروني غير صالح !").required("البريد الألكتروني مطلوب !"),
  userName: yup.string().required("اسم المستخدم مطلوب !").matches(/^[A-Za-z-0-9]+$/, "يرجى كتابة اسم المستخدم بالانجليزي"),
  password: yup.string().required("رقم السري مطلوب !").min(6, "الرقم السري لا يقل عن 6 احرف او ارقام").max(100, "الرقم السري لا يزيد عن 100 احرف او ارقام"),
  confirmPassword: yup.string().required("تاكيد الرقم السري مطلوب !").oneOf([yup.ref('password'), null], 'تاكيد الرقم السري غير مطابق للرقم السري'),
  // classOfAccount: yup.string().required("تصنيف الحساب مطلوب !"),
});

const ChangePhoneNumber: React.FC = () => {
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const [isPhoneNumberOpen, setIsPhoneNumberOpen] = useState(false);
  const [isThisPhoneNumberOpen, setIsThisPhoneNumberOpen] = useState(false);
  const [messageTitle] = useState("");
  const [values, setvalues] = useState<any>({});
  const [message] = useState("");
  const history = useHistory();
  const [presentToast] = useIonToast();

  //model property
  const [presentModalSendOTP, onModalSendOTPDismiss] = useIonModal(ModalSendOTP, {
    values: values,
    onModalSendOTPDismiss: (data: string, role: string) => onModalSendOTPDismiss(data, role),
  });

  function openModalSendOTP() {
    presentModalSendOTP({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
        }
      },
    });
  }


  function signUpFunc() {
    if (isPhoneNumberOpen) {
      signUpFunction(values)
        .then((returnedData) => {
          console.log("returnedData", returnedData);
          if (returnedData.data.responseCode == responseCodes.Error) {
            presentToastFunction(presentToast, "top", "danger", `${returnedData.data.responseMessage}`);
          } else if (returnedData.data.result.responseCode == responseCodes.OK) {
            presentToastFunction(presentToast, "top", "success", `${returnedData.data.result.responseMessage}`)
            openModalSendOTP()
          }
        })
        .catch((e) => {
          console.log(e.response.data);
          if (isObject(e.response.data.errors)) {
            presentToastFunction(presentToast, "top", "danger", `خطي في الحفظ`);
          }
        });
    }
  }
  return (
    <IonPage>
      <IonContent>
        <div className="w-full h-full relative flex flex-col justify-between items-center pt-3">
          <div className="w-full grid grid-cols-3 z-50">
            <div></div>
            <div className="col-span-1  py-2 flex flex-row justify-center items-center">
              <p className="font-bold text-primaryColor100 text-2xl">
                مستخدم جديد
              </p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  history.back();
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-primaryColor100"
                />
              </button>
            </div>
          </div>
          <div className="w-full relative bg-white flex flex-col justify-end items-center">
            <div className="absolute flex flex-row justify-center items-center bottom-0 w-full">
              <img className="w-full" src={SignUp_Background} />
            </div>
            <div className="w-36 z-50">
              <img src={SignUp_Search_Person} />
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: "",
              secondName: "",
              LastName: "",
              phoneNumber: "",
              email: "",
              userName: "",
              password: "",
              confirmPassword: "",
              classOfAccount: ""
            }}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setIsPhoneNumberOpen(true);
              setvalues(values);
            }}
          >
            {({
              handleSubmit,
              errors,
            }) => (
              <div className="w-full h-full  py-2 px-8 bg-white flex flex-col justify-start items-center">
                <p className="font-bold text-primaryColor100 text-4xl mt-2">
                  مستخدم جديد
                </p>
                <p className="font-medium text-paragraphColor text-xl mt-1">
                  التحقق من معلومات المستخدم
                </p>
                <div className="relative w-full  mt-6">
                  <Field
                    type="text"
                    name="firstName"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="الأسم الأول"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    الأسم الأول
                  </label>
                  {errors.firstName ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.firstName}
                    </p>
                  ) : null}
                </div>

                <div className="relative w-full  mt-6">
                  <Field
                    type="text"
                    name="secondName"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="الأسم الثاني"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    الأسم الثاني
                  </label>
                  {errors.secondName ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.secondName}
                    </p>
                  ) : null}
                </div>

                <div className="relative w-full  mt-6">
                  <Field
                    type="text"
                    name="LastName"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="الأسم الأخير"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    الأسم الأخير
                  </label>
                  {errors.LastName ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.LastName}
                    </p>
                  ) : null}
                </div>
                <div className="relative w-full  mt-4">
                  <Field
                    type="number"
                    name="phoneNumber"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="رقم الهاتف"
                  />
                  <span className="font-bold"> مثال: 0534542111
                  </span>
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    رقم الهاتف
                  </label>
                  {errors.phoneNumber ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.phoneNumber}
                    </p>
                  ) : null}
                </div>
                <div className="relative w-full  mt-4">
                  <Field
                    type="text"
                    name="email"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="البريد الألكتروني"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    البريد الألكتروني
                  </label>
                  {errors.email ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="relative w-full  mt-4">
                  <Field
                    type="text"
                    name="userName"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="اسم المستخدم"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    اسم المستخدم (يرجى كتابة بالانجليزي)
                  </label>
                  {errors.userName ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.userName}
                    </p>
                  ) : null}
                </div>
                <div className="relative w-full  mt-4">
                  <Field
                    type="password"
                    name="password"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="الرقم السري"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    الرقم السري
                  </label>
                  {errors.password ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="relative w-full  mt-4">
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="تاكيد الرقم السري"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                    تاكيد الرقم السري
                  </label>
                  {errors.confirmPassword ? (
                    <p className="text-xl font-bold text-redColor mt-2">
                      {errors.confirmPassword}
                    </p>
                  ) : null}
                </div>
                <div className="w-full mt-4">
                  <Button
                    size={"big"}
                    theme={"primary"}
                    text={"إنشاء الحساب"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>

        <ModalSheet
          isOpen={isPhoneNumberOpen}
          initialBreakpoint={0.7}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setIsPhoneNumberOpen(false);
          }}
          close={() => {
            setIsPhoneNumberOpen(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap pb-5 ">
              سيتم ارسال كود التحقق للرقم التالي
            </p>
            <div className="w-full mt-4 pb-5">
              <p className="text-2xl text-center font-bold">
                <a href={`tel:00966${Number(values.phoneNumber).toString()}`} style={{ textDecorationLine: "underline" }}>
                  {Number(values.phoneNumber).toString()}  00966
                </a>
              </p>

            </div>
            <div className="w-full mt-4">
              <Button
                theme={"primary"}
                text={"نعم هو "}
                size={"big"}
                onClick={() => {
                  setIsThisPhoneNumberOpen(true);
                  setIsPhoneNumberOpen(false);
                  signUpFunc();
                }}
              />
            </div>

            <div className="w-full mt-4">
              <Button
                theme={"danger"}
                text={"لا ساقوم بتغيرة"}
                size={"big"}
                onClick={() => {
                  setIsThisPhoneNumberOpen(false);
                  setIsPhoneNumberOpen(false);
                }}
              />
            </div>

          </div>
        </ModalSheet>

        <MessageModal
          isOpen={messageModalIsOpen}
          errorTitle={messageTitle}
          errorMessage={message}
          onClickDoneButton={() => {
            setMessageModalIsOpen(false);
          }}
        />
      </IonContent>
      //{" "}
    </IonPage>
  );
};

export default ChangePhoneNumber;


const ModalSendOTP = ({
  values,
  onModalSendOTPDismiss,
}: {
  values: any,
  onModalSendOTPDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const otpCodeRef = useRef<HTMLIonInputElement>(null);
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [message, setMessage] = useState("");
  const [presentToast] = useIonToast();
  const broswerHistory = createBrowserHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="font-bold text-primaryColor100 text-2xl whitespace-nowrap pt-5"> ادخال رمز الدخول</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem>
          <label className="absolute text-xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
            رمز الدخول
          </label>
          <IonInput className="block px-5 py-2.5 mt-2 font-bold placeholder-paragraphColor w-full text-xl bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600" ref={otpCodeRef} />
        </IonItem>
        <div className="pt-5">
          <Button
            theme={"primary"}
            text={"موافق"}
            size={"big"}
            onClick={() => {
              let valuesDa: any = {
                fullName: values.firstName + " " + values.secondName + " " + values.LastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                userName: values.userName,
                password: values.password,
                confirmPassword: values.confirmPassword,
                ad_Number: values.advertiserNumber,
                oTPCode: Number(otpCodeRef.current?.value)
              }

              signUpFunction(valuesDa)
                .then((returnedData) => {
                  if (returnedData.data.responseCode == responseCodes.OK && returnedData.data.responseMessage == "User has been Registered") {
                    setMessageTitle("تم انشاء الحساب بنجاح");
                    setMessage("سوف يتم تحويلك الى صفحة تسجيل الدخول !");
                    setMessageModalIsOpen(true);
                    setTimeout(() => {
                      setMessageModalIsOpen(false);
                      broswerHistory.push('/app/signIn');
                      broswerHistory.go(0);
                    }, 4000);
                  } else if (returnedData.data.responseCode == responseCodes.Error) {
                    presentToastFunction(presentToast, "top", "danger", `${returnedData.data.dateSet[0]}`)
                  } else if (returnedData.data.result.responseCode == responseCodes.Error && returnedData.data.result.responseMessage == "your OTP not valid") {
                    presentToastFunction(presentToast, "top", "danger", `يرجى ادخال كود التحقق الصحيح`)
                  }
                })
                .catch((e) => {
                  console.log(e.response.data);
                  if (isObject(e.response.data.errors)) {
                    presentToastFunction(presentToast, "top", "success", `خطي في الحفظ`)
                  }
                })
            }}
          />
        </div>

        <div className="pt-5">
          <Button
            theme={"warning"}
            text={"ارسل الكود مره اخري"}
            size={"big"}
            onClick={() => {
              SendOTPToPhoneNumber(values).then((data) => {
                if (data.data.result) {
                  presentToastFunction(presentToast, "top", "success", `تم ارسال كود التحقق`)
                } else {
                  presentToastFunction(presentToast, "top", "danger", `خطئ عند ارسال الكود حاول مره ثانية`)
                }
              })
            }}
          />
        </div>

        <div className="pt-5">
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              onModalSendOTPDismiss(null, "cancel")
            }}
          />
        </div>

      </IonContent>
      <MessageModal
        isOpen={messageModalIsOpen}
        errorTitle={messageTitle}
        errorMessage={message}
        onClickDoneButton={() => {
          setMessageModalIsOpen(false);
        }}
      />
    </IonPage>

  );
};
