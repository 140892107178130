import { Http } from "@capacitor-community/http";
import axios from "axios";
const baseUrl = "https://thki.sa/api/api/";


//nafaz
export const loginCallBackNafazFunction = async (id: any) => {
  return await axios.get(
    `${baseUrl}IdentitySecurity/LoginCallBackNafaz?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};
export const CallBackNafazFunction = async () => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/CallBackNafaz`,
    {
      response: "dsdsdsd"
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};



export const getAPPViewsFunction = async () => {
  return await axios.get(
    `${baseUrl}LandDesign/GetAPPViews`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};


export const onFileUploadValidationSize = async (file: any, size: number) => {
  if (file) {
    if (file.size <= size)
      return true;
  }
  return false;
};


export const getPropertyAdvertisementsFunction = async (parcel_Cod: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetAddLandDetailsTypes?objId=${parcel_Cod}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};


export const getNhcIntegrationByObjIdFunction = async (objId: any) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/GetNhcIntegrationByObjId?objId=${objId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getFavoriteLandByParcCodeFunction = async (parcel_Cod: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/getFavoriteLandByParcCode?parcel_Cod=${parcel_Cod}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getMyAdvertisementsFunction = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetAddLandDetailsByUserId?subUserId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const deleteNhcIntegration = async (id: any, token: any) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/DeleteNhcIntegration?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const getAllNhcIntegrationFunction = async (token: any) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/GetAllNhcIntegration`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const getNhcIntegrationByUserIdFunction = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/GetNhcIntegrationByUserId?userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const deleteAccountFunction = async (token: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/DeleteUser`,
    {
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const getPropertyTypes = async () => {
  const options = {
    url: baseUrl + `LandDesign/GetAddLandTypes`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return Http.get(options);
};
export const addAdvertisementFunction = async (formData: any, token: String) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}LandDesign/CreateLandDetails`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};


export const CheckNhcIntegration = async (formData: any, token: String) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}NhcIntegration/CheckNhcIntegration`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};


export const CreateNhcIntegration = async (formData: any, token: String) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}NhcIntegration/CreateNhcIntegration`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};

export const getAdvertisementFilesFunction = async (file: any, Parcel_Cod: any, token: any) => {
  return await axios.post(
    `${baseUrl}Files/DowmloadFilesBase64`,
    {
      fileName: file.name,
      fileType: file.type,
      Parcel_Cod: Parcel_Cod
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const dowmloadFilesBuildingsWalletBase64Function = async (fileName: any, fileTypeId: any, token: any) => {
  return await axios.get(
    `${baseUrl}Files/dowmloadFilesBuildingsWalletBase64?fileName=${fileName}&fileTypeId=${fileTypeId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const deleteAdvertisementOfPropertyFunction = async (
  advertisementId: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}LandDesign/DeleteLandDetailsForMobile`,
    {
      ad_Id: advertisementId,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const saveFilesDataFunction = async (
  fileBase64: any,
  parcel_Cod: String,
  fileName: String,
  token: string
) => {
  return await axios.post(
    `${baseUrl}Files/SaveFilesData`,
    {
      fileBase64: fileBase64,
      parcel_Cod: parcel_Cod,
      fileName: fileName,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const UpdateAdDateLandDetailsAsyncFunction = async (
  advertisementId: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}LandDesign/UpdateAdDateLandDetailsAsync`,
    {
      ad_Id: advertisementId,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const editAdvertisementOfPropertyFunction = async (
  formData: any,
  token: String
) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}LandDesign/UpdateLandDetailsAsync`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};

export const getAdvertisementByPropertyFunction = async (
  parcel_Cod: any,
  ad_Id: any
) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetAddLandDetails?Parcel_Cod=${parcel_Cod}&ad_Id=${ad_Id}`,

    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};


export const getNhcIntegrationByAdLicenseNumberFunction = async (
  adLicenseNumber: any,
  addIDType: any,
) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/GetNhcIntegrationByAdLicenseNumber?adLicenseNumber=${adLicenseNumber}&addIDType=${addIDType}`,

    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const GetAddLandDetailsWaittingApprovalFunction = async (token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetAddLandDetailsWaittingApproval`,

    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getFullNameFunction = async (
  id: any
) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetFullName?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

//logo functions
export const createLogosDataFunction = async (model: any, token: any) => {
  return await axios.post(
    `${baseUrl}LandDesign/CreateLogosData`,
    model,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const editLogosDataIsActiveFunction = async (model: any, token: any) => {
  return await axios.post(
    `${baseUrl}LandDesign/EditLogosDataIsActive`,
    model,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const ApprovalAddsFunction = async (addID: any, isApproval: any, rejectNotes: any, token: any) => {
  return await axios.post(
    `${baseUrl}LandDesign/ApprovalAdds`,
    {
      addID: addID,
      isApproval: isApproval,
      rejectNotes: rejectNotes
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getLogosDataFunction = async () => {
  return await axios.get(
    `${baseUrl}LandDesign/GetLogosData`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getLogoslogoAddFunction = async (token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetLogoslogoAdd`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const deleteLogosDataFunction = async (logoID: any, token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/DeleteLogosData?logoID=${logoID}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const uploadFileToAdvertisementFunction = async (file: any, token: any) => {
  const uploadForm = new FormData();
  uploadForm.append("file", file);
  return await axios.post(
    `${baseUrl}Files/UploadFiles?fileName=${file.name}`,
    uploadForm,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const addFileToAdvertisementFunction = async (formData: any, token: any) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}Files/CreateFilesDetails`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};


export const AddPhoneAndMailFunction = async (PhoneNumber: any, UserId: any, Email: any, oTPCode: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/AddPhoneAndMail`,
    {
      PhoneNumber: PhoneNumber,
      UserId: UserId,
      Email: Email,
      oTPCode: oTPCode,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const GetPhoneAndMailFunction = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}IdentitySecurity/GetPhoneAndMail?userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const createFavoriteLandFunction = async (
  Parcel_Cod: number,
  UserId: any,
  token: any,
  District_Name: any,
  City_Name: any,
  Neighbourhood_Name: any,
  Land_Numbe: any,
  Latitude: any,
  Longitude: any,
  FavoriteLandObjId: any
) => {
  return await axios.post(
    `${baseUrl}LandDesign/CreateFavoriteLand`,
    {
      parcel_Cod: Parcel_Cod,
      userId: UserId,
      District_Name: District_Name,
      City_Name: City_Name,
      Neighbourhood_Name: Neighbourhood_Name,
      Land_Numbe: Land_Numbe,
      Latitude: Latitude,
      Longitude: Longitude,
      FavoriteLandObjId: FavoriteLandObjId,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getFavoriteLandFunction = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetFavoriteLand?userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getFavoriteLandAllFunction = async (token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/GetFavoriteLandAll`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const deleteFavoriteLandFunction = async (id: number, token: any) => {
  return await axios.get(
    `${baseUrl}LandDesign/DeleteFavoriteLand?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const deleteAllFilesOfAdvertisementFunction = async (
  advertisementId: any,
  Parcel_Cod: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}Files/DeleteFilesDetails`,
    {
      ad_Id: advertisementId,
      Parcel_Cod: Parcel_Cod,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const deleteFilesByIDOfAdvertisementFunction = async (
  fileId: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}Files/DeleteFilesDetailsByID`,
    {
      FileID: fileId,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

};

export const getArcGISTokenFunction = async () => {
  let url = `https://www.arcgis.com/sharing/generateToken?f=json&referer=https://www.arcgis.com`;
  const params = new URLSearchParams()
  params.append("username", "thkiSa")
  params.append("password", "Thki.sa2587**")

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  return axios.post(url, params, config)
};


//IdentitySecurity
export const signInFunction = async (values: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/Login`,
    {
      UserName: values.userName,
      Password: values.password,
      OTPCode: values.OTPCode
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const loginNafazFunction = async (personIdValue: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/loginNafaz`,
    {
      personId: personIdValue,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};



export const getAPPVersionsFunction = async () => {
  return await axios.get(
    `${baseUrl}LandDesign/GetAPPVersions`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const SendOTPToPhoneNumber = async (phoneNumber: any) => {
  return await axios.get(
    `${baseUrl}IdentitySecurity/SendOTPToPhoneNumber?phone=${phoneNumber}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const ValidateOTPPhoneNumber = async (phone: any, code: any, userId: any) => {
  return await axios.get(
    `${baseUrl}IdentitySecurity/ValidatePhoneNumber?phone=${phone}&code=${code}&userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const ForgotPasswordFunc = async (model: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/ForgotPassword`,
    {
      "phoneNumber": model.phoneNumber,
      "userId": model.userId,
      "newPassword": model.newPassword,
      "oTPCode": model.oTPCode
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const signUpFunction = async (values: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/RegisterUser`,
    {
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      userName: values.userName,
      password: values.password,
      confirmPassword: values.confirmPassword,
      ad_Number: values.advertiserNumber,
      classOfAccount: 0,
      oTPCode: values.oTPCode,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

/*/////////////////////////////////////////*/
export const EditUserFunction = async (values: any, image: any, token: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/EditUser`,
    {
      id: values.id,
      profileImage: image ?? undefined,
      address: values.address ?? undefined,
      email: values.email ?? undefined,
      phoneNumber: values.phoneNumber ?? undefined,
      officePhoneNumber: values.officePhoneNumber ?? undefined,
      bio: values.bio ?? undefined,
      nickName: values.nickName ?? undefined,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
/*/////////////////////////////////////////*/

export const EditUsersInRoleFunction = async (userId: any, roles: any, token: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/EditUsersInRole`,
    {
      userId: userId,
      role: roles
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

/*//////////////////////////////////////*/
export const GetUserFunction = async (id: any, token: any) => {
  return await axios.get(
    `${baseUrl}IdentitySecurity/GetUser?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const GetProfileImageFunction = async (id: any, token: any) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/GetProfileImage?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
/*//////////////////////////////////////*/

export const GetUserListFunction = async (token: any) => {
  return await axios.get(
    `${baseUrl}IdentitySecurity/GetUserList`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const SendMessagesToGetLogo = async (message: any, UserId: any, IsReport: any, IsLogoMessage: any, token: any) => {
  return await axios.post(
    `${baseUrl}IdentitySecurity/SendMessagesToGetLogo`,
    {
      message: message,
      UserId: UserId,
      IsReport: IsReport,
      IsLogoMessage: IsLogoMessage
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};



//BuildingsWallet
export const CreateBuildingsWalletFunc = async (model: any, token: any) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/CreateBuildingsWallet`,
    {
      "Parcel_Cod": model.Parcel_Cod,
      "City_Name": model.City_Name,
      "District_Name": model.District_Name,
      "Neighbourhood_Name": model.Neighbourhood_Name,
      "Land_Numbe": model.Land_Numbe,
      "Longitude": model.Longitude,
      "Latitude": model.Latitude,

    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const GetBuildingsWalletByIdFunc = async (parcelId: any, token: any) => {
  console.log("parcelId", parcelId);

  return await axios.get(
    `${baseUrl}BuildingsWallet/GetBuildingsWalletById?parcelId=${parcelId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const GetBuildingsWalletFunc = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}BuildingsWallet/GetBuildingsWallet?userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const GetGenerateLinkBuildingsWalletFunc = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}BuildingsWallet/GetGenerateLinkBuildingsWallet?userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const GetPointsEarnedCounterByUserIDFunc = async (userId: any, token: any) => {
  return await axios.get(
    `${baseUrl}BuildingsWallet/GetPointsEarnedCounterByUserID?userId=${userId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};



export const GenerateLinkBuildingsWalletFunc = async (UserId: any, token: any) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/GenerateLinkBuildingsWallet`,
    {
      UserId: UserId
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const AsynPointsEarnedCounterByUserIDFunc = async (code: any, token: any) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/AsynPointsEarnedCounterByUserID`,
    {
      Code: code
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const CreateBuildingsWalletSingleFunc = async (formData: any, token: any) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}BuildingsWallet/CreateBuildingsWalletSingle`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};

export const EditCreateBuildingsWalletSingleFunc = async (formData: any, token: any) => {
  if (formData) {
    return await axios({
      method: "post",
      url: `${baseUrl}BuildingsWallet/EditCreateBuildingsWalletSingle`,
      data: formData,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, Accept: "application/json" },
    })
  }
};

export const DeleteBuildingsWalletFunc = async (Id: any, token: any) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/DeleteBuildingsWallet`,
    {
      Id: Id
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const DeleteBuildingsWalletSingleFunc = async (Id: any, token: any) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/DeleteBuildingsWalletSingle`,
    {
      Id: Id
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};


export const EditTitleInBuildingsWalletParcelFunction = async (
  Id: any,
  Title: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/EditTitleInBuildingsWalletParcel`,
    {
      Id: Id,
      Title: Title,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const EditTypeInBuildingsWalletFunction = async (
  userId: any,
  TypeInBuildingsWallet: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}BuildingsWallet/EditTypeInBuildingsWallet`,
    {
      userId: userId,
      TypeInBuildingsWallet: TypeInBuildingsWallet,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const deleteFilesByBuildingsWalletParcelFunction = async (
  Id: any,
  UserId: any,
  token: String
) => {
  return await axios.post(
    `${baseUrl}Files/deleteFilesByBuildingsWalletParcel`,
    {
      FileID: Id,
      UserId: UserId,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const GetMaxAddCountByRegionFunction = async (region: any, getOthsersUser: boolean = false) => {
  return await axios.get(
    `${baseUrl}NhcIntegration/GetMaxAddCountByRegion?region=${region}&getOthsersUser=${getOthsersUser}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};


/*/////////////////////////////////////////*/
export const GetPaymentTypes = async (token: string) => {
  return await axios.get(
    `${baseUrl}Invoices/GetPaymentTypes`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

/*/////////////////////////////////////////*/

export const GetAllPaymentOrders = async (token: string) => {
  return await axios.get(
    `${baseUrl}Invoices/GetAllPaymentOrders`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
/*/////////////////////////////////////////*/

export const GetPaymentOrdersByUser = async (id: string, token: string) => {
  return await axios.get(
    `${baseUrl}Invoices/GetPaymentOrdersByUser?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

/*/////////////////////////////////////////*/

export const CreateCharge = async (paymentType: any, token: string) => {
  return await axios.get(
    `${baseUrl}Invoices/CreateCharge?paymentType=${paymentType}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
/*/////////////////////////////////////////*/

export const PaymentCompleted = async (charge_id: any, token: string) => {
  return await axios.get(
    `${baseUrl}Invoices/PaymentCompleted?charge_id=${charge_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
/*/////////////////////////////////////////*/

export const GetPaymentChargersByUser = async (userid: any, token: string) => {
  return await axios.get(
    `${baseUrl}Invoices/GetPaymentChargersByUser?id=${userid}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
/*/////////////////////////////////////////*/