import React from "react";
import { IonContent, IonPage, IonIcon } from "@ionic/react";
import Logo_White from "../../images/logo_white.svg";
import SignIn_Background from "../../images/signIn_Background.svg";
import {
  chevronBackOutline,
  logoFacebook,
  logoTwitter,
  logoYoutube
} from "ionicons/icons";

const ContactUs: React.FC = () => {

  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        <div className="w-full flex flex-col justify-start items-center bg-primaryColor100">
          <div className="w-full grid grid-cols-3">
            <div className="col-span-1 py-6 px-6 flex flex-row justify-start items-center"></div>
            <div className="col-span-1  py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl">تواصل معنا</p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center z-50">
              <button
                onClick={() => {
                  window.location.assign(`/app/home`);
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full py-10 relative bg-primaryColor100 flex flex-col justify-center items-center">
            <div className=" absolute flex flex-row justify-center items-center bottom-0 w-full">
              <img className="w-full" src={SignIn_Background} />
            </div>
            <div className="w-26">
              <img src={Logo_White} />
            </div>
            <p className="font-bold text-white text-4xl mt-4">ذكي</p>
          </div>
        </div>
        <div className="w-full py-4 flex flex-col justify-start items-center">
          <div className="w-10/12 flex flex-col justify-center items-start">
            <p className="font-bold text-primaryColor100 text-xl">رقم الهاتف</p>
            <a
              href="tel:0560806050"
              className="font-medium text-darkBlueColor text-2xl"
            >
              0560806050
            </a>
          </div>
          <hr className="w-10/12 border-paragraphColor border-[0.1px] my-4" />
          <div className="w-10/12  flex flex-col justify-center items-start">
            <p className="font-bold text-primaryColor100 text-xl">
              البريد الألكتروني
            </p>
            <a
              href="mailto:info@rbw.sa"
              className="font-medium text-darkBlueColor text-2xl"
            >
              info@rbw.sa
            </a>
          </div>
          <hr className="w-10/12 border-paragraphColor border-[0.1px] my-4" />
          <div className="w-10/12  flex flex-col justify-center items-start">
            <p className="font-bold text-primaryColor100 text-xl">
              الموقع الألكتروني
            </p>
            <a
              href="https://thki.sa"
              className="font-medium text-darkBlueColor text-2xl"
            >
              www.thki.sa
            </a>
          </div>
          <hr className="w-10/12 border-paragraphColor border-[0.1px] my-4" />

          <div className="w-10/12  flex flex-col justify-center items-start">
            <p className="font-bold text-primaryColor100 text-xl">
              العمولة            </p>
            <p className="font-medium text-darkBlueColor text-2xl">
              عمولتنا هي 1% من قيمة العقار المباع أو المؤجر والمستثمر ، وهي في ذمة من قام بعرض العقار ، بخصوص الشركات العقارية والمكاتب العقارية والمسوقين المعتمدين من الهيئة العامة للعقار فالعمولة هي 0.5% والله الموفق.

            </p>
          </div>
          <hr className="w-10/12 border-paragraphColor border-[0.1px] my-4" />

          <div className="w-10/12  flex flex-col justify-center items-start">
            <p className="font-bold text-primaryColor100 text-xl">
              الحساب البنكي
            </p>
            <p className="font-medium text-darkBlueColor text-2xl">SA 801 000 000 1400 012 432 307</p>
          </div>
          <hr className="w-10/12 border-paragraphColor border-[0.1px] my-4" />

          <div className="w-10/12  flex flex-col justify-center items-start">
            <p className="font-bold text-primaryColor100 text-xl">
              التواصل الأجتماعي
            </p>
            <div className="w-full flex flex-row justify-start items-center mt-2">
              <a>
                <IonIcon
                  icon={logoFacebook}
                  className="text-2xl text-paragraphColor"
                />
              </a>

              <a className="mx-2">
                <IonIcon
                  icon={logoTwitter}
                  className="text-2xl text-paragraphColor"
                />
              </a>
              <a className="mx-2">
                <IonIcon
                  icon={logoYoutube}
                  className="text-2xl text-paragraphColor"
                />
              </a>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ContactUs;
