import { IonButton, IonPage } from "@ionic/react";
import docsReportmage from "../../images/docsReportmage.jpg";

const DocsReportModel = ({
  docsReportModelDismiss,
}: {
  userId: any,
  IsReport: any,
  IsLogoMessage: any
  docsReportModelDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {

  return (
    <IonPage>
      <div className="wrapper">
        <div className="w-full flex flex-col justify-start items-center py-4 px-6">
          <div className="w-full flex flex-col justify-start items-center">
            <div className="w-full flex flex-row justify-between items-center">

              <div className="flex flex-col justify-center items-center w-full my-4">
                <p className="font-bold text-primaryColor100 text-2xl">
                  دليل المستخدم
                </p>
              </div>

            </div>
            <img className="rounded w-full animate__animated animate__flipInX" src={docsReportmage} />
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default DocsReportModel;
