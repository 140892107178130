import { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  IonSearchbar,
  useIonToast,
  IonItemSliding,
  IonList,
  IonItemOptions,
  IonItem,
  IonItemOption,
} from "@ionic/react";
import {
  chevronBackOutline,
  personCircleOutline,
  addCircleOutline,
  trash,
} from "ionicons/icons";
import { useSelector } from "react-redux";
import Button from "../../../components/atoms/button";
import Spinner from "../../../components/atoms/spinner";
import {
  DeleteBuildingsWalletSingleFunc,
  GetBuildingsWalletByIdFunc,
} from "../../../services/API";
import { presentToastFunction } from "../../../services/genericMethoud/generice";
import ErrorModal from "../../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import CreateBuildingsWalletSingleModal from "../CreateBuildingsWalletSingle";
import DetailsBuildingsWalletSingleModel from "../DetailsBuildingsWalletSingle";
import EditBuildingsWalletSingleModal from "../EditBuildingsWalletSingle";
import editSvg from "../../../images/editSvg.svg";
import clickSvg from "../../../images/clickSvg.svg";
import moneySvg from "../../../assets/uicons/svg/fi-ts-money-bills.svg";
import houseBuildingSvg from "../../../assets/uicons/svg/fi-ts-house-building.svg";

const BuildingsWalletSingleModel = ({
  itemsData,
  dismissBuildingsWalletSingleModel,
}: {
  itemsData: any;
  dismissBuildingsWalletSingleModel: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [data, setData] = useState<any>([]);
  const [acualCurrentData, setAcualCurrentData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<any>();
  const [acualData, setAcualData] = useState<any>({});

  const [presentdeleteModal, dismissdeleteModal] = useIonModal(DeleteModal, {
    itemsData: item,
    onDismissdismissdeleteModal: (data: string, role: string) =>
      dismissdeleteModal(data, role),
  });

  function openDeleteModal() {
    presentdeleteModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletByPacelFuncData();
        }
      },
    });
  }

  const [
    presentCreateBuildingsWalletSingleModal,
    dismissCreateBuildingsWalletSingleModal,
  ] = useIonModal(CreateBuildingsWalletSingleModal, {
    itemsDataS: itemsData,
    dismissCreateBuildingsWalletSingleModal: (data: string, role: string) =>
      dismissCreateBuildingsWalletSingleModal(data, role),
  });

  function openCreateBuildingsWalletSingleModal() {
    presentCreateBuildingsWalletSingleModal({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletByPacelFuncData();
        }
      },
    });
  }

  const [
    presentDetailsBuildingsWalletSingleModel,
    dismissDetailsBuildingsWalletSingleModel,
  ] = useIonModal(DetailsBuildingsWalletSingleModel, {
    itemsDataS: itemsData,
    itemsDataSCurrent: acualCurrentData,
    dismissDetailsBuildingsWalletSingleModel: (data: string, role: string) =>
      dismissDetailsBuildingsWalletSingleModel(data, role),
  });

  function openDetailsBuildingsWalletSingleModel() {
    presentDetailsBuildingsWalletSingleModel({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  const [
    presentEditBuildingsWalletSingleModal,
    dismissEditBuildingsWalletSingleModal,
  ] = useIonModal(EditBuildingsWalletSingleModal, {
    itemsDataS: itemsData,
    itemsDataSCurrent: acualCurrentData,
    dismissEditBuildingsWalletSingleModal: (data: string, role: string) =>
      dismissEditBuildingsWalletSingleModal(data, role),
  });

  function openEditBuildingsWalletSingleModal() {
    presentEditBuildingsWalletSingleModal({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletByPacelFuncData();
        }
      },
    });
  }

  const handleTitle = (filter: any) => {
    if (filter) {
      const newAdds = acualData.filter(function (str: any) {
        return str.title.toString().includes(filter);
      });
      setData(newAdds);
    } else {
      getBuildingsWalletByPacelFuncData();
    }
  };

  function getBuildingsWalletByPacelFuncData() {
    GetBuildingsWalletByIdFunc(itemsData.id, token)
      .then((returnedData) => {
        console.log("itemsData", itemsData);
        console.log(returnedData);
        setData(returnedData.data.data);
        setAcualData(returnedData.data.data);
        console.log("data", data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getBuildingsWalletByPacelFuncData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        {isLoading ? (
          <Spinner isFull={false} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                {itemsData.title}
              </p>
            </div>

            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  dismissBuildingsWalletSingleModel(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full rounded flow-root px-2 mt-2">
            {itemsData.land_Numbe && (
              <div className="float-left border-[1px] rounded-lg border-paragraphColor p-2 animate__animated animate__bounceInLeft">
                <div className="flex flex-row justify-start items-center text-darkGreen100 mt-2">
                  <IonIcon
                    icon={houseBuildingSvg}
                    className="text-darkGreen100 ml-2"
                    style={{ fontSize: "20px" }}
                  />
                  <p className="text-primaryColor100">
                    القطعة رقم:{" "}
                    <span className="text-black">{itemsData.land_Numbe}</span>
                  </p>
                </div>
              </div>
            )}
            <div className="float-right animate__animated animate__bounceInRight">
              <p className="font-bold text-primaryColor100 mt-2">
                <IonIcon
                  icon={addCircleOutline}
                  onClick={() => {
                    openCreateBuildingsWalletSingleModal();
                  }}
                  className="text-4xl text-primaryColor100 ml-2"
                />
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            <div className="w-full flex flex-col justify-start items-center">
              <IonSearchbar
                placeholder="البحث بالعنوان"
                onIonChange={(ev) => handleTitle(ev.detail.value)}
              ></IonSearchbar>
            </div>
            {data != null && data.length > 0 ? (
              data.map((item: any, index: any) => {
                return (
                  <IonList>
                    <IonItemSliding>
                      <IonItemOptions
                        side="start"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        {/* <IonItemOption
                          color="none"
                          onClick={() => {
                            setIsLoading(true);
                            setItem(item);
                            setIsLoading(false);
                            let getCurrent = data.find(
                              (i: any) => i.id == item.id
                            );
                            setAcualCurrentData(getCurrent);
                            openDetailsBuildingsWalletSingleModel();
                          }}
                          style={{ fontSize: "16px", width: "3.5em" }}
                        >
                          <IonIcon slot="top" icon={infoSvg}></IonIcon>
                          التفاصيل
                        </IonItemOption> */}

                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setIsLoading(true);
                            setItem(item);
                            let getCurrent = data.find(
                              (i: any) => i.id == item.id
                            );

                            setAcualCurrentData(getCurrent);
                            openEditBuildingsWalletSingleModal();
                            setIsLoading(false);
                          }}
                          style={{ fontSize: "16px", width: "3em" }}
                        >
                          <IonIcon slot="top" icon={editSvg}></IonIcon>
                          تعديل
                        </IonItemOption>
                      </IonItemOptions>
                      <IonItem
                        lines="none"
                        onClick={() => {
                          setIsLoading(true);
                          setItem(item);
                          let getCurrent = data.find(
                            (i: any) => i.id == item.id
                          );
                          setAcualCurrentData(getCurrent);
                          openDetailsBuildingsWalletSingleModel();
                          setIsLoading(false);
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e1e1e1",
                            width: "19em",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full ${
                            (!item.ownerName || item.ownerName == "") &&
                            (!item.buildingPrice || item.buildingPrice == 0) &&
                            "h-28"
                          } flex flex-row justify-between items-center p-4 mb-4 animate__animated animate__fadeInUp`}
                        >
                          <div className="h-full flex flex-col col-span-3 justify-start items-start">
                            <p className="font-bold text-primaryColor100 text-xl">
                              {item.title}
                            </p>

                            {item.ownerName && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={personCircleOutline}
                                  className="text-darkGreen50 font-bold animate__animated animate__fadeInRightBig"
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-darkGreen50 text-lg mr-2 leading-5 w-36">
                                  الإسم:{" "}
                                  <span className="text-black">
                                    {item.ownerName}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.buildingPrice && item.buildingPrice != 0 && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={moneySvg}
                                  className="text-indigo font-bold animate__animated animate__fadeInRightBig"
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-indigo text-lg mr-2">
                                  سعر العقار:{" "}
                                  <span className="text-black">
                                    {item.buildingPrice}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="h-full flex flex-col col-span-2 justify-center items-end ">
                            <div
                              className="mr-2 mt-2"
                              // style={{ width: "8.6em", textAlign: "center" }}
                            >
                              {/* <Button
                                size="ssmall"
                                text="التفاصيل"
                                theme={"primary"}
                                onClick={() => {
                                  setIsLoading(true);
                                  setItem(item);
                                  setIsLoading(false);
                                  let getCurrent = data.find(
                                    (i: any) => i.id == item.id
                                  );
                                  setAcualCurrentData(getCurrent);
                                  openDetailsBuildingsWalletSingleModel();
                                }}
                              /> */}
                              <IonIcon
                                icon={clickSvg}
                                style={{ fontSize: "30px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </IonItem>

                      <IonItemOptions
                        side="end"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setItem(item);
                            openDeleteModal();
                          }}
                          style={{ fontSize: "19px", width: "3em" }}
                        >
                          <IonIcon
                            slot="top"
                            icon={trash}
                            className="text-2xl text-redColor"
                          ></IonIcon>
                          حذف
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  </IonList>
                );
              })
            ) : (
              <div className="pt-5">
                <p className="text-xl font-bold text-redColor mt-2">
                  عفوا لا يوجد بيانات
                </p>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const DeleteModal = ({
  itemsData,
  onDismissdismissdeleteModal,
}: {
  itemsData: any;
  onDismissdismissdeleteModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }
  return (
    <IonContent>
      <div className="wrapper animate__animated animate__fadeInUp">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl">
            هل انت متأكد من الحذف ؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            {isLoading ? (
              <Spinner
                isFull={false}
                cssExtra={"absolute top-2"}
                color={"primary"}
                size={"large"}
              />
            ) : null}

            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  setIsLoading(true);
                  console.log("itemsData", itemsData);
                  DeleteBuildingsWalletSingleFunc(itemsData.id, token)
                    .then((result: any) => {
                      console.log(result);
                      setIsLoading(true);
                      if (result.data.result) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `تم الحذف بنجاح`
                        );
                        onDismissdismissdeleteModal(null, "confirm");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `عفوا لم يتم الحفظ`
                        );
                      }
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setErrorTitle("خطأ ");
                      setErrorMessage("يرحي المحاوله مره ثانيه!");
                      openpErrorModal();
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onDismissdismissdeleteModal(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};

export default BuildingsWalletSingleModel;
