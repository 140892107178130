import { IonContent, IonPage, } from "@ionic/react";
import Button from "../../components/atoms/button";
import {
  IonCheckbox,
  IonItem,
  IonLabel
} from '@ionic/react';
import { useState } from "react";

const SignInModel = ({
  onSignInModelismiss,
}: {
  onSignInModelismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const [ionCheckbox, setIonCheckbox] = useState(false);

  const openInNewTab = (url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <IonPage>
      <IonContent>
        <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
          <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap pt-5">
            يرجى الموافقة علي تعليمات الهيئة العامة للعقار            </p>
          <div className="mt-4 pt-5">
            <Button
              theme={"primary"}
              text={"رابط تعليمات الهيئة العامة للعقار"}
              size={"big"}
              onClick={() => {
                openInNewTab("https://rega.gov.sa:4433//UploadImages/213024185قرار%20مجلس%20الإدارة%20بشأن%20ضوابط%20الإعلانات%20العقارية.pdf")
              }}
            />
            <IonItem className="pt-5">
              <IonCheckbox onIonChange={e => setIonCheckbox(e.detail.checked)} slot="start"></IonCheckbox>
              <IonLabel>الموافقة علي تعليمات الهيئة</IonLabel>
            </IonItem>

          </div>

          {ionCheckbox ? (
            <div className="w-full mt-4 pt-4">
              <Button
                theme={"primary"}
                text={"موافق"}
                size={"big"}
                onClick={() => {
                  onSignInModelismiss('confirm');
                }}
              />
            </div>
          ) : null}

          <div className="w-full mt-4 pt-4">
            <Button
              theme={"danger"}
              text={"إغلاق"}
              size={"big"}
              onClick={() => {
                onSignInModelismiss();
              }}
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignInModel;
