import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  useIonToast,
  IonActionSheet,
} from "@ionic/react";
import {
  caretForwardCircle,
  chevronBackOutline,
  heart,
  share,
  trash,
} from "ionicons/icons";
import Button from "../../components/atoms/button";
import { ChangeEvent, useEffect, useState } from "react";
import {
  EditUserFunction,
  GetUserFunction,
  onFileUploadValidationSize,
} from "../../services/API";
import { useSelector } from "react-redux";
import Spinner from "../../components/atoms/spinner";
import { ErrorMessage, Field, Formik } from "formik";
import {
  convertToBase64,
  presentToastFunction,
} from "../../services/genericMethoud/generice";
import { responseCodes } from "../../services/API/responseCodes";
import * as Yup from "yup";

const EditProfile = ({
  editProfileDismiss,
}: {
  userId: any;
  IsReport: any;
  IsLogoMessage: any;
  editProfileDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [selectedFile, setSelectedFile] = useState<File | any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [userData, setUserData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  let [presentToast] = useIonToast();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedImage(null);
    setSelectedFile(null);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // Validate file type (only images or videos)
      if (file.type.startsWith("image/")) {
        const fileBase64: any = await convertToBase64(file);
        setSelectedImage(fileBase64);
        setSelectedFile(file);
      } else {
        // Reset selected file and show error (optional)
        setSelectedImage(null);
        setSelectedFile(null);
        alert("Please select a valid image.");
      }
    }
  };

  function getUserData() {
    GetUserFunction(userInfo.id, token)
      .then((returnedData) => {
        setIsLoading(true);
        setUserData(returnedData.data.dateSet);
        console.log(returnedData);

        setSelectedImage(
          !returnedData.data.dateSet.profileImage ||
            returnedData.data.dateSet.profileImage == ""
            ? "https://cdn-icons-png.flaticon.com/512/149/149071.png"
            : returnedData.data.dateSet.profileImage
        );
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getUserData();
  }, []);

  async function editUserFunction(values: any) {
    setIsLoading(true);
    let dataToBeSent = { id: userInfo.id };
    let imageToBeSent;
    if (selectedFile) {
      let checkFileResult = await onFileUploadValidationSize(
        selectedFile,
        6 * 1048576
      );
      if (!checkFileResult) {
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          ` عفوا الملف  ${selectedFile.name}  حجم اكبر من 6 ميجا`
        );
        return;
      }
      const regex = /data:.*base64,/;
      const fileWithOut = await selectedImage.replace(regex, "");
      imageToBeSent = await fileWithOut;
    }

    dataToBeSent = await { ...values, address: values.address };

    dataToBeSent = await { ...values, email: values.email };

    dataToBeSent = await { ...values, phoneNumber: values.phoneNumber };

    dataToBeSent = await { ...values, nickName: values.nickName };

    dataToBeSent = await {
      ...values,
      officePhoneNumber: values.officePhoneNumber,
    };

    dataToBeSent = await { ...values, bio: values.bio };

    console.log("dataToBeSentdataToBeSent", dataToBeSent);
    await EditUserFunction(dataToBeSent, imageToBeSent, token)
      .then(async (response: any) => {
        console.log(response.data);
        if (response.data.responseCode == responseCodes.OK) {
          presentToastFunction(
            presentToast,
            "top",
            "success",
            "تم تعديل البيانات بنجاح"
          );
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            response.data.responseMessage
          );
        }
      })
      .catch((e: any) => {
        console.log(e);
        presentToastFunction(presentToast, "top", "danger", e);
      });
    debugger;
  }

  const openFileDialog = () => {
    (document as any).getElementById("file-upload").click();
  };

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email("الرجاء كتابة الإيميل بشكل صحيح")
        .nullable(true),
    });
  };

  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        {isLoading ? (
          <Spinner isFull={true} color={"primary"} size={"large"} />
        ) : null}
        <Formik
          initialValues={{
            id: userInfo?.id,
            fullName: userData?.fullName,
            nickName: userData?.nickName,
            userName: userData?.userName?.split(".")[0],
            address: userData?.address,
            email: userData?.email,
            phoneNumber: userData?.phoneNumber,
            officePhoneNumber: userData?.officePhoneNumber,
            bio: userData?.bio,
          }}
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={validationSchema}
          onSubmit={async (values: any, actions) => {
            await editUserFunction(values);
            setIsLoading(false);
          }}
        >
          {({ handleSubmit, dirty, isValid }) => (
            <>
              <div
                className="w-full flex flex-col justify-start items-center bg-primaryColor100 rounded-lg"
                style={{ marginBottom: "45px", height: "9em" }}
              >
                <div className="w-full grid grid-cols-3">
                  <div className="col-span-1 py-6 px-6 flex flex-row justify-start items-center"></div>
                  <div className="col-span-1 py-6 flex flex-row justify-center items-center">
                    <p className="font-bold text-white text-2xl whitespace-nowrap">
                      تعديل بيانات الحساب
                    </p>
                  </div>
                  <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center z-50">
                    <button
                      onClick={() => {
                        editProfileDismiss(null, "cancel");
                      }}
                      className="flex flex-col justify-center items-center"
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-white"
                      />
                    </button>
                  </div>
                </div>

                <div className="profile-picture-container animate__animated animate__jackInTheBox">
                  <Field
                    type="file"
                    name="profileImage"
                    id="file-upload"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <img
                    src={selectedImage}
                    style={{ filter: "blur(0.5px)", height: "6rem" }}
                    className="blur-sm size-28 group-hover:blur-0 rounded-full"
                    height={80}
                    width={80}
                    alt="الصورة الشخصية"
                    id="profile-picture"
                  />
                  <IonButton
                    className="py-3"
                    style={{
                      marginRight: "-5px",
                      paddingTop: "9px",
                      marginTop: "auto",
                    }}
                    onClick={openFileDialog}
                  >
                    رفع الصورة
                  </IonButton>
                </div>
              </div>
              <div className="w-full mt-4 pt-4 mr-8 animate__animated animate__lightSpeedInRight">
                <h2 className="text-2xl">البيانات الخاصة</h2>
              </div>

              <div className="form-container">
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInRight">
                  <Field
                    disabled={true}
                    readOnly={true}
                    name="fullName"
                    type="text"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="اكتب اسم المستخدم"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-paragraphColor -translate-y-6 right-6">
                    الإسم
                  </label>
                </div>
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInRight">
                  <Field
                    name="nickName"
                    type="text"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="اكتب كنية المستخدم"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                    كنية المستخدم
                  </label>
                </div>
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInLeft">
                  <Field
                    disabled={true}
                    readOnly={true}
                    name="userName"
                    type="text"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="اكتب رقم الهوية"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-paragraphColor -translate-y-6 right-6">
                    رقم الهوية
                  </label>
                </div>
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInRight">
                  <Field
                    type="text"
                    name="address"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="ادخل العنوان المناسب"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                    العنوان
                  </label>
                </div>
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInRight">
                  <Field
                    type="email"
                    name="email"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="اكتب بريدك الإلكتروني"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-redColor text-lg"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                    البريد الإلكتروني
                  </label>
                </div>
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInLeft">
                  <Field
                    type="text"
                    autoFocus={true}
                    maxLength={10}
                    name="phoneNumber"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="مثال: 0534542111"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                    رقم الهاتف
                  </label>
                </div>
                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInRight">
                  <Field
                    type="text"
                    autoFocus={true}
                    maxLength={10}
                    name="officePhoneNumber"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="مثال: 0534542111"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                    رقم المكتب
                  </label>
                </div>

                <div className="relative w-full px-4 mt-4 animate__animated animate__lightSpeedInLeft">
                  <Field
                    as="textarea"
                    name="bio"
                    className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                    placeholder="ادخل نبذة عامة عنك"
                  />
                  <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                    نبذة عامة
                  </label>
                </div>

                <div className="w-full mt-4 px-4 animate__animated animate__fadeInUp">
                  <IonButton
                    className="w-full py-3 px-4 bg-primaryColor100 font-bold text-xl flex flex-row justify-center items-center rounded shadow"
                    disabled={(!isValid || !dirty) && !selectedFile}
                    onClick={() => setShowActionSheet(true)}
                    expand="block"
                  >
                    تأكيد
                  </IonButton>
                </div>

                <div className="w-full my-4 px-4 animate__animated animate__fadeInUp">
                  <Button
                    theme={"danger"}
                    text={"إغلاق"}
                    size={"big"}
                    onClick={() => {
                      editProfileDismiss();
                    }}
                  />
                </div>
              </div>
              <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                header="هل انت متأكد من تعديل البيانات"
                buttons={[
                  {
                    text: "نعم",
                    handler: handleSubmit,
                  },
                  {
                    text: "لا",
                    handler: () => setShowActionSheet(false),
                  },
                  {
                    text: "إلغاء",
                    role: "cancel",
                  },
                ]}
              ></IonActionSheet>
            </>
          )}
        </Formik>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
