import { warning } from "ionicons/icons";
import dangerSvg from "../../images/danger.svg";
import successSvg from "../../images/success.svg";

export const validAdd = "valid";
export const waitingAccept = "waitingAccept";
export const notValid = "notValid";

export const convertToBase64 = (fileTo: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileTo);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getBase64Size = (base64: string) =>
  new Promise((resolve, reject) => {
    try {
      // Remove the data URL prefix if present
      let base64String = base64.split(",")[1] || base64;

      // Calculate the padding characters
      let padding = (base64String.match(/=/g) || []).length;

      // Calculate the size in bytes
      let sizeInBytes = (base64String.length * 3) / 4 - padding;

      resolve(sizeInBytes);
    } catch (error) {
      reject(error);
    }
  });

export const presentToastFunction = (
  presentToast: any,
  position: "top" | "middle" | "bottom",
  colorName: string,
  messageName: string
) => {
  //color types  "danger" ｜ "dark" ｜ "light" ｜ "medium" ｜ "primary" ｜ "secondary" ｜ "success" ｜ "tertiary" ｜ "warning"
  presentToast({
    message: messageName,
    duration: 5000,
    position: position,
    translucent: true,
    color: "light",
    cssClass: `font-bold ${colorName == "warning"
      ? "text-yellowColor"
      : colorName == "success"
        ? "text-primaryColor100"
        : colorName == "danger"
          ? "text-redColor"
          : "text-primaryColor100"
      } shadow-md drop-shadow-md border-1 border-solid border-[#e1e1e1] text-xl`,
    icon:
      colorName == "warning"
        ? warning
        : colorName == "danger"
          ? dangerSvg
          : colorName == "success"
            ? successSvg
            : "",
    animated: true,
  });
};

export const downloadPDF = (pdfBase64: any, fileName: string = "yourPdfFile.pdf", mimeType: string = "application/pdf") => {
  // const downloadLink = document.createElement("a");
  // document.body.appendChild(downloadLink);
  // (downloadLink.style as any) = 'display: none';
  // downloadLink.href = pdfBase64
  // downloadLink.download = fileName;
  // // downloadLink.target = '_blank';
  // downloadLink.click();

  const byteCharacters = atob(pdfBase64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href); // Cleanup

};

export const downloadFile = (data: Blob, fileName: string): boolean => {
  if (!data) {
    return false;
  }
  try {
    const downloadedFile = new Blob([data], { type: data.type });
    const a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    a.download = fileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = "_blank";

    a.click();
    document.body.removeChild(a);

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const plotsList = [
  {
    name: "مبنى للبيع",
    color: "rgba(255,0,198)",
  },
  {
    name: "مبنى مختلط",
    color: "rgba(134,21,233)",
  },
  {
    name: "ارض للبيع",
    color: "rgba(255,125,1)",
  },
  {
    name: "ايجار واستثمار",
    color: "rgba(38,161,254)",
  },
];


export const getColorAdvertisementType = (typeOfProperty: any) => {
  switch (typeOfProperty) {
    case 1:  //ارض للبيع
      return [255, 125, 1];
    case 3:  //ايجار واستثمار
      return [38, 161, 254];
    case 4:   //مبنى للبيع
      return [255, 0, 198];
    default:
      break;
  }
};

export const getAdvertisementTypeId = (typeOfProperty: any) => {
  switch (typeOfProperty) {
    case "ارض للبيع":
      return 1;
    case "ايجار واستثمار":
      return 3;
    case "مبنى للبيع":
      return 4;
    case "مبنى مختلط":
      return 5;
    default:
      break;
  }
};

export const getAdvertisementType = (typeOfProperty: any) => {
  switch (typeOfProperty) {
    case 1:
      return "ارض للبيع";
    case 3:
      return "ايجار واستثمار";
    case 4:
      return "مبنى للبيع";
    default:
      break;
  }
};



export const getAdvertisementTypeWithImage = (
  LandForSale_Image: any,
  PropertyForRent_Image: any,
  PropertyForSale_Image: any
) => {
  return [
    {
      name: "ارض للبيع",
      value: 1,
      image: LandForSale_Image,
      color: "rgba(255,125,1,0.2)",
    },
    {
      name: "ايجار واستثمار",
      value: 3,
      image: PropertyForRent_Image,
      color: "rgba(38,161,254,0.2)",
    },
    {
      name: "مبنى للبيع",
      value: 4,
      image: PropertyForSale_Image,
      color: "rgba(255,0,198,0.2)",
    },
  ];
};

export const LandTypesBuildingSaleSymbol = {
  type: "simple-fill",
  color: "rgba(255,0,198,0.2)",
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};

export const LandTypesMixedBuildingSymbol = {
  type: "simple-fill",
  color: "rgba(134,21,233,0.2)",
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};

export const LandTypesLandSaleSymbol = {
  type: "simple-fill",
  color: "rgba(255,125,1,0.2)",
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};

export const LandTypesRentAndInvestmentSymbol = {
  type: "simple-fill",
  color: "rgba(38,161,254,0.2)",
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};

export const handleStyleButtonByLandTypes = (condition: any) => {
  if (condition == "مبنى للبيع") {
    return {
      backgroundColor: "rgba(255,0,198,0.2)",
    };
  }

  if (condition == "مبنى مختلط") {
    return {
      backgroundColor: "rgba(134,21,233,0.2)",
    };
  }

  if (condition == "ارض للبيع") {
    return {
      backgroundColor: "rgba(255,125,1,0.2)",
    };
  }

  if (condition == "ايجار واستثمار") {
    return {
      backgroundColor: "rgba(38,161,254,0.2)",
    };
  }
};

export const handleAddStatus = (condition: any) => {
  if (condition == "valid") {
    return "تمت الموافقة";
  }

  if (condition == "waitingAccept") {
    return "بإنتظار الموافقة";
  }

  if (condition == "notValid") {
    return "يوجد ملاحظة";
  }
};

export const isObject = (val: any) => {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
};
