import { useState, useEffect, useRef } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonModal,
  IonSearchbar,
  useIonToast,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonButton,
} from "@ionic/react";
import {
  chevronBackOutline,
  createOutline,
  trophy,
  trash,
  informationCircleOutline,
  arrowBack,
  arrowForward,
} from "ionicons/icons";
import { useSelector } from "react-redux";
import Button from "../../../components/atoms/button";
import Spinner from "../../../components/atoms/spinner";
import info from "../../../images/info.svg";
import labelSvg from "../../../images/label.svg";
import emptySvg from "../../../images/empty.svg";
import districtSvg from "../../../images/district.svg";
import neighborhoodSvg from "../../../images/neighborhood.svg";
import navigateSvg from "../../../images/navigate.svg";
import citySvg from "../../../assets/uicons/svg/fi-ts-city.svg";
import {
  DeleteBuildingsWalletFunc,
  EditTitleInBuildingsWalletParcelFunction,
  GetBuildingsWalletFunc,
  GetGenerateLinkBuildingsWalletFunc,
  GetPointsEarnedCounterByUserIDFunc,
} from "../../../services/API";
import { presentToastFunction } from "../../../services/genericMethoud/generice";
import ErrorModal from "../../../components/molecules/errorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import BuildingsWalletSingleModel from "../BuildingsWalletSingleList";
import Graphic from "@arcgis/core/Graphic";
import Request from "@arcgis/core/request";
import Polygon from "@arcgis/core/geometry/Polygon";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Point from "@arcgis/core/geometry/Point";
import { selectedSymbol } from "../../../services/genericMethoud/gisMethouds";
import { arcgisServicesBaseMinistryUrl } from "../../../configs/arcgisServices";
import lastPageSvg from "../../../images/lastPage.svg";
import firstPageSvg from "../../../images/firstPage.svg";
import clickSvg from "../../../images/clickSvg.svg";
import Joyride from "react-joyride";

const BuildingsWalletModel = ({
  BuildingsWalletModelDismiss,
}: {
  BuildingsWalletModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<any>();
  const [setLinkShring] = useState<any>("");
  const [setPointsForYou] = useState<any>(0);
  const [acualData, setAcualData] = useState<any>({});
  const [presentToast] = useIonToast();
  let graphicsLayer: GraphicsLayer = new GraphicsLayer();

  const [presentdeleteModal, dismissdeleteModal] = useIonModal(DeleteModal, {
    itemsData: item,
    onDismissdismissdeleteModal: (data: string, role: string) =>
      dismissdeleteModal(data, role),
  });

  function openDeleteModal() {
    presentdeleteModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletFuncData();
        }
      },
    });
  }

  const [presentBuildingsWalletSingleModel, dismissBuildingsWalletSingleModel] =
    useIonModal(BuildingsWalletSingleModel, {
      itemsData: item,
      dismissBuildingsWalletSingleModel: (data: string, role: string) =>
        dismissBuildingsWalletSingleModel(data, role),
    });

  function openBuildingsWalletSingleModel() {
    presentBuildingsWalletSingleModel({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletFuncData();
        }
      },
    });
  }

  const [presentDetailsAddModel, DetailsAddModelDismiss] = useIonModal(
    DetailsAddModel,
    {
      gtItem: item,
      DetailsAddModelDismiss: (data: string, role: string) =>
        DetailsAddModelDismiss(data, role),
    }
  );

  function openDetailsAddModel() {
    presentDetailsAddModel({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletFuncData();
        }
      },
    });
  }

  const handleFavoriteLand = (filter: any) => {
    if (filter) {
      const newAdds = acualData.filter(function (str: any) {
        return str.land_Numbe.toString().includes(filter);
      });
      setData(newAdds);
      setCurrentPage(1);
    } else {
      getBuildingsWalletFuncData();
    }
  };

  function getBuildingsWalletFuncData() {
    setIsLoading(true);
    GetBuildingsWalletFunc(userInfo.id, token)
      .then((returnedData) => {
        console.log(returnedData);
        setData(returnedData.data.data);
        setAcualData(returnedData.data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }
  function getGenerateLinkBuildingsWallet() {
    setIsLoading(true);
    GetGenerateLinkBuildingsWalletFunc(userInfo.id, token)
      .then((returnedData) => {
        console.log("getGenerateLinkBuildingsWallet", returnedData);
        if (returnedData.data.data) {
          let urlShering =
            "https://thki.sa/app/gCode?code=" + returnedData.data.data;
          setLinkShring(urlShering);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  function GetPointsEarnedCounterByUserID() {
    setIsLoading(true);
    GetPointsEarnedCounterByUserIDFunc(userInfo.id, token)
      .then((returnedData) => {
        console.log("GetPointsEarnedCounterByUserID", returnedData);
        if (returnedData.data.data) {
          setPointsForYou(returnedData.data.data.buildingsWalletPointCount);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  //#region zoom to objId
  function createFeatureGraphics(
    type: any,
    geometry: any,
    wiki: any,
    attr: any,
    symbolS: any
  ) {
    console.log("createFeatureGraphics ==>geometry", geometry);
    let fFeature: any;
    switch (type) {
      case "polygon":
        fFeature = new Polygon({
          rings: geometry,
          spatialReference: {
            wkid: wiki,
          },
        });
        break;
      case "point":
        fFeature = new Point({
          x: geometry.x,
          y: geometry.y,
          spatialReference: {
            wkid: wiki,
          },
        });
        break;
    }

    let fFeatureGraphic = new Graphic({
      geometry: fFeature,
      symbol: symbolS,
      attributes: attr,
    });
    return fFeatureGraphic;
  }
  let zoomToLayer = (type: any, geometry: any, wiki: any, ZoomLevel: any) => {
    graphicsLayer.removeAll();
    let fGetGraphic: any = createFeatureGraphics(
      type,
      geometry,
      wiki,
      "",
      selectedSymbol
    );
    graphicsLayer.add(fGetGraphic);

    if (ZoomLevel) {
      let opts = {
        duration: 5000,
      };
      window.mapviews.goTo(
        {
          target: fGetGraphic,
          zoom: 17,
        },
        opts
      );
    } else {
      let gr: any = graphicsLayer.graphics;
      window.mapviews.goTo(gr.items[0].geometry.extent.expand(2));
    }
  };

  let getGraphicFromObj = (obj: any) => {
    let queryOption: any = {
      responseType: "json",
      query: {
        f: "json",
        where: `OBJECTID in (${obj}) `,
        returnCountOnly: false,
        outFields: "*",
        returnDistinctValues: false,
        returnGeometry: true,
      },
    };
    Request(arcgisServicesBaseMinistryUrl + "0/query", queryOption).then(
      (response: any) => {
        if (response.data.features.length >= 0) {
          zoomToLayer(
            "polygon",
            response.data.features[0].geometry.rings[0],
            4326,
            false
          );
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `يرجى التاكد من رقم القطعة`
          );
        }
      }
    );
    setIsLoading(false);
  };
  //#endregion
  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".walletDetails",
      content:
        "تفاصيل المحفظة: يتم عرض تفاصيل المحفظة الحالية وبعض المعلومات عنها.",
      placement: "auto" as const,
      disableBeacon: true,
    },
    {
      target: ".search-bar",
      content: "حقل البحث: يستخدم للبحث عن محافظ الوسطاء.",
    },
    {
      target: ".list",
      content: "قائمة محافظ الوسطاء: يتم فيه عرض قائمة من محافظ الوسطاء.",
    },
    {
      target: ".slide",
      content:
        "قائمة التحكم: يمكنك سحب البطاقة يمينًا او يسارًا لعرض عناصر التجكم.",
    },
    {
      target: ".pagination-controls",
      content:
        "التحكم في الصفحات: يمكنك التنقل بين الصفحات ومن الصفحة السابقة إلى الصفحةالتالية.",
    },
  ]);

  useEffect(() => {
    let BuildingsWalletOpenedFirstTime = window.localStorage.getItem(
      "BuildingsWalletOpenedFirstTime"
    );

    if (BuildingsWalletOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }

    getBuildingsWalletFuncData();
    getGenerateLinkBuildingsWallet();
    GetPointsEarnedCounterByUserID();
    console.log(data, "data");
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(data.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <IonPage>
      {!isLoading && run && (
        <Joyride
          steps={steps}
          run={run}
          continuous={run}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          showSkipButton={false}
          hideBackButton={true}
          hideCloseButton={true}
          disableScrolling={false}
          showProgress={true}
          locale={{
            back: "الرجوع",
            close: "اغلاق",
            last: "إنهاء",
            next: "التالي",
            skip: "تخطي",
          }}
          callback={(data) => {
            console.log(data);
            let walletDetails: any = document.getElementById(
              "walletDetailsExpand"
            );
            if (data.action == "update" && data.index == 3) {
              let ionItemSliding =
                document.getElementsByTagName("ion-item-sliding")[0];
              if (ionItemSliding) {
                ionItemSliding.open("end");
              }
            }
            if (data.action == "update" && data.index == 0) {
              if (walletDetails) {
                walletDetails.open = true;
              }
            }
            if (data.action == "update" && data.index == 1) {
              if (walletDetails) {
                walletDetails.open = false;
              }
            }
            if (data.status == "finished" || data.action == "reset") {
              window.localStorage.setItem(
                "BuildingsWalletOpenedFirstTime",
                "true"
              );
              setRun(false);
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <IonContent>
        {isLoading ? (
          <Spinner isFull={false} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                محفظة الوسيط
              </p>
            </div>
            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  BuildingsWalletModelDismiss(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center mt-2">
            <details className="walletDetails" id="walletDetailsExpand">
              <summary className="font-bold text-primaryColor100 text-2xl text-center mt-2 animate__animated animate__bounceInRight">
                معلومات عن المحفظة
                <IonIcon
                  icon={informationCircleOutline}
                  style={{ marginBottom: "-4px" }}
                />
              </summary>

              {data != null &&
              data[0]?.buildingsWalletTypesUsers?.buildingsWalletTypesId ==
                1 ? (
                <p className="font-bold text-primaryColor100 text-2xl text-[#8f1991] text-center mt-2">
                  تمتلك الان المحفظة الإفتراضية
                </p>
              ) : data != null &&
                data[0]?.buildingsWalletTypesUsers?.buildingsWalletTypesId ==
                  2 ? (
                <p className="font-bold text-primaryColor100 text-2xl text-[#8f1991] text-center mt-2">
                  <IonIcon
                    size="large"
                    icon={trophy}
                    className="text-4xl ml-2 text-[#FFD700]"
                  />
                  تمتلك الان المحفظة البرونزية
                </p>
              ) : null}

              <div className="w-full h-full relative flex flex-col justify-start items-center text-center">
                <h1
                  className="font-bold text-primaryColor100 mt-2 leading-7"
                  style={{ width: "21em" }}
                >
                  هي مكتب عقاري إلكتروني تتيح لك إدارة جميع عقاراتك والاحتفاظ
                  بمستنداتها الرسمية مع إمكانية الوصول لها في أي وقت.
                </h1>
                <h1
                  className="font-bold text-primaryColor100 p-4 text-[#a8112a] leading-7"
                  style={{ width: "21em" }}
                >
                  ( ملاحظة هامة : جميع البيانات في المحفظة سرية للغاية ولايمكن
                  الاطلاع عليها إلا من قبل صاحب المحفظة )
                </h1>
              </div>
            </details>
            <div className="w-full flex flex-col justify-start items-center px-4 mt-2 search-bar">
              <IonSearchbar
                placeholder="البحث برقم القطعة"
                onIonChange={(ev) => handleFavoriteLand(ev.detail.value)}
              ></IonSearchbar>
            </div>
            {currentCards != null && currentCards.length > 0 ? (
              currentCards.map((item: any, index: any) => {
                return (
                  <IonList className="list slide">
                    <IonItemSliding>
                      <IonItemOptions
                        side="start"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        {/* <IonItemOption
                          color="none"
                          onClick={() => {
                            setIsLoading(true);
                            setItem(item);
                            setIsLoading(false);
                            openBuildingsWalletSingleModel();
                          }}
                          style={{ fontSize: "16px", width: "3.5em" }}
                        >
                          <IonIcon slot="top" icon={info}></IonIcon>
                          التفاصيل
                        </IonItemOption> */}
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setItem(item);
                            openDetailsAddModel();
                          }}
                          style={{ fontSize: "16px", width: "3em" }}
                        >
                          <IonIcon slot="top" icon={labelSvg}></IonIcon>
                          تغيير العنوان
                        </IonItemOption>
                      </IonItemOptions>

                      <IonItem lines="none">
                        <div
                          key={index}
                          style={{
                            width: "19em",
                            border: "1px solid #e1e1e1",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 my-2 ${
                            !isLoading && "animate__animated animate__fadeInUp"
                          }`}
                        >
                          <div
                            className="h-full flex flex-col col-span-3 justify-start items-start"
                            onClick={() => {
                              setIsLoading(true);
                              setItem(item);
                              openBuildingsWalletSingleModel();
                              setIsLoading(false);
                            }}
                          >
                            <p className="font-bold text-primaryColor100 text-xl mb-2">
                              <IonIcon
                                size="small"
                                icon={createOutline}
                                className={`text-1xl ml-2 ${
                                  !isLoading &&
                                  "animate__animated animate__fadeInRightBig"
                                }`}
                              />
                              {item.title == "" || item.title == null
                                ? "لا يوجد عنوان"
                                : item.title
                                ? item.title
                                : null}
                            </p>

                            {item.district_Name && (
                              <div className="flex flex-row justify-start items-center">
                                <IonIcon
                                  icon={districtSvg}
                                  className={`font-bold text-darkGreen100 ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-darkGreen100 text-lg mr-2">
                                  المنطقة:{" "}
                                  <span className="text-black">
                                    {item.district_Name}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.city_Name && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={citySvg}
                                  className={`font-bold text-primaryColor100 ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-primaryColor100 text-lg mr-2">
                                  المدينة:{" "}
                                  <span className="text-black">
                                    {item.city_Name}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.neighbourhood_Name && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={neighborhoodSvg}
                                  className={`font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-lg mr-2">
                                  الحي:{" "}
                                  <span className="text-black">
                                    {item.neighbourhood_Name}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="h-full flex flex-col col-span-2 justify-center items-end">
                            {item.land_Numbe && (
                              <div
                                className="flex flex-col justify-end items-center"
                                onClick={() => {
                                  setIsLoading(true);
                                  setItem(item);
                                  openBuildingsWalletSingleModel();
                                  setIsLoading(false);
                                }}
                              >
                                <p className="font-bold text-primaryColor55 text-xl whitespace-nowrap">
                                  رقم القطعة
                                </p>
                                <p className="text-black text-xl">
                                  {item.land_Numbe}
                                </p>
                              </div>
                            )}
                            <div className="flex flex-row justify-start items-center mt-4">
                              <IonIcon
                                icon={clickSvg}
                                className={`${
                                  !isLoading &&
                                  "animate__animated animate__fadeInLeftBig"
                                } ml-2`}
                                size="large"
                                onClick={() => {
                                  setIsLoading(true);
                                  setItem(item);
                                  openBuildingsWalletSingleModel();
                                  setIsLoading(false);
                                }}
                              />
                              <IonIcon
                                size="large"
                                icon={navigateSvg}
                                className={`text-primaryColor100 text-xl ${
                                  !isLoading &&
                                  "animate__animated animate__fadeInLeftBig"
                                }`}
                                onClick={() => {
                                  setItem(item);
                                  console.log("item.objectId", item.parcel_Cod);
                                  getGraphicFromObj(item.parcel_Cod);
                                  BuildingsWalletModelDismiss(null, "cancel");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </IonItem>

                      <IonItemOptions
                        side="end"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setItem(item);
                            setIsLoading(false);
                            openDeleteModal();
                          }}
                          style={{ fontSize: "19px", width: "3em" }}
                        >
                          <IonIcon
                            slot="top"
                            icon={trash}
                            className="text-2xl text-redColor"
                          ></IonIcon>
                          حذف
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  </IonList>
                );
              })
            ) : (
              <>
                <div className="pt-5">
                  <p className="text-xl font-bold text-redColor mt-2">
                    عفوا لا يوجد بيانات
                  </p>
                </div>
                <div className="pt-5">
                  <img
                    src={emptySvg}
                    className="animate__animated animate__jello animate__infinite"
                    height={150}
                    width={150}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {currentCards.length > 0 && (
          <div
            className={`pagination-buttons pagination-controls ${
              !isLoading && "animate__animated animate__fadeInDown"
            }`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <IonButton
              onClick={handleFirstPage}
              style={{ maxWidth: "50px" }}
              disabled={currentPage === 1}
            >
              <IonIcon icon={firstPageSvg} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <IonIcon icon={arrowForward} />
            </IonButton>
            {getPageNumbers().map((pageNumber) => (
              <IonButton
                style={{ maxWidth: "40px" }}
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </IonButton>
            ))}
            <IonButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ maxWidth: "50px" }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              <IonIcon icon={lastPageSvg} />
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

const DeleteModal = ({
  itemsData,
  onDismissdismissdeleteModal,
}: {
  itemsData: any;
  onDismissdismissdeleteModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [presentToast] = useIonToast();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }
  return (
    <IonContent>
      <div className="wrapper animate__animated animate__fadeInDown">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl">
            هل انت متأكد من الحذف
          </p>
          <p className="font-bold text-primaryColor100 text-2xl">
            سيتم حذف جميع البيانات المتعلقة بالقطعة؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            {isLoading ? (
              <Spinner
                isFull={false}
                cssExtra={"absolute top-2"}
                color={"primary"}
                size={"large"}
              />
            ) : null}

            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  setIsLoading(true);
                  DeleteBuildingsWalletFunc(itemsData.id, token)
                    .then((result: any) => {
                      console.log(result);
                      setIsLoading(true);
                      if (result.data.result) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `تم الحذف بنجاح`
                        );
                        onDismissdismissdeleteModal(null, "confirm");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `عفوا لم يتم الحفظ`
                        );
                      }
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setErrorTitle("خطأ ");
                      setErrorMessage("يرحي المحاوله مره ثانيه!");
                      openpErrorModal();
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onDismissdismissdeleteModal(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};

const DetailsAddModel = ({
  gtItem,
  DetailsAddModelDismiss,
}: {
  gtItem: any;
  DetailsAddModelDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const inputClassWithOutError =
    "block px-5 py-2.5 text-1xl  placeholder-paragraphColor w-full  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputClassWithError =
    "input-error block px-5 py-2.5  text-1xl  placeholder-paragraphColor w-full   bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputNeedAddIsOpenRef = useRef<HTMLIonInputElement>(null);
  const { token } = useSelector((state: any) => state.reducer);
  const [presentToast] = useIonToast();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IonPage>
      <div className="w-full h-full pt-4 flex flex-col justify-start items-center animate__animated animate__bounceInUp">
        <p className="font-bold text-primaryColor100 text-2xl">
          يرجى كتابة العنوان
        </p>
        <div className="w-96 mt-4 animate__animated animate__bounceInDown">
          <IonItem>
            <IonInput
              type="text"
              autofocus={true}
              className={
                inputNeedAddIsOpenRef.current?.value
                  ? `${inputClassWithOutError}`
                  : `${inputClassWithError}`
              }
              ref={inputNeedAddIsOpenRef}
            ></IonInput>
          </IonItem>
        </div>
        <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4 animate__animated animate__bounceInUp">
          <Button
            theme={"primary"}
            text={"حفظ"}
            size={"big"}
            onClick={() => {
              if (inputNeedAddIsOpenRef.current?.value) {
                EditTitleInBuildingsWalletParcelFunction(
                  gtItem.id,
                  inputNeedAddIsOpenRef.current?.value,
                  token
                )
                  .then((ss: any) => {
                    console.log(ss);
                    if (ss.data) {
                      setIsLoading(false);
                      presentToastFunction(
                        presentToast,
                        "top",
                        "success",
                        "تم الحفظ بنجاح"
                      );
                      DetailsAddModelDismiss(
                        inputNeedAddIsOpenRef.current?.value,
                        "confirm"
                      );
                    } else {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "danger",
                        ss.data
                      );
                    }
                    setIsLoading(false);
                  })
                  .catch((e: any) => {
                    console.log(e);
                    setIsLoading(false);
                    if (e.response?.status == 401) {
                      window.localStorage.removeItem("persistantState");
                      window.location.assign(`/app/signIn`);
                    }
                    presentToastFunction(
                      presentToast,
                      "top",
                      "danger",
                      "عفوا لم تتم الإضافة"
                    );
                  });
              }
            }}
          />
          <Button
            theme={"danger"}
            text={"إغلاق"}
            size={"big"}
            onClick={() => {
              DetailsAddModelDismiss();
            }}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default BuildingsWalletModel;
