export async function getVideoThumbnail(videoBase64: any, timeInSeconds = 1) {
    // Convert base64 to Blob
    const byteCharacters = atob(videoBase64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'video/mp4' });

    // Create a video element
    const video = document.createElement('video');
    video.src = URL.createObjectURL(blob);

    await new Promise(resolve => video.addEventListener('loadeddata', resolve));

    // Seek to the specified time
    video.currentTime = timeInSeconds;

    await new Promise(resolve => video.addEventListener('seeked', resolve));

    // Create a canvas to draw the frame
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Get the base64 encoded image
    const thumbnailBase64 = canvas.toDataURL('image/jpeg');

    // Clean up
    URL.revokeObjectURL(video.src);

    return thumbnailBase64;
}

