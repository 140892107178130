import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonToggle,
  IonModal,
} from "@ionic/react";
import Button from "../../components/atoms/button";
import {
  chevronBackOutline,
  languageOutline,
  phonePortraitOutline,
  locateOutline,
  contrastOutline,
  newspaperOutline,
  starHalfOutline,
  logOutOutline,
} from "ionicons/icons";
import { useDispatch } from "react-redux";
import { signOut } from "../../services/redux/actions";
import { useHistory } from "react-router-dom";

const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const signOutFunction = () => dispatch(signOut());

  const [signOutModalIsOpen, setSignOutModalIsOpen] = useState(false);

  const signOutFunctionForModal = () => {
    signOutFunction();
    setSignOutModalIsOpen(false);
    history.replace("/app/onBoarding");
  };

  const navigationList = [
    {
      label: "تغير اللغة",
      path: "",
      Icon: languageOutline,
    },
    {
      label: "تغير رقم الهاتف",
      path: "/changePhoneNumber",
      Icon: phonePortraitOutline,
    },
    {
      label: "الموقع",
      path: "",
      Icon: locateOutline,
    },
  ];
  const navigationList2 = [
    {
      label: "الشروط و الأحكام",
      path: "",
      Icon: newspaperOutline,
    },
    {
      label: "تقيم التطبيق",
      path: "",
      Icon: starHalfOutline,
    },
  ];
  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        <div className="w-full flex flex-col justify-start items-center bg-white">
          <div className="w-full grid grid-cols-3">
            <div></div>
            <div className="col-span-1  py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-primaryColor100 text-2xl">
                الأعدادات
              </p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  history.back();
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-primaryColor100"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-center">
          <div className="w-full flex flex-col justify-start items-start pt-4 px-8">
            <p className="font-medium text-black text-2xl">اعدادات شخصية</p>
            <p className="font-light text-paragraphColor text-base">
              وهي إعدادات لها علاقة بالحساب الشخصي
            </p>
          </div>
          <div className="w-5/6 flex flex-col justify-center items-center">
            {navigationList.map((item: any, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    history.push(item.path);
                  }}
                  className="w-full flex flex-row justify-between items-center mt-5"
                >
                  <div className="flex flex-row justify-start items-center">
                    <IonIcon
                      icon={item.Icon}
                      className="text-xl text-paragraphColor"
                    />
                    <p className="font-medium text-darkBlueColor text-xl mr-4">
                      {item.label}
                    </p>
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-darkBlueColor"
                    />
                  </div>
                </button>
              );
            })}
          </div>
          <hr className="w-5/6 border-paragraphColor border-[0.1px] mt-4" />
          <div className="w-full flex flex-col justify-start items-start pt-4 px-8">
            <p className="font-medium text-black text-2xl">اعدادات التطبيق</p>
            <p className="font-light text-paragraphColor text-base">
              وهي إعدادات لها علاقة باعدادات التطبيق
            </p>
          </div>
          <div className="w-5/6 flex flex-col justify-center items-center">
            <div className="w-full flex flex-row justify-between items-center mt-5">
              <div className="flex flex-row justify-start items-center">
                <IonIcon
                  icon={contrastOutline}
                  className="text-xl text-paragraphColor"
                />
                <p className="font-medium text-darkBlueColor text-xl mr-4">
                  الغامق و الفاتح
                </p>
              </div>
              <div className="flex flex-row justify-start items-center">
                <IonToggle color="dark" />
              </div>
            </div>
            {navigationList2.map((item: any, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    history.push(item.path);
                  }}
                  className="w-full flex flex-row justify-between items-center mt-5"
                >
                  <div className="flex flex-row justify-start items-center">
                    <IonIcon
                      icon={item.Icon}
                      className="text-xl text-paragraphColor"
                    />
                    <p className="font-medium text-darkBlueColor text-xl mr-4">
                      {item.label}
                    </p>
                  </div>
                  <div className="flex flex-row justify-start items-center">
                    <IonIcon
                      icon={chevronBackOutline}
                      className="text-2xl text-darkBlueColor"
                    />
                  </div>
                </button>
              );
            })}
            <button
              onClick={() => {
                setSignOutModalIsOpen(true);
              }}
              className="w-full flex flex-row justify-between items-center mt-5"
            >
              <div className="flex flex-row justify-start items-center">
                <IonIcon
                  icon={logOutOutline}
                  className="text-xl text-paragraphColor"
                />
                <p className="font-medium text-darkBlueColor text-xl mr-4">
                  تسجيل الخروج
                </p>
              </div>
            </button>
          </div>
        </div>
        <IonModal
          id="errorModal"
          isOpen={signOutModalIsOpen}
          trigger="open-custom-dialog"
        >
          <div className="wrapper">
            <div className="flex flex-col justify-start items-center py-6">
              <p className="font-bold text-primaryColor100 text-2xl">
                هل انت متأكد من تسجيل الخروج ؟
              </p>
              <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
                <div className="flex flex-row justify-end">
                  <Button
                    size={"small"}
                    theme={"danger"}
                    text={"نعم"}
                    onClick={signOutFunctionForModal}
                  />
                </div>
                <div className="flex flex-row justify-start">
                  <Button
                    size={"small"}
                    theme={"primary"}
                    text={"لا"}
                    onClick={() => {
                      setSignOutModalIsOpen(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
