import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  EditTypeInBuildingsWalletFunction,
  EditUsersInRoleFunction,
  GetUserListFunction,
} from "../../services/API";
import Spinner from "../../components/atoms/spinner";
import wallet from "../../images/wallet.svg";
import roles from "../../images/roles.svg";
import emptySvg from "../../images/empty.svg";
import {
  chevronBackOutline,
  callOutline,
  personCircleOutline,
  arrowBack,
  arrowForward,
} from "ionicons/icons";
import idBadgeSvg from "../../assets/uicons/svg/fi-ts-id-badge.svg";
import Button from "../../components/atoms/button";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ErrorModal from "../../components/molecules/errorModal";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import { responseCodes } from "../../services/API/responseCodes";
import lastPageSvg from "../../images/lastPage.svg";
import firstPageSvg from "../../images/firstPage.svg";
import Joyride from "react-joyride";

const ChangeRoles = ({
  disOpenRolesModal,
}: {
  disOpenRolesModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [data, setData] = useState<any>([]);
  const [acualData, setAcualData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<any>({});

  function getArabicRole(roles: any) {
    let arabicRole: any = [];
    roles.forEach((element: any) => {
      if (element == "user") {
        arabicRole.push("مستخدم");
      }
      if (element == "admin") {
        arabicRole.push("مدير");
      }
      if (element == "operation") {
        arabicRole.push("موظف");
      }
    });
    return arabicRole.toString();
  }

  const handleUsers = (fullName: any) => {
    if (fullName) {
      console.log("fullName", fullName);
      console.log("acualData", acualData);

      const newUsers = acualData.filter(function (str: any) {
        return str.fullName?.includes(fullName);
      });
      setData(newUsers);
      setCurrentPage(1);
    } else {
      GetUserList();
    }
  };

  const handlePhoneNumber = (phoneNumber: any) => {
    if (phoneNumber) {
      const newUsers = acualData.filter(function (str: any) {
        return str.phoneNumber?.includes(phoneNumber);
      });
      setData(newUsers);
      setCurrentPage(1);
    } else {
      GetUserList();
    }
  };

  const [presentEditRolesModalModal, onDismissEditRolesModal] = useIonModal(
    EditRolesModal,
    {
      itemsData: item,
      onDismissEditRolesModal: (data: string, role: string) =>
        onDismissEditRolesModal(data, role),
    }
  );

  function presentEditRolesModal() {
    presentEditRolesModalModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          GetUserList();
        }
      },
    });
  }

  const [
    presentPresentEditBuildingWalltType,
    onDismissPresentEditBuildingWalltType,
  ] = useIonModal(PresentEditBuildingWalltTypeModal, {
    itemsData: item,
    onDismissPresentEditBuildingWalltType: (data: string, role: string) =>
      onDismissPresentEditBuildingWalltType(data, role),
  });

  function presentEditBuildingWalltTypeModal() {
    presentPresentEditBuildingWalltType({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          GetUserList();
        }
      },
    });
  }

  function GetUserList() {
    GetUserListFunction(token)
      .then((returnedData) => {
        console.log(returnedData);

        setData(returnedData.data.dateSet);
        setAcualData(returnedData.data.dateSet);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".search-bar-name",
      content: "حقل البحث بالإسم: يستخدم للبحث عن المستخدمين عن خلال اسمهم.",
      placement: "auto" as const,
      disableBeacon: true,
    },
    {
      target: ".search-bar-phone",
      content:
        "حقل البحث برقم الجوال: يستخدم للبحث عن المستخدمين عن خلال رقم جوالهم.",
    },
    {
      target: ".list",
      content: "قائمة المستخدمين: يتم فيه عرض قائمة من بيانات المستخدمين.",
    },
    {
      target: ".slide",
      content:
        "قائمة التحكم: يمكنك سحب البطاقة يمينًا او يسارًا لعرض عناصر التجكم.",
    },
    {
      target: ".pagination-controls",
      content:
        "التحكم في الصفحات: يمكنك التنقل بين الصفحات ومن الصفحة السابقة إلى الصفحةالتالية.",
    },
  ]);

  useEffect(() => {
    let usersControlOpenedFirstTime = window.localStorage.getItem(
      "usersControlOpenedFirstTime"
    );

    if (usersControlOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }

    GetUserList();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 5;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(data.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <IonPage>
      {!isLoading && run && (
        <Joyride
          steps={steps}
          run={run}
          continuous={run}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          showSkipButton={false}
          hideBackButton={true}
          hideCloseButton={true}
          disableScrolling={false}
          showProgress={true}
          locale={{
            back: "الرجوع",
            close: "اغلاق",
            last: "إنهاء",
            next: "التالي",
            skip: "تخطي",
          }}
          callback={(data) => {
            console.log(data);
            if (data.action == "update" && data.index == 3) {
              let ionItemSliding =
                document.getElementsByTagName("ion-item-sliding")[0];
              if (ionItemSliding) {
                ionItemSliding.open("end");
              }
            }
            if (data.status == "finished" || data.action == "reset") {
              window.localStorage.setItem(
                "usersControlOpenedFirstTime",
                "true"
              );
              setRun(false);
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <IonContent>
        <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              {isLoading ? (
                <Spinner isFull={true} color={"primary"} size={"large"} />
              ) : null}
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                تحكم المستخدمين
              </p>
            </div>

            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  disOpenRolesModal(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4 search-bar-name">
            <IonSearchbar
              placeholder="البحث بالاسم"
              onIonChange={(ev) => handleUsers(ev.detail.value)}
            ></IonSearchbar>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4 search-bar-phone">
            <IonSearchbar
              placeholder="البحث برقم الجوال"
              onIonChange={(ev) => handlePhoneNumber(ev.detail.value)}
            ></IonSearchbar>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            {currentCards.length > 0 ? (
              currentCards.map((item: any, index: any) => {
                return (
                  <IonList className="list slide">
                    <IonItemSliding>
                      <IonItemOptions
                        side="start"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setItem(item);
                            presentEditRolesModal();
                          }}
                          style={{ fontSize: "14px", width: "4em" }}
                        >
                          <IonIcon slot="top" icon={roles}></IonIcon>
                          تعديل الصلاحيات
                        </IonItemOption>
                      </IonItemOptions>

                      <IonItem lines="none">
                        <div
                          key={index}
                          style={{
                            border: "1px solid #e1e1e1",
                            width: "19em",
                            borderRadius: "10px",
                          }}
                          className={`shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 my-2 ${
                            !isLoading && "animate__animated animate__fadeInUp"
                          }`}
                        >
                          <div className="h-full flex flex-col col-span-3 justify-start items-start">
                            {item.userName && (
                              <div className="flex flex-row justify-start items-center">
                                <IonIcon
                                  icon={idBadgeSvg}
                                  className={`text-darkGreen100 font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-darkGreen100 text-lg mr-2">
                                  <span className="text-darkGreen100">
                                    {item.userName}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.fullName && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={personCircleOutline}
                                  className={`text-indigo font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <p className="text-indigo text-lg mr-2 leading-5 w-36">
                                  الإسم:{" "}
                                  <span className="text-black">
                                    {item.fullName}
                                  </span>
                                </p>
                              </div>
                            )}

                            {item.phoneNumber && (
                              <div className="flex flex-row justify-start items-center mt-1">
                                <IonIcon
                                  icon={callOutline}
                                  className={`text-tan font-bold ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInRightBig"
                                  }`}
                                  style={{ fontSize: "20px" }}
                                />
                                <br />
                                <p className="text-tan text-lg mr-2">
                                  الهاتف:{" "}
                                  <span className="text-black">
                                    {item.phoneNumber}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="h-full flex flex-col col-span-2 justify-center items-end">
                            {getArabicRole(item.roles) && (
                              <div className="flex flex-col justify-end items-center">
                                <p
                                  className="font-bold text-primaryColor55 text-xl"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  الادوار الحالية
                                </p>
                                <p
                                  className={`text-black text-xl ${
                                    !isLoading &&
                                    "animate__animated animate__fadeInLeftBig"
                                  }`}
                                >
                                  {getArabicRole(item.roles)}
                                </p>
                              </div>
                            )}
                            {item.buildingWalltName != "" &&
                            item.buildingWalltName != null ? (
                              <>
                                <div className="flex flex-col justify-end items-center">
                                  <p
                                    className="font-bold text-primaryColor55 text-xl"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    المحفظة الحالية
                                  </p>
                                  <p
                                    className={`text-black text-xl ${
                                      !isLoading &&
                                      "animate__animated animate__fadeInLeftBig"
                                    }`}
                                  >
                                    {item.buildingWalltName}
                                  </p>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </IonItem>

                      <IonItemOptions
                        side="end"
                        style={{ width: "auto", textAlign: "center" }}
                      >
                        <IonItemOption
                          color="none"
                          onClick={() => {
                            setItem(item);
                            presentEditBuildingWalltTypeModal();
                          }}
                          style={{ fontSize: "16px", width: "3.5em" }}
                        >
                          <IonIcon slot="top" icon={wallet}></IonIcon>
                          تعديل نوع المحفظة
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  </IonList>
                );
              })
            ) : (
              <>
                <div className="pt-5">
                  <p className="text-xl font-bold text-redColor mt-2">
                    عفوا لا يوجد مستخدمين
                  </p>
                </div>
                <div className="pt-5">
                  <img
                    src={emptySvg}
                    className="animate__animated animate__jello animate__infinite"
                    height={150}
                    width={150}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {currentCards.length > 0 && (
          <div
            className={`pagination-buttons pagination-controls ${
              !isLoading && "animate__animated animate__fadeInDown"
            }`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <IonButton
              onClick={handleFirstPage}
              style={{ maxWidth: "50px" }}
              disabled={currentPage === 1}
            >
              <IonIcon icon={firstPageSvg} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <IonIcon icon={arrowForward} />
            </IonButton>
            {getPageNumbers().map((pageNumber) => (
              <IonButton
                style={{ maxWidth: "40px" }}
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </IonButton>
            ))}
            <IonButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ maxWidth: "50px" }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              <IonIcon icon={lastPageSvg} />
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

const EditRolesModal = ({
  itemsData,
  onDismissEditRolesModal,
}: {
  itemsData: any;
  onDismissEditRolesModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleTypeValue, setRoleTypeValue] = useState<any>();
  const inputClassWithOutError =
    "block px-5 py-2.5  placeholder-paragraphColor w-full text-sm  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const [presentToast] = useIonToast();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }
  return (
    <IonContent>
      {isLoading ? (
        <Spinner
          isFull={false}
          cssExtra={"absolute top-2"}
          color={"primary"}
          size={"large"}
        />
      ) : null}
      <div className="wrapper">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap animate__animated animate__fadeInDown">
            يرجى اختيار الدور
          </p>
          <div className="relative w-full mt-4 px-4 animate__animated animate__fadeInDown">
            <IonList className={`${inputClassWithOutError}`}>
              <IonItem>
                <IonSelect
                  interface="action-sheet"
                  placeholder="يرجى الاختيار"
                  onIonChange={(e: any) => setRoleTypeValue(e.target.value)}
                >
                  <IonSelectOption value="operation">موظف</IonSelectOption>
                  <IonSelectOption value="user">مستخدم </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
            <label className="absolute text-sm font-bold top-2 z-10  bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
              يرجى الاختيار
            </label>
          </div>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 animate__animated animate__fadeInUp">
            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"primary"}
                text={"حفظ"}
                onClick={() => {
                  setIsLoading(true);
                  let roles: any = [];
                  let rolesArray = [];
                  rolesArray.push(roleTypeValue);
                  if (rolesArray?.some((r: any) => ["user"].includes(r))) {
                    roles.push({
                      roleName: "user",
                      isSelected: true,
                    });
                  } else {
                    roles.push({
                      roleName: "user",
                      isSelected: false,
                    });
                  }
                  if (rolesArray?.some((r: any) => ["operation"].includes(r))) {
                    roles.push({
                      roleName: "operation",
                      isSelected: true,
                    });
                  } else {
                    roles.push({
                      roleName: "operation",
                      isSelected: false,
                    });
                  }
                  console.log(roles);

                  EditUsersInRoleFunction(itemsData.id, roles, token)
                    .then((result) => {
                      setIsLoading(false);
                      if (result.data.responseCode == responseCodes.OK) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          "تم الحفظ بنجاح"
                        );
                        onDismissEditRolesModal(null, "confirm");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `لم يتم الحفظ..يرجى المحاولة :: ${result.data.responseMessage}`
                        );
                        onDismissEditRolesModal(null, "cancel");
                      }
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setErrorTitle("خطأ");
                      setErrorMessage("يرحي المحاوله مره ثانيه!");
                      openpErrorModal();
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"danger"}
                text={"اغلاق"}
                onClick={() => {
                  onDismissEditRolesModal(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};

const PresentEditBuildingWalltTypeModal = ({
  itemsData,
  onDismissPresentEditBuildingWalltType,
}: {
  itemsData: any;
  onDismissPresentEditBuildingWalltType: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [typeValue, setTypeValue] = useState<any>();
  const inputClassWithOutError =
    "block px-5 py-2.5  placeholder-paragraphColor w-full text-sm  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const [presentToast] = useIonToast();

  //model property
  const [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }
  return (
    <IonContent>
      {isLoading ? (
        <Spinner
          isFull={false}
          cssExtra={"absolute top-2"}
          color={"primary"}
          size={"large"}
        />
      ) : null}
      <div className="wrapper">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap animate__animated animate__fadeInDown">
            يرجى اختيار نوع المحفظة
          </p>
          <div className="relative w-full mt-4 px-4 animate__animated animate__fadeInDown">
            <IonList className={`${inputClassWithOutError}`}>
              <IonItem>
                <IonSelect
                  interface="action-sheet"
                  placeholder="يرجى الاختيار"
                  onIonChange={(e: any) => setTypeValue(e.target.value)}
                >
                  <IonSelectOption value="1">المحفظةالإفتراضية</IonSelectOption>
                  <IonSelectOption value="2">
                    المحفظة البرونزية{" "}
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>
            <label className="absolute text-sm font-bold top-2 z-10  bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
              يرجى الاختيار
            </label>
          </div>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 animate__animated animate__fadeInUp">
            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"حفظ"}
                onClick={() => {
                  setIsLoading(true);
                  EditTypeInBuildingsWalletFunction(
                    itemsData.id,
                    Number(typeValue),
                    token
                  )
                    .then((result) => {
                      setIsLoading(false);
                      if (result.data.result) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          "تم الحفظ بنجاح"
                        );
                        onDismissPresentEditBuildingWalltType(null, "confirm");
                      } else {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `لم يتم الحفظ..يرجى المحاولة :: ${result.data.responseMessage}`
                        );
                        onDismissPresentEditBuildingWalltType(null, "cancel");
                      }
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setErrorTitle("خطأ");
                      setErrorMessage("يرحي المحاوله مره ثانيه!");
                      openpErrorModal();
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"اغلاق"}
                onClick={() => {
                  onDismissPresentEditBuildingWalltType(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};

export default ChangeRoles;
