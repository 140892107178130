export const RequestOptions = (
  body?: any,
  returnCountOnly?: boolean,
  returnGeometry?: boolean,
  spatialRel?: string,
  geometry?: any,
  inSR?: string,
  outSR?: string,
  where?: string
) => {
  return {
    responseType: "json",
    body: body,
    query: {
      f: "json",
      returnCountOnly: returnCountOnly,
      outFields: "*",
      where: where,
      returnGeometry: returnGeometry,
      spatialRel: spatialRel,
      geometry: geometry,
      inSR: inSR,
      outSR: outSR,
    },
  };
};

export const NumberWithCommas = (x: any) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
