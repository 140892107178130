import { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonItem,
  useIonModal,
  useIonToast,
  IonTextarea,
} from "@ionic/react";
import { chevronBackOutline, map } from "ionicons/icons";
import { useHistory } from "react-router";
import ArrowLeft from "../../components/atoms/icons/arrowLeft";
import {
  ApprovalAddsFunction,
  getAdvertisementFilesFunction,
  getFullNameFunction,
} from "../../services/API";
import "./advertisement.styles.css";
import { useSelector } from "react-redux";
import Button from "../../components/atoms/button";
import { getAdvertisementByPropertyFunction } from "../../services/API";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import moment from "moment";
import Spinner from "../../components/atoms/spinner";
import { presentToastFunction } from "../../services/genericMethoud/generice";

const ApprovalAdvertisement = ({
  itemData,
  dismissApprovalAdvertisementModal,
}: {
  itemData: any;
  dismissApprovalAdvertisementModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [attachments, setAttachments] = useState<any>([]);
  const [property, setProperty] = useState<any>(itemData);
  const [userName, setUserName] = useState<string>("");
  const [image, setImage] = useState<any>("");
  const [video, setVideo] = useState<any>("");
  const [notesChange, SetNotesChange] = useState<any>("");
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [isLoading, setIsLoading] = useState(false);
  const inputClassWithError =
    "input-error block px-5 py-2.5 font-bold placeholder-paragraphColor w-full bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const inputClassWithOutError =
    "block px-5 py-2.5 font-bold placeholder-paragraphColor w-full  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  const history = useHistory();

  //model property
  const [presentIamgeModal, onCloseImageDismiss] = useIonModal(
    ModalImagesExample,
    {
      image,
      onCloseImageDismiss: (data: string, role: string) =>
        onCloseImageDismiss(data, role),
    }
  );

  function openpImageModal() {
    presentIamgeModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  //model video property
  const [presentVideoModal, onCloseVideoDismiss] = useIonModal(
    ModalVideoExample,
    {
      video,
      onCloseVideoDismiss: (data: string, role: string) =>
        onCloseVideoDismiss(data, role),
    }
  );

  function openpVideoModal() {
    presentVideoModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.7,
      // backdropBreakpoint: 0.7,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  //model property
  const [presentRejectAddModal, onCloseRejectAdd] = useIonModal(RejectAdd, {
    ad_Id: property.ad_Id,
    rejectNotes: notesChange,
    token: token,
    Parcel_Cod: property.parcel_Cod,
    onCloseRejectAdd: (data: string, role: string) =>
      onCloseRejectAdd(data, role),
  });

  function openpRejectAddModal() {
    presentRejectAddModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getAdvantmentFunc();
          dismissApprovalAdvertisementModal();
        }
      },
    });
  }

  const [presentAcceptAddModal, onCloseAcceptAddModal] = useIonModal(
    AcceptAddModal,
    {
      ad_Id: property.ad_Id,
      token: token,
      Parcel_Cod: property.parcel_Cod,
      onCloseAcceptAddModal: (data: string, role: string) =>
        onCloseAcceptAddModal(data, role),
    }
  );

  function openpAcceptAddModal() {
    presentAcceptAddModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getAdvantmentFunc();
          dismissApprovalAdvertisementModal();
        }
      },
    });
  }

  function getAdvantmentFunc() {
    getAdvertisementByPropertyFunction(property.parcel_Cod, property.ad_Id)
      .then((returnedData) => {
        setProperty(returnedData.data[0]);
        setAttachments(returnedData.data[0].attachFiles);
        getFullNameFunction(property.authorization_number).then(
          (result: any) => {
            setUserName(result.data);
          }
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    getAdvantmentFunc();
  }, []);
  return (
    <IonPage>
      <IonContent>
        <div
          id="ii"
          className="w-full h-full relative flex flex-col justify-start items-center"
        >
          {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}

          <div className="w-full grid grid-cols-3">
            <div className="col-span-1 "></div>
            <div className="col-span-1  py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-primaryColor100 text-2xl">
                {property.landType.name}
              </p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  dismissApprovalAdvertisementModal(null, "cancle");
                }}
                className="bg-white flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-primaryColor100"
                />
              </button>
            </div>
          </div>

          <div
            className="flex flex-row justify-start items-center"
            onClick={() => {
              window.location.assign(`/app/${property.parcel_Cod}`);
            }}
          >
            <img src={map} className="w-12 rounded" />
            <p className="font-bold text-primaryColor100 text-base mr-4">
              الذهاب الى الموقع على الخريطة
            </p>
          </div>
          <div className="flex flex-row justify-start items-center">
            <ArrowLeft />
          </div>

          <div className="w-full flex flex-col justify-start items-center px-8 pb-8">
            {property.senMessFRemoveAddSevenDay ? (
              <div className="col-span-1 py-6 w-full flex flex-row justify-start items-center mt-2">
                <p className="font-bold text-redColor text-1xl bg-whiteBlueColor">
                  الاعلان قارب على الانتهاء
                </p>
              </div>
            ) : (
              ""
            )}

            <div className="col-span-1 py-6 w-full flex flex-row justify-start items-center mt-2">
              <p className="font-bold text-primaryColor100 text-2xl">
                معلومات العقار
              </p>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    عنوان الإعلان :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.addTitle}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    رقم الاعلان :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.ad_Id}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    إسم المنطقة :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.district_Name}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    إسم المدينة :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.city_Name}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    إسم الحي :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.neighbourhood_Name}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    نوع العقار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.property_Type}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    نوع إستخدام العقار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.using_For}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    نوع الأعلان الرئيسي :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.the_main_type_of_ad}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    نوع الأعلان الفرعي :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.ad_subtype}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    رقم العقار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.land_Number}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    عدد الوحدات :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.number_Of_Units}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    رقم الوحدة :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.unit_Number}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    رقم الدور :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.floor_Number}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    المساحة :
                  </p>
                </div>
                <div>
                  <p className="font-bold text-black text-xl">
                    {property.the_Space} - متر مربع
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    واجهة- اتجاه العقار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.real_Estate_Facade}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    {" "}
                    اطوال العقار (طول / عرض) :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.property_limits_and_lenghts}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    إسم الشارع :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.street_Name}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    عرض الشارع :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.street_Width}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    عمر العقار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.construction_Date}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    {" "}
                    وصف العقار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.ad_description}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    مؤثث (مفروش) :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.furnished}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    عدد الغرف :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.rooms_Number}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    نوع الغرف :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.rooms_Type}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    التكيف :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.air_Condition}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    المطبخ :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.kitchen}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    توافر المواقف :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.availability_of_Parking}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              {property.availability_of_Parking == "نعم" ? (
                <>
                  <div className="w-full flex flex-row justify-between items-center">
                    <div>
                      <p
                        className="font-bold text-primaryColor100 text-xl"
                        style={{ backgroundColor: "#FAEBD6" }}
                      >
                        عدد المواقف :
                      </p>
                    </div>
                    <div>
                      <p
                        className="font-bold text-black text-xl mr-2"
                        style={{ textAlign: "justify" }}
                      >
                        {property.number_of_parking}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full border-paragraphColor border-[0.1px] my-4" />
                </>
              ) : null}

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    توافر المصاعد :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.availability_of_elevators}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              {property.availability_of_elevators == "نعم" ? (
                <>
                  <div className="w-full flex flex-row justify-between items-center">
                    <div>
                      <p
                        className="font-bold text-primaryColor100 text-xl"
                        style={{ backgroundColor: "#FAEBD6" }}
                      >
                        عدد المصاعد :
                      </p>
                    </div>
                    <div>
                      <p
                        className="font-bold text-black text-xl mr-2"
                        style={{ textAlign: "justify" }}
                      >
                        {property.number_of_elevators}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full border-paragraphColor border-[0.1px] my-4" />
                </>
              ) : null}

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    مرافق :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.facilities}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    سعر البيع :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.selling_Price} ر.س
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    سعر الإيجار :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.rental_Price} ر.س
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    سعر متر البيع :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.selling_Meter_Price} ر.س
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    هل يوجد رهن او قيد للعقارر(الارض) :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.is_there_amortgage}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    النزاعات القائمة على العقار(الارض) :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.rights_and_obligations_over}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    مشكلات العقار التي تسبب في خفض قيمته :
                  </p>
                </div>
                <div>
                  <p className="font-bold text-black text-xl">
                    {property.information_that_may_affect}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    خط العرض :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.lattitude}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    خط الطول :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.longitude}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    جهة المعلن :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.advertiser_category}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    صفة المعلن :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.advertiser_character}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-col justify-center items-center">
                <div className="w-full flex flex-row justify-between items-center">
                  <div>
                    <p
                      className="font-bold text-primaryColor100 text-xl"
                      style={{ backgroundColor: "#FAEBD6" }}
                    >
                      أسم المعلن :
                    </p>
                  </div>
                  <div>
                    <p
                      className="font-bold text-black text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      {userName}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    البريد الالكتروني للمعلن :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {property.advertiser_email}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-col justify-center items-center">
                <div className="w-full flex flex-row justify-between items-center">
                  <div>
                    <p
                      className="font-bold text-primaryColor100 text-xl"
                      style={{ backgroundColor: "#FAEBD6" }}
                    >
                      رقم جوال المعلن :
                    </p>
                  </div>
                  <div>
                    <p
                      className="text-primaryColor100 font-bold text-xl mr-2"
                      style={{ textAlign: "justify" }}
                    >
                      <a
                        href={`tel:0${property.advertiser_mobile_number}`}
                        style={{ textDecorationLine: "underline" }}
                      >
                        0{property.advertiser_mobile_number}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    تاريخ النشر :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {moment(property.advertisement_publication_date).format(
                      "LLL"
                    )}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    تاريح تحديث الإعلان :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {moment(property.ad_update_date).format("LLL")}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />

              <div className="w-full flex flex-row justify-between items-center">
                <div>
                  <p
                    className="font-bold text-primaryColor100 text-xl"
                    style={{ backgroundColor: "#FAEBD6" }}
                  >
                    تاريخ إنتهاء الإعلان :
                  </p>
                </div>
                <div>
                  <p
                    className="font-bold text-black text-xl mr-2"
                    style={{ textAlign: "justify" }}
                  >
                    {moment(property.ad_expiration).format("LLL")}
                  </p>
                </div>
              </div>
              <hr className="w-full border-paragraphColor border-[0.1px] my-4" />
            </div>

            <div className="col-span-1 w-full flex flex-row justify-start items-center mt-8">
              <p
                className="font-bold text-primaryColor100 text-2xl"
                style={{ backgroundColor: "#FAEBD6" }}
              >
                الملفات المرفقة :
              </p>
            </div>
            {attachments.length == 0 ? (
              <div className="w-full bg-bkColor rounded border-[1px] border-paragraphColor flex flex-row justify-center items-center py-4 mt-4">
                <p className="font-medium text-paragraphColor text-sm">
                  لا توجد ملفات على هذا العقار !
                </p>
              </div>
            ) : (
              <div className="w-full flex flex-col justify-center items-center">
                {attachments.map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-row justify-between items-center"
                    >
                      <div>
                        <p className="font-bold text-black text-xl">
                          {item.name}
                        </p>
                      </div>
                      <div>
                        <Button
                          size="small"
                          text="عرض"
                          theme={"primary"}
                          onClick={() => {
                            setIsLoading(true);
                            getAdvertisementFilesFunction(
                              item,
                              property.parcel_Cod,
                              token
                            )
                              .then((returnedData: any) => {
                                debugger;
                                if (returnedData.data.isImage == true) {
                                  setImage(returnedData.data.file);
                                  openpImageModal();
                                  setIsLoading(false);
                                } else {
                                  setVideo(returnedData.data.file);
                                  openpVideoModal();
                                  setIsLoading(false);
                                }
                              })
                              .catch((e: any) => {
                                console.log(e);
                                setIsLoading(false);
                              });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="w-full bg-bkColor rounded border-[1px] border-paragraphColor flow-root  py-2 px-2 mt-4  mb-3 shadow-md">
            <div className="relative w-full  mt-2">
              <IonItem>
                <IonTextarea
                  inputmode="text"
                  required={true}
                  autofocus={true}
                  placeholder="ملاحظات"
                  autoGrow={true}
                  onIonChange={(e: any) => SetNotesChange(e.target.value)}
                  className={
                    notesChange
                      ? `${inputClassWithOutError}`
                      : `${inputClassWithError}`
                  }
                ></IonTextarea>
              </IonItem>
              <label className="absolute text-1xl font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                ملاحظات الإعلان في حالة الرفض
              </label>

              <div className="py-5 float-right mr-2" style={{ width: "45%" }}>
                <Button
                  theme={"primary"}
                  text={"موافق"}
                  size={"big"}
                  onClick={() => {
                    openpAcceptAddModal();
                  }}
                />
              </div>

              <div className="py-5 float-left" style={{ width: "45%" }}>
                <Button
                  theme={"danger"}
                  text={"رفض"}
                  size={"big"}
                  onClick={() => {
                    console.log(notesChange);

                    openpRejectAddModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const ModalImagesExample = ({
  image,
  onCloseImageDismiss,
}: {
  image: any;
  onCloseImageDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>عرض الصورة</IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseImageDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <img src={image} className="w-full " />
      </IonContent>
    </IonContent>
  );
};

const ModalVideoExample = ({
  video,
  onCloseVideoDismiss,
}: {
  video: any;
  onCloseVideoDismiss: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>عرض الفيديو</IonTitle>
          <IonButtons slot="end">
            <Button
              size={"small"}
              text="اغلاق"
              theme={"danger"}
              onClick={() => onCloseVideoDismiss(null, "cancel")}
            ></Button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <video autoPlay controls src={video} className="w-full"></video>
      </IonContent>
    </IonContent>
  );
};

const RejectAdd = ({
  rejectNotes,
  ad_Id,
  token,
  onCloseRejectAdd,
}: {
  rejectNotes: any;
  ad_Id: any;
  token: any;
  Parcel_Cod: any;
  onCloseRejectAdd: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [presentToast] = useIonToast();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IonContent>
      <div className="wrapper">
        <div className="flex flex-col justify-start items-center py-6">
          {isLoading ? (
            <Spinner
              color={"primary"}
              cssExtra={"absolute top-2"}
              size={"large"}
            />
          ) : null}
          <p className="font-bold text-primaryColor100 text-2xl">
            هل انت متأكد من الرفض ؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  setIsLoading(true);
                  if (rejectNotes.trim().length > 0) {
                    ApprovalAddsFunction(ad_Id, false, rejectNotes, token)
                      .then((res: any) => {
                        if (res.data.result) {
                          presentToastFunction(
                            presentToast,
                            "top",
                            "success",
                            `تم الإرسال بنجاح`
                          );
                          setIsLoading(false);
                          onCloseRejectAdd(null, "confirm");
                        }
                      })
                      .catch((err) => {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `خطأ..يرجى المحاولة :: ${err}`
                        );
                        setIsLoading(false);
                        onCloseRejectAdd(null, "cancel");
                      });
                  } else {
                    presentToastFunction(
                      presentToast,
                      "top",
                      "danger",
                      `يرجى ادخال سبب الرفض`
                    );
                    setIsLoading(false);
                    onCloseRejectAdd(null, "cancel");
                  }
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onCloseRejectAdd(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const AcceptAddModal = ({
  ad_Id,
  token,
  onCloseAcceptAddModal,
}: {
  rejectNotes: any;
  ad_Id: any;
  token: any;
  Parcel_Cod: any;
  onCloseAcceptAddModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [presentToast] = useIonToast();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IonContent>
      <div className="wrapper">
        {isLoading ? (
          <Spinner
            color={"primary"}
            cssExtra={"absolute top-2"}
            size={"large"}
          />
        ) : null}
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl">
            هل انت متأكد من الموافقة ؟
          </p>
          <div className="mt-6 w-full grid grid-cols-2 gap-6 ">
            <div className="flex flex-row justify-end">
              <Button
                size={"small"}
                theme={"danger"}
                text={"نعم"}
                onClick={() => {
                  setIsLoading(true);
                  ApprovalAddsFunction(ad_Id, true, "", token)
                    .then((res: any) => {
                      if (res.data.result) {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `تم الإرسال بنجاح`
                        );
                        setIsLoading(false);
                        onCloseAcceptAddModal(null, "confirm");
                      }
                    })
                    .catch((err) => {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "danger",
                        `خطأ..يرجى المحاولة :: ${err}`
                      );
                      setIsLoading(false);
                      onCloseAcceptAddModal(null, "cancel");
                    });
                }}
              />
            </div>
            <div className="flex flex-row justify-start">
              <Button
                size={"small"}
                theme={"primary"}
                text={"لا"}
                onClick={() => {
                  onCloseAcceptAddModal(null, "cancel");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default ApprovalAdvertisement;
