import {
  IonButton,
  IonContent,
  IonIcon,
  isPlatform,
  useIonToast,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CreateCharge } from "../../services/API";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import Spinner from "../../components/atoms/spinner";
import { chargerDates } from "./chargerDates";
import visaSvg from "../../images/visa.svg";
import mastercardSvg from "../../images/mastercard.svg";
import applePaySvg from "../../images/apple-pay.svg";

import {
  ApplePayButton,
  ThemeMode,
  Scope,
  Environment,
  Locale,
  ButtonType,
  Edges,
} from "@tap-payments/apple-pay-button";

const ServiceDetailsModal = ({
  itemsData,
  onDismissServiceDetailsModal,
}: {
  itemsData: any;
  onDismissServiceDetailsModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  let { token } = useSelector((state: any) => state.reducer);
  const { userInfo } = useSelector((state: any) => state.reducer);
  const [presentToast] = useIonToast();

  let isOpenApplePay = false;
  // isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad");

  const [isApplePayReady, setIsApplePayReady] = useState(false);

  function CreateInvoice(idService: any) {
    setIsLoading(true);
    CreateCharge(idService, token).then((result: any) => {
      if (result.status == 200) {
        console.log("aaaaaaaaaaaaaa==>", result);
        if (result.data.url) {
          window.location.href = result.data.url;
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `عفوا حدث خطأ اثناء تحضير عملية الدفع`
          );
        }
      }
      // if (!isOpenApplePay) {
      //   setIsLoading(false);
      // }
    });
  }

  const applePayButtonRef = useRef<HTMLDivElement>(null);

  const handleFirstButtonClick = () => {
    if (applePayButtonRef.current) {
      // Trigger click on the ApplePayButton wrapper
      const button = applePayButtonRef.current.querySelector("button");
      if (button) {
        button.click();
      }
    }
  };

  useEffect(() => {
    console.log(
      isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")
    );
    // if (isOpenApplePay) {
    //   setIsLoading(true);
    // }
  }, []);

  // useEffect(() => {
  //   if (isOpenApplePay && !isApplePayReady) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [isApplePayReady]);

  return (
    <IonContent>
      <div className="wrapper animate__animated animate__fadeInDown">
        <div className="flex flex-col justify-start items-center py-6">
          <p className="font-bold text-primaryColor100 text-2xl">
            الخدمة المختارة
          </p>
          <div className="px-10 py-5 bg-white rounded-lg shadow-lg">
            {isLoading ? (
              <Spinner
                isFull={false}
                cssExtra={"absolute top-2"}
                color={"primary"}
                size={"large"}
              />
            ) : null}

            <div className="mb-8 text-xl">
              <table className="border-2 text-center">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-2">الخدمة</th>
                    <th className="py-2 px-4 border-2">العدد</th>
                    <th className="py-2 px-4 border-2">المدة</th>
                    <th className="py-2 px-4 border-2">السعر</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-2">{itemsData.name}</td>
                    <td className="py-2 px-4 border-2">1</td>
                    <td className="py-2 px-4 border-2">
                      {itemsData.chargerDates == chargerDates.yearly
                        ? "سنوية"
                        : itemsData.chargerDates == chargerDates.Monthly
                        ? "شهرية"
                        : "مرة واحدة لكل عملية شراء"}
                    </td>
                    <td className="py-2 px-4 border-2 text-tan">
                      {itemsData.amount} ريال
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex justify-end">
              <div className="text-right">
                <h3 className="text-2xl font-semibold text-primaryColor100">
                  الإجمالي
                </h3>
                <p className="text-xl font-bold text-center text-tan">
                  {itemsData.amount} ريال
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-start" dir="ltr">
              <IonButton
                size={"default"}
                color={"light"}
                className="border border-primaryColor100 color-white rounded-lg mt-5 w-full mr-2 text-xl"
                onClick={() => {
                  CreateInvoice(itemsData.id);
                }}
              >
                Pay with <IonIcon icon={visaSvg} className="ml-2 text-6xl" />{" "}
                <IonIcon icon={mastercardSvg} className="ml-2 text-6xl" />
                <IonIcon icon={applePaySvg} className="ml-2 text-6xl" />
              </IonButton>
            </div>
            {isOpenApplePay && (
              <>
                <div
                  onClick={handleFirstButtonClick}
                  className="w-full flex flex-row justify-center items-center border border-primaryColor100 rounded-lg text-xl mt-5"
                  dir="ltr"
                >
                  Pay with
                  {/* <IonButton
                    size={"default"}
                    color={"light"}
                    className="border border-primaryColor100 color-white rounded-lg mt-5 w-full mr-2 text-xl"
                    onClick={() => {
                      setOpenApplePay(true);
                    }}
                  >
                    Pay with{" "}
                    <IonIcon
                      icon={applePaySvg}
                      className="ml-2 text-6xl text-primaryColor100"
                    />
                  </IonButton>*/}
                  <div
                    className="w-20 text-center mr-14"
                    ref={applePayButtonRef}
                  >
                    <ApplePayButton
                      // publicKey="pk_test_eNo23yLY8RJA5nWmE9KIhjuq"
                      publicKey="pk_live_uox9J8DGkwt6BYr15ijIl2SF"
                      environment={Environment.Production}
                      debug
                      merchant={{
                        domain: "thki.sa",
                        id: "6116136",
                      }}
                      transaction={{
                        amount: itemsData.amount,
                        currency: "SAR",
                      }}
                      scope={Scope.TapToken}
                      acceptance={
                        {
                          // supportedBrands: [
                          //   SupportedNetworks.Mada,
                          //   SupportedNetworks.Visa,
                          //   SupportedNetworks.MasterCard
                          // ],
                          // supportedCards: ["DEBIT", "CREDIT"],
                          // supportedCardsWithAuthentications: ["3DS", "EMV"],
                        }
                      }
                      customer={{
                        //id: `cus_${userInfo.userName.split(".")[0]}`,
                        name: [
                          {
                            lang: Locale.AR,
                            first: userInfo.fullName.split(" ")[0],
                            last: userInfo.fullName.split(" ").pop() || "", // Fallback if no last name
                            middle: userInfo.fullName.split(" ")[1] || "", // Fallback if no middle name
                          },
                        ],
                        contact: {
                          email: userInfo.email,
                          phone: {
                            countryCode: "00966",
                            number: userInfo.phoneNumber || "",
                          },
                        },
                      }}
                      interface={{
                        locale: Locale.AR,
                        theme: ThemeMode.LIGHT,
                        type: ButtonType.PAY,
                        edges: Edges.CURVED,
                      }}
                      onCancel={() => {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `تم الغاء الدفع من خلال apple pay`
                        );
                        onDismissServiceDetailsModal(
                          "CancelApplePay",
                          "CancelApplePay"
                        );
                      }}
                      onError={(err) => {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `حدث خطأ ${err}`
                        );
                        onDismissServiceDetailsModal(err, "ErrorApplePay");
                      }}
                      onSuccess={async (token: any) => {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "success",
                          `تم الدفع بنجاح من خلال apple pay`
                        );
                        onDismissServiceDetailsModal(token, "SuccessApplePay");
                      }}
                      onReady={() => {
                        setIsApplePayReady(true);
                      }}
                      onClick={() => {
                        presentToastFunction(
                          presentToast,
                          "top",
                          "warning",
                          `جاري التحويل الي خدمة الدفع من apple pay`
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};
export default ServiceDetailsModal;
