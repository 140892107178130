const initialState = {
  authenticationState: "unAuthenticated",
  userInfo: {},
  otherData: {},
  token: "",
  onBoardingSkippedStatus: false,
  signInAsVisitor: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "setToken":
      return {
        ...state,
        token: action.payload,
      };
    case "changeToAuthenticated":
      return {
        ...state,
        authenticationState: action.payload,
      };
    case "changeToUnAuthenticated":
      return {
        ...state,
        authenticationState: action.payload,
      };
    case "setUserInfo":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "setMapView":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "setOtherData":
      return {
        ...state,
        otherData: action.payload,
      };
    case "setOnBoardingSkippedStatus":
      return {
        ...state,
        onBoardingSkippedStatus: action.payload,
      };
    case "setSignInAsVisitor":
      return {
        ...state,
        signInAsVisitor: action.payload,
      };
    case "signOut":
      return {
        ...state,
        userInfo: action.payload.userInfo,
          authenticationState: action.payload.authenticationState,
          token: action.payload.token,
          onBoardingSkippedStatus: action.payload.onBoardingSkippedStatus,
          signInAsVisitor: action.payload.onBoardingSkippedStatus,
      };

    default:
      return state;
  }
};

export default reducer;