import { useState, useEffect, createRef } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonSearchbar,
  IonItem,
  IonList,
  IonButton,
} from "@ionic/react";
import { arrowBack, arrowForward, chevronBackOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { GetAllPaymentOrders } from "../../services/API";
import Spinner from "../../components/atoms/spinner";
import emptySvg from "../../images/empty.svg";
import lastPageSvg from "../../images/lastPage.svg";
import firstPageSvg from "../../images/firstPage.svg";
import Joyride from "react-joyride";
import { chargerDates } from "../PaymentRedirect/chargerDates";

const AllPaymentsModal = ({
  disAllPaymentsModal,
}: {
  disAllPaymentsModal: (
    data?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const { token } = useSelector((state: any) => state.reducer);
  const [data, setData] = useState<any>([]);
  const [actualData, setActualData] = useState<any>([]);
  const [calculatedPayments, setCalculatedPayments] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const handleAllPayments = (filter: any) => {
    if (filter) {
      const newAdds = actualData.filter(function (str: any) {
        return (
          str.user.fullName.toString().includes(filter) ||
          str.createdDate.split("T")[0].toString().includes(filter)
        );
      });
      setData(newAdds);
      getSumPayments(newAdds);
      setCurrentPage(1);
    } else {
      getAllPaymentsData();
    }
  };

  function getAllPaymentsData() {
    setIsLoading(true);
    GetAllPaymentOrders(token)
      .then((returnedData) => {
        console.log("getAllPaymentsData==>", returnedData.data);
        setData(returnedData.data);
        getSumPayments(returnedData.data);
        setActualData(returnedData.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  //#endregion
  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".search-bar",
      content: "حقل البحث: يستخدم للبحث عن عمليات الدفع.",
      placement: "bottom" as const,
      disableBeacon: true,
    },
    {
      target: ".list",
      content: "قائمة عمليات الدفع: يتم فيه عرض قائمة من عمليات الدفع.",
    },
    {
      target: ".total",
      content:
        "اجمالي المبلغ المدفوع: يتم فيه عرض اجمالي المبلغ المدفوع من عمليات الدفع الناجحة.",
      placement: "top" as const,
    },
    {
      target: ".pagination-controls",
      content:
        "التحكم في الصفحات: يمكنك التنقل بين الصفحات ومن الصفحة السابقة إلى الصفحةالتالية.",
      placement: "top" as const,
    },
  ]);

  useEffect(() => {
    contentRef.current?.scrollToTop(500);

    let allPaymentsOpenedFirstTime = window.localStorage.getItem(
      "allPaymentsOpenedFirstTime"
    );

    if (allPaymentsOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }

    getAllPaymentsData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 2;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data?.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(data?.length / cardsPerPage);

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    let startPage: any, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than maxPagesToShow total pages so calculate start and end pages
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        // Current page near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        // Current page near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  function getSumPayments(dataToSumAt: any = data) {
    setIsLoading(true);
    setCalculatedPayments(
      dataToSumAt.reduce((accumulator: any, current: any) => {
        if (current.status == "CAPTURED" || current.status === "already paid") {
          return accumulator + current.amount;
        }
        return accumulator;
      }, 0)
    );
    setIsLoading(false);
  }
  const contentRef = createRef<HTMLIonContentElement>();

  return (
    <IonPage>
      {!isLoading && run && (
        <Joyride
          steps={steps}
          run={run}
          continuous={run}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          showSkipButton={false}
          hideBackButton={true}
          hideCloseButton={true}
          disableScrolling={false}
          showProgress={true}
          locale={{
            back: "الرجوع",
            close: "اغلاق",
            last: "إنهاء",
            next: "التالي",
            skip: "تخطي",
          }}
          callback={(data) => {
            console.log(data);
            if (data.status == "finished" || data.action == "reset") {
              window.localStorage.setItem("allPaymentsOpenedFirstTime", "true");
              setRun(false);
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <IonContent ref={contentRef}>
        {isLoading ? (
          <Spinner isFull={true} color={"primary"} size={"large"} />
        ) : null}
        <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
          <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
            <div className="col-span-1"></div>
            <div className="col-span-1 py-4 flex flex-row justify-center items-center">
              <p className="font-bold text-white text-2xl whitespace-nowrap">
                عمليات الدفع لدي ذكي
              </p>
            </div>
            <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  disAllPaymentsModal(null, "cancel");
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-white"
                />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-center px-4">
            <div className="w-full flex flex-col justify-start items-center px-2 search-bar">
              <IonSearchbar
                placeholder="البحث بإسم العميل والتاريخ"
                onIonChange={(ev) => handleAllPayments(ev.detail.value)}
              ></IonSearchbar>
            </div>
            {currentCards.length > 0 ? (
              currentCards.map((item: any, index: any) => {
                return (
                  <IonList className="list slide">
                    <IonItem lines="none" key={index}>
                      <div
                        style={{
                          border: "1px solid #e1e1e1",
                          width: "19em",
                          borderRadius: "10px",
                        }}
                        className="shadow-md drop-shadow-md w-full flex flex-row justify-between items-center p-4 mb-4 list animate__animated animate__fadeInUp"
                      >
                        <div className="h-full flex flex-col col-span-3 justify-start items-start">
                          <p className="font-bold text-primaryColor100 text-xl">
                            <span>الإسم: </span>
                            {item.paymentType.name}
                          </p>
                          <p className="font-bold text-primaryColor100 text-xl">
                            <span>مدة الخدمة: </span>
                            {item.paymentType.chargerDates ==
                            chargerDates.yearly
                              ? "سنوية"
                              : item.paymentType.chargerDates ==
                                chargerDates.Monthly
                              ? "شهرية"
                              : "مرة واحدة لكل عملية شراء"}
                          </p>

                          {item.user.fullName && (
                            <div className="flex flex-row justify-start items-center mt-1">
                              <p className="text-lg font-bold text-primaryColor100">
                                اسم العميل:{" "}
                                <span className="text-black font-normal">
                                  {item.user.fullName}
                                </span>
                              </p>
                            </div>
                          )}

                          {item.createdDate && (
                            <div className="flex flex-row justify-start items-center mt-1">
                              <p className="text-lg font-bold text-primaryColor100">
                                تاريخ الشراء:{" "}
                                <span className="text-black font-normal">
                                  {item.createdDate
                                    .split("T")[0]
                                    .split("-")
                                    .reverse()
                                    .join("-")}
                                </span>
                              </p>
                            </div>
                          )}

                          {item.status && (
                            <div className="flex flex-row justify-start items-center mt-1">
                              <p className="text-lg font-bold text-primaryColor100">
                                حالة الدفع:{" "}
                                <span className="text-black font-normal">
                                  {item.status == "CAPTURED" ||
                                  item.status == "already paid"
                                    ? "تم الدفع بنجاح"
                                    : item?.status == "INITIATED"
                                    ? "لم يتم استكمال عملية الدفع"
                                    : "لم يتم الدفع"}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>

                        <div className="h-full flex flex-col col-span-2 justify-center items-end">
                          {item.amount && (
                            <div className="flex flex-col justify-end items-center">
                              <p className="font-bold text-primaryColor100 text-xl">
                                السعر
                              </p>
                              <p className="text-tan text-xl">{item.amount}</p>
                              <p className="text-tan text-xl">ريال</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </IonItem>
                  </IonList>
                );
              })
            ) : (
              <>
                <div className="pt-5">
                  <p className="text-xl font-bold text-redColor mt-2">
                    عفوًا لا توجد بيانات
                  </p>
                </div>
                <div className="pt-5">
                  <img
                    src={emptySvg}
                    className="animate__animated animate__jello animate__infinite"
                    height={150}
                    width={150}
                  />
                </div>
              </>
            )}
            {calculatedPayments && currentCards.length > 0 && (
              <div
                style={{
                  border: "1px solid #e1e1e1",
                  width: "19em",
                  borderRadius: "10px",
                }}
                className="shadow-md drop-shadow-md w-full flex flex-row justify-start items-center p-4 mb-4 text-xl font-bold text-primaryColor100 total animate__animated animate__fadeInUp"
              >
                إجمالي العمليات الناجحة:{" "}
                <span className="text-tan font-normal mr-1">
                  {calculatedPayments} ريال
                </span>
              </div>
            )}
          </div>
        </div>
        {currentCards.length > 0 && (
          <div
            className={`pagination-buttons pagination-controls ${
              !isLoading && "animate__animated animate__fadeInDown"
            }`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <IonButton
              onClick={handleFirstPage}
              style={{ maxWidth: "50px" }}
              disabled={currentPage === 1}
            >
              <IonIcon icon={firstPageSvg} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <IonIcon icon={arrowForward} />
            </IonButton>
            {getPageNumbers().map((pageNumber) => (
              <IonButton
                style={{ maxWidth: "40px" }}
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </IonButton>
            ))}
            <IonButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ maxWidth: "50px" }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
            <IonButton
              style={{ maxWidth: "50px" }}
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
            >
              <IonIcon icon={lastPageSvg} />
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AllPaymentsModal;
