import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonModal,
  IonSelectOption,
  IonSelect,
  useIonModal,
  useIonToast,
  IonItem,
  IonList,
  IonInput,
  IonButton,
  isPlatform,
  IonTitle,
  IonHeader,
  IonLabel,
  IonSearchbar,
  IonToolbar,
  IonPopover,
  IonAvatar,
  IonImg,
  useIonAlert,
} from "@ionic/react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import {
  receiptOutline,
  navigate,
  searchOutline,
  settingsOutline,
  eye,
  caretDownCircleOutline,
  add,
  bookmarks,
  chevronForwardOutline,
  chevronBackOutline,
  newspaperOutline,
} from "ionicons/icons";
import Spinner from "../../components/atoms/spinner";
import noImageSvg from "../../images/noImage.svg";
import googleMapsSvg from "../../images/googleMaps.svg";
// import Logo from "../../images/logo.svg";
import Logo from "../../images/logo.png";
import userSvg from "../../images/user.svg";
import realEstateBrokerSvg from "../../images/realEstateBroker.svg";
import navSvg from "../../assets/uicons/svg/fi-ts-gps-navigation.svg";
import close from "../../images/close.svg";
import FalLogo from "../../images/falLogo.svg";
import menuImage from "../../images/menu.svg";
import LegendImage from "../../images/Legned.png";
import LoginImage from "../../images/login.svg";
import walletSvg from "../../images/wallet.svg";
import whatsappSvg from "../../images/whatsapp.svg";
import { Geolocation, Position } from "@capacitor/geolocation";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import ModalSheet from "../../components/atoms/modalSheet";
import Polygon from "@arcgis/core/geometry/Polygon";
import Point from "@arcgis/core/geometry/Point";
import Request from "@arcgis/core/request";
import "./index.css";
import {
  CreateBuildingsWalletFunc,
  createFavoriteLandFunction,
  createLogosDataFunction,
  deleteLogosDataFunction,
  getAPPVersionsFunction,
  GetBuildingsWalletFunc,
  getFavoriteLandByParcCodeFunction,
  getLogosDataFunction,
  getNhcIntegrationByAdLicenseNumberFunction,
  getNhcIntegrationByObjIdFunction,
  onFileUploadValidationSize,
} from "../../services/API";
import ErrorModal from "../../components/molecules/errorModal";
import Button from "../../components/atoms/button";
import showOnGoogleMapsFunction from "../../services/others/showOnGoogleMaps";
import shareOnWhatsAppFunction from "../../services/others/shareOnWhatsApp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropertyTypesModal from "../../components/molecules/propertyTypesModal";
import Profile from "../profile";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import Property from "../property";
import {
  arcgisServicesLandUrl,
  arcgisServicesBaseMinistryUrl,
  arcgisServicesBaseUrl,
  vectorTileServerMinistryUrl,
  arcgisServicesLandFeatureUrl,
  routeParamsApiKey,
} from "../../configs/arcgisServices";
import Query from "@arcgis/core/rest/support/Query";
import SccessMessage from "../../components/atoms/tosterMessage";
import { setSignInAsVisitor } from "../../services/redux/actions";
import {
  favoriteLandSymbol,
  pictureSymbolFunction,
  requestOptions,
  selectedSymbol,
} from "../../services/genericMethoud/gisMethouds";
import { PointLogosDTO } from "../../services/models/pointLogoDTO";
import {
  convertToBase64,
  getColorAdvertisementType,
  handleStyleButtonByLandTypes,
  presentToastFunction,
} from "../../services/genericMethoud/generice";
import { createBrowserHistory } from "history";
import { SocialSharing } from "@ionic-native/social-sharing";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer.js";
import NhcAdvertisement from "../nhcAdvertisement";
import QRCode from "react-qr-code";
import AddPropertyNhcIntegration from "../nhcIntegration";
import EstateBrokersModal from "../estateBrokers/estateBrokersModal";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import { LocalNotifications } from "@capacitor/local-notifications";
import TrackVM from "@arcgis/core/widgets/Track/TrackViewModel.js";
import Track from "@arcgis/core/widgets/Track.js";
import { Device } from "@capacitor/device";

import RouteParameters from "@arcgis/core/rest/support/RouteParameters.js";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet.js";
import * as route from "@arcgis/core/rest/route.js";
import TrackingModals from "../tracking/TrackingModals";
import { AndoridVersion, IOSVersion } from "../../configs/GenericConfig";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import DocsReportModel from "../profile/docsReportModel";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol.js";
import Color from "@arcgis/core/Color.js";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer.js";
import { NumberWithCommas } from "../../configs/GenericFunc";
import BuildingsWalletSingleModel from "../BuildingsWallet/BuildingsWalletSingleList";

var geolocate = require("mock-geolocation");

let watchId: any = undefined;
let watchIdCurrentLocation: any = undefined;
let isZoomTracking: boolean = false;
let isTrackCurrentLocation: boolean = false;

//this for get hirosintal search
let RegionsUrl = arcgisServicesBaseMinistryUrl + "4/query";
let CitiesUrl = arcgisServicesBaseMinistryUrl + "3/query";
let DistrictUrl = arcgisServicesBaseMinistryUrl + "2/query";
let SubDivisionPlanS = arcgisServicesBaseMinistryUrl + "5/query";
let parcelsUrl = arcgisServicesBaseMinistryUrl + "0/query";

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    mapviews: any;
    map: any;
    F_Land_parcels: any;
    F_Land_parcelsFlashLight: any;
    F_Land_parcelsHeatmap: any;
    F_FavoriteLand: any;
    F_Region: any;
  }
}

//#region routes
// Point the URL to a valid routing service
const routeUrl =
  "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World";
const routeLayer = new GraphicsLayer();

const routeParams: any = new RouteParameters({
  apiKey: routeParamsApiKey,
  stops: new FeatureSet(),
  outSpatialReference: {
    wkid: 3857,
  },
});

//Define the symbology used to display the stops

let stopTextSymbol = {
  type: "text",
  color: "red",
  haloColor: "#4f2cdb",
  haloSize: "1px",
  text: "B",
  xoffset: 3,
  yoffset: 3,
  font: {
    size: 20,
    family: "Arial",
    weight: "bold",
  },
};

let startTextSymbol = {
  type: "text",
  color: "red",
  haloColor: "#4f2cdb",
  haloSize: "1px",
  text: "A",
  xoffset: 3,
  yoffset: 3,
  font: {
    size: 20,
    family: "Arial",
    weight: "bold",
  },
};

// Define the symbology used to display the route
const routeSymbol = {
  type: "simple-line",
  color: [0, 0, 255, 0.5],
  width: 7,
};
//#endregion

let map: any = new Map();

let tileLayer = new VectorTileLayer({
  url: vectorTileServerMinistryUrl,
  id: "vectortile",
});

map.add(tileLayer);
map.add(routeLayer);

let MapViewData: MapView;
let graphicsLayer: GraphicsLayer = new GraphicsLayer();
let graphicsLogoLayerlogoAdd: GraphicsLayer = new GraphicsLayer();
let graphicsLogoLayer: GraphicsLayer = new GraphicsLayer();
let graphicsbuildingsWalletLayer: GraphicsLayer = new GraphicsLayer();
graphicsbuildingsWalletLayer.id = "graphicsbuildingsWalletLayer";

window.F_Land_parcelsFlashLight = new FeatureLayer({});
window.F_Land_parcels = new FeatureLayer({});
window.F_FavoriteLand = new FeatureLayer({});

let FeatureF_FavoriteLand = (mapInstance: Map, trans: number) => {
  window.F_FavoriteLand = new FeatureLayer({
    url: arcgisServicesLandFeatureUrl + "0",
    opacity: trans,
    outFields: ["*"],
    id: "F_FavoriteLand",
    minScale: 1128.4971765,
  });
  mapInstance.add(window.F_FavoriteLand);
};

let FeatureF_Region = (mapInstance: Map, trans: number) => {
  window.F_Region = new FeatureLayer({
    url: RegionsUrl,
    opacity: trans,
    id: "F_Region",
    outFields: ["*"],
    labelsVisible: false,
  });
  mapInstance.add(window.F_Region);
};

let FeatureF_Land_parcelsMapService = (mapInstance: Map, trans: number) => {
  let f_Land_parcelsMapService = new MapImageLayer({
    url: arcgisServicesLandUrl,
    opacity: trans,
    id: "F_Land_parcelsMapService",
    sublayers: [
      {
        id: 1,
        definitionExpression: "Feature_Ad is null",
      },
    ],
    // apiKey: isDev() ? LocalarcgisServicesLandToken : arcgisServicesLandToken,
    minScale: 26111,
  });
  mapInstance.add(f_Land_parcelsMapService);
};

let FeatureF_Land_parcels = (mapInstance: Map, trans: number) => {
  window.F_Land_parcels = new FeatureLayer({
    url: arcgisServicesLandFeatureUrl + "1",
    opacity: trans,
    id: "F_Land_parcels",
    outFields: ["*"],
    definitionExpression: "Feature_Ad is null",
    minScale: 26111,
    labelsVisible: false,
  });
  mapInstance.add(window.F_Land_parcels);
};

let FeatureF_Land_parcelsFlashLight = (mapInstance: Map, trans: number) => {
  window.F_Land_parcelsFlashLight = new FeatureLayer({
    url: arcgisServicesLandFeatureUrl + "1",
    opacity: trans,
    id: "F_Land_parcels",
    outFields: ["*"],
    definitionExpression: "Feature_Ad ='true'",
  });
  mapInstance.add(window.F_Land_parcelsFlashLight);
};

const getSymbolType: any = (typeOfProperty: any) => {
  return getSymbolRenderType(typeOfProperty);
};

const getSymbolRenderType = (typeOfProperty: any) => {
  return {
    type: "simple-marker",
    style: "circle",
    color: getColorAdvertisementType(typeOfProperty),
    size: "15px", // pixels
    outline: {
      color: [255, 255, 0],
      width: 0,
    },
  };
};

const uniqueValueRenderer = new UniqueValueRenderer({
  field: "BaseLandType",
  uniqueValueInfos: [
    {
      value: 1,
      symbol: getSymbolType(1),
      // label: "Label for Value1"
    },
    {
      value: 3,
      symbol: getSymbolType(3),
      // label: "Label for Value2"
    },
    {
      value: 4,
      symbol: getSymbolType(4),
      // label: "Label for Value2"
    },
  ],
});

let FeatureF_Land_parcelsHeatmap = (mapInstance: Map, trans: number) => {
  window.F_Land_parcelsHeatmap = new FeatureLayer({
    url: arcgisServicesLandFeatureUrl + "2",
    opacity: trans,
    id: "F_Land_parcelsHeatmap",
    outFields: ["*"],
    maxScale: 4513.9887055,
  });

  // const colors = [
  //   "rgba(115, 0, 115, 0)",
  //   "rgba(255,125,1, 0.5)",
  //   "rgba(255,125,1, 0.5)",
  //   "rgba(255,125,1, 0.5)",
  //   "rgba(38,161,254, 0.5)",
  //   "rgba(38,161,254, 0.5)",
  //   "rgba(38,161,254, 0.5)",
  //   "rgba(38,161,254, 0.5)",
  //   "rgba(255,0,198, 0.5)",
  //   "rgba(255,0,198, 0.5)",
  //   "rgba(255,0,198, 0.5)",
  //   "rgba(255,0,198, 0.5)",
  //   "rgba(255,0,198, 0.5)",
  // ];
  // window.F_Land_parcelsHeatmap.renderer = {
  //   type: "heatmap",
  //   field: "BaseLandType",
  //   colorStops: [
  //     { color: colors[0], ratio: 0 },
  //     { color: colors[1], ratio: 0.083 },
  //     { color: colors[2], ratio: 0.166 },
  //     { color: colors[3], ratio: 0.249 },
  //     { color: colors[4], ratio: 0.332 },
  //     { color: colors[5], ratio: 0.415 },
  //     { color: colors[6], ratio: 0.498 },
  //     { color: colors[7], ratio: 0.581 },
  //     { color: colors[8], ratio: 0.664 },
  //     { color: colors[9], ratio: 0.747 },
  //     { color: colors[10], ratio: 0.83 },
  //     { color: colors[11], ratio: 0.913 },
  //     { color: colors[12], ratio: 1 },
  //   ],
  //   radius: 10,
  //   maxDensity: 0.00875,
  //   minDensity: 0,
  // };

  window.F_Land_parcelsHeatmap.renderer = uniqueValueRenderer;
  mapInstance.add(window.F_Land_parcelsHeatmap);
};

function createFeatureGraphics(
  type: any,
  geometry: any,
  wiki: any,
  attr: any,
  symbolS: any
) {
  console.log("createFeatureGraphics ==>geometry", geometry);
  let fFeature: any;
  switch (type) {
    case "polygon":
      fFeature = new Polygon({
        rings: geometry,
        spatialReference: {
          wkid: wiki,
        },
      });
      break;
    case "point":
      fFeature = new Point({
        x: geometry.x,
        y: geometry.y,
        spatialReference: {
          wkid: wiki,
        },
      });
      break;
  }

  let fFeatureGraphic = new Graphic({
    geometry: fFeature,
    symbol: symbolS,
    attributes: attr,
  });
  return fFeatureGraphic;
}

let zoomToLayer = (type: any, geometry: any, wiki: any, ZoomLevel: any) => {
  graphicsLayer.removeAll();

  let fGetGraphic: any = createFeatureGraphics(
    type,
    geometry,
    wiki,
    "",
    selectedSymbol
  );
  graphicsLayer.add(fGetGraphic);

  if (ZoomLevel) {
    let opts = {
      duration: 5000,
    };
    MapViewData.goTo(
      {
        target: fGetGraphic,
        zoom: 17,
      },
      opts
    );
  } else {
    let gr: any = graphicsLayer.graphics;
    MapViewData.goTo(gr.items[0].geometry.extent.expand(2));
  }
};

let Home: React.FC = () => {
  let [region, setRegion] = useState("");
  let [city, setCity] = useState("");
  let [neighborhood, setNeighborhood] = useState("");
  let [selectedDistricts_Name, setSelectedDistricts_Name] = useState("");
  let [parcelName, setParcelName] = useState("");
  let [subDivisionPlanSName, setSubDivisionPlanSName] = useState("");
  let { userInfo } = useSelector((state: any) => state.reducer);
  let dispatch = useDispatch();
  let [isLoading, setIsLoading] = useState(true);
  let [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  let [legendModalIsOpen, setLegendModalIsOpen] = useState(false);
  let [whatsUpIsOpen, setWhatsUpIsOpen] = useState(false);
  let [regions, setRegions] = useState<any[]>([]);
  let [cities, setCities] = useState<any[]>([]);
  let [districts, setDistricts] = useState<any[]>([]);
  let [parcels, setParcels] = useState<any[]>([]);
  let [subDivisionPlanS, setSubDivisionPlanS] = useState<any[]>([]);
  let [errorTitle, setErrorTitle] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [propertyModalIsOpen, setPropertyModalIsOpen] = useState(false);
  let [
    favoriteLandByParcCodeModalIsOpen,
    setFavoriteLandByParcCodeModalIsOpen,
  ] = useState(false);
  let [whatsUpLocationButton, setwhatsUpLocationButton] = useState("primary");
  let [whatsUpAddsButton, setwhatsUpAddsButton] = useState("primary");
  let [editIsOpen, setEditIsOpen] = useState(false);
  let [isOpenSignIn, setIsOpenSignIn] = useState(false);
  let [logoPointsIsOpen, setLogoPointsIsOpen] = useState(false);
  let [landOperationsIsOpen, setLandOperationsIsOpen] = useState(false);
  let [landOperationsValue, setLandOperationsValue] = useState("");
  let [propertyNumber, setPropertyNumber] = useState("");
  let [landAddress, setLandAddress] = useState("");
  let [parcelNumberOrText, setParcelNumberOrText] = useState("");
  let [logoPointsTypeValue, setLogoPointsTypeValue] = useState("");
  let [logoPointsActiveValue, setLogoPointsActiveValue] = useState("");
  let [logoPointsZoomLevelValue, setLogoPointsZoomLevelValue] = useState("");
  let [logoFile, setLogoFile] = useState<any>();
  let [property, setProperty] = useState<any>("");
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [isUser, setIsUser] = useState<boolean>(false);
  let [isOperation, setIsOperation] = useState<boolean>(false);
  let [isCreateFavoriteLand, setIsCreateFavoriteLand] =
    useState<boolean>(false);
  let [isCreateBuildingsWallet, setIsCreateBuildingsWallet] =
    useState<boolean>(false);
  let [getLogosData, SetGetLogosData] = useState<any>();
  let [objectId, setObjectId] = useState<any>("");
  let [selectedFeature, setSelectedFeature] = useState<any>();
  let [currentSelectedFeature, setCurrentSelectedFeature] = useState<any>();
  let [parcel_Cod, setParcel_Cod] = useState<any>("");
  let [parcelNumber, setParcelNumber] = useState<any>("");
  let [sUBDIVISIONPLAN_ID, setSUBDIVISIONPLAN_ID] = useState<any>("");
  let { authenticationState } = useSelector((state: any) => state.reducer);
  let inputLogoNameRef = useRef<HTMLIonInputElement>(null);
  let inputLogoDescriptionRef = useRef<HTMLIonInputElement>(null);
  let inputLogoWidthRef = useRef<HTMLIonInputElement>(null);
  let inputLogoHeightRef = useRef<HTMLIonInputElement>(null);
  let { token } = useSelector((state: any) => state.reducer);
  let inputClassWithError =
    "input-error block px-5 py-2.5  placeholder-paragraphColor w-full text-sm  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  let inputClassWithOutError =
    "block px-5 py-2.5  placeholder-paragraphColor w-full text-sm  bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600";
  // let [itItemData] = useState();
  let broswerHistory = createBrowserHistory();
  let [isTrackingMyLocationIsOpen, setTrackingMyLocationIsOpen] =
    useState<boolean>(false);

  // let [buildingsWalletData, setBuildingsWalletData] = useState<any>([]);
  let [propertyAdvertisements, setPropertyAdvertisements] = useState<any>([]);
  let [propertyFavoriteLand, setPropertyFavoriteLand] = useState<any>([]);
  let history = useHistory();
  let [currentEventClick, setCurrentEventClick] = useState();

  let setSignInAsVisitorFunction = (returnedData: Object) =>
    dispatch(setSignInAsVisitor(returnedData));

  let [city_Name, setcity_Name] = useState("");
  let [districts_Name, setdistricts_Name] = useState("");
  let [neighbourhood_Name, setneighbourhood_Name] = useState("");

  let [presentToast] = useIonToast();
  let setIntervalFirst: any;
  let setIntervalSecond: any;
  const [presentAlert] = useIonAlert();

  let location = history.location;
  window.mapviews = MapViewData;
  window.map = map;

  //model
  let [present, dismiss] = useIonModal(Profile, {
    history: history,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  function openProfileModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        window.F_Land_parcels.refresh();
        if (ev.detail.role === "confirm") {
          window.F_Land_parcels.refresh();
        }
      },
    });
  }

  //model property
  let [presentproperty, dismissproperty] = useIonModal(Property, {
    baseLandType: property?.attributes?.Parcel_Status,
    propertyNumber: propertyNumber,
    objectId: objectId,
    parcel_Cod: parcel_Cod,
    latitude: property?.geometry?.centroid?.latitude,
    longitude: property?.geometry?.centroid?.longitude,
    neighbourhood_Name: neighbourhood_Name,
    districts_Name: districts_Name,
    city_Name: city_Name,
    sUBDIVISIONPLAN_ID: sUBDIVISIONPLAN_ID,
    rings: property?.geometry?.rings,
    dismissproperty: (data: string, role: string) =>
      dismissproperty(data, role),
  });
  function openpropertyModal() {
    presentproperty({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        window.F_Land_parcels.refresh();
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  function animationEffect(mapView: any) {
    console.log("animationEffect");
    mapView
      .whenLayerView(window.F_Land_parcelsFlashLight)
      .then((featureLayerView: any) => {
        applyFilter(featureLayerView, "Feature_Ad");
      });
  }

  function applyFilter(layerView: any, field: any) {
    let featureFilter = {
      where: `${field}='R' OR ${field}='true'`,
    };

    setIntervalFirst = setInterval(() => {
      layerView.featureEffect = {
        filter: featureFilter,
        includedEffect:
          "invert(70%) saturate(150%) hue-rotate(320deg) drop-shadow(16px 16px 80px blue) bloom(200%, 1px, 0.2)",
        excludedLabelsVisible: true,
      };
    }, 600);

    setIntervalSecond = setInterval(() => {
      layerView.featureEffect = {
        filter: featureFilter,
        includedEffect: "grayscale(25%) blur(5px) opacity(55%)",
        excludedLabelsVisible: true,
      };
    }, 500);
  }

  //error model property
  let [presentErrorModal, dismissErrorModal] = useIonModal(ErrorModal, {
    errorTitle: errorTitle,
    errorMessage: errorMessage,
    dismissErrorModal: (data: string, role: string) =>
      dismissErrorModal(data, role),
  });

  function openpErrorModal() {
    presentErrorModal({
      animated: true,
      breakpoints: [1, 1, 1, 0.5, 1],
      initialBreakpoint: 0.5,
      // backdropBreakpoint: 0.5,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }
  //sccess model property
  let [presentSccessMessage, dismissSccessMessage] = useIonModal(
    SccessMessage,
    {
      messageTitle: errorTitle,
      message: errorMessage,
      dismissSccessMessage: (data: string, role: string) =>
        dismissSccessMessage(data, role),
    }
  );

  let [buildingsWalletSingleItem, setBuildingsWalletSingleItem] =
    useState<any>(null);
  let [buildingWalletSingle, isBuildingWalletSingle] = useState<any>(null);

  const [presentBuildingsWalletSingleModel, dismissBuildingsWalletSingleModel] =
    useIonModal(BuildingsWalletSingleModel, {
      itemsData: buildingsWalletSingleItem,
      dismissBuildingsWalletSingleModel: (data: string, role: string) =>
        dismissBuildingsWalletSingleModel(data, role),
    });

  function openBuildingsWalletSingleModel() {
    presentBuildingsWalletSingleModel({
      animated: true,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          getBuildingsWalletFuncData();
        }
      },
    });
  }

  //model property
  let [presentPropertyTypesModal, dismissPropertyTypesModal] = useIonModal(
    PropertyTypesModal,
    {
      baseLandType: property?.attributes?.Parcel_Status,
      propertyNumber: propertyNumber,
      objectId: objectId,
      parcel_Cod: parcel_Cod,
      latitude: property?.geometry?.centroid?.latitude,
      longitude: property?.geometry?.centroid?.longitude,
      rings: property?.geometry?.rings,
      city_Name: city_Name,
      districts_Name: districts_Name,
      neighbourhood_Name: neighbourhood_Name,
      dismissPropertyTypesModal: (data: string, role: string) =>
        dismissPropertyTypesModal(data, role),
    }
  );

  let getIntersectWithGraphics = async (geometry: any, url: string) => {
    let query = new Query({
      returnGeometry: true,
      geometry: geometry,
      outFields: ["*"],
      spatialRelationship: "intersects",
    });
    let featureLayer = new FeatureLayer({
      url: url,
      outFields: ["*"],
    });
    let intersectingGraphics = await featureLayer.queryFeatures(query);
    console.log("intersectingGraphics", intersectingGraphics);

    return intersectingGraphics.features;
  };

  let getPlotData = () => {
    MapViewData.on("click", (event: any) => {
      setCurrentEventClick(event);
      MapViewData.hitTest(event).then(async (returnedData: any) => {
        console.log("returnedDatass", returnedData);

        let getGraphicsbuildingsWalletLayer = returnedData.results.filter(
          (layer: any) =>
            layer.graphic.layer.id == "graphicsbuildingsWalletLayer"
        );
        console.log(
          "getGraphicsbuildingsWalletLayer",
          getGraphicsbuildingsWalletLayer
        );

        if (
          getGraphicsbuildingsWalletLayer != null &&
          getGraphicsbuildingsWalletLayer.length > 0
        ) {
          isBuildingWalletSingle(true);
          setBuildingsWalletSingleItem({
            id: getGraphicsbuildingsWalletLayer[0].graphic.attributes
              .buildingsWalletId,
            land_Numbe:
              getGraphicsbuildingsWalletLayer[0].graphic.attributes.land_Numbe,
            parcel_Cod:
              getGraphicsbuildingsWalletLayer[0].graphic.attributes.parcel_Cod,
            title: getGraphicsbuildingsWalletLayer[0].graphic.attributes.title,
          });
          openBuildingsWalletSingleModel();
          console.log(
            "getGraphicsbuildingsWalletLayer parcel_Cod",
            getGraphicsbuildingsWalletLayer[0].graphic.attributes.parcel_Cod
          );
        } else {
          isBuildingWalletSingle(false);
        }

        console.log(
          "returnedDatass latitude",
          returnedData.results[0]?.mapPoint.latitude
        );
        console.log(
          "returnedDatass longitude",
          returnedData.results[0]?.mapPoint.longitude
        );

        if (returnedData.results[0]?.mapPoint) {
          if (returnedData.results[0]?.graphic) {
            console.log(
              "setCurrentSelectedFeature.results",
              returnedData.results
            );
            setCurrentSelectedFeature(returnedData.results);
          }

          let feature = await getIntersectWithGraphics(
            returnedData.results[0].mapPoint,
            arcgisServicesBaseMinistryUrl + "0"
          );
          console.log("feature", feature);
          if (feature && feature.length > 0) {
            setIsLoading(true);
            let plot: any = feature[0];
            let parcel_Cod = plot.attributes.OBJECTID;
            console.log("plot", plot);
            if (parcel_Cod) {
              setProperty(plot);
              getNhcIntegrationByObjIdFunction(parcel_Cod)
                .then(async (returnedData: any) => {
                  console.log("returnedData===>", returnedData);

                  if (returnedData.data.length > 0) {
                    setPropertyAdvertisements(returnedData.data);
                  } else {
                    setPropertyAdvertisements([]);
                  }

                  setneighbourhood_Name(
                    plot.attributes.DISTRICTNAME_AR == null
                      ? ""
                      : plot.attributes.DISTRICTNAME_AR
                  );
                  setdistricts_Name(
                    plot.attributes.REGIONNAME_AR == null
                      ? ""
                      : plot.attributes.REGIONNAME_AR
                  );
                  setcity_Name(
                    plot.attributes.CITYNAME_AR == null
                      ? ""
                      : plot.attributes.CITYNAME_AR
                  );
                  setObjectId(plot.attributes.OBJECTID);
                  setSelectedFeature(plot);
                  setParcel_Cod(plot.attributes.OBJECTID);
                  setPropertyNumber(
                    plot.attributes.SUBDIVISIONPARCELNUMBER == null
                      ? ""
                      : plot.attributes.SUBDIVISIONPARCELNUMBER
                  );
                  console.log("plot.attributessssss", plot.attributes);
                  setLandAddress(`${
                    plot.attributes.REGIONNAME_AR == null
                      ? "__"
                      : plot.attributes.REGIONNAME_AR
                  } | ${
                    plot.attributes.CITYNAME_AR == null
                      ? "__"
                      : plot.attributes.CITYNAME_AR
                  } | ${
                    plot.attributes.DISTRICTNAME_AR == null
                      ? "__"
                      : plot.attributes.DISTRICTNAME_AR
                  } | المخطط: 
                     ${
                       plot.attributes.SUBDIVISIONPLAN_ID == null
                         ? "__"
                         : plot.attributes.SUBDIVISIONPLAN_ID
                     } | القطعة:  ${
                    plot.attributes.SUBDIVISIONPARCELNUMBER == null
                      ? "__"
                      : plot.attributes.SUBDIVISIONPARCELNUMBER
                  }`);
                  // setLandAddress(`${plot.attributes.DISTRICTNAME_AR == null ? "__" : plot.attributes.DISTRICTNAME_AR} / ${plot.attributes.CITYNAME_AR == null ? "__" : plot.attributes.CITYNAME_AR} /
                  //   ${plot.attributes.REGIONNAME_AR == null ? "__" : plot.attributes.REGIONNAME_AR}`);
                  console.log("landAddress", landAddress);

                  setParcelNumber(
                    plot.attributes.SUBDIVISIONPARCELNUMBER == null
                      ? ""
                      : plot.attributes.SUBDIVISIONPARCELNUMBER
                  );
                  setSUBDIVISIONPLAN_ID(
                    plot.attributes.SUBDIVISIONPLAN_ID == null
                      ? ""
                      : plot.attributes.SUBDIVISIONPLAN_ID
                  );
                  setTimeout(() => {
                    if (isHaveF_FavoriteLand.length <= 0) {
                      setPropertyModalIsOpen(true);
                    }
                    setIsLoading(false);
                  }, 1000);
                })
                .catch((error: any) => {
                  setIsLoading(false);
                  setErrorTitle("خطأ في تحميل البيانات");
                  setErrorMessage("حدث خطأ في تحميل بيانات العقار المختار !");
                  openpErrorModal();
                  console.log(error);
                });
            }
          }
        }

        let isHaveF_FavoriteLand = returnedData.results.filter(
          (geLayer: any) => geLayer.layer.id == "F_FavoriteLand"
        );
        console.log("isHaveF_FavoriteLand", isHaveF_FavoriteLand);

        if (isHaveF_FavoriteLand.length > 0) {
          setIsLoading(true);
          getFavoriteLandByParcCodeFunction(
            Number(isHaveF_FavoriteLand[0].graphic.attributes.ParcelCode)
          )
            .then(async (returnedData: any) => {
              console.log("returnedData", returnedData);

              setPropertyFavoriteLand(returnedData.data);
              setTimeout(() => {
                setFavoriteLandByParcCodeModalIsOpen(true);
                setIsLoading(false);
              }, 1000);
            })
            .catch((error: any) => {
              setIsLoading(false);
              setErrorTitle("خطأ في تحميل البيانات");
              setErrorMessage("حدث خطأ في تحميل بيانات الوحدة المفضلة !");
              openpErrorModal();
              console.log(error);
            });
        }
      });
    });
  };

  let getRegions = () => {
    setIsLoading(true);
    let zonesArray: any = [];
    let queryOption: any = requestOptions(
      "",
      false,
      true,
      "",
      "",
      "",
      "",
      `1=1`
    );
    Request(RegionsUrl, queryOption)
      .then((response: any) => {
        if (
          response.data.features.length > 0 &&
          response.data.features[0].geometry
        ) {
          console.log("region result", response.data.features);
          response.data.features.forEach((result: any, index: number) => {
            zonesArray.push({
              name:
                result.attributes.REGIONNAME_AR != null
                  ? result.attributes.REGIONNAME_AR
                  : "بدون اسم",
              key: result.attributes.REGION_ID ?? index,
              graphic: result.geometry,
            });
          });
          setRegions(zonesArray);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  let getCitiesOfRegion = (selectedRegion: any) => {
    setIsLoading(true);
    let CitiesArray: any = [];
    let queryOption: any = requestOptions(
      "",
      false,
      true,
      "",
      "",
      "",
      "",
      `REGION_ID= N'${selectedRegion}'`
    );
    Request(CitiesUrl, queryOption)
      .then((response: any) => {
        if (
          response.data.features.length > 0 &&
          response.data.features[0].geometry
        ) {
          response.data.features.forEach((result: any, index: number) => {
            CitiesArray.push({
              name:
                result.attributes.CITYNAME_AR != null
                  ? result.attributes.CITYNAME_AR
                  : "بدون اسم",
              key: result.attributes.CITY_ID ?? index,
              graphic: result.geometry,
            });
          });
          setCities(CitiesArray);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  //model property
  const [presentAddProperty, dismisAddProperty] = useIonModal(
    AddPropertyNhcIntegration,
    {
      dismisAddProperty: (data: string, role: string) =>
        dismisAddProperty(data, role),
    }
  );

  function openpAddPropertyModal() {
    presentAddProperty({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  let getDistrictOfCity = (selectedCity: any) => {
    console.log("selectedCity", selectedCity);

    setIsLoading(true);
    let districtArray: any = [];
    let queryOption: any = requestOptions(
      "",
      false,
      true,
      "",
      "",
      "",
      "",
      `CITY_ID= N'${selectedCity}'`
    );
    Request(DistrictUrl, queryOption)
      .then((response: any) => {
        if (
          response.data.features.length > 0 &&
          response.data.features[0].geometry
        ) {
          console.log("getDistrictOfCity response", response.data.features);
          response.data.features.forEach((result: any, index: number) => {
            districtArray.push({
              name:
                result.attributes.DISTRICTNAME_AR != null
                  ? result.attributes.DISTRICTNAME_AR
                  : "بدون اسم",
              key: result.attributes.DISTRICT_ID ?? index,
              keySelected: result.attributes.OBJECTID ?? index,
              graphic: result.geometry,
            });
          });
          setDistricts(districtArray);
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "warning",
            `لا توجد بيانات مرتبطة بالبيانات المختاره`
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  let getSubDivisionPlanS = async (selectedDistrict: any) => {
    console.log("selectedDistrict", selectedDistrict);
    setIsLoading(true);
    let subDivisionPlanSArray: any = [];
    let graphic = featureGeometry("polygon", selectedDistrict.rings, "4326");
    let feature: any = await getIntersectWithGraphics(
      graphic,
      SubDivisionPlanS
    );
    console.log("feature", feature);
    if (feature.length > 0 && feature[0].geometry) {
      feature.forEach((result: any, index: number) => {
        subDivisionPlanSArray.push({
          name:
            result.attributes.SUBDIVISIONPLAN_ID != null
              ? result.attributes.SUBDIVISIONPLAN_ID
              : "بدون اسم",
          key: result.attributes.SUBDIVISIONPLAN_ID ?? index,
          graphic: result.geometry,
        });
      });
      setSubDivisionPlanS(subDivisionPlanSArray);
    } else {
      presentToastFunction(
        presentToast,
        "top",
        "warning",
        `لا توجد بيانات مرتبطة بالبيانات المختاره`
      );
    }
    setIsLoading(false);
  };

  let getParcels = (subDivisionPlanS: any, districkName: any) => {
    setIsLoading(true);
    console.log("getParcels==>DISTRICTNAME_AR", subDivisionPlanS);
    console.log("getParcels==>SUBDIVISIONPLAN_ID", districkName);
    let parcelsArr: any = [];
    let queryOption: any = requestOptions(
      "",
      false,
      true,
      "",
      "",
      "",
      "",
      `SUBDIVISIONPLAN_ID= N'${subDivisionPlanS}' and DISTRICTNAME_AR= N'${districkName}'`
    );
    Request(parcelsUrl, queryOption)
      .then((response: any) => {
        if (
          response.data.features.length > 0 &&
          response.data.features[0].geometry
        ) {
          console.log("getParcels response", response.data.features);
          response.data.features.forEach((result: any, index: number) => {
            parcelsArr.push({
              name:
                result.attributes.SUBDIVISIONPARCELNUMBER != null
                  ? result.attributes.SUBDIVISIONPARCELNUMBER
                  : "بدون اسم",
              key: result.attributes.SUBDIVISIONPARCELNUMBER ?? index,
              graphic: result.geometry,
            });
          });
          setParcels(parcelsArr);
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "warning",
            `لا توجد بيانات مرتبطة بالبيانات المختاره`
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  let REGA_20MapServiceURL = arcgisServicesBaseMinistryUrl + "0/query";
  let getGraphicFromObj = (obj: any) => {
    let queryOption: any = {
      responseType: "json",
      query: {
        f: "json",
        where: `OBJECTID in (${obj}) `,
        returnCountOnly: false,
        outFields: "*",
        returnDistinctValues: false,
        returnGeometry: true,
      },
    };
    Request(REGA_20MapServiceURL, queryOption)
      .then((response: any) => {
        if (response.data.features.length >= 0) {
          zoomToLayer(
            "polygon",
            response.data.features[0].geometry.rings[0],
            4326,
            false
          );
        } else {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `يرجى التاكد من رقم القطعة`
          );
        }
      })
      .catch((error: any) => {});
    setIsLoading(false);
  };

  const [isModalOpen, setIsModalOpen] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if (isModalOpen == "region") {
      setFilteredData(
        regions.filter(
          (item) =>
            item &&
            item.name
              .toString()
              .toLowerCase()
              .includes(searchQuery.toString().toLowerCase())
        )
      );
    }
    if (isModalOpen == "city") {
      setFilteredData(
        cities.filter(
          (item) =>
            item &&
            item.name
              .toString()
              .toLowerCase()
              .includes(searchQuery.toString().toLowerCase())
        )
      );
    }
    if (isModalOpen == "neighborhood") {
      setFilteredData(
        districts.filter(
          (item) =>
            item &&
            item.name
              .toString()
              .toLowerCase()
              .includes(searchQuery.toString().toLowerCase())
        )
      );
    }
    if (isModalOpen == "subDivision") {
      setFilteredData(
        subDivisionPlanS.filter(
          (item) =>
            item &&
            item.name
              .toString()
              .toLowerCase()
              .includes(searchQuery.toString().toLowerCase())
        )
      );
    }
    if (isModalOpen == "parcel") {
      setFilteredData(
        parcels.filter(
          (item) =>
            item &&
            item.name
              .toString()
              .toLowerCase()
              .includes(searchQuery.toString().toLowerCase())
        )
      );
    }
  }, [searchQuery]); // Re-run the effect if searchQuery or isModalOpen changes

  const handleSearch = (event: CustomEvent) => {
    const value = (event.target as HTMLInputElement).value || "";
    setSearchQuery(value);
  };

  const handleSelectItem = async (selectedItem: any) => {
    console.log("iteeeeeeeeeeem", selectedItem);

    if (isModalOpen == "region") {
      setRegion(selectedItem.name);
      setCity("");
      setNeighborhood("");
      setSubDivisionPlanSName("");
      setParcelName("");
      let graphicResult = regions.filter((item) => {
        return item.key === selectedItem.key;
      });
      zoomToLayer("polygon", graphicResult[0].graphic.rings[0], 4326, false);
      getCitiesOfRegion(selectedItem.key);
      setDistricts([]);
      setSubDivisionPlanS([]);
      setParcels([]);
    }
    if (isModalOpen == "city") {
      setCity(selectedItem.name);
      setNeighborhood("");
      setSubDivisionPlanSName("");
      setParcelName("");
      let graphicResult = cities.filter((item) => {
        return item.key === selectedItem.key;
      });
      zoomToLayer("polygon", graphicResult[0].graphic.rings[0], 4326, false);
      getDistrictOfCity(selectedItem.key);
      setSubDivisionPlanS([]);
      setParcels([]);
    }
    if (isModalOpen == "neighborhood") {
      setNeighborhood(selectedItem.name);
      setSubDivisionPlanSName("");
      setParcelName("");
      let graphicResult = districts.filter((item) => {
        return item.keySelected === selectedItem.keySelected;
      });
      zoomToLayer("polygon", graphicResult[0].graphic.rings[0], 4326, false);
      await getSubDivisionPlanS(graphicResult[0].graphic);
      console.log("selected district", graphicResult[0].name);
      setSelectedDistricts_Name(graphicResult[0].name);
      setParcels([]);
    }
    if (isModalOpen == "subDivision") {
      setSubDivisionPlanSName(selectedItem.name);
      setParcelName("");
      let graphicResult = subDivisionPlanS.filter((item) => {
        return item.key === selectedItem.key;
      });
      zoomToLayer("polygon", graphicResult[0].graphic.rings[0], 4326, false);
      getParcels(selectedItem.name, selectedDistricts_Name);
    }
    if (isModalOpen == "parcel") {
      setParcelName(selectedItem.name);

      let graphicResult = parcels.filter((item) => {
        return item.key === selectedItem.key;
      });

      zoomToLayer("polygon", graphicResult[0].graphic.rings[0], 4326, false);
    }

    setIsModalOpen("");
    setSearchQuery("");
  };

  const [currentRegion, setCurrentRegion]: any = useState(null);
  const [EstateBrokersById, setEstateBrokersById]: any = useState();

  function checkIfEstateBroker() {
    const parts = window.location.pathname.split("/");
    const result = parts.splice(3, parts.length).join("");
    setEstateBrokersById(result);
    openEstateBrokersByIdModal();
  }

  useEffect(() => {
    if (getLogosData) {
      graphicsLogoLayer.removeAll();
      getLogosData.forEach((item: any) => {
        if (item.isActive) {
          if (item.type == "logoAdd") {
            generateLogos(
              item.type,
              item.id,
              item.logoFile,
              Number(item.longitude) - 0.00004,
              Number(item.latitude) + 0.00005,
              `${item.height}px`,
              `${item.height}px`
            );
          } else {
            generateLogos(
              item.type,
              item.id,
              item.logoFile,
              Number(item.longitude) - 0.00004,
              Number(item.latitude) + 0.00005,
              `${item.height}px`,
              `${item.height}px`
            );
          }
        }
      });
    }
  }, [getLogosData]);

  useEffect(() => {
    getBuildingsWalletFuncData();
  }, []);

  //#region routing
  async function addStop(event: any) {
    console.log("event==>", event);

    // Add a point at the location of the map click
    const stop = new Graphic({
      geometry: event.mapPoint != null ? event.mapPoint : event.geometry,
      symbol: event.mapPoint != null ? stopTextSymbol : startTextSymbol,
    });

    routeLayer.add(stop);

    // Execute the route if 2 or more stops are input

    routeParams.stops.features.push(stop);
    if (routeParams.stops.features.length >= 2) {
      route.solve(routeUrl, routeParams).then(showRoute);
    } else {
      console.log("try to get currentLocation");
      let chcekPremission = await Geolocation.checkPermissions();
      console.log("chcekPremission", chcekPremission);
      if (
        chcekPremission.coarseLocation != "denied" &&
        chcekPremission.location != "denied"
      ) {
        let currentLocation: any;
        try {
          currentLocation = await Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
            timeout: 1000000,
          });
          console.log("currentLocation", currentLocation);
        } catch (err: any) {
          alert("يرجي تفعيل تحديد الموقع : " + err.message);
          setIsLoading(false);
          return;
        }

        let pointDegrees: any = {
          type: "point",
          longitude: currentLocation.coords.longitude,
          latitude: currentLocation.coords.latitude,
        };

        let symbol: any = {
          type: "simple-marker",
          size: "16px",
          color: "#0031C4",
          outline: {
            color: "#77A4D2",
            width: "3px",
          },
        };

        let pointGraphicForLocation = new Graphic({
          geometry: pointDegrees,
          symbol: symbol,
        });

        addStop(pointGraphicForLocation);
      } else {
        alert("يرجي تفعيل تحديد الموقع : ");
        setIsLoading(false);
        return;
      }
    }
  }

  // geolocation simulator
  function stubGeolocation() {
    let coords = [
        {
          lat: 24.479095,
          lng: 39.60418,
        },
        {
          lat: 24.479837,
          lng: 39.60404,
        },
        {
          lat: 24.480648,
          lng: 39.603826,
        },
        {
          lat: 24.481653,
          lng: 39.6036,
        },
        {
          lat: 24.481214,
          lng: 39.603708,
        },
        {
          lat: 24.480804,
          lng: 39.603794,
        },
        {
          lat: 24.48013,
          lng: 39.603922,
        },
        {
          lat: 24.480296,
          lng: 39.605296,
        },
        {
          lat: 24.477181,
          lng: 39.608997,
        },
        {
          lat: 24.479773,
          lng: 39.614368,
        },
      ],
      currentCoordIndex = 0;

    geolocate.use();

    setInterval(() => {
      geolocate.change(coords[currentCoordIndex]);
      currentCoordIndex = (currentCoordIndex + 1) % coords.length;
    }, 1500);
  }

  // Adds the solved route to the map as a graphic
  function showRoute(data: any) {
    console.log("showRoute==>data", data);

    let total_Kilometers =
      data.routeResults[0].route.attributes.Total_Kilometers;
    let stopCount = data.routeResults[0].route.attributes.StopCount;
    let total_TravelTime =
      data.routeResults[0].route.attributes.Total_TravelTime;
    console.log("showRoute==>total_Kilometers", total_Kilometers);
    console.log("showRoute==>stopCount", stopCount);
    console.log("showRoute==>total_TravelTime", total_TravelTime);

    const routeResult = data.routeResults[0].route;
    routeResult.symbol = routeSymbol;
    routeLayer.add(routeResult);
    MapViewData.goTo(routeResult);

    setPropertyModalIsOpen(false);
    setTrackingModalMode("tracking");
    setTrackingModalData({
      routeResult: routeResult,
      landAddress: landAddress,
    } as any);
    setIsLoading(false);
    // MapViewData.rotation = 90;
  }
  //#endregion

  let initMapFunction = () => {
    MapViewData = new MapView({
      container: "viewDivMap",
      map: map,
      center: [41.33832290529733, 32.10074513959272], //longitude latitude
      zoom: 6,
    });
    MapViewData.constraints.rotationEnabled = true;
    MapViewData.ui.remove("zoom");
    MapViewData.constraints.minZoom = 5;
    FeatureF_Region(map, 0);
    FeatureF_Land_parcelsMapService(map, 1);
    FeatureF_Land_parcels(map, 0);
    FeatureF_Land_parcelsFlashLight(map, 1);
    FeatureF_Land_parcelsHeatmap(map, 1);
    FeatureF_FavoriteLand(map, 1);
    map.add(graphicsLayer);
    map.add(graphicsLogoLayer);
    map.add(graphicsLogoLayerlogoAdd);
    map.add(graphicsbuildingsWalletLayer);

    //move pointer
    // let currentReionExtentName = "";

    //use geoLoction  // يتم توقيفها عند الرفع
    //stubGeolocation();

    reactiveUtils.watch(
      () => MapViewData.navigating,
      (center: any) => {
        console.log(
          "MapViewData.navigating==>isTrackCurrentLocation",
          isTrackCurrentLocation
        );
        if (isTrackCurrentLocation) {
          isZoomTracking = true;
          isTrackCurrentLocation = false;
        }
      }
    );

    // MapViewData.watch("view navigation browserTouchPanEnabled", (event) => {
    //   console.log("navigation==>isTrackCurrentLocation", isTrackCurrentLocation);
    //   if (isTrackCurrentLocation) {
    //     isZoomTracking = true;
    //     isTrackCurrentLocation = false;
    //   }
    // })

    MapViewData.watch("scale", (evt) => {
      let latitude = localStorage.getItem("CurrentLatitude");
      if (latitude != null) {
        localStorage.removeItem("CurrentLatitude");
      }
      localStorage.setItem(
        "CurrentLatitude",
        MapViewData.center.latitude.toString()
      );

      let longitude = localStorage.getItem("CurrentLongitude");
      if (longitude != null) {
        localStorage.removeItem("CurrentLongitude");
      }
      localStorage.setItem(
        "CurrentLongitude",
        MapViewData.center.longitude.toString()
      );

      let scale = localStorage.getItem("CurrentScale");
      if (scale != null) {
        localStorage.removeItem("CurrentScale");
      }
      localStorage.setItem("CurrentScale", evt.toString());

      /*/////////////////////////////*/
      // reactiveUtils.watch(
      //   () => MapViewData.center,
      //   (center: any) => {
      //     const query = new Query();
      //     query.geometry = center;
      //     query.spatialRelationship = "intersects";
      //     query.returnGeometry = false;
      //     window.F_Region.queryFeatures(query)
      //       .then((res: any) => {
      //         let regionName = res.features[0].attributes.REGIONNAME_AR;
      //         if (currentReionExtentName == "") {
      //           currentReionExtentName = regionName;
      //           console.log("regionName", regionName);
      //           console.log("call api for get big user for adds==>1");
      //           setCurrentRegion(currentReionExtentName);
      //         }
      //         if (currentReionExtentName != regionName) {
      //           console.log("regionName", regionName);
      //           console.log("call api for get big user for adds==>2");
      //           currentReionExtentName = regionName;
      //           setCurrentRegion(currentReionExtentName);
      //         }
      //       })
      //       .catch(() => {});
      //   }
      // );
      /*/////////////////////////////*/
    });

    graphicsLogoLayer.visible = false; //if you need to make another zoom extent make another grathic layer and asign data in him
    graphicsLogoLayerlogoAdd.visible = false; //if you need to make another zoom extent make another grathic layer and asign data in him
    MapViewData.watch("zoom", function (evt: any) {
      if (MapViewData.zoom >= 18) {
        graphicsLogoLayer.visible = true;
      } else if (MapViewData.zoom <= 18) {
        graphicsLogoLayer.visible = false;
      }
      if (MapViewData.zoom >= 16) {
        graphicsLogoLayerlogoAdd.visible = true;
      } else if (MapViewData.zoom <= 16) {
        graphicsLogoLayerlogoAdd.visible = false;
      }
    });
    //zoom in current
    if (localStorage.getItem("CurrentLatitude") != null) {
      MapViewData.center.latitude = Number(
        localStorage.getItem("CurrentLatitude")
      );
    }
    if (localStorage.getItem("CurrentLongitude") != null) {
      MapViewData.center.longitude = Number(
        localStorage.getItem("CurrentLongitude")
      );
    }
    if (localStorage.getItem("CurrentScale") != null) {
      MapViewData.scale = Number(localStorage.getItem("CurrentScale"));
    }
    getPlotData();
    //for routing
    //MapViewData.on("click", addStop);

    setTimeout(() => {
      animationEffect(MapViewData);
    }, 2000);
  };

  // useEffect(() => {
  //   if (window.location.pathname.includes("estateBrokers")) {
  //     // setTimeout(() => {
  //     checkIfEstateBroker();
  //     // }, 2000);
  //   }
  // }, [window.mapviews]);

  async function pushNotification() {
    console.log("start ==>pushNotification");

    let options = {
      notifications: [
        {
          id: 111,
          title: "Test Title",
          body: "Test Body",
          largeBody: "",
          largeIcon: "",
          summaryText: "",
        },
      ],
    };

    try {
      console.log("pushNotification");
      if (
        (await (await LocalNotifications.requestPermissions()).display) ===
        "granted"
      ) {
        console.log("pushNotification==>granted");
        // continue with stuff
        await LocalNotifications.schedule(options);
      } else {
        console.log("pushNotification==> !granted");
      }
    } catch (error) {
      JSON.stringify(error);
    }
  }

  function changeFlashLightFeature() {
    if (property) {
      setIsLoading(true);
      let featureFalsh =
        property.attributes.Feature_Ad == "null" ||
        property.attributes.Feature_Ad == null ||
        property.attributes.Feature_Ad == "" ||
        property.attributes.Feature_Ad == "false"
          ? "true"
          : "false";

      console.log("selectedFeature", selectedFeature);
      console.log("currentSelectedFeature", currentSelectedFeature);

      let isHaveFlashLand = currentSelectedFeature.filter(
        (geLayer: any) => geLayer.graphic.attributes?.Feature_Ad == "true"
      );
      console.log("changeFlashLightFeature==>isHaveFlashLand", isHaveFlashLand);
      if (isHaveFlashLand.length > 0) {
        let getHaveFlashLand = isHaveFlashLand[0].graphic;
        console.log(
          "getHaveFlashLand.attributes.OBJECTID",
          getHaveFlashLand.attributes.OBJECTID
        );
        let deleteFeatures = [];
        deleteFeatures.push({ objectId: getHaveFlashLand.attributes.OBJECTID });
        window.F_Land_parcels.applyEdits({
          deleteFeatures: deleteFeatures,
        }).then((result: any) => {
          console.log("delete result", result);
          if (result.deleteFeatureResults[0].error == null) {
            presentToastFunction(
              presentToast,
              "top",
              "success",
              `تم الفاء تحويل القطعه رقم ${property.attributes.SUBDIVISIONPARCELNUMBER}`
            );
            window.F_Land_parcels.refresh();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setErrorTitle("خطأ في تحميل البيانات");
            setErrorMessage("يرجى اختيار القطعه ");
            openpErrorModal();
          }
        });
      } else {
        console.log(
          "selectedFeature.attributes.CITYNAME_AR",
          selectedFeature.attributes.CITYNAME_AR
        );

        const attributes: any = {};
        attributes["Feature_Ad"] = featureFalsh;
        attributes["Parcel_Cod"] = `${selectedFeature.attributes.OBJECTID}`;
        attributes["ZK"] =
          selectedFeature.attributes.CITYNAME_AR == null
            ? ""
            : selectedFeature.attributes.CITYNAME_AR;
        attributes["DK"] =
          selectedFeature.attributes.DISTRICTNAME_AR == null
            ? ""
            : selectedFeature.attributes.DISTRICTNAME_AR;
        attributes["NK"] =
          selectedFeature.attributes.DISTRICTNAME_AR == null
            ? ""
            : selectedFeature.attributes.DISTRICTNAME_AR;
        attributes["Land_Numbe"] =
          selectedFeature.attributes.SUBDIVISIONPARCELNUMBER == null
            ? ""
            : selectedFeature.attributes.SUBDIVISIONPARCELNUMBER;

        const addFeature = new Graphic({
          geometry: selectedFeature.geometry,
          attributes: attributes,
        });

        window.F_Land_parcels.applyEdits({ addFeatures: [addFeature] }).then(
          (response: any) => {
            console.log("response", response);
            if (response.addFeatureResults[0].error == null) {
              presentToastFunction(
                presentToast,
                "top",
                "success",
                `تم تحويل القطعه رقم ${property.attributes.SUBDIVISIONPARCELNUMBER}`
              );
              window.F_Land_parcels.refresh();
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setErrorTitle("خطأ في تحميل البيانات");
              setErrorMessage("يرجى اختيار القطعه ");
              openpErrorModal();
            }
          }
        );
      }
    }
  }

  async function changeFavoriteLandFeature(pPointgraphic: any) {
    if (property) {
      setIsLoading(true);
      console.log("selectedFeature", selectedFeature);
      console.log("currentSelectedFeature", currentSelectedFeature);

      let attributes = {
        ParcelCode: `${parcel_Cod}`,
        City_Name: city_Name,
        District_Name: districts_Name,
        Neighbourhood_Name: neighbourhood_Name,
        Land_Numbe: propertyNumber,
      };

      const addFeature = new Graphic({
        geometry: selectedFeature.geometry.centroid,
        attributes: attributes,
      });

      console.log("addFeature", addFeature);

      window.F_FavoriteLand.applyEdits({ addFeatures: [addFeature] }).then(
        (response: any) => {
          console.log("response", response);
          if (response.addFeatureResults[0].error == null) {
            console.log(
              "response.addFeatureResults",
              response.addFeatureResults[0].objectId
            );

            createFavoriteLandFunction(
              parcel_Cod,
              userInfo.id,
              token,
              districts_Name,
              city_Name,
              neighbourhood_Name,
              property.attributes.SUBDIVISIONPARCELNUMBER,
              pPointgraphic.geometry.x,
              pPointgraphic.geometry.y,
              response.addFeatureResults[0].objectId
            )
              .then((result: any) => {
                console.log("result", result);
                if (result?.data.errorResult) {
                  presentToastFunction(
                    presentToast,
                    "top",
                    "danger",
                    `يرجي اضافه رقم جوالك بالنظام اولا , اذا كنت اضفته من قبل يرجي تسجيل الخروج ثم الدخول مره ثانيه`
                  );
                  deletePoint(response.addFeatureResults[0].objectId);
                } else {
                  if (result?.data?.data == "deplicateEnters") {
                    presentToastFunction(
                      presentToast,
                      "top",
                      "danger",
                      `تم اضافة القطعة من قبل `
                    );
                    deletePoint(response.addFeatureResults[0].objectId);
                  } else if (result?.data?.data == "landHaveAdds") {
                    presentToastFunction(
                      presentToast,
                      "top",
                      "danger",
                      `عفوا لايمكن اضافه القطعه للمفضلة لان يوجد عليها عروض `
                    );
                    deletePoint(response.addFeatureResults[0].objectId);
                  } else if (result.data.data.parcel_Cod) {
                    presentToastFunction(
                      presentToast,
                      "top",
                      "success",
                      "تمت الإضافة بنجاح"
                    );
                  }
                }
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                presentToastFunction(
                  presentToast,
                  "top",
                  "danger",
                  `عفوا حدث خطأ ${err}`
                );
              });
            window.F_FavoriteLand.refresh();
            setIsLoading(false);
          }
        }
      );
    }
  }

  function deletePoint(objId: any) {
    window.F_FavoriteLand.applyEdits({
      deleteFeatures: [{ objectId: objId }],
    }).then((result: any) => {
      if (result.deleteFeatureResults[0].error == null) {
      } else {
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          "خطئ عند رجوع البيانات الجغرافيه لوضعها الطبيعي"
        );
      }
    });
  }

  let handleRemoveLogos = (id: any) => {
    let newlogos = getLogosData.filter((getId: any) => getId.id !== id);
    SetGetLogosData(newlogos);
  };

  function generatetBuildingsWalletFuncLogos(
    id: any,
    longitude: any,
    latitude: any,
    parcel_Cod: any,
    land_Numbe: any,
    title: any
  ) {
    console.log("MapViewData", MapViewData);

    let point: any = {
      type: "point",
      longitude: Number(longitude),
      latitude: Number(latitude),
      spatialReference: 102100,
      id: id,
    };

    let attData = {
      buildingsWalletId: id,
      parcel_Cod: parcel_Cod,
      land_Numbe: land_Numbe,
      title: title,
    };

    let symbolBuildingsWallet: any = {
      type: "simple-marker",
      style: "square",
      size: "15px",
      color: "#0031C4",
      outline: {
        color: "#77A4D2",
        width: "10px",
      },
    };
    console.log("generatetBuildingsWalletFuncLogos point", point);

    let pointGraphic = new Graphic({
      geometry: point,
      symbol: symbolBuildingsWallet,
      attributes: attData,
    });
    graphicsbuildingsWalletLayer.graphics.push(pointGraphic);
  }

  function generateLogos(
    type: any,
    id: any,
    file: any,
    longitude: any,
    latitude: any,
    width: any,
    height: any
  ) {
    let picIcon: any = pictureSymbolFunction(file, width, height);
    let point: any = {
      type: "point",
      longitude: longitude,
      latitude: latitude,
      spatialReference: MapViewData?.spatialReference,
      id: id,
    };

    let attData = {
      logoId: id,
    };
    let pointGraphic = new Graphic({
      geometry: point,
      symbol: picIcon,
      attributes: attData,
    });
    if (type == "logoAdd") {
      graphicsLogoLayerlogoAdd.graphics.push(pointGraphic);
    } else {
      graphicsLogoLayer.graphics.push(pointGraphic);
    }
  }

  async function getLogoData() {
    let rresultData = await getLogosDataFunction();
    SetGetLogosData(rresultData.data);
  }

  useEffect(() => {
    getLogoData();
    getRegions();
    if (userInfo?.roles?.some((r: any) => ["admin"].includes(r))) {
      setIsAdmin(true);
    }
    if (userInfo?.roles?.some((r: any) => ["user"].includes(r))) {
      setIsUser(true);
    }
    if (userInfo?.roles?.some((r: any) => ["operation"].includes(r))) {
      setIsOperation(true);
    }

    getAPPVersionsInfo();
  }, []);

  const logDeviceInfo = async () => {
    const info = await Device.getInfo();
    return info;
  };

  const logBatteryInfo = async () => {
    const info = await Device.getBatteryInfo();
    return info;
  };

  function presentAlertUpdateApp(url: any) {
    return presentAlert({
      cssClass: "animate__animated animate__fadeInDown",
      header: "تنبية",
      message: `<strong class="strongStyle">يرجي تحديث تطبيق ذكي من المتجر ؟ </strong >`,
      buttons: [
        {
          text: "موافق",
          role: "confirm",
          handler: () => {
            window.open(`${url}`, "_blank", "noopener,noreferrer");
          },
        },
      ],
      backdropDismiss: false,
      onDidDismiss: () => {
        console.log("presentAlertUpdateApp onDidDismiss");
      },
    });
  }
  const getAPPVersionsInfo = async () => {
    getAPPVersionsFunction()
      .then(async (getAPPVersions: any) => {
        console.log("getAPPVersionsFunction==>getAPPVersions", getAPPVersions);
        let deInfo = await logDeviceInfo();
        console.log("deInfo==>", deInfo);

        // let batInfo = await logBatteryInfo();
        // console.log("batInfo==>", batInfo);
        if (deInfo.operatingSystem == "ios") {
          console.log("IOSVersion", IOSVersion);
          console.log(
            "Number(getAPPVersions.data.iosVersion)",
            Number(getAPPVersions.data.iosVersion)
          );
          if (Number(getAPPVersions.data.iosVersion) > IOSVersion) {
            //need popup to goto apple store
            console.log("old version");
            setTimeout(() => {
              presentAlertUpdateApp("https://thki.sa/ios");
            }, 20000);
          }
        } else if (deInfo.operatingSystem == "android") {
          console.log("AndoridVersion", AndoridVersion);
          console.log(
            "Number(getAPPVersions.data.andoridVersion)",
            Number(getAPPVersions.data.andoridVersion)
          );
          if (Number(getAPPVersions.data.andoridVersion) > AndoridVersion) {
            //need popup to goto andorid store
            setTimeout(() => {
              presentAlertUpdateApp("https://thki.sa/android");
            }, 20000);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (
      (location.pathname.includes("app") ||
        location.pathname.includes("app/")) &&
      !location.pathname.includes("estateBrokers") &&
      !location.pathname.includes("PaymentRedirect")
    ) {
      //    /app/178803
      let getUrlPar: any = Number(location.pathname.replace("/app/", ""));
      if (getUrlPar !== null && !Number.isNaN(getUrlPar) && getUrlPar != 0) {
        getGraphicFromObj(getUrlPar);
      } else if (location.pathname.includes("app/d/")) {
        //  thki.sa/app/d/178803
        let getUrlPar: any = Number(location.pathname.replace("/app/d/", ""));
        if (!Number.isNaN(getUrlPar) && !Number.isNaN(getUrlPar)) {
          window.location.assign(`/app/d?name=${getUrlPar}`);
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    initMapFunction();
    if (window.location.pathname.includes("estateBrokers")) {
      checkIfEstateBroker();
    }
  }, [location.pathname]);

  const [adLicenseNumber, setadLicenseNumber] = useState();
  const [itItemData, setItemData] = useState();

  //model EstateBrokers property
  const [presentEstateBrokersModal, onCloseEstateBrokersDismiss] = useIonModal(
    EstateBrokersModal,
    {
      currentRegion: currentRegion,
      id: null,
      onCloseEstateBrokersDismiss: (data: string, role: string) =>
        onCloseEstateBrokersDismiss(data, role),
    }
  );
  function getRegionNameByGis() {
    let currentReionExtentName = "";

    const query = new Query();
    query.geometry = MapViewData.center;
    query.spatialRelationship = "intersects";
    query.returnGeometry = false;
    window.F_Region.queryFeatures(query)
      .then((res: any) => {
        let regionName = res.features[0].attributes.REGIONNAME_AR;
        if (currentReionExtentName == "") {
          currentReionExtentName = regionName;
          console.log("regionName", regionName);
          console.log("call api for get big user for adds==>1");
          setCurrentRegion(currentReionExtentName);
        }
        if (currentReionExtentName != regionName) {
          console.log("regionName", regionName);
          console.log("call api for get big user for adds==>2");
          currentReionExtentName = regionName;
          setCurrentRegion(currentReionExtentName);
        }
      })
      .catch(() => {});
    return "ok";
  }

  function openEstateBrokersModal() {
    console.log("currentRegion", currentRegion);
    let result = getRegionNameByGis();
    if (result == "ok") {
      presentEstateBrokersModal({
        animated: true,
        // breakpoints: [0.5, 1], // Allowing only middle and full screen
        // initialBreakpoint: 0.5, // Start at the middle of the screen
        // backdropBreakpoint: 0.7,

        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          if (ev.detail.role === "confirm") {
          }
        },
      });
    }
    setIsLoading(false);
  }

  //model EstateBrokersById property
  const [presentEstateBrokersByIdModal, onCloseEstateBrokersByIdDismiss] =
    useIonModal(EstateBrokersModal, {
      currentRegion: null,
      id: EstateBrokersById,
      onCloseEstateBrokersDismiss: (data: string, role: string) =>
        onCloseEstateBrokersByIdDismiss(data, role),
    });

  function openEstateBrokersByIdModal() {
    presentEstateBrokersByIdModal({
      animated: true,
      // breakpoints: [0.5, 1], // Allowing only middle and full screen
      // initialBreakpoint: 0.5, // Start at the middle of the screen
      // backdropBreakpoint: 0.7,

      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "backdrop") {
          window.location.assign(`/app/home`);
        }
      },
    });
  }

  async function getDataByByAdLicenseNumber(addNum: any, addIDType: any) {
    let result = await getNhcIntegrationByAdLicenseNumberFunction(
      addNum,
      addIDType
    );
    console.log("GetNhcIntegrationByAdLicenseNumber", result);
    setItemData(result.data.data);
    if (result?.data?.message) {
      presentToastFunction(
        presentToast,
        "top",
        "danger",
        `${result?.data?.message}`
      );
    } else {
      setTimeout(() => {
        presentAdvertisementFunc();
      }, 1000);
    }
  }

  const [presentAdvertisementModal, onDismissAdvertisement] = useIonModal(
    NhcAdvertisement,
    {
      objectId: objectId,
      adLicenseNumber: adLicenseNumber,
      advertisement: itItemData,
      onDismissAdvertisement: (data: string, role: string) =>
        onDismissAdvertisement(data, role),
    }
  );

  function presentAdvertisementFunc() {
    console.log("presentAdvertisementFunc==>itItemData", itItemData);
    setTimeout(() => {
      console.log("again presentAdvertisementFunc==>itItemData", itItemData);
      presentAdvertisementModal({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          if (ev.detail.role === "confirm") {
          }
        },
      });
      setIsLoading(false);
    }, 2000);
  }

  function callBackWatchPosition(position: Position | null, err?: any) {
    console.log("position", position);
    console.log("err", err);
    if (err) {
      alert("يرجي تفعيل تحديد الموقع : " + err.message);
    } else {
      graphicsLayer.removeAll();
      let pointDegrees: any = {
        type: "point",
        longitude: position?.coords.longitude,
        latitude: position?.coords.latitude,
      };

      let symbolArrow: any = {
        type: "simple-marker",
        style: "circle",
        size: "25px",
        color: "#0031C4",
        outline: {
          color: "#77A4D2",
          width: "1px",
        },
      };

      let symbolCircle: any = {
        type: "simple-marker",
        style: "circle",
        size: "25px",
        color: "#0031C4",
        outline: {
          color: "#77A4D2",
          width: "1px",
        },
      };

      let pointGraphicForLocationArrow = new Graphic({
        geometry: pointDegrees,
        symbol: symbolArrow,
      });
      let pointGraphicForLocationCircle = new Graphic({
        geometry: pointDegrees,
        symbol: symbolCircle,
      });

      graphicsLayer.add(pointGraphicForLocationCircle);
      graphicsLayer.add(pointGraphicForLocationArrow);

      console.log("isZoomTracking==>", isZoomTracking);
      if (!isZoomTracking) {
        MapViewData.center = pointDegrees;
        // console.log("pointGraphicForLocationArrow",pointGraphicForLocationArrow);
        MapViewData.goTo(pointGraphicForLocationArrow);
        MapViewData.zoom = 18;
        isTrackCurrentLocation = true;
      }
    }
  }

  let trackingMyLocationFunction = async (isNotClear: boolean) => {
    let chcekPremission = await Geolocation.checkPermissions();
    console.log("chcekPremission", chcekPremission);
    if (
      chcekPremission.coarseLocation != "denied" &&
      chcekPremission.location != "denied"
    ) {
      if (isNotClear) {
        watchId = await Geolocation.watchPosition(
          { enableHighAccuracy: true, timeout: 1000000 },
          callBackWatchPosition
        );
      } else {
        await Geolocation.clearWatch({ id: watchId });
        graphicsLayer.removeAll();
        isZoomTracking = false;
      }
    } else {
      alert("يرجي تفعيل تحديد الموقع : ");
      return;
    }
  };

  function createFavoriteLand() {
    setIsLoading(true);
    let x: number = property?.geometry?.centroid?.x;
    let y: number = property?.geometry?.centroid?.y;
    //create point  geometry
    let pointGeometryG = {
      x: x,
      y: y,
    };
    let att = {
      ParcelCode: parcel_Cod,
      City_Name: city_Name,
      District_Name: districts_Name,
      Neighbourhood_Name: neighbourhood_Name,
      Land_Numbe: propertyNumber,
    };
    let pPointgraphic: any = createFeatureGraphics(
      "point",
      pointGeometryG,
      102100,
      att,
      favoriteLandSymbol
    );
    console.log("pPointgraphic", pPointgraphic);
    changeFavoriteLandFeature(pPointgraphic);
  }

  function getBuildingsWalletFuncData() {
    setIsLoading(true);
    GetBuildingsWalletFunc(userInfo.id, token)
      .then((returnedData) => {
        console.log(returnedData);
        graphicsbuildingsWalletLayer.removeAll();
        let buildingsWalletDat = returnedData.data.data;
        console.log("buildingsWalletData", buildingsWalletDat);
        if (buildingsWalletDat.length > 0) {
          buildingsWalletDat.forEach((element: any) => {
            console.log("buildingsWalletData element", element);
            generatetBuildingsWalletFuncLogos(
              element.id,
              element.longitude,
              element.latitude,
              element.parcel_Cod,
              element.land_Numbe,
              element.title
            );
          });
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  function createBuildingsWallet() {
    setIsLoading(true);
    let model = {
      Parcel_Cod: parcel_Cod,
      City_Name: city_Name,
      District_Name: districts_Name,
      Neighbourhood_Name: neighbourhood_Name,
      Land_Numbe: propertyNumber,
      Longitude: property?.geometry?.centroid?.longitude,
      Latitude: property?.geometry?.centroid?.latitude,
    };
    CreateBuildingsWalletFunc(model, token)
      .then((result: any) => {
        if (result.data.result == "deplicateEnters") {
          presentToastFunction(
            presentToast,
            "top",
            "danger",
            `تم اضافة القطعة من قبل `
          );
        } else if (result.data.result == true) {
          presentToastFunction(
            presentToast,
            "top",
            "success",
            "تمت الإضافة بنجاح"
          );

          getBuildingsWalletFuncData();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          `عفوا حدث خطأ ${err}`
        );
      });
  }

  function editLandNumberId(obj: any, newLandNumberId: any) {
    let affFeatureLandUrl = arcgisServicesBaseUrl + "7/updateFeatures";
    let benchEdit = `[
          {
            "attributes": {
              "OBJECTID": ${obj},
              "Land_Numbe": '${newLandNumberId}'
            }
          }
        ]
        `;
    let formDataT = new FormData();
    formDataT.set("f", "json");
    formDataT.set("Features", benchEdit);
    let benchEditOption: any = {
      body: formDataT,
      responseType: "json",
    };

    Request(affFeatureLandUrl, benchEditOption)
      .then((response) => {
        presentToastFunction(presentToast, "top", "success", "تم تحديث بنجاح");
        window.F_Land_parcels.refresh();
      })
      .catch((err) => {
        presentToastFunction(
          presentToast,
          "top",
          "danger",
          `عفوا حدث خطأ ${err}`
        );
      });
  }

  function featureGeometry(type: any, features: any, wKid: any) {
    let obj: any = {
      type: type,
      spatialReference: {
        wkid: wKid,
      },
    };
    switch (type) {
      case "polyline":
        obj["paths"] = features;
        break;
      case "polygon":
        obj["rings"] = features;
    }
    return obj;
  }

  let [trackingModalMode, setTrackingModalMode]: any = useState();
  let [trackingModalData, setTrackingModalData]: any = useState();

  // Function to handle data from the child
  const handleDataFromChild = (data: any) => {
    // You can update parent state based on data from child here
    if (data == "startDriving") {
      // يتم تفعليها عن الضغط علي زر انطلق
      if (!isTrackingMyLocationIsOpen) {
        setTrackingModalMode("driving");
        setTrackingMyLocationIsOpen(true);
        trackingMyLocationFunction(true);
      }
    }
  };

  const [docsReportModelPresent, docsReportModelDismiss] = useIonModal(
    DocsReportModel,
    {
      docsReportModelDismiss: (data: string, role: string) =>
        docsReportModelDismiss(data, role),
    }
  );

  function docsReportModelFunc() {
    docsReportModelPresent({
      animated: true,
      breakpoints: [0.6, 1, 1, 0.5, 1],
      initialBreakpoint: 0.8,
      // backdropBreakpoint: 0.8,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
        }
      },
    });
  }

  return (
    <IonPage>
      <IonContent>
        <div className="h-screen" style={{ position: "relative" }}>
          <div className="w-full max-h-screen h-full" id="viewDivMap" />

          {trackingModalMode &&
            trackingModalMode != "" &&
            trackingModalData && (
              <TrackingModals
                mode={trackingModalMode}
                data={trackingModalData} // Pass data as props
                onDataChange={handleDataFromChild} // Callback to receive data from child
                onClose={() => {
                  setTrackingModalMode(null);
                  setTrackingMyLocationIsOpen(false);
                  trackingMyLocationFunction(false);
                  routeLayer.removeAll();
                }} // Example callback to close modal
              />
            )}
          {/* <TrackingModals /> */}
          {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}

          {/* <button className="bg-primaryColor100 bottom-0 fixed left-0 md:flex" style={{ fontSize: "25px", bottom: "136px" }} onClick={() => {
            pushNotification();
          }}> push Notification</button> */}
          {!trackingModalMode && (
            <div
              className={`bottom-0 fixed left-0 md:flex ${
                !isLoading && "animate__animated animate__backInUp"
              }`}
              style={{
                width: "100px",
                bottom: "14px",
                opacity: "0.6!important",
              }}
              onClick={() => {
                window.open(
                  "https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08dc09e4-10fd-4217-8944-923d55eda7ff",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <div
                className="bottom-0 fixed left-0 md:flex"
                style={{
                  left: "8px",
                  margin: "0 auto",
                  maxWidth: "30px",
                  bottom: "2px",
                }}
              >
                <QRCode
                  size={256}
                  className="pb-2"
                  style={{ height: "auto", maxWidth: "150%" }}
                  value={`https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08dc09e4-10fd-4217-8944-923d55eda7ff`}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <img src={FalLogo} />
            </div>
          )}

          {!trackingModalMode && (
            <div className="absolute top-0 pt-4 w-full">
              <div className="w-full flex flex-row justify-start item-center pt-8 px-12">
                <div className="grid grid-cols-3 gap-4 w-full">
                  <div className="flex flex-row justify-start items-center"></div>

                  <div
                    className={`flex flex-row justify-center items-center mr-4  `}
                  >
                    {/* <div className="h-8"> */}
                    <div className="h-8">
                      {/* <h2 className="font-bold text-[blue]">ذكـــي</h2>{" "} */}
                      <img src={Logo} height={"70"} width={"70"} />
                      {/* <h1 className="font-bold text-[Red]">
                        {" "}
                        الاطلاق التجريبي
                      </h1> */}
                    </div>
                  </div>

                  <div className={`flex flex-row justify-end items-center `}>
                    {/* {authenticationState == "authenticated" ? ( */}
                    <div className="h-12 w-12">
                      <button id="popover-button">
                        <IonPopover
                          trigger="popover-button"
                          dismissOnSelect={true}
                          mode="ios"
                        >
                          <IonContent className="animate__animated animate__jackInTheBox">
                            <IonList className="p-2">
                              {authenticationState == "authenticated" ? (
                                <IonItem
                                  button={true}
                                  detail={true}
                                  detailIcon={caretDownCircleOutline}
                                  onClick={() => openProfileModal()}
                                >
                                  القائمة الرئيسية
                                </IonItem>
                              ) : (
                                <IonItem
                                  button={true}
                                  detail={true}
                                  detailIcon={LoginImage}
                                  onClick={() => {
                                    setSignInAsVisitorFunction(false);
                                    window.location.assign(`/app/signIn`);
                                  }}
                                >
                                  تسجيل الدخول
                                </IonItem>
                              )}
                              {window.F_Land_parcels ? (
                                <IonItem
                                  button={true}
                                  detail={true}
                                  detailIcon={searchOutline}
                                  onClick={() => setFilterModalIsOpen(true)}
                                >
                                  البحث
                                </IonItem>
                              ) : null}
                              <IonItem
                                button={true}
                                detail={true}
                                detailIcon={receiptOutline}
                                onClick={() => setLegendModalIsOpen(true)}
                              >
                                دليل الوان ذكي
                              </IonItem>
                              <IonItem
                                button={true}
                                detail={true}
                                detailIcon={newspaperOutline}
                                onClick={() => docsReportModelFunc()}
                              >
                                دليل المستخدم
                              </IonItem>
                              {authenticationState == "authenticated" ? (
                                <IonItem
                                  button={true}
                                  detail={true}
                                  detailIcon={add}
                                  onClick={() => openpAddPropertyModal()}
                                >
                                  اضافة عقار
                                </IonItem>
                              ) : null}
                            </IonList>
                          </IonContent>
                        </IonPopover>
                        <IonIcon
                          icon={menuImage}
                          className="text-5xl text-primaryColor100 cursor-pointer"
                        />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!trackingModalMode && (
            <div
              className={`absolute left-0 pt-20 top-0 ${
                !isLoading && "animate__animated animate__backInUp"
              }`}
            >
              <div
                className="fixed w-12 left-0 ml-5 mt-16 pt-3"
                style={{
                  width: isTrackingMyLocationIsOpen ? "78px" : undefined,
                }}
              >
                {isTrackingMyLocationIsOpen && (
                  <IonIcon
                    className="text-3xl cursor-pointer"
                    icon={close}
                    onClick={() => {
                      setTrackingMyLocationIsOpen(false);
                      trackingMyLocationFunction(false);
                    }}
                  />
                )}
                <button
                  className={`shadow-md drop-shadow-md h-12 w-12 rounded-full absolute ${
                    isTrackingMyLocationIsOpen
                      ? "cursor-default"
                      : "cursor-pointer"
                  } bg-white bg-opacity-60`}
                  onClick={() => {
                    console.log(
                      "click agian isZoomTracking is",
                      isZoomTracking
                    );
                    console.log(
                      "click agian isTrackingMyLocationIsOpen",
                      isTrackingMyLocationIsOpen
                    );
                    if (!isTrackingMyLocationIsOpen) {
                      setTrackingMyLocationIsOpen(true);
                      trackingMyLocationFunction(true);
                    } else {
                      isZoomTracking = false;
                      setTrackingMyLocationIsOpen(true);
                      trackingMyLocationFunction(true);
                    }
                  }}
                >
                  <IonIcon
                    icon={navigate}
                    className="text-2xl text-primaryColor100 mt-1"
                  />
                </button>
              </div>
              <div className="pt-20 w-16">
                <button
                  className={`shadow-md drop-shadow-md h-16 w-16 rounded-full fixed left-0 ml-5 mt-16 animate__animated animate__heartBeat animate__infinite bg-primaryColor100 bg-opacity-100`}
                  onClick={() => {
                    setIsLoading(true);
                    openEstateBrokersModal();
                  }}
                >
                  <IonIcon
                    icon={realEstateBrokerSvg}
                    className="text-4xl text-white mt-1"
                  />
                </button>
              </div>
            </div>
          )}
        </div>

        <ModalSheet
          isOpen={whatsUpIsOpen}
          initialBreakpoint={0.4}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setWhatsUpIsOpen(false);
          }}
          close={() => {
            setWhatsUpIsOpen(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl">
              من فضلك اختر نوع الإرسال
            </p>

            <div className="w-full mt-4">
              <Button
                theme={whatsUpLocationButton}
                text={"إرسال موقع القطعة"}
                size={"big"}
                onClick={() => {
                  setwhatsUpLocationButton("outline");
                  setTimeout(() => {
                    setwhatsUpLocationButton("primary");
                  }, 100);

                  setIsLoading(true);
                  setTimeout(async () => {
                    let message = ` قطعة: ${propertyNumber} | ${neighbourhood_Name.replace(
                      "حي ",
                      ""
                    )} | ${city_Name}        `;
                    if (isPlatform("mobileweb")) {
                      shareOnWhatsAppFunction(parcel_Cod, message, true);
                    } else if (isPlatform("android") || isPlatform("ios")) {
                      SocialSharing.shareViaWhatsApp(
                        message,
                        "",
                        `thki.sa/app/${parcel_Cod}`
                      )
                        .then((result) => {
                          console.log(result);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else {
                      alert("else isPlatform('ios')");
                      shareOnWhatsAppFunction(parcel_Cod, message, true);
                    }
                    setIsLoading(false);
                  }, 2500);
                }}
              />
            </div>
            {propertyAdvertisements.length > 0 ? (
              <div className="w-full mt-4">
                <Button
                  theme={whatsUpAddsButton}
                  text={"إرسال الاعلان بداخل القطعة"}
                  size={"big"}
                  onClick={() => {
                    setwhatsUpAddsButton("outline");
                    setTimeout(() => {
                      setwhatsUpAddsButton("primary");
                    }, 100);

                    setIsLoading(true);
                    setTimeout(async () => {
                      let message = ` قطعة: ${
                        property.attributes.SUBDIVISIONPARCELNUMBER
                      } | ${neighbourhood_Name.replace(
                        "حي ",
                        ""
                      )} | ${city_Name}        `;
                      if (isPlatform("android") || isPlatform("ios")) {
                        SocialSharing.shareViaWhatsApp(
                          message,
                          "",
                          `thki.sa/app/d/${parcel_Cod}`
                        )
                          .then((result) => {
                            console.log(result);
                          })
                          .catch((err) => {
                            console.log(err);
                            shareOnWhatsAppFunction(parcel_Cod, message, false);
                          });
                      } else {
                        shareOnWhatsAppFunction(parcel_Cod, message, false);
                      }
                      setIsLoading(false);
                    }, 2500);
                  }}
                />
              </div>
            ) : null}

            <div className="w-full mt-4">
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setWhatsUpIsOpen(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={isCreateFavoriteLand}
          initialBreakpoint={0.7}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setIsCreateFavoriteLand(false);
          }}
          close={() => {
            setIsCreateFavoriteLand(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl text-center">
              هل ترغب بإضافة هذا العقار إلى مفضلتك ؟
            </p>
            <p className="font-bold text-primaryColor100 text-2xl">
              وسنقوم بإشعارك عندما يتم عرضه
            </p>
            <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
              <Button
                theme={"primary"}
                text={"موافق"}
                size={"big"}
                onClick={() => {
                  createFavoriteLand();
                  setIsCreateFavoriteLand(false);
                }}
              />
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setIsCreateFavoriteLand(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={isCreateBuildingsWallet}
          initialBreakpoint={0.7}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setIsCreateBuildingsWallet(false);
          }}
          close={() => {
            setIsCreateBuildingsWallet(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl">
              هل ترغب بإضافة هذه القطعة إلى محفظتك ؟
            </p>
            <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
              <Button
                theme={"primary"}
                text={"موافق"}
                size={"big"}
                onClick={() => {
                  createBuildingsWallet();
                  setIsCreateBuildingsWallet(false);
                }}
              />
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setIsCreateBuildingsWallet(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={isOpenSignIn}
          initialBreakpoint={0.5}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setIsOpenSignIn(false);
          }}
          close={() => {
            setIsOpenSignIn(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl">
              يرجى تسجيل الدخول لتتمكن من الاضافة
            </p>

            <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
              <Button
                theme={"primary"}
                text={"موافق"}
                size={"big"}
                onClick={() => {
                  broswerHistory.push("/app/signIn");
                  broswerHistory.go(0);
                  setIsOpenSignIn(false);
                }}
              />
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setIsOpenSignIn(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={editIsOpen}
          initialBreakpoint={0.4}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setEditIsOpen(false);
          }}
          close={() => {
            setEditIsOpen(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl">
              ادخل رقم أو اسم القطعة
            </p>

            <div className="w-full mt-4">
              <input
                type="text"
                value={parcelNumberOrText}
                onChange={(e) => setParcelNumberOrText(e.target.value)}
                className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                placeholder="الرقم أو الاسم"
              />
            </div>
            <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4">
              <Button
                theme={"primary"}
                text={"موافق"}
                size={"big"}
                onClick={() => {
                  editLandNumberId(objectId, parcelNumberOrText);
                  setEditIsOpen(false);
                }}
              />
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setEditIsOpen(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={landOperationsIsOpen}
          initialBreakpoint={0.4}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setLandOperationsIsOpen(false);
          }}
          close={() => {
            setLandOperationsIsOpen(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl whitespace-nowrap animate__animated animate__bounceInDown">
              اختار نوع العملية
            </p>
            <div className="w-full mt-4 border-solid border-2 rounded border-paragraphColor hover:border-primaryColor100 animate__animated animate__bounceInDown">
              <IonItem>
                <IonSelect
                  interface="action-sheet"
                  placeholder="يرجى الاختيار"
                  onIonChange={(e: any) =>
                    setLandOperationsValue(e.target.value)
                  }
                >
                  {isOperation ? (
                    <>
                      <IonSelectOption value="2">
                        اعدادات اللوجو
                      </IonSelectOption>
                    </>
                  ) : isAdmin ? (
                    <>
                      <IonSelectOption value="2">
                        اعدادات اللوجو
                      </IonSelectOption>
                      <IonSelectOption value="3">
                        إضافة أو الغاء القطعة المضيئة
                      </IonSelectOption>
                    </>
                  ) : null}
                </IonSelect>
              </IonItem>
            </div>
            <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4 animate__animated animate__bounceInUp">
              <Button
                theme={"primary"}
                text={"موافق"}
                size={"big"}
                onClick={() => {
                  if (landOperationsValue == "1") {
                    setEditIsOpen(true);
                    setLandOperationsIsOpen(false);
                  } else if (landOperationsValue == "2") {
                    setLogoPointsIsOpen(true);
                    setLandOperationsIsOpen(false);
                  } else if (landOperationsValue == "3") {
                    changeFlashLightFeature();
                    setLandOperationsIsOpen(false);
                  }
                }}
              />
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setLandOperationsIsOpen(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={logoPointsIsOpen}
          initialBreakpoint={0.9}
          breakpoints={[1, 1, 1, 1]}
          onDidDismiss={() => {
            setLogoPointsIsOpen(false);
          }}
          close={() => {
            setLogoPointsIsOpen(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-2xl animate__animated animate__bounceInDown">
              اعدادات اللوجو والشعارات
            </p>
            {getLogosData?.find((i: any) => i.parcelObjId == parcel_Cod) ? (
              <>
                <div className="w-full mt-4 animate__animated animate__bounceInDown">
                  <Button
                    theme={"danger"}
                    text={"حذف اللوجو"}
                    size={"big"}
                    onClick={() => {
                      let logoId = getLogosData?.find(
                        (i: any) => i.parcelObjId == parcel_Cod
                      );
                      deleteLogosDataFunction(logoId.id, token).then(
                        (result) => {
                          if (result) {
                            let currentGraphic = graphicsLogoLayer.graphics
                              .filter((x) => x.attributes.logoId == logoId.id)
                              .getItemAt(0);
                            if (currentGraphic) {
                              graphicsLogoLayer.graphics.remove(currentGraphic);
                            } else {
                              let currentGraphic =
                                graphicsLogoLayerlogoAdd.graphics
                                  .filter(
                                    (x) => x.attributes.logoId == logoId.id
                                  )
                                  .getItemAt(0);
                              graphicsLogoLayerlogoAdd.graphics.remove(
                                currentGraphic
                              );
                            }
                            handleRemoveLogos(logoId.id);
                            presentToastFunction(
                              presentToast,
                              "top",
                              "success",
                              "تم الحذف بنجاح"
                            );
                            setLogoPointsIsOpen(false);
                          }
                        }
                      );
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="relative w-full mt-4 animate__animated animate__bounceInLeft">
                  <IonList
                    className={
                      logoPointsTypeValue
                        ? `${inputClassWithOutError}`
                        : `${inputClassWithError}`
                    }
                  >
                    {isOperation ? (
                      <IonItem>
                        <IonSelect
                          interface="action-sheet"
                          placeholder="يرجى الاختيار"
                          onIonChange={(e: any) =>
                            setLogoPointsTypeValue(e.target.value)
                          }
                        >
                          <IonSelectOption value="hospital">
                            مستشفي
                          </IonSelectOption>
                          <IonSelectOption value="mosque">مسجد</IonSelectOption>
                          <IonSelectOption value="school">
                            مدرسة أولاد
                          </IonSelectOption>
                          <IonSelectOption value="girlSchool">
                            مدرسة بنات
                          </IonSelectOption>
                          <IonSelectOption value="beqala">
                            بقالة
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    ) : isAdmin ? (
                      <IonItem>
                        <IonSelect
                          interface="action-sheet"
                          placeholder="يرجى الاختيار"
                          onIonChange={(e: any) =>
                            setLogoPointsTypeValue(e.target.value)
                          }
                        >
                          <IonSelectOption value="hospital">
                            مستشفي
                          </IonSelectOption>
                          <IonSelectOption value="mosque">مسجد</IonSelectOption>
                          <IonSelectOption value="school">
                            مدرسة أولاد
                          </IonSelectOption>
                          <IonSelectOption value="girlSchool">
                            مدرسة بنات
                          </IonSelectOption>
                          <IonSelectOption value="beqala">
                            بقالة
                          </IonSelectOption>
                          <IonSelectOption value="fireing">
                            محروقات
                          </IonSelectOption>
                          <IonSelectOption value="logoAdd">
                            لوجو دعائي
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    ) : null}
                  </IonList>
                  <label className="absolute text-sm font-bold top-2 z-10  bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                    اختيار اللوجو أو الشعار
                  </label>
                </div>

                {logoPointsTypeValue == "logoAdd" ? (
                  <>
                    <div className="relative w-full mt-2 animate__animated animate__bounceInRight">
                      <IonItem>
                        <IonInput
                          type="number"
                          autofocus={true}
                          className={
                            inputLogoWidthRef.current?.value
                              ? `${inputClassWithOutError}`
                              : `${inputClassWithError}`
                          }
                          ref={inputLogoWidthRef}
                        ></IonInput>
                      </IonItem>
                      <label className="absolute text-sm font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                        العرض
                      </label>
                    </div>

                    <div className="relative w-full mt-2 animate__animated animate__bounceInLeft">
                      <IonItem>
                        <IonInput
                          type="number"
                          autofocus={true}
                          className={
                            inputLogoHeightRef.current?.value
                              ? `${inputClassWithOutError}`
                              : `${inputClassWithError}`
                          }
                          ref={inputLogoHeightRef}
                        ></IonInput>
                      </IonItem>
                      <label className="absolute text-sm font-bold top-2 z-10 pt-2 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                        الطول
                      </label>
                    </div>

                    <div className="relative w-full mt-2 animate__animated animate__bounceInRight">
                      <IonItem>
                        <IonInput
                          type="text"
                          autofocus={true}
                          className={
                            inputLogoNameRef?.current?.value
                              ? `${inputClassWithOutError}`
                              : `${inputClassWithError}`
                          }
                          ref={inputLogoNameRef}
                        ></IonInput>
                      </IonItem>
                      <label className="absolute text-sm font-bold top-2 z-10  pt-2 bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                        اسم اللوجو
                      </label>
                    </div>

                    <div className="relative w-full mt-2 animate__animated animate__bounceInLeft">
                      <IonItem>
                        <IonInput
                          type="text"
                          autofocus={true}
                          className={
                            inputLogoDescriptionRef?.current?.value
                              ? `${inputClassWithOutError}`
                              : `${inputClassWithError}`
                          }
                          ref={inputLogoDescriptionRef}
                        ></IonInput>
                      </IonItem>
                      <label className="absolute text-sm font-bold top-2 z-10  pt-2 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                        وصف اللوجو
                      </label>
                    </div>

                    <div className="w-full animate__animated animate__backInUp">
                      <IonItem>
                        <div className="relative w-full mt-4">
                          <input
                            onChange={(e: any) => {
                              setLogoFile(e.currentTarget.files);
                            }}
                            type="file"
                            multiple
                            accept=".svg"
                            className={
                              logoFile
                                ? `${inputClassWithOutError}`
                                : `${inputClassWithError}`
                            }
                            placeholder="الملف"
                          />
                          <label className="absolute text-sm font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                            يرجى اخيار الملف
                          </label>
                        </div>
                      </IonItem>
                    </div>
                  </>
                ) : null}
              </>
            )}
            <div className="w-full mt-4 px-4 grid grid-cols-2 gap-4 animate__animated animate__backInUp">
              {logoPointsTypeValue ? (
                <Button
                  theme={"primary"}
                  text={"موافق"}
                  size={"big"}
                  onClick={async () => {
                    setIsLoading(true);
                    let model = new PointLogosDTO();
                    model.longitude = property?.geometry?.centroid?.longitude;
                    model.latitude = property?.geometry?.centroid?.latitude;
                    model.parcelObjId = parcel_Cod;
                    model.logoFileName = logoPointsTypeValue;
                    model.isActive = true;
                    model.type = logoPointsTypeValue;
                    model.zoomLevel = 18; //not use && use only diffrent graphic layer
                    if (logoPointsTypeValue == "logoAdd") {
                      model.width = Number(inputLogoWidthRef.current?.value);
                      model.height = Number(inputLogoHeightRef.current?.value);
                    } else {
                      model.width = 20;
                      model.height = 20;
                    }
                    if (logoPointsTypeValue == "logoAdd") {
                      if (
                        !logoFile ||
                        !inputLogoDescriptionRef.current?.value ||
                        !inputLogoNameRef.current?.value
                      ) {
                        setIsLoading(false);
                        presentToastFunction(
                          presentToast,
                          "top",
                          "danger",
                          `يرجى ادخال باقي البيانات`
                        );
                        return;
                      } else {
                        model.logoName =
                          inputLogoNameRef?.current?.value?.toString();
                        model.description =
                          inputLogoDescriptionRef?.current?.value?.toString();
                        let checkFileResult = await onFileUploadValidationSize(
                          logoFile[0],
                          10 * 5000
                        );
                        if (!checkFileResult) {
                          setIsLoading(false);
                          presentToastFunction(
                            presentToast,
                            "top",
                            "danger",
                            ` عفوا الملف  ${logoFile[0].name}   حجم اكبر من 5 كيلوبايت`
                          );
                          return;
                        }
                        let files: any = await convertToBase64(logoFile[0]);
                        let regex = /data:.*base64,/;
                        let fileWithOut = files.replace(regex, "");
                        model.LogoFile = fileWithOut;
                      }
                    }
                    if (model.width == 0 || model.height == 0) {
                      presentToastFunction(
                        presentToast,
                        "top",
                        "danger",
                        `عفوا طول او عرض الللوجو صفر..يرجى المحاولة`
                      );
                    } else {
                      createLogosDataFunction(model, token)
                        .then((result) => {
                          if (result) {
                            getLogoData();
                            presentToastFunction(
                              presentToast,
                              "top",
                              "success",
                              "تمت الإضافه بنجاح"
                            );
                            setLogoPointsIsOpen(false);
                            setIsLoading(false);
                          }
                          setLogoPointsActiveValue("");
                          setLogoPointsTypeValue("");
                          setLogoFile("");
                          setLogoPointsZoomLevelValue("");
                          setIsLoading(false);
                        })
                        .catch((result) => {
                          setIsLoading(false);
                          presentToastFunction(
                            presentToast,
                            "top",
                            "danger",
                            `${result} عفوا خطئ اثناء الحفظ`
                          );
                        });
                    }
                  }}
                />
              ) : null}
              <Button
                theme={"danger"}
                text={"إغلاق"}
                size={"big"}
                onClick={() => {
                  setLogoPointsIsOpen(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <ModalSheet
          isOpen={filterModalIsOpen}
          initialBreakpoint={0.8}
          breakpoints={[0, 0.25, 0.5, 0.75]}
          onDidDismiss={() => {
            setFilterModalIsOpen(false);
          }}
          close={() => {
            setFilterModalIsOpen(false);
          }}
        >
          <div className="w-full h-full pt-4 flex flex-col justify-start items-center animate__animated animate__rotateInUpLeft">
            {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}

            <p className="font-bold text-primaryColor100 text-2xl">
              البحث المتقدم
            </p>

            <div className="relative w-full mt-4">
              <IonInput
                readonly={true}
                placeholder="يرجى اختيار المنطقة"
                value={region}
                onClick={() => {
                  setIsModalOpen("region");
                  setFilteredData(regions);
                }}
                className="w-full border-2 rounded border-paragraphColor text-2xl text-primaryColor100"
              ></IonInput>
              <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                المنطقة
              </label>
            </div>
            <div className="relative w-full mt-4">
              <IonInput
                readonly={true}
                disabled={region ? false : true}
                placeholder="يرجى اختيار المدينة"
                value={city}
                onClick={() => {
                  setIsModalOpen("city");
                  setFilteredData(cities);
                }}
                className="w-full border-2 rounded border-paragraphColor text-2xl text-primaryColor100"
              ></IonInput>
              <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                المدينة{" "}
                <span className="text-xl font-bold top-2 z-10 bg-white px-2 text-paragraphColor -translate-y-6 right-10">
                  (اختياري)
                </span>
              </label>
            </div>

            <div className="relative w-full mt-4">
              <IonInput
                readonly={true}
                disabled={city ? false : true}
                placeholder="يرجى اختيار الحي"
                value={neighborhood}
                onClick={() => {
                  setIsModalOpen("neighborhood");
                  setFilteredData(districts);
                }}
                className="w-full border-2 rounded border-paragraphColor text-2xl text-primaryColor100"
              ></IonInput>
              <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                الحي{" "}
                <span className="text-xl font-bold top-2 z-10 bg-white px-2 text-paragraphColor -translate-y-6 right-10">
                  (اختياري)
                </span>
              </label>
            </div>

            <div className="relative w-full mt-4">
              <IonInput
                readonly={true}
                disabled={neighborhood ? false : true}
                placeholder="يرجى اختيار المخطط"
                value={subDivisionPlanSName}
                onClick={() => {
                  setIsModalOpen("subDivision");
                  setFilteredData(subDivisionPlanS);
                }}
                className="w-full border-2 rounded border-paragraphColor text-2xl text-primaryColor100"
              ></IonInput>
              <label className="absolute text-xl font-bold top-2 z-10  bg-white  px-2  text-primaryColor100 -translate-y-6 right-10">
                المخطط{" "}
                <span className="text-xl font-bold top-2 z-10 bg-white px-2 text-paragraphColor -translate-y-6 right-10">
                  (اختياري)
                </span>
              </label>
            </div>

            <div className="relative w-full mt-4">
              <IonInput
                readonly={true}
                disabled={subDivisionPlanSName ? false : true}
                placeholder="يرجى اختيار القطعة"
                value={parcelName}
                onClick={() => {
                  setIsModalOpen("parcel");
                  setFilteredData(parcels);
                  console.log("parccccccels", parcels);
                }}
                className="w-full border-2 rounded border-paragraphColor text-2xl text-primaryColor100"
              ></IonInput>
              <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2 text-primaryColor100 -translate-y-6 right-10">
                رقم القطعة{" "}
                <span className="text-xl font-bold top-2 z-10 bg-white px-2 text-paragraphColor -translate-y-6 right-10">
                  (اختياري)
                </span>
              </label>
            </div>

            <div className="w-full my-4">
              <Button
                theme={"primary"}
                text={"تأكيد"}
                size={"big"}
                onClick={() => {
                  setFilterModalIsOpen(false);
                }}
              />
            </div>
          </div>
        </ModalSheet>

        <IonModal
          id="propertyModal"
          isOpen={propertyModalIsOpen && !buildingWalletSingle}
          backdropDismiss={true}
          onWillDismiss={() => {
            setPropertyModalIsOpen(false);
          }}
          trigger="open-custom-dialog"
        >
          <div className="wrapper">
            <div className="w-full flex flex-col justify-start items-center py-2 px-6">
              <div className="w-full flex flex-col justify-start items-center">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="flex flex-col justify-start items-start">
                    <p className="font-bold text-darkBlue text-xl w-64 animate__animated animate__fadeInRight">
                      {landAddress}
                    </p>
                  </div>

                  {isAdmin || isOperation ? (
                    <div className="flex flex-col justify-center items-center animate__animated animate__fadeInLeft">
                      <IonIcon
                        icon={settingsOutline}
                        style={{ height: "35px", color: "#dc3545" }}
                        onClick={() => {
                          setLandOperationsIsOpen(true);
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                {propertyAdvertisements.length == 0 ? (
                  <div className="w-full bg-bkColor rounded border-[1px] border-paragraphColor flex flex-row justify-center items-center py-4 mt-4 animate__animated animate__fadeInUp">
                    <p className="font-medium text-paragraphColor text-sm">
                      لا توجد اعلانات على هذا العقار
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col justify-start item-start w-full">
                    <p className="font-bold text-primaryColor100 text-xl mt-2 animate__animated animate__fadeInRight">
                      اعلانات القطعة
                    </p>
                    {propertyAdvertisements.length > 1 && (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "15px",
                          color: "#0031c4",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span>
                          &#8594; اسحب يمين او يسار لتبديل الإعلان &#8592;
                        </span>
                      </div>
                    )}
                    <div className="w-full flex flex-row justify-start items-center animate__animated animate__wobble">
                      <div
                        className={`flex space-x-3`}
                        style={{
                          overflow:
                            propertyAdvertisements.length > 1
                              ? "auto"
                              : "unset",
                        }}
                      >
                        <div
                          className={`flex flex-shrink-0 ${
                            propertyAdvertisements.length == 1 && "w-full"
                          } overflow-x-auto flex space-x-4 p-4 ${
                            propertyAdvertisements.length > 1 && "pr-0"
                          }`}
                          id="scrollContainer"
                        >
                          {propertyAdvertisements
                            .slice(0, 2)
                            .map((item: any, index: any) => (
                              <>
                                <div
                                  key={index}
                                  className={`${
                                    propertyAdvertisements.length > 1 && "w-max"
                                  } bg-[#fbfbff] rounded border-[1px] border-[transparent] flex flex-row justify-start items-start mt-2 shadow-md ml-2 cursor-pointer`}
                                  onClick={async () => {
                                    setIsLoading(true);
                                    setadLicenseNumber(item.adLicenseNumber);
                                    console.log("iteeeeeem", item);
                                    await getDataByByAdLicenseNumber(
                                      item.adLicenseNumber,
                                      item.idType
                                    );
                                  }}
                                >
                                  <div
                                    className="w-max basis-1/4 py-1 px-1 mr-1 bordrer-solid border-2 rounded"
                                    style={handleStyleButtonByLandTypes(
                                      item.landTypeDesc
                                    )}
                                  >
                                    <img
                                      src={
                                        item.image && item.image != ""
                                          ? item.image
                                          : noImageSvg
                                      }
                                      className="w-20 h-20 object-cover border-solid border-2 border-transparent rounded-lg bg-white"
                                    />
                                  </div>

                                  <div
                                    className={`${
                                      propertyAdvertisements.length == 1 &&
                                      "basis-3/4"
                                    } mr-1 bordrer-solid border-2 rounded py-1 px-1 border-transparent`}
                                    style={{
                                      lineHeight: "normal",
                                      width:
                                        propertyAdvertisements.length > 1
                                          ? "60%"
                                          : "unset",
                                    }}
                                  >
                                    <p
                                      className={`text-primaryColor100 mr-2 font-bold whitespace-nowrap`}
                                      style={{
                                        width: "10em",
                                        overflow: "auto",
                                        textOverflow: "auto",
                                      }}
                                    >
                                      {" "}
                                      {item.propertyType
                                        ? item.propertyType
                                        : "غير محدد"}{" "}
                                      {item.landTypeDesc && "("}{" "}
                                      {item.landTypeDesc
                                        ? item.landTypeDesc
                                        : "غير محدد"}{" "}
                                      {item.landTypeDesc && ")"}
                                    </p>

                                    <p className="text-black mr-2">
                                      المساحة :{" "}
                                      {item.propertyArea == 0 ||
                                      item.propertyArea == null
                                        ? "غير محدد"
                                        : item.propertyArea}
                                    </p>

                                    <p
                                      className="text-redColor mr-2"
                                      onClick={() => {
                                        console.log(item);
                                      }}
                                    >
                                      {item.landTotalPrice == 0 ||
                                      item.landTotalPrice == null
                                        ? ""
                                        : `السعر: ${NumberWithCommas(
                                            item.landTotalPrice
                                          )}`}
                                    </p>
                                  </div>

                                  <div className="py-1 px-1 mr-1 bordrer-solid border-2 border-l-0 rounded-r-lg bg-primaryColor100">
                                    <IonIcon
                                      icon={eye}
                                      style={{
                                        width: "25px",
                                        height: "5em",
                                        margin: "auto",
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-auto flex flex-row justify-between items-center py-2 mt-2 animate__animated animate__jello">
                  <div className="flex flex-row justify-start items-center">
                    <div className="flex flex-col justify-center items-center text-center ml-4">
                      <div
                        onClick={async () => {
                          setWhatsUpIsOpen(true);
                        }}
                        className="w-11 h-9 rounded flex flex-col flex-auto justify-center items-center border-2 border-primaryColor100"
                      >
                        <IonIcon
                          icon={whatsappSvg}
                          style={{
                            color: "#0031c4",
                            fontSize: "large",
                            margin: "unset",
                          }}
                        ></IonIcon>
                      </div>
                      <p className="mt-px" style={{ fontSize: "15px" }}>
                        واتساب
                      </p>
                    </div>

                    <div className="flex flex-col justify-center items-center text-center ml-4">
                      <div
                        onClick={() => {
                          showOnGoogleMapsFunction(property.geometry.centroid);
                        }}
                        className="w-11 h-9 text-primaryColor100 rounded flex flex-col flex-auto justify-center items-center border-2 border-primaryColor100"
                      >
                        <IonIcon
                          icon={googleMapsSvg}
                          className="w-16 h-11"
                          style={{
                            color: "#0031c4",
                            fontSize: "large",
                            margin: "unset",
                          }}
                        ></IonIcon>
                      </div>
                      <p
                        className="mt-px"
                        style={{
                          fontSize: "14px",
                          width: "38px",
                          height: "24px",
                          lineHeight: "normal",
                        }}
                      >
                        جوجل ماب
                      </p>
                    </div>

                    {isUser || authenticationState == "unAuthenticated" ? (
                      <>
                        <div className="flex flex-col justify-center items-center text-center ml-4">
                          <div
                            onClick={() => {
                              if (authenticationState == "unAuthenticated") {
                                setIsOpenSignIn(true);
                              } else {
                                setIsCreateFavoriteLand(true);
                              }
                            }}
                            className="w-11 h-9 bg-primaryColor100 text-primaryColor100 rounded flex flex-col flex-auto justify-center items-center border-2 border-primaryColor100"
                          >
                            <IonIcon
                              icon={bookmarks}
                              style={{
                                color: "white",
                                fontSize: "large",
                                margin: "unset",
                              }}
                            ></IonIcon>
                          </div>
                          <p className="mt-px text-xl">مفضلة</p>
                        </div>
                      </>
                    ) : null}

                    <div className="flex flex-col justify-center items-center text-center ml-4">
                      <div
                        onClick={() => {
                          //showOnGoogleMapsFunction(property.geometry.centroid);
                          setIsLoading(true);
                          routeLayer.removeAll();
                          routeParams.stops.features = [];
                          addStop(currentEventClick);
                        }}
                        className="w-11 h-9 bg-primaryColor100 text-primaryColor100 rounded flex flex-col flex-auto justify-center items-center border-2 border-primaryColor100"
                      >
                        <IonIcon
                          icon={navSvg}
                          style={{
                            color: "white",
                            fontSize: "large",
                            margin: "unset",
                          }}
                        ></IonIcon>
                      </div>
                      <p className="mt-px text-xl">تتبع</p>
                    </div>

                    <div className="flex flex-col justify-center items-center text-center ml-4">
                      <div
                        onClick={() => {
                          if (authenticationState == "unAuthenticated") {
                            setIsOpenSignIn(true);
                          } else {
                            setIsCreateBuildingsWallet(true);
                          }
                        }}
                        className="w-11 h-9 rounded flex flex-col flex-auto justify-center items-center border-2 border-primaryColor100"
                      >
                        <IonIcon
                          icon={walletSvg}
                          style={{
                            color: "#0031c4",
                            fontSize: "large",
                            margin: "unset",
                          }}
                        ></IonIcon>
                      </div>
                      <p className="mt-px" style={{ fontSize: "15px" }}>
                        محفظة
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonModal>

        <IonModal
          isOpen={favoriteLandByParcCodeModalIsOpen}
          initialBreakpoint={0.7}
          breakpoints={[1, 1, 1, 1]}
          onWillDismiss={() => {
            setFavoriteLandByParcCodeModalIsOpen(false);
          }}
          trigger="open-custom-dialog"
        >
          <div className="wrapper">
            {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
            <div className="flex flex-col justify-end items-end  p-2">
              <IonButton
                color="danger"
                onClick={() => {
                  setFavoriteLandByParcCodeModalIsOpen(false);
                }}
              >
                {" "}
                اغلاق
              </IonButton>
            </div>
            <div className="w-full flex flex-col justify-start items-center py-4 px-6">
              <div className="w-full flex flex-col justify-start items-center ">
                <div className="grid gap-x-8 gap-y-4 grid-cols-3">
                  <div className="w-full flex flex-col justify-center items-end">
                    <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                      <p className="font-bold text-paragraphColor text-xl">
                        قطعة
                      </p>
                      <p className="font-bold text-white text-xl">
                        {propertyFavoriteLand[0]?.land_Numbe}
                      </p>
                    </div>
                  </div>

                  <div className="w-full flex flex-col justify-center items-end">
                    <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                      <p className="font-bold text-paragraphColor text-xl">
                        حي
                      </p>
                      <p className="font-bold text-white text-xl">
                        {propertyFavoriteLand[0]?.neighbourhood_Name
                          .replace("حى", "")
                          .replace("حي", "")}
                      </p>
                    </div>
                  </div>

                  <div className="w-full flex flex-col justify-center items-end">
                    <div className="flex flex-col justify-start items-center p-2 px-4 rounded bg-[#04299A]">
                      <p className="font-bold text-paragraphColor text-xl">
                        مدينة{" "}
                      </p>
                      <p className="font-bold text-white text-xl">
                        {propertyFavoriteLand[0]?.city_Name}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-bkColor rounded border-[1px]  border-paragraphColor flex flex-col justify-start items-start py-2 px-2 mt-2">
                  <div className="w-full flex flex-row justify-between items-center mt-5 mt-3">
                    <div className="flex flex-col justify-start items-start ">
                      <p className="font-bold text-primaryColor100 text-2xl">
                        عدد طالبي هذا العقار: ( {propertyFavoriteLand.length} )
                      </p>
                    </div>
                  </div>
                  <table className="w-full text-2xl items-center py-2 mt-2">
                    <tr>
                      <th>رقم الجوال</th>
                    </tr>
                    {propertyFavoriteLand.map((item: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td>
                            <a
                              href={`tel:0${Number(
                                item.phoneNumber
                              ).toString()}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              0{Number(item.phoneNumber).toString()}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </IonModal>

        <IonModal
          isOpen={legendModalIsOpen}
          initialBreakpoint={0.7}
          breakpoints={[1, 1, 1, 1]}
          onWillDismiss={() => {
            setLegendModalIsOpen(false);
          }}
          trigger="open-custom-dialog"
        >
          <div className="wrapper liftUpModal">
            <div className="w-full flex flex-col justify-start items-center py-4 px-6">
              <div className="w-full flex flex-col justify-start items-center ">
                <div className="w-full flex flex-row justify-center items-center mt-1">
                  <div className="flex flex-col justify-start items-start">
                    <p className="font-bold text-primaryColor100 text-2xl">
                      دليل الوان ذكي
                    </p>
                  </div>
                </div>
                <img
                  className="rounded w-full animate__animated animate__flipInX"
                  src={LegendImage}
                />
              </div>
            </div>
          </div>
        </IonModal>

        <IonModal
          isOpen={isModalOpen != ""}
          initialBreakpoint={0.7}
          breakpoints={[1, 1, 1, 1]}
          onWillDismiss={() => {
            setIsModalOpen("");
            setSearchQuery("");
          }}
          trigger="open-custom-dialog"
        >
          <IonHeader>
            <IonToolbar className="py-1">
              <IonTitle slot="center" className="my-2 text-primaryColor100">
                الرجاء الاختيار
              </IonTitle>
            </IonToolbar>
            <IonSearchbar
              placeholder="البحث"
              value={searchQuery}
              onIonInput={(e: CustomEvent) => handleSearch(e)}
            />
          </IonHeader>
          <IonContent className="animate__animated animate__zoomInDown">
            <IonList className="p-2">
              {filteredData.map((item, index) => (
                <IonItem
                  className="text-2xl text-primaryColor100"
                  key={index}
                  button
                  onClick={() => handleSelectItem(item)}
                >
                  <IonLabel>{item.name}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonContent>
        </IonModal>

        {/* <TrackingModals /> */}
        {/* isOpen={trackingModalMode}
          setIsopen={setTrackingModalMode}
          data={trackingModalData}
        /> */}
      </IonContent>
    </IonPage>
  );
};

export default Home;
