export const setToken = (data) => ({
  type: "setToken",
  payload: data,
});

export const changeToAuthenticated = () => ({
  type: "changeToAuthenticated",
  payload: "authenticated",
});

export const changeToUnAuthenticated = () => ({
  type: "changeToUnAuthenticated",
  payload: "unAuthenticated",
});

export const setUserInfo = (data) => ({
  type: "setUserInfo",
  payload: data,
});
export const setMapView = (data) => ({
  type: "setMapView",
  payload: data,
});
export const setOnBoardingSkippedStatus = (data) => ({
  type: "setOnBoardingSkippedStatus",
  payload: data,
});
export const setSignInAsVisitor = (data) => ({
  type: "setSignInAsVisitor",
  payload: data,
});
export const setCurrentUserTryToLogin = (data) => ({
  type: "setCurrentUserTryToLogin",
  payload: data,
});
export const setOtherData = (data) => ({
  type: "setOtherData",
  payload: data,
});

export const signOut = () => ({
  type: "signOut",
  payload: {
    userInfo: {},
    authenticationState: "unAuthenticated",
    token: "",
    onBoardingSkippedStatus: false,
    setSignInAsVisitor: false,
  },
});
