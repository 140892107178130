import React from "react";
import {
  IonModal,
  IonContent,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

interface Props {
  isOpen: any;
  close: any;
  onDidDismiss: any;
  children: any;
  initialBreakpoint?: any;
  breakpoints?: any;
}

const ModalSheet: React.FC<Props> = ({
  isOpen,
  close,
  onDidDismiss,
  children,
  initialBreakpoint,
  breakpoints,
}: Props): JSX.Element => {
  return (
    <IonModal
      initialBreakpoint={initialBreakpoint ? initialBreakpoint : 0.6}
      breakpoints={breakpoints ? breakpoints : [0, 0.25, 0.5, 0.75]}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="ion-padding liftUpModal">{children}</div>
      {/* <IonContent className="ion-padding">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => close()}>X</IonButton>
          </IonButtons>
        </IonToolbar>

        {children}
      </IonContent> */}
    </IonModal>
  );
};

export default ModalSheet;
