import React from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon } from "@ionic/react";
import SignIn_Background from "../../images/onBoardingBackground.svg";
import ChangePhoneNumber_Image from "../../images/changePhoneNumber.svg";
import Button from "../../components/atoms/button";
import { chevronBackOutline } from "ionicons/icons";

const SignIn: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage className="max-h-screen">
      <IonContent className="max-h-screen">
        <div className="w-full h-full flex flex-col justify-between items-center">
          <div className="w-full grid grid-cols-3">
            <div></div>
            <div className="col-span-1  py-6 flex flex-row justify-center items-center">
              <p className="font-bold text-primaryColor100 text-2xl">
                تغير رقم الهاتف
              </p>
            </div>
            <div className="col-span-1 py-6 px-6 flex flex-row justify-end items-center">
              <button
                onClick={() => {
                  history.back();
                }}
                className="flex flex-col justify-center items-center"
              >
                <IonIcon
                  icon={chevronBackOutline}
                  className="text-2xl text-primaryColor100"
                />
              </button>
            </div>
          </div>
          <div className="w-full relative pt-16 bg-white flex flex-col justify-end items-center">
            <div className="absolute flex flex-row justify-center items-center bottom-0 w-full">
              <img className="w-full" src={SignIn_Background} />
            </div>
            <div className="w-36 z-10">
              <img src={ChangePhoneNumber_Image} />
            </div>
          </div>
          <div className="w-full h-full py-2 px-8 bg-white flex flex-col justify-start items-center">
            <p className="font-bold text-primaryColor100 text-3xl mt-2">
              تغير رقم الهاتف
            </p>
            <p className="font-medium text-paragraphColor text-xl mt-1">
              قم بكتابة رقم هاتفك القديم و الجديد لتأكيد الرقم
            </p>
            <div className="relative w-full  mt-16">
              <input
                type="text"
                className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                placeholder="ادخل رقم الهاتف القديم"
              />
              <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                رقم الهاتف القديم
              </label>
            </div>
            <div className="relative w-full  mt-6">
              <input
                type="text"
                className="block px-5 py-2.5 font-bold placeholder-paragraphColor w-full text-xl text-primaryColor100 bg-transparent rounded border-2 border-paragraphColor appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600"
                placeholder="ادخل رقم الهاتف الجديد"
              />
              <label className="absolute text-xl font-bold top-2 z-10 bg-white px-2  text-primaryColor100 -translate-y-6 right-6">
                رقم الهاتف الجديد
              </label>
            </div>
            <div className="w-full mt-8">
              <Button
                theme={"primary"}
                text={"تغير"}
                size={"big"}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
