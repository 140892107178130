import {
  IonContent,
  useIonModal,
  useIonToast,
  IonIcon,
  IonImg,
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonFooter,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { useEffect, useState } from "react";
import Spinner from "../../components/atoms/spinner";
import MyAdvertisements from "../myAdvertisements";
import userSvg from "../../images/user.svg";
import phoneOfficeSvg from "../../assets/uicons/svg/fi-ts-phone-office.svg";
import phoneSvg from "../../assets/uicons/svg/fi-ts-circle-phone-flip.svg";
import emptySvg from "../../images/empty.svg";
import landLocationSvg from "../../assets/uicons/svg/fi-ts-land-location.svg";
import bioSvg from "../../assets/uicons/svg/fi-ts-comment-dots.svg";
import realEstateEmpoloyeeSvg from "../../assets/uicons/svg/fi-ts-employee-man.svg";
import realEstateEmpoloyeesSvg from "../../assets/uicons/svg/fi-ts-employees.svg";
import { Clipboard } from "@capacitor/clipboard";
import { presentToastFunction } from "../../services/genericMethoud/generice";
import {
  call,
  chevronBackOutline,
  heart,
  logoWhatsapp,
  mailOutline,
  megaphoneOutline,
  personOutline,
  shareOutline,
} from "ionicons/icons";
import {
  GetMaxAddCountByRegionFunction,
  GetUserFunction,
} from "../../services/API";
import { useSelector } from "react-redux";
import Joyride from "react-joyride";
import "./style.css";
import CreateFavoriteLand from "../createFavoriteLand";

const EstateBrokersModal = ({
  currentRegion,
  id,
  onCloseEstateBrokersDismiss,
}: {
  currentRegion: any;
  id?: any;
  onCloseEstateBrokersDismiss: (
    currentRegion?: string | null | undefined | number,
    role?: string
  ) => void;
}) => {
  const [estateBroker, setEstateBroker]: any = useState();
  const [otherBrokers, setOtherBrokers]: any = useState();
  const [topBroker, setTopBroker]: any = useState();

  let { token } = useSelector((state: any) => state.reducer);

  const [isLoading, setIsLoading] = useState(false);

  const [currentEstateBroker, setCurrentEstateBroker] = useState(
    "realEstateEmpoloyee"
  );
  let [presentToast] = useIonToast();

  function getTopBroker(regionName: any) {
    setTopBroker(null);
    GetMaxAddCountByRegionFunction(regionName, false).then((result: any) => {
      if (result.status == 200) {
        console.log("NEW NEW NEW==>", result);
        setTopBroker(result.data);
      }
      setIsLoading(false);
    });
  }

  function getEstateBrokerById(id: any) {
    setEstateBroker(null);
    GetUserFunction(id, token).then((result: any) => {
      if (result.status == 200) {
        console.log("aaaaaaaaaaaaaa==>", result);
        setEstateBroker(result?.data?.dateSet);
      }
      setIsLoading(false);
    });
  }

  function getOtherBrokers(regionName: any) {
    setIsLoading(true);
    setOtherBrokers(null);
    GetMaxAddCountByRegionFunction(regionName, true).then((result: any) => {
      if (result.status == 200) {
        console.log("NEW NEW OTHERS==>", result);
        setOtherBrokers(result.data);
      }
    });
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    if (id) {
      setIsLoading(true);
      getEstateBrokerById(id);
      setCurrentEstateBroker("realEstateEmpoloyeeById");
    } else {
      setIsLoading(true);
      getTopBroker(currentRegion);
      setCurrentEstateBroker("realEstateEmpoloyee");
    }
  }, [currentRegion]);

  const [presentMyAdvertisements, disMissmyAdvertisements] = useIonModal(
    MyAdvertisements,
    {
      onDismissMyAdvertisements: (data: string, role: string) =>
        disMissmyAdvertisements(data, role),
      userId:
        estateBroker?.userId ??
        estateBroker?.id ??
        topBroker?.userId ??
        topBroker?.id ??
        id,
    }
  );

  function openMyAdvertisementsModal() {
    console.log("topBrokertopBrokertopBroker", topBroker);
    console.log("estateBrokerestateBrokerestateBroker", estateBroker);
    console.log("otherBrokersotherBrokersotherBrokers", otherBrokers);

    if (
      estateBroker?.userId ||
      estateBroker?.id ||
      topBroker?.userId ||
      topBroker?.id ||
      id
    ) {
      presentMyAdvertisements({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          if (ev.detail.role === "confirm") {
          }
        },
      });
    }
  }

  const [CreateFavoriteLandPresent, CreateFavoriteLandDismiss] = useIonModal(
    CreateFavoriteLand,
    {
      CreateFavoriteLandDismiss: (data: string, role: string) =>
        CreateFavoriteLandDismiss(data, role),
      id:
        estateBroker?.userId ??
        estateBroker?.id ??
        topBroker?.userId ??
        topBroker?.id ??
        id,
    }
  );

  function openCreateFavoriteLandModal() {
    if (
      estateBroker?.userId ||
      estateBroker?.id ||
      topBroker?.userId ||
      topBroker?.id ||
      id
    ) {
      CreateFavoriteLandPresent({
        animated: true,
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          if (ev.detail.role === "confirm") {
          }
        },
      });
    }
  }

  const [run, setRun]: any = useState(true);
  const [steps] = useState([
    {
      target: ".EstateBrokerToClipboard",
      content:
        "نسخ عنوان الوسيط العقاري: بالضغط عليه يتم نسخ عنوان الوسيط العقاري للوصول اليه مره اخري بشكل اسرع.",
      placement: "right" as const,
      disableBeacon: true,
    },
    {
      target: ".profilePhoto",
      content:
        "الصوره الشخصيه للوسيط: يتم عرض الصوره الشخصيه للوسيط العقاري ان وجدت.",
    },
    {
      target: ".adsNum",
      content:
        "البيانات المختصره: يتم عرض اسم الوسيط العقاري وعدد الإعلانات الي قام بها الوسيط العقاري في المنطقة بشكل سريع اسفل صورته.",
    },
    {
      target: ".moreDetails",
      content:
        "بيانات اضافية: هنا يتم عرض بيانات اضافية للوسيط العقاري ان وجت.",
    },
    {
      target: ".getEstateAllAds",
      content:
        "مشاهدة الاعلانات: عند الضغط يتم عرض جميع اعلانات الوسيط العقاري والذهاب اليها.",
      placement: "top" as const,
    },
    {
      target: ".getEstateFavourites",
      content:
        "مشاهدة المفضلة: عند الضغط يتم عرض جميع المفضلات للوسيط العقاري والذهاب اليها.",
      placement: "top" as const,
    },
    // {
    //   target: ".topEstateBroker",
    //   content:
    //     "الوسيط العقاري الأكثر رواجًا: يتم عرض الوسيط العقاري الأكثر رواجاً اولًا في اعلي قائمة وسطاء المنطقة العقاريين.",
    // },
    // {
    //   target: ".otherEstateBroker",
    //   content:
    //     "باقي الوسطاء العقاريين: هنا يتم عرض باقي الوسطاء العقاريين في المنطقة وبالضغط علي الوسيط يمكنك الحصول علي معلومات اكثر عنه.",
    // },
  ]);

  useEffect(() => {
    let estateBrokerOpenedFirstTime = window.localStorage.getItem(
      "estateBrokerOpenedFirstTime"
    );

    if (estateBrokerOpenedFirstTime) {
      setRun(false);
    } else {
      setRun(true);
    }
  }, []);

  return (
    <>
      {isLoading ? <Spinner color={"primary"} size={"large"} /> : null}
      {!isLoading && run && !id && topBroker && (
        <Joyride
          steps={steps}
          run={run}
          continuous={run}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          showSkipButton={false}
          hideBackButton={true}
          hideCloseButton={true}
          disableScrolling={false}
          showProgress={true}
          locale={{
            back: "الرجوع",
            close: "اغلاق",
            last: "إنهاء",
            next: "التالي",
            skip: "تخطي",
          }}
          callback={(data) => {
            console.log(data);

            if (data.status == "finished" || data.action == "reset") {
              window.localStorage.setItem(
                "estateBrokerOpenedFirstTime",
                "true"
              );
              setRun(false);
            }
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}

      {topBroker || estateBroker ? (
        <>
          {currentEstateBroker === "realEstateEmpoloyee" && (
            <IonContent>
              <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
                <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                  <div className="col-span-1 py-4 px-6 flex flex-row justify-right items-right EstateBrokerToClipboard">
                    {(topBroker?.userId || topBroker?.id) && (
                      <button
                        onClick={() => {
                          setIsLoading(true);
                          Clipboard.write({
                            string: `https://thki.sa/app/estateBrokers/${
                              topBroker?.userId ?? topBroker?.id
                            }`,
                          });
                          setIsLoading(false);
                          presentToastFunction(
                            presentToast,
                            "top",
                            "success",
                            `تم نسخ رابط الوسيط بنجاح`
                          );
                        }}
                        className="flex flex-col justify-center items-center"
                      >
                        <IonIcon
                          icon={shareOutline}
                          className="text-2xl text-white font-bold"
                        />
                      </button>
                    )}
                  </div>
                  <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                    <p className="font-bold text-white text-2xl whitespace-nowrap">
                      الوسيط العقاري الأكثر رواجًا
                    </p>
                  </div>
                  <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                    <button
                      onClick={() => {
                        // if (estateBroker?.userId || estateBroker?.id) {
                        // window.location.assign(`/app/home`);
                        // } else {
                        onCloseEstateBrokersDismiss(null, "cancel");
                        // }
                      }}
                      className="flex flex-col justify-center items-center"
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-white"
                      />
                    </button>
                  </div>
                </div>
                <div className="w-min	form-container">
                  <div className="relative mt-4 animate__animated animate__lightSpeedInRight photo profilePhoto">
                    {/* <IonAvatar className="w-auto h-auto "> */}
                    <IonImg
                      alt={topBroker?.nickName ?? topBroker?.fullName}
                      src={
                        !topBroker?.profileImageName ||
                        topBroker?.profileImageName == ""
                          ? userSvg
                          : topBroker?.profileImageName
                      }
                      className={`rounded ${
                        !topBroker?.profileImageName &&
                        "bg-primaryColor100 h-60"
                      } ${topBroker?.profileImageName && "w-96 h-96"} `}
                    />
                    {/* </IonAvatar> */}
                  </div>

                  {(topBroker?.nickName ||
                    topBroker?.fullName ||
                    topBroker?.ads) && (
                    <IonItem
                      lines="none"
                      className="ion-text-center text-2xl text-primaryColor100 whitespace-pre-wrap mt-4 animate__animated animate__lightSpeedInRight adsNum"
                    >
                      {topBroker?.nickName ||
                        (topBroker?.fullName && (
                          <IonLabel>
                            {topBroker?.nickName ?? topBroker?.fullName}
                          </IonLabel>
                        ))}
                      {topBroker?.ads && (
                        <div className="bg-[#6030ff] text-white text-base px-2 py-0.5 rounded-md">
                          {topBroker?.ads} إعلان
                        </div>
                      )}
                    </IonItem>
                  )}

                  <hr className="h-px mx-auto bg-gray-500 my-4 border-0 rounded animate__animated animate__lightSpeedInRight" />

                  <IonList className="animate__animated animate__lightSpeedInLeft moreDetails">
                    {topBroker?.officePhoneNumber && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={phoneOfficeSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          رقم المكتب:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            <a
                              href={`tel:${topBroker?.officePhoneNumber}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={call} className="mr-2 text-2xl" />
                            </a>
                            <a
                              href={`https://wa.me/+966${topBroker?.officePhoneNumber?.slice(
                                1
                              )}`}
                              target="_blank"
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon
                                icon={logoWhatsapp}
                                className="mr-4 text-2xl"
                              />
                            </a>
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {topBroker?.phoneNumber && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={phoneSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          تواصل:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            <a
                              href={`tel:${topBroker?.phoneNumber}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={call} className="mr-2 text-2xl" />
                            </a>
                            <a
                              href={`https://wa.me/+966${topBroker?.phoneNumber?.slice(
                                1
                              )}`}
                              target="_blank"
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon
                                icon={logoWhatsapp}
                                className="mr-4 text-2xl"
                              />
                            </a>
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {topBroker?.address && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={landLocationSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          العنوان:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            {topBroker?.address}
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {topBroker?.email && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={mailOutline}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          الإيميل:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            {topBroker?.email}
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {topBroker?.bio && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={bioSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          الوصف:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            {topBroker?.bio}
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}
                  </IonList>

                  <div
                    className="w-full mt-4 px-4 animate__animated animate__fadeInUp getEstateAllAds"
                    dir="ltr"
                  >
                    <IonButton
                      size="large"
                      color={"primary"}
                      onClick={() => {
                        openMyAdvertisementsModal();
                        onCloseEstateBrokersDismiss(null, "cancel");
                      }}
                    >
                      <IonIcon slot="start" icon={megaphoneOutline} />
                      عرض إعلانات الوسيط العقاري
                    </IonButton>
                  </div>

                  <div
                    className="w-full mt-4 px-4 text-center animate__animated animate__fadeInUp getEstateFavourites"
                    dir="ltr"
                  >
                    <IonButton
                      size="large"
                      color={"primary"}
                      onClick={() => {
                        openCreateFavoriteLandModal();
                        onCloseEstateBrokersDismiss(null, "cancel");
                      }}
                    >
                      <IonIcon slot="start" icon={heart} />
                      عرض الوحدات المفضلة
                    </IonButton>
                  </div>
                </div>
              </div>
            </IonContent>
          )}

          {currentEstateBroker === "realEstateEmpoloyeeById" && (
            <IonContent>
              <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
                <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                  <div className="col-span-1 py-4 px-6 flex flex-row justify-right items-right intializer">
                    {(estateBroker?.userId || estateBroker?.id) && (
                      <button
                        onClick={() => {
                          setIsLoading(true);
                          Clipboard.write({
                            string: `https://thki.sa/app/estateBrokers/${
                              estateBroker?.userId ?? estateBroker?.id
                            }`,
                          });
                          setIsLoading(false);
                          presentToastFunction(
                            presentToast,
                            "top",
                            "success",
                            `تم نسخ رابط الوسيط بنجاح`
                          );
                        }}
                        className="flex flex-col justify-center items-center"
                      >
                        <IonIcon
                          icon={shareOutline}
                          className="text-2xl text-white font-bold"
                        />
                      </button>
                    )}
                  </div>
                  <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                    <p className="font-bold text-white text-2xl whitespace-nowrap">
                      بيانات الوسيط العقاري
                    </p>
                  </div>
                  <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                    <button
                      onClick={() => {
                        if (estateBroker?.userId || estateBroker?.id) {
                          window.location.assign(`/app/home`);
                        } else {
                          onCloseEstateBrokersDismiss(null, "cancel");
                        }
                      }}
                      className="flex flex-col justify-center items-center"
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-white"
                      />
                    </button>
                  </div>
                </div>
                <div className="w-min	form-container estateBrokerDetails">
                  <div
                    className={`relative mt-4 animate__animated animate__lightSpeedInRight photo`}
                  >
                    {/* <IonAvatar className="w-auto h-auto"> */}
                    <IonImg
                      alt={estateBroker?.nickName ?? estateBroker?.fullName}
                      src={
                        !estateBroker?.profileImage ||
                        estateBroker?.profileImage == ""
                          ? userSvg
                          : estateBroker?.profileImage
                      }
                      className={`rounded ${
                        !estateBroker?.profileImage && "bg-primaryColor100 h-60"
                      } ${estateBroker?.profileImage && "w-96 h-96"} `}
                    />
                    {/* </IonAvatar> */}
                  </div>

                  {(estateBroker?.nickName ||
                    estateBroker?.fullName ||
                    estateBroker?.ads) && (
                    <IonItem
                      lines="none"
                      className="ion-text-center text-2xl text-primaryColor100 whitespace-pre-wrap mt-4 animate__animated animate__lightSpeedInRight"
                    >
                      {estateBroker?.nickName ||
                        (estateBroker?.fullName && (
                          <IonLabel>
                            {estateBroker?.nickName ?? estateBroker?.fullName}
                          </IonLabel>
                        ))}

                      {estateBroker?.ads && (
                        <div className="bg-[#6030ff] text-white text-base px-2 py-0.5 rounded-md">
                          {estateBroker?.ads} إعلان اجمالًا
                        </div>
                      )}
                    </IonItem>
                  )}

                  <hr className="h-px mx-auto bg-gray-500 my-4 border-0 rounded" />

                  <IonList className="animate__animated animate__lightSpeedInLeft">
                    {estateBroker?.officePhoneNumber && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={phoneOfficeSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          رقم المكتب:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            <a
                              href={`tel:${estateBroker?.officePhoneNumber}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={call} className="mr-2 text-2xl" />
                            </a>
                            <a
                              href={`https://wa.me/+966${estateBroker?.officePhoneNumber?.slice(
                                1
                              )}`}
                              target="_blank"
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon
                                icon={logoWhatsapp}
                                className="mr-4 text-2xl"
                              />
                            </a>
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {estateBroker?.phoneNumber && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={phoneSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          رقم الهاتف:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            <a
                              href={`tel:${estateBroker?.phoneNumber}`}
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon icon={call} className="mr-2 text-2xl" />
                            </a>
                            <a
                              href={`https://wa.me/+966${estateBroker?.phoneNumber?.slice(
                                1
                              )}`}
                              target="_blank"
                              style={{ textDecorationLine: "underline" }}
                            >
                              <IonIcon
                                icon={logoWhatsapp}
                                className="mr-4 text-2xl"
                              />
                            </a>
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {estateBroker?.address && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={landLocationSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          العنوان:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            {estateBroker?.address}
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}

                    {estateBroker?.email && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={mailOutline}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          الإيميل:{" "}
                          <a
                            href={`mailto:${estateBroker?.address}`}
                            style={{ textDecorationLine: "underline" }}
                          >
                            <span className="text-primaryColor100 whitespace-pre-wrap">
                              {estateBroker?.email}
                            </span>
                          </a>
                        </IonLabel>
                      </IonItem>
                    )}

                    {estateBroker?.bio && (
                      <IonItem>
                        <IonIcon
                          color="primary"
                          aria-hidden="true"
                          icon={bioSvg}
                          slot="start"
                        ></IonIcon>
                        <IonLabel
                          color={"tertiary"}
                          className="text-indigo text-2xl"
                        >
                          الوصف:{" "}
                          <span className="text-primaryColor100 whitespace-pre-wrap">
                            {estateBroker?.bio}
                          </span>
                        </IonLabel>
                      </IonItem>
                    )}
                  </IonList>

                  <div
                    className="w-full mt-4 px-4 animate__animated animate__fadeInUp finalizer"
                    dir="ltr"
                  >
                    <IonButton
                      size="large"
                      color={"primary"}
                      onClick={() => {
                        openMyAdvertisementsModal();
                        onCloseEstateBrokersDismiss(null, "cancel");
                      }}
                    >
                      <IonIcon slot="start" icon={megaphoneOutline} />
                      عرض إعلانات الوسيط العقاري
                    </IonButton>
                  </div>
                  <div
                    className="w-full mt-4 px-4 text-center animate__animated animate__fadeInUp finalizer"
                    dir="ltr"
                  >
                    <IonButton
                      size="large"
                      color={"primary"}
                      onClick={() => {
                        openCreateFavoriteLandModal();
                        onCloseEstateBrokersDismiss(null, "cancel");
                      }}
                    >
                      <IonIcon slot="start" icon={heart} />
                      عرض الوحدات المفضلة
                    </IonButton>
                  </div>
                </div>
              </div>
            </IonContent>
          )}

          {currentEstateBroker === "realEstateEmpoloyees" && (
            <IonContent>
              <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
                <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
                  <div className="col-span-1"></div>
                  <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                    <p className="font-bold text-white text-2xl whitespace-nowrap">
                      جميع الوسطاء العقاريين بالمنطقة
                    </p>
                  </div>
                  <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                    <button
                      onClick={() => {
                        // if (estateBroker?.userId || estateBroker?.id) {
                        // window.location.assign(`/app/home`);
                        // } else {
                        onCloseEstateBrokersDismiss(null, "cancel");
                        // }
                      }}
                      className="flex flex-col justify-center items-center"
                    >
                      <IonIcon
                        icon={chevronBackOutline}
                        className="text-2xl text-white"
                      />
                    </button>
                  </div>
                </div>
                <div className="form-container">
                  <IonList className="px-4 animate__animated animate__lightSpeedInRight topEstateBroker">
                    <IonItem
                      className="topEstateBroker"
                      onClick={() => {
                        setCurrentEstateBroker("realEstateEmpoloyeeById");
                        getEstateBrokerById(
                          otherBrokers?.userId ?? otherBrokers?.id
                        );
                      }}
                    >
                      <IonAvatar slot="start">
                        <IonImg
                          src={
                            !otherBrokers?.profileImageName ||
                            otherBrokers?.profileImageName == ""
                              ? userSvg
                              : otherBrokers?.profileImageName
                          }
                          alt={otherBrokers?.nickName ?? otherBrokers?.fullName}
                          className="bg-primaryColor100"
                        ></IonImg>
                      </IonAvatar>
                      <IonLabel>
                        <h2 className="whitespace-pre-wrap text-xl">
                          الإسم:{" "}
                          <span className="text-primaryColor100 text-xl">
                            {otherBrokers?.nickName ??
                              otherBrokers?.fullName ??
                              "الوسيط العقاري الأكثر رواجًا"}
                          </span>
                        </h2>
                        <p className="text-xl">
                          عدد الإعلانات:{" "}
                          <span className="text-primaryColor100 text-xl font-medium">
                            {otherBrokers?.ads}
                          </span>{" "}
                          <span className="bg-primaryColor100 text-white rounded-md px-2 text-xl mr-4">
                            الوسيط الأكثر رواجًا
                          </span>
                        </p>
                      </IonLabel>
                    </IonItem>
                    {otherBrokers?.otherUsersInSameRegion?.length > 0
                      ? otherBrokers?.otherUsersInSameRegion
                          .sort((a: any, b: any) => b.ads - a.ads)
                          .map((item: any, index: any) => {
                            return (
                              <IonItem
                                className="otherEstateBroker"
                                key={index}
                                onClick={() => {
                                  setCurrentEstateBroker(
                                    "realEstateEmpoloyeeById"
                                  );
                                  getEstateBrokerById(item?.userId ?? item?.id);
                                }}
                              >
                                <IonAvatar slot="start">
                                  <IonImg
                                    src={
                                      !item?.profileImageName ||
                                      item?.profileImageName == ""
                                        ? userSvg
                                        : item?.profileImageName
                                    }
                                    alt={item?.nickName ?? item?.fullName}
                                    className="bg-primaryColor100"
                                    id="otherEstateBrokers"
                                  ></IonImg>
                                </IonAvatar>
                                <IonLabel>
                                  <h2 className="whitespace-pre-wrap text-xl">
                                    الإسم:{" "}
                                    <span className="text-primaryColor100 text-xl">
                                      {item?.nickName ??
                                        item?.fullName ??
                                        "الوسيط العقاري"}
                                    </span>
                                  </h2>
                                  <p className="text-xl">
                                    عدد الإعلانات:{" "}
                                    <span className="text-primaryColor100 text-xl font-medium">
                                      {item?.ads}
                                    </span>
                                  </p>
                                </IonLabel>
                              </IonItem>
                            );
                          })
                      : null}
                  </IonList>
                </div>
              </div>
            </IonContent>
          )}

          <IonFooter translucent={true}>
            <IonSegment value={currentEstateBroker}>
              <IonSegmentButton
                disabled={
                  currentEstateBroker == "realEstateEmpoloyee" ||
                  currentEstateBroker == "realEstateEmpoloyees"
                    ? true
                    : false
                }
                value="realEstateEmpoloyeeById"
                className="animate__animated animate__rubberBand"
              >
                <IonLabel
                  className="text-primaryColor100"
                  style={{ textWrap: "wrap" }}
                >
                  بيانات الوسيط العقاري
                </IonLabel>
                <IonIcon
                  icon={personOutline}
                  className="text-primaryColor100"
                ></IonIcon>
              </IonSegmentButton>

              <IonSegmentButton
                className="animate__animated animate__rubberBand"
                disabled={id ? true : false}
                value="realEstateEmpoloyee"
                onClick={() => {
                  setCurrentEstateBroker("realEstateEmpoloyee");
                  getTopBroker(currentRegion);
                }}
              >
                <IonLabel
                  className="text-primaryColor100"
                  style={{ textWrap: "wrap" }}
                >
                  الوسيط العقاري الأكثر رواجًا
                </IonLabel>
                <IonIcon icon={realEstateEmpoloyeeSvg}></IonIcon>
              </IonSegmentButton>

              <IonSegmentButton
                className="animate__animated animate__rubberBand"
                disabled={id ? true : false}
                value="realEstateEmpoloyees"
                onClick={() => {
                  getOtherBrokers(currentRegion);
                  setCurrentEstateBroker("realEstateEmpoloyees");
                }}
              >
                <IonLabel
                  className="text-primaryColor100"
                  style={{ textWrap: "wrap" }}
                >
                  جميع الوسطاء العقاريين
                </IonLabel>
                <IonIcon icon={realEstateEmpoloyeesSvg}></IonIcon>
              </IonSegmentButton>
            </IonSegment>
          </IonFooter>
        </>
      ) : (
        <IonContent>
          <div className="w-full h-full relative flex flex-col justify-start items-center h-auto">
            <div className="w-full grid grid-cols-3 bg-primaryColor100 rounded-lg">
              <div className="col-span-1"></div>
              <div className="col-span-1 py-4 flex flex-row justify-center items-center">
                <p className="font-bold text-white text-2xl whitespace-nowrap">
                  الوسطاء العقاريين
                </p>
              </div>
              <div className="col-span-1 py-4 px-6 flex flex-row justify-end items-center">
                <button
                  onClick={() => {
                    // if (estateBroker?.userId || estateBroker?.id) {
                    // window.location.assign(`/app/home`);
                    // } else {
                    onCloseEstateBrokersDismiss(null, "cancel");
                    // }
                  }}
                  className="flex flex-col justify-center items-center"
                >
                  <IonIcon
                    icon={chevronBackOutline}
                    className="text-2xl text-white"
                  />
                </button>
              </div>
            </div>
            <div className="form-container mt-4 text-center animate__animated animate__bounceInUp">
              <div className="pt-5">
                <p className="text-3xl font-bold text-redColor mt-2">
                  لا يوجد وسطاء عقاريين بهذه المنطقة
                </p>
              </div>
              <div className="pt-5 px-32">
                <img
                  src={emptySvg}
                  className="animate__animated animate__jello animate__infinite"
                  height={150}
                  width={150}
                />
              </div>
            </div>
          </div>
        </IonContent>
      )}
    </>
  );
};

export default EstateBrokersModal;
