import React from "react";
import { IonHeader, IonProgressBar, IonTitle, IonToolbar } from "@ionic/react";

interface Props {
  isFull?: Boolean;
  color: any;
  size: any;
  cssExtra?: any;
}

const Spinner: React.FC<Props> = ({
  isFull,
  color,
  cssExtra,
}: Props): JSX.Element => {

  return (
    // <div
    //   className={`${isFull ? "bg-bkColor" : ""
    //     } backdrop-blur-sm h-screen w-full flex flex-col justify-center items-center fixed top-0 bottom-0 left-0 right-0 z-50 bg-transparent`}
    // >
    //   <div
    //     className={`flex flex-col justify-center items-center ${cssExtra} ${isFull ? "" : "bg-blue-100"
    //       } p-6 rounded`}
    //   >
    // <div color="" className="backdrop-blur-sm h-screen">
    <div
      className={`${isFull ? "bg-bkColor" : ""
        } backdrop-blur-sm h-screen w-full flex flex-col fixed top-0 z-50 bg-transparent `}
    >

      <IonToolbar className="bg-transparent" >
        <IonProgressBar type="indeterminate" className="bg-transparent"  ></IonProgressBar>
      </IonToolbar>
    </div>
    // </div>
    // </div>
    // </div>
  );

};

export default Spinner;
